import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { expenseAll } from '../../redux/actions/expenseAction';
import { expenseItemFindOne, expenseItemSave, expenseItemUpdate } from '../../redux/actions/expenseItemActions';

const ExpenseItem = () => {
    const dispatch = useDispatch();
    const usersignin = useSelector((state: any) => state?.userSignin);
    const { userInfo } = usersignin;
    const userid = userInfo?.token._id;

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            expense_Category: "",
            expenseItemName: "",
            expenseCategory_Id: "",
            hsn: "",
            rate: "",
            user_id: userid,
        },
    });
    const params = useParams()
    const { id } = params;
    const navigate = useNavigate();
    const [expenseList, setExpenseList] = useState([] as any)
    const [expense_Category_Id, setExpense_Category_Id] = useState(null as any)
    const [expense_Category, setExpense_Category] = useState(null as any)
    const fetchData = () => {
        dispatch(expenseAll(userid) as any).then((res: any) => {
            setExpenseList(res.payload);
        });


    };
    const handleCancelClick = () => {
        reset()
    }
    useEffect(() => {
        if (id) {
            dispatch(expenseItemFindOne(id) as any).then((res: any) => {
                setValue("expenseItemName", res.payload.expenseItemName)
                setValue("expense_Category", res.payload.expense_Category)
                setExpense_Category_Id(res.payload.expenseCategory_Id)
                setExpense_Category(res.payload.expense_Category)
                setValue("hsn", res.payload.hsn)
                setValue("rate", res.payload.rate)
            });
        }
    }, [id]);
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmit = (data: any) => {
        const [selectedId, selectedCategory] = data.expense_Category.split(':');
        let obj = {
            expense_Category: expense_Category ? expense_Category : selectedCategory,
            expenseItemName: data.expenseItemName,
            expenseCategory_Id: expense_Category_Id ? expense_Category_Id : selectedId,
            hsn: data.hsn,
            rate: data.rate,
            user_id: userid,
        }
        if (id) {
            dispatch(expenseItemUpdate(id, obj) as any).then((res: any) => {
                if (res.type === "EXPENSE_ITEM_UPDATE_SUCCESS") {
                    fetchData();
                    reset()
                }
                toast.success("Update SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
                navigate("/app/expenseItemReport");
            });
        } else {
            dispatch(expenseItemSave(obj) as any).then((res: any) => {
                if (res.type === "EXPENSE_ITEM_POST_SUCCESS") {
                    reset()
                    fetchData()
                    toast.success("Saved Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    navigate("/app/expenseItemReport");
                }
            });
        }
    };
    return (
        <div className="mr-5 lg:mr-0">
            <form onSubmit={handleSubmit(onSubmit)} >
                <fieldset className="mx-3 rounded-lg px-2">
                    <legend className="p-4 font-bold text-[#008065]">
                        Expense Items
                    </legend>

                    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-3 mt-3">
                        <div className="flex flex-col mb-2 px-6 sm:px-0 w-full">
                            <label className="required font-bold text-[#008065] text-sm">
                                <span>Expense Category</span>
                            </label>
                            <select
                                className="border-2 border-[#008065] focus:outline-none rounded-[7px] pl-2 h-9 w-full"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor: errors.expense_Category ? "#ff0000" : "#008065",
                                }}
                                disabled={id ? true : false}
                                {...register("expense_Category", {
                                    required: "Expense Category is required",
                                })}
                            >
                                <option value="" disabled hidden>
                                    Select Category
                                </option>
                                {expenseList.map((expense: any, index: any) => (
                                    <option key={index} value={`${expense._id.$oid}:${expense.expense_Category}`}>
                                        {expense.expense_Category}
                                    </option>
                                ))}
                            </select>
                            {errors.expense_Category && (
                                <p
                                    style={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        color: "#ff0000",
                                    }}
                                    className="text-red-400 text-sm"
                                >
                                    {errors.expense_Category.message}
                                </p>
                            )}
                        </div>

                        <div className="flex flex-col mb-2 px-6 sm:px-0 w-full">
                            <label className="font-bold text-[#008065] text-sm required">
                                <span>Item Name</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Name"
                                className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor: errors.expenseItemName ? "#ff0000" : "#008065",
                                }}
                                {...register("expenseItemName", {
                                    required: "Item Name Category is required",
                                })}

                            />
                            {errors.expenseItemName && (
                                <span
                                    style={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        color: "#ff0000",
                                    }}
                                    className="text-red-400 text-sm"
                                >
                                    {errors.expenseItemName.message}
                                </span>
                            )}
                        </div>

                        {/* Adjustment Date */}
                        <div className="flex flex-col mb-2 px-6 sm:px-0 w-full">
                            <label className="font-bold text-[#008065] text-sm">
                                Item HSN
                            </label>
                            <input
                                placeholder='HSN'
                                type="text"
                                className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: errors.adjustmentDate ? "#FF0000" : "#008065",
                                }}
                                {...register("hsn", {
                                    // required: "Adjustment Date is required",
                                })}
                            />
                        </div>

                        <div className="flex flex-col mb-2 px-6 sm:px-0 w-full">
                            <label className="font-bold text-[#008065] text-sm">
                                Rate
                            </label>
                            <input
                                placeholder='Rate'
                                type="text"
                                className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: errors.adjustmentDate ? "#FF0000" : "#008065",
                                }}
                                {...register("rate", {
                                    // required: "Adjustment Date is required",
                                })}
                            />
                        </div>
                    </div>


                    {/* Submit and Cancel Buttons */}
                    <div className="rounded-xl px-3 mb-3 grid sm:justify-end">
                        <div className="flex flex-col sm:flex-row ">
                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 self-center">
                                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4 ">
                                    <button
                                        type="button"
                                        className="rounded-xl font-bold text-white bg-[#008065] text-sm h-8 w-full"
                                        style={{ fontFamily: "Poppins" }}
                                        onClick={handleCancelClick}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-1 px-2 self-center">
                                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                                    <button
                                        type="submit"
                                        className="rounded-xl font-bold text-white bg-[#008065] text-sm h-8 w-full"
                                        style={{ fontFamily: "Poppins" }}
                                    >
                                        {id ? "Update" : "Save"}

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>
        </div>
    )
}

export default ExpenseItem
