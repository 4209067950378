import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
// type SelectedRange = { range: string; startDate: string; endDate: string };

interface DashboardBarDiaProps {
	// selectedRange: SelectedRange;
	weekoutfloww: Number;
	weekinfloww: Number;
	datasetss: any;
	labels: any[];
	currencySymbol: any;
}
const DashboardBarDia: React.FC<DashboardBarDiaProps> = ({
	// selectedRange,
	weekinfloww,
	weekoutfloww,
	datasetss,
	labels,
	currencySymbol,
}) => {
	const initialBarData = {
		labels: ["January", "February", "March", "April", "May"],
		datasets: [
			{
				label: `Inflow (${currencySymbol})`,
				data: [0, 0, 0, 0, 0],
				backgroundColor: "rgba(75, 192, 192, 0.5)",
				borderColor: "rgba(75, 192, 192, 1)",
				borderWidth: 1,
			},
			{
				label: `Outflow (${currencySymbol})`,
				data: [0, 0, 0, 0, 0],
				backgroundColor: "rgba(255, 99, 132, 0.5)",
				borderColor: "rgba(255, 99, 132, 1)",
				borderWidth: 1,
			},
		],
	};
	const [bardata, setBarData] = useState(initialBarData);

	useEffect(() => {
		if (datasetss) {
			const weeklyInflow = datasetss[0]?.data || [];
			const weeklyOutflow = datasetss[1]?.data || [];

			setBarData({
				labels: labels || [],
				datasets: [
					{
						label: `Inflow (${currencySymbol})`,
						data: weeklyInflow,
						backgroundColor: "rgba(75, 192, 192, 0.5)",
						borderColor: "rgba(75, 192, 192, 1)",
						borderWidth: 1,
					},
					{
						label: `Outflow (${currencySymbol})`,
						data: weeklyOutflow,
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
					},
				],
			});
		}
	}, [currencySymbol, datasetss, labels]);

	// useEffect(() => {
	// 	if (datasetss.length === 0 && labels.length === 0) {
	// 		setBarData(initialBarData);
	// 	}
	// }, [datasetss, initialBarData, labels]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			title: {
				display: true,
				text: "Cash Inflow and Outflow",
			},
			tooltip: {
				callbacks: {
					label: function (context: any) {
						return currencySymbol + context.raw;
					},
				},
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					callback: function (value: any) {
						return currencySymbol + value;
					},
				},
			},
		},
	};
	return (
		<div className="w-full h-full">
			<Bar data={bardata} options={options} />
		</div>
	);
};

export default DashboardBarDia;
