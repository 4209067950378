import api from "../axiosConfig";
import {
	POS_GET_FAIL,
	POS_GET_REQUEST,
	POS_GET_SUCCESS,
} from "../constants/posConstants";
export const posGet = () => async (dispatch: any, getState: any) => {
	dispatch({ type: POS_GET_REQUEST });
	try {
		const {
			userSignin: {
				userInfo: { token },
			},
		} = getState();

		const { data } = await api.get(`/api/pos/Details/${token?._id}`, {
			headers: { Authorization: `Bearer ${token?.tocken}` },
		});
		return dispatch({ type: POS_GET_SUCCESS, payload: data });
	} catch (error: any) {
		dispatch({
			type: POS_GET_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
