import Axios from "axios";
import api from "../axiosConfig";
import { CONTACT_DELETE_FAIL, CONTACT_DELETE_REQUEST, CONTACT_DELETE_SUCCESS, CONTACT_FIND_ALL_FAIL, CONTACT_FIND_ALL_REQUEST, CONTACT_FIND_ALL_SUCCESS } from "../constants/ContactConstants";


export const contactPost =
   (contact: any) => async (dispatch: any, getState: any) => {
      try {
         const config = {
            headers: {
               "Content-Type": "multipart/form-data",
            },
         };
         const { data } = await api.post(
            "/api/contact/save",
            contact,
            config
         );
         return data;
      } catch (error: any) {
         return error;
      }
   };

export const contactFindAll =
   (limit: any, offset: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: CONTACT_FIND_ALL_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await Axios.get(
            `/api/contact/find-all?limit=${limit}&offset=${offset}`,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: CONTACT_FIND_ALL_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: CONTACT_FIND_ALL_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const contactDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: CONTACT_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await Axios.delete(`/api/contact/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: CONTACT_DELETE_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: CONTACT_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };
