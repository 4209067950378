import api from "../axiosConfig";
import {
	TRIALBALANCE_GET_FAIL,
	TRIALBALANCE_GET_REQUEST,
	TRIALBALANCE_GET_SUCCESS,
} from "../constants/trialBalanceConstants";

export const trialBalanceGet =
	(selectedPeriod: any, startDate: any, endDate: any) =>
	async (dispatch: any, getState: any) => {
		dispatch({ type: TRIALBALANCE_GET_REQUEST });
		try {
			const {
				userSignin: {
					userInfo: { token },
				},
			} = getState();

			const { data } = await api.get(
				`/api/trialBalance/Details/${token?._id}`,
				{
					headers: { Authorization: `Bearer ${token?.tocken}` },
					params: {
						period: selectedPeriod,
						startDate: startDate,
						endDate: endDate,
					},
				}
			);
			console.log("data====================================>", data);
			return dispatch({
				type: TRIALBALANCE_GET_SUCCESS,
				payload: data,
			});
		} catch (error: any) {
			dispatch({
				type: TRIALBALANCE_GET_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
		}
	};
