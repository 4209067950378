import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ExternalFindAll, invoiceDelete } from "../../redux/actions/externalInvoiceAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";

function ExternalInvoice() {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortOrder, setSortOrder] = useState("default");
  const [ExternalData, setExternalData] = useState([] as any);
  const externaluserdetails = useSelector((state: any) => state.externalGetAll);
  const { externalgetdata } = externaluserdetails;
  const [invoiceId, setInvoiceId] = useState(null as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [invoiceRows, setInvoiceRows] = useState([] as any);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });


  const handleDownload = (data: any) => {
    const totalAmount = data.toatalAmount;
    const toWords = (num: any) => {
      const numberToWords = require("number-to-words");
      return numberToWords.toWords(num);
    };
    const roundedtotalAmount = Math.round(totalAmount);
    const totalAmountInWords = Number.isFinite(roundedtotalAmount)
      ? toWords(roundedtotalAmount)
      : "Invalid amount";
    const totalAmountInWordsPrint = totalAmountInWords + " Only";
    // formData.totalInWords = totalAmountInWordsPrint;
    const queryParams = new URLSearchParams();
    queryParams.append("param1", "#000000");
    queryParams.append("param2", "#000000");
    queryParams.append("param3", "#000000");
    queryParams.append("param4", "#000000");
    queryParams.append("param5", totalAmountInWordsPrint);
    fetch(`/api/externalsuperadmin/generated_pdf/${data._id.$oid}?${queryParams.toString()}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link: any = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    let data = [...ExternalData];
    data = data?.filter((value: any) =>
      value?.companyName?.toLowerCase().includes(query?.toLowerCase())
    );
    setInvoiceRows(data);
  };

  const fetchData = (page: number, pageSize: number) => {
    const offset = page * pageSize;
    dispatch(ExternalFindAll(pageSize, offset) as any).then((res: any) => {
      if (res && res.type === "EXTERNAL_INVOICE_FIND_ALL_SUCCESS") {
        const clients = Array.isArray(res.payload?.clients) ? res.payload.clients : [];
        setInvoiceRows(clients);
        setExternalData(clients)
        setTotalRows(res.payload?.total_count || 0);
      }
    });
  };

  useEffect(() => {
    fetchData(paginationModel.page, paginationModel.pageSize);
  }, [paginationModel]);


  const handleSortChange = (newSortOrder: any) => {
    const sortedData = [...ExternalData].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.companyName.localeCompare(b.companyName);
      } else {
        return b.companyName.localeCompare(a.companyName);
      }
    });
    setInvoiceRows(sortedData);
    setSortOrder(newSortOrder);
  };
  const deleteId = (data: any) => {
    setShowConfirmation(true);
    setInvoiceId(data._id.$oid);
  };
  const handleConfirm = () => {
    dispatch(invoiceDelete(invoiceId) as any).then((res: any) => {
      if (res) {
        fetchData(paginationModel.page, paginationModel.pageSize);
        setInvoiceId(null);
        setShowConfirmation(false);
      }
    });
  };
  const handleCancel = () => {
    setShowConfirmation(false);
  };
  const rows = invoiceRows?.map((item: any, index: any) => ({
    id: uuidv4(),
    ...item,
    Sl_No: index + 1,
  }));

  const columns: GridColDef[] = [
    {
      field: "Sl_No",
      headerName: "S No",
      minWidth: 110,
      editable: false,
      flex: 1,
    },
    {
      field: "companyName",
      headerName: "Billed By",
      editable: false,
      minWidth: 200,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.companyName ? params?.row?.companyName : "N/A"}</>
      ),
    },
    {
      field: "clientName",
      headerName: "Shipped By",
      editable: false,
      minWidth: 200,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.clientName ? params?.row?.clientName : "N/A"}</>
      ),
    },
    {
      field: "creation_date",
      headerName: "Creation Date",
      editable: false,
      minWidth: 170,
      flex: 2,
      renderCell: (params: any) => {
        const dateString = params?.row?.creation_date.$date
        const formattedDate = dateString
          ? new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })
          : "N/A";

        return <>{formattedDate}</>;
      }
    },

    {
      field: "device",
      headerName: "Device",
      editable: false,
      minWidth: 130,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.device ? params?.row?.device : "N/A"}</>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      editable: false,
      minWidth: 130,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.country ? params?.row?.country : "N/A"}</>
      ),
    },
    {
      field: "state",
      headerName: "State",
      editable: false,
      minWidth: 130,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.state ? params?.row?.state : "N/A"}</>
      ),
    },
    {
      field: "count",
      headerName: "Count",
      editable: false,
      minWidth: 120,
      flex: 2,
      renderCell: (params: any) => (
        <div className="pl-4">
          {params?.row?.count ? params?.row?.count : "N/A"}
        </div>
        // <>{params?.row?.count ? params?.row?.count: "N/A"}</>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      editable: false,
      minWidth: 120,
      flex: 2,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleDownload(params.row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#00A787"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
          </IconButton>

          <IconButton
            aria-label="delete"
            onClick={() => {
              deleteId(params.row);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5  cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div className="">
      <div className="p-4 text-2xl font-bold text-[#045545] mx-2">
        External Invoices - {totalRows}
      </div>
      <div className="bg-[#F1F7F6]  rounded-xl px-3 py-3 mx-2">
        <div className="flex justify-between flex-col lg:flex-row md:flex-row xl:flex-row 2xl:flex-row ">
          <div className="flex items-center">
            <div className="relative flex mb-2">
              <input
                onChange={(e) => onSearch(e)}
                type="text"
                id="simple-search"
                className="w-48 bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                placeholder="Search"
                required
              />
              <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="block mb-2">
            <div className="relative flex items-center">
              <select
                defaultValue={""}
                id="sortDropdown"
                onChange={(e) => handleSortChange(e.target.value)}
                className="block  h-[2.6rem] w-48 p-2 text-sm  border border-[#00A787] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
              >
                <option value="" disabled hidden>
                  Sort By
                </option>
                <option value="asc">Product Name A to Z</option>
                <option value="desc">Product Name Z to A</option>
              </select>
            </div>
          </div>
        </div>

        <Box
          sx={{
            height: "80vh",
            width: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <DataGrid
            // autoHeight
            rows={rows}
            columns={columns}
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 500,
              // Styling goes here
            }}
            checkboxSelection
            disableRowSelectionOnClick
            paginationMode="server"
            rowCount={totalRows}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              setPaginationModel(newModel);
              // Optionally clear rows if fetching a completely new page
              if (newModel.page !== paginationModel.page) {
                setInvoiceRows([]);
              }
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Box>
      </div>
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
}

export default ExternalInvoice;
