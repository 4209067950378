import {
	CategoryScale,
	Chart as ChartJS,
	LinearScale,
	LineElement,
	PointElement,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

// type SelectedRange = { range: string; startDate: string; endDate: string };
interface DashboardLineDiaProps {
	// selectedRange: SelectedRange;
	totalInvoices: Number;
	linesets: any;
	lineLables: any[];
}

const DashboardLineDia: React.FC<DashboardLineDiaProps> = ({
	// selectedRange,
	totalInvoices,
	linesets,
	lineLables,
}) => {
	const initialGraphData = {
		labels: [],
		datasets: [
			{
				label: "Invoice Count",
				data: [],
				borderColor: "rgba(75,192,192,1)",
				backgroundColor: "rgba(75,192,192,0.2)",
				fill: true,
			},
		],
	};
	const [graphdata, setGraphData] = useState(initialGraphData);

	useEffect(() => {
		if (linesets) {
			setGraphData({
				labels: lineLables as any,
				datasets: [
					{
						label: "Invoice Count",
						data: linesets,
						borderColor: "rgba(75,192,192,1)",
						backgroundColor: "rgba(75,192,192,0.2)",
						fill: true,
					},
				],
			});
		}
	}, [linesets, lineLables]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: { display: true },
		},
		scales: {
			x: { beginAtZero: true },
			y: { beginAtZero: true },
		},
	};

	return (
		<div className="w-full h-full">
			<Line data={graphdata} options={options} />
		</div>
	);
};

export default DashboardLineDia;
