import api from "../axiosConfig";
import {
   EXPENSE_ITEM_DELETE_FAIL,
   EXPENSE_ITEM_DELETE_REQUEST,
   EXPENSE_ITEM_DELETE_SUCCESS,
   EXPENSE_ITEM_GET_FAIL,
   EXPENSE_ITEM_GET_ONE_FAIL,
   EXPENSE_ITEM_GET_ONE_REQUEST,
   EXPENSE_ITEM_GET_ONE_SUCCESS,
   EXPENSE_ITEM_GET_REQUEST,
   EXPENSE_ITEM_GET_SUCCESS,
   EXPENSE_ITEM_POST_FAIL,
   EXPENSE_ITEM_POST_REQUEST,
   EXPENSE_ITEM_POST_SUCCESS,
   EXPENSE_ITEM_UPDATE_FAIL,
   EXPENSE_ITEM_UPDATE_REQUEST,
   EXPENSE_ITEM_UPDATE_SUCCESS,
} from "../constants/expenseItemConstants";

export const expenseItemSave =
   (company: any) => async (dispatch: any, getState: any) => {
      // company.user_id = environment.user_id;
      dispatch({ type: EXPENSE_ITEM_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         company.user_id = token._id;
         const { data } = await api.post(`/api/expenseItem/save`, company, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: EXPENSE_ITEM_POST_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: EXPENSE_ITEM_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const expenseItemGetAll = () => async (dispatch: any, getState: any) => {
   dispatch({ type: EXPENSE_ITEM_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(`/api/expenseItem/getall/${token?._id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: EXPENSE_ITEM_GET_SUCCESS, payload: data });
   } catch (error: any) {
      return dispatch({
         type: EXPENSE_ITEM_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const expenseItemFindOne =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: EXPENSE_ITEM_GET_ONE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.get(`/api/expenseItem/findOne/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: EXPENSE_ITEM_GET_ONE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         return dispatch({
            type: EXPENSE_ITEM_GET_ONE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };
export const expenseItemDelete =
   (id: string) => async (dispatch: any, getState: any) => {
      dispatch({ type: EXPENSE_ITEM_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/expenseItem/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });

         return dispatch({ type: EXPENSE_ITEM_DELETE_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: EXPENSE_ITEM_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const expenseItemUpdate =
   (id: any, updatedet: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: EXPENSE_ITEM_UPDATE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         updatedet.user_id = token._id;
         const { data } = await api.put(
            `/api/expenseItem/update/${id}`,
            updatedet,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({ type: EXPENSE_ITEM_UPDATE_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: EXPENSE_ITEM_UPDATE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };
