import api from "../axiosConfig";
import { PROFITANDlOSS_GET_FAIL, PROFITANDlOSS_GET_REQUEST, PROFITANDlOSS_GET_SUCCESS } from "../constants/BalanceSheetConstants";

export const balanceSheetGet =
    (selectedPeriod: any, startDate: any, endDate:any) => async (dispatch: any, getState: any) => {
        dispatch({ type: PROFITANDlOSS_GET_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.get(
                `/api/BalanceSheet/Details/${token?._id}`,
                {
                    headers: { Authorization: `Bearer ${token?.tocken}` },
                    params: {
                        period: selectedPeriod,
                        startDate: startDate,
                        endDate: endDate,
                    },
                }
            );
            return dispatch({
                type: PROFITANDlOSS_GET_SUCCESS,
                payload: data,
            });
        } catch (error: any) {
            dispatch({
                type: PROFITANDlOSS_GET_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };