import React from 'react'
import { toWords } from "number-to-words";

const CalculateIndianFormattotal = (totalAmountInWords: any) => {
        const words = toWords(totalAmountInWords).split(" "); // Convert the number to words and split into an array
        const length = words.length;
        if (length > 2) {
          // Insert "and" before the last word if the number is greater than 100
          words.splice(length - 1, 0, "and");
        }
        return words.join(" "); // Join the words back into a string
    
}

export default CalculateIndianFormattotal
