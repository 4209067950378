import React from 'react'

const CalculateRoundOff = (roundOffValue:any, finalamount:any) => {
    const diff = roundOffValue === "Nearest" 
    ? Math.round(finalamount) - finalamount
    : roundOffValue === "Up To" 
        ? Math.ceil(finalamount) - finalamount
        : Math.floor(finalamount) - finalamount;
return parseFloat((diff).toFixed(2));
}

export default CalculateRoundOff
