import { toWords } from "number-to-words";
import NumberFormat from "../commen/NumberFormat";
import { useSelector } from "react-redux";
import CalculateIndianFormattotal from "../commen/CalculateIndianFormattotal";

const PrintPurchaseFAProductWise = ({
  Invoice,
  componentRef,
  roundOffEnable,
  amountInWords
}: any) => {
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const totalAmount = Invoice.toatalAmount;
  const roundedTotalAmount = Math.round(totalAmount);
  const totalAmountInWords = Number.isFinite(roundedTotalAmount)
    ? amountInWords === "International Format"
      ? toWords(roundedTotalAmount)+ " Only"
      : "Rupees " + CalculateIndianFormattotal(roundedTotalAmount) + " Only"
    : "Invalid amount";


  return (
    <>
      <div ref={componentRef}>
        <div style={{ breakBefore: "page" }}></div>
        {/*   image and clien information                */}
        <div className="grid grid-cols-6">
          {Invoice?.imageData ? (
            <div className="col-start-1 col-end-2">
              <div className=" font-title  sm:text-left ">
                <label
                  style={{ fontFamily: "poppins" }}
                  className="   items-start  text-blue      "
                >
                  <input type="file" accept="image/*" className="hidden" />
                  <img
                    className=" pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 "
                    src={Invoice?.imageData}
                    alt="logonew "
                  />
                </label>
              </div>
            </div>
          ) : null}
          <div className="col-start-2 col-span-4 ">
            <div className=" text-center  pt-7 text-4xl ">
              {Invoice?.companyName}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className=" text-center">
            <div className="mt-1">
              <span>
                {Invoice?.companyAddress ? (
                  <span>Address: {Invoice?.companyAddress},</span>
                ) : null}
                {Invoice?.companyCity ? (
                  <span>{Invoice?.companyCity},</span>
                ) : null}

                {Invoice?.companyState ? (
                  <span>{Invoice?.companyState}-</span>
                ) : null}
                {Invoice?.companyPostalCode ? (
                  <span>{Invoice?.companyPostalCode},</span>
                ) : null}
                {Invoice?.companygst ? (
                  <span>
                    {" "}
                    Gstn:
                    {Invoice?.companygst}
                  </span>
                ) : null}
              </span>
            </div>
            <div>
              <span>
                {Invoice?.companyPan ? (
                  <span>PAN: {Invoice?.companyPan},</span>
                ) : null}
                {Invoice?.companyEmail ? (
                  <span>
                    Email:{" "}
                    <span className="lowercase">{Invoice?.companyEmail},</span>
                  </span>
                ) : null}{" "}
                {Invoice?.companyMobile ? (
                  <span>Mobile: {Invoice?.companyMobile},</span>
                ) : null}
              </span>
            </div>
          </div>
        </div>
        <div className="border "></div>
        {/*        *******************   invoice name                */}

        <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
          <div className="justify-center flex flex-row relative ">
            <input
              readOnly
              className="w-11/12 rounded-[7px] text-center xs:w-1/4  focus:outline-none truncate"
              autoComplete="nope"
              style={{
                fontFamily: "poppins",
                fontSize: "34px",
                fontWeight: 700,
              }}
              id="input_focus"
              value={Invoice?.invoicehead}
            />
            {/* </h1> */}
          </div>
          {/*     client details           */}
          <div className=" px-8 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
            <div className="flex-1 md:mx-2">
              <div className="flex flex-col sm:flex-row pt-3  justify-between">
                <div className="">
                  <div className="flex flex-row  items-center mb-2 ">
                    <div
                      className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      style={{
                        color: "#008065",
                        fontWeight: 700,
                      }}
                    >
                      Vendor Details
                    </div>
                  </div>
                  {Invoice.vendorName ? (
                    <>
                      <div className="flex justify-between">
                        <div
                          style={{
                            marginRight: "30px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Name
                        </div>
                        <div className=" ">
                          <input
                            className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                            readOnly
                            value={Invoice.vendorName}
                            style={{
                              width: "300px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.vendorMobile ? (
                    <>
                      <div className="flex justify-between">
                        <div
                          style={{
                            marginRight: "30px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Mobile
                        </div>
                        <div className="">
                          <input
                            className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                            readOnly
                            value={Invoice.vendorMobile}
                            style={{
                              width: "300px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.vendorEmail ? (
                    <>
                      <div
                        className="flex justify-between"
                        style={{ maxWidth: "500px", width: "325px" }}
                      >
                        <div
                          style={{
                            marginRight: "37px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Email
                        </div>
                        <div className="">
                          <input
                            className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                            readOnly
                            value={Invoice.vendorEmail}
                            style={{
                              width: "300px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {Invoice.vendorgst ? (
                    <>
                      <div className="flex justify-between ">
                        <div
                          style={{
                            marginRight: "45px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          GST
                        </div>
                        <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full">
                          <input
                            className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                            readOnly
                            value={Invoice.vendorgst}
                            style={{
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="">
                  <div className="flex flex-row items-center justify-between mb-2 mr-8">
                    <div
                      style={{
                        color: "#008065",
                        fontWeight: 700,
                      }}
                      className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Purchase FA No
                    </div>

                    <div className="font-title flex-1 text-right ">
                      <input
                        readOnly
                        value={Invoice?.invoiceNo}
                        style={{
                          fontFamily: "poppins",
                        }}
                        className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full  block focus:outline-none "
                      />
                    </div>
                    <div></div>
                  </div>

                  {Invoice.purchaseFADate ? (
                    <>
                      <div className="flex flex-row items-center justify-between mb-2 mr-8">
                        <div
                          style={{
                            color: "#008065",
                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Purchase FA Date
                        </div>
                        <div className="font-title flex-1 text-right ">
                          <input
                            readOnly
                            value={Invoice.purchaseFADate}
                            style={{}}
                            className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-2 sm:ml-auto block focus:outline-none "
                          />
                        </div>
                        <div></div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.PurchaseBillNo ? (
                    <>
                      <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                        <div
                          style={{
                            color: "#008065",
                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                        >
                          Purchase Bill No
                        </div>
                        <div className="font-title flex-1 text-right">
                          <input
                            readOnly
                            value={Invoice.PurchaseBillNo}
                            style={{}}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full ml-2 sm:ml-auto  focus:outline-none "
                          ></input>
                        </div>
                        <div></div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.purchaseBillDate ? (
                    <>
                      <div className="flex flex-row items-center justify-between mb-2 mr-8">
                        <div
                          style={{
                            color: "#008065",
                            fontFamily: "poppins",

                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Purchase Bill Date
                        </div>
                        <div className="font-title flex-1 text-right ">
                          <input
                            readOnly
                            value={Invoice.purchaseBillDate}
                            style={{
                              fontFamily: "poppins",
                            }}
                            className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-2 sm:ml-auto block focus:outline-none "
                          />
                        </div>
                        <div></div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="px-8 mt-3">
            <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white bg-[#008065]">
              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" sm:w-1/4 md:w-1/6 lg:w-2/12 xl:w-1/12 2xl:w-1/12 text-left "
              >
                <span className="inline-block">S No</span>
              </div>
              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                  paddingLeft: "8px",
                }}
                className=" sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left "
              >
                <span className="inline-block">Asset</span>
              </div>
              {userdata?.hsnNo ? (
                <div
                  style={{
                    // fontFamily: "",
                    fontSize: "14px",
                    fontWeight: 700,
                    paddingLeft: "8px",
                  }}
                  className="sm:w-1/4 2xl:ml-10  border-blue-600"
                >
                  <span className="">HSN/SAC</span>
                </div>
              ) : (
                <></>
              )}

              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 2xl:text-center  2xl:pl-1  xl:text-center xl:mr-10 lg:text-center md:text-right lg:mr-6 md:mr-8  sm:text-left"
              >
                <span className="">Price</span>
              </div>

              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-left 2xl:text-left   sm:text-center"
              >
                <span className="2xl:pl-8">Qty</span>
              </div>
              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-left 2xl:text-center sm:text-center   "
              >
                Disc(%)
              </div>
              {/* {userdata?.gstValue === "IGST" ? (
                                <>
                                    <div
                                        style={{
                                            // fontFamily: "",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                        className="w-full sm:w-1/4 text-center sm:text-center"
                                    >
                                        IGST(%)
                                    </div>
                                </>
                            ) : (<>
                                <div
                                    style={{
                                        // fontFamily: "",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                    }}
                                    className={"w-full sm:w-1/4 text-center sm:text-center"}
                                >
                                    CGST(%)
                                </div>
                                <div
                                    style={{
                                        // fontFamily: "",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                    }}
                                    className={"w-full sm:w-1/4 text-center sm:text-center"}
                                >
                                    <span className="inline-block">SGST(%)</span>
                                </div>
                            </>)} */}
              <div
                style={{
                  // fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-center sm:text-center   "
              >
                Tax
              </div>
              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-right 2xl:text-right   "
              >
                Total
              </div>
            </div>
          </div>

          {Invoice?.asset?.map((product: any, index: number) => {
            return (
              <div
                key={`${index}_${product.id}`}
                className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row  xl:flex-row flex-col rounded-lg w-full  2xl:px-6 xl:px-6  md:px-7 px-4  py-1 items-center relative text-sm"
              >
                <div
                  style={{
                    fontFamily: "",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 md:w-1/12 lg:w-1/12 xl:w-1/12 2xl:w-1/12 text-right sm: text-center flex flex-row sm:block"
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    S No
                  </span>
                  <span className="inline-block w-full  product   mb-1 sm:mb-0   2xl:px-3  lg:px-3 md:px-3">
                    <input
                      readOnly
                      value={index + 1}
                      style={{
                        fontFamily: "",
                        fontWeight: 400,
                      }}
                      className="font-title  block w-full 2xl:text-left xl:ml-2  md:ml-2 xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: "",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12  lg:pl-4 xl:pl-0 text-right md:text-left xl:text-left 2xl:text-left flex flex-row sm:block"
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Product
                  </span>
                  <span className="inline-block w-full  product   mb-1 sm:mb-0   2xl:px-3  lg:px-3 md:px-3">
                    <input
                      readOnly
                      value={product.asset_name}
                      style={{
                        fontFamily: "",
                        fontWeight: 400,
                      }}
                      className="font-title  block w-full 2xl:text-left xl:ml-2  md:ml-8 lg:ml-4  xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                {userdata?.hsnNo ? (
                  <div
                    style={{
                      fontFamily: "",
                      fontWeight: 700,
                    }}
                    className=" w-full  sm:w-1/4   text-right"
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Hsn
                    </span>
                    <span className="inline-block w-full  sm:w-full mb-1 sm:mb-0 ">
                      <input
                        readOnly
                        style={{
                          fontFamily: "",
                          fontWeight: 400,
                        }}
                        value={product.hsn ? product.hsn : "N/A"}
                        className="font-title  block w-full xl:w-10   2xl:ml-8 xl:ml-2 md:ml-4  2xl:text-left  xl:text-left  lg:text-left  md:text-left    sm:text-left  sm:ml-7    text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      />
                    </span>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    fontFamily: "",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-left   flex flex-row sm:block "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Price
                  </span>
                  <span className="inline-block  w-full   mb-1 sm:mb-0 ">
                    <input
                      style={{
                        fontFamily: "",
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      readOnly
                      value={product?.rate}
                      type={"number"}
                      className="font-title  block w-full 2xl:text-center 2xl:ml-2 xl:ml-2 md:ml-3  xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>

                <div
                  style={{
                    fontFamily: "",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Qty
                  </span>
                  <span className="inline-block  w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product.qty}
                      type={"number"}
                      style={{
                        fontFamily: "",
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full 2xl:text-center    xl:text-center xl:pl-4        lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>

                <div
                  style={{
                    fontFamily: "",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Disc(%)
                  </span>
                  <span className="inline-block  w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product.discount ? product.discount : "N/A"}
                      type={""}
                      style={{
                        fontFamily: "",
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full 2xl:text-center    xl:text-center xl:pl-4        lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>

                {/* {userdata?.gstValue === "IGST" ? (
                                    <>
                                        <div
                                            style={{
                                                fontFamily: "",
                                                fontWeight: 700,
                                            }}
                                            className={"  w-full sm:w-1/4 text-right flex flex-row sm:block"}
                                        >
                                            <span
                                                style={{
                                                    marginBottom: "5px",
                                                }}
                                                className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                IGST(%)
                                            </span>
                                            <span className="inline-block  w-full mb-1 sm:mb-0">
                                                <input
                                                    readOnly
                                                    value={product.prodigst}
                                                    type={"number"}
                                                    style={{
                                                        fontFamily: "",
                                                        marginTop: "",
                                                        fontWeight: 400,
                                                    }}
                                                    className="font-title text-right block w-full 2xl:text-center xl:text-center xl:pl-4 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                                />
                                            </span>
                                        </div>
                                    </>
                                ) : (<>
                                    <div
                                        style={{
                                            fontFamily: "",
                                            fontWeight: 700,
                                        }}
                                        className={"w-full sm:w-1/4 text-right flex flex-row sm:block"}
                                    >
                                        <span
                                            style={{
                                                marginBottom: "5px",
                                            }}
                                            className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                        >
                                            CGST(%)
                                        </span>
                                        <span className="inline-block  w-full mb-1 sm:mb-0">
                                            <input
                                                readOnly
                                                value={product.prodcgst}
                                                type={"number"}
                                                style={{
                                                    fontFamily: "",
                                                    marginTop: "",
                                                    fontWeight: 400,
                                                }}
                                                className={`${"font-title text-right block w-full 2xl:text-center xl:text-center xl:pl-4 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"}`}
                                            />
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "",
                                            fontWeight: 700,
                                        }}
                                        className={"w-full sm:w-1/4 text-right flex flex-row sm:block"}
                                    >
                                        <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md">
                                            SGST(%)
                                        </span>
                                        <span className="inline-block  w-full mb-1 sm:mb-0">
                                            <input
                                                readOnly
                                                value={product.prodsgst}
                                                type={"number"}
                                                style={{
                                                    fontFamily: "",
                                                    marginTop: "",
                                                    fontWeight: 400,
                                                }}
                                                className="font-title text-right block w-full 2xl:text-center xl:text-center xl:pl-4 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            />
                                        </span>
                                    </div>
                                </>)} */}

                <div
                  style={{
                    fontFamily: "",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Tax
                  </span>
                  <span className="inline-block  w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product?.assetTax ? product?.assetTax : "N/A"}
                      style={{
                        fontFamily: "",
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full 2xl:text-center    xl:text-center xl:pl-4        lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                <>
                  <div
                    style={{
                      fontFamily: "",
                      fontWeight: 700,
                    }}
                    className=" w-full sm:w-1/4 text-right  flex flex-row sm:block"
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Total
                    </span>
                    <span className="font-block w-full sm:w-full  md:w-full text-xs  sm:text-sm lg:w-full lg:pr-4 md:pr-4  sm:pr-7  lg:pr-4  xl:pr-5 whitespace-nowrap md:text-xs">
                      <NumberFormat value={product.amount}></NumberFormat>
                    </span>
                  </div>
                </>
              </div>
            );
          })}
          {/********************************** * subtotal *******************************/}
          {Invoice?.subtotal ? (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                subtotal
              </div>

              <div
                className="font-block w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                <NumberFormat value={Invoice?.subtotal} />
              </div>
            </div>
          ) : null}

          {roundOffEnable == true && (
            <div
              className={
                "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
              }
            >
              <div
                className={
                  "w-full sm:w-1/2 px-4 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
                }
              >
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                  }
                >
                  Round Off
                </div>

                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    "font-title text-lg w-1/2 text-right sm:pr-11 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                  }
                >
                  <NumberFormat value={Invoice.roundOff} />
                </div>
              </div>
            </div>
          )}
          {Invoice?.toatalAmount ? (
            <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                Total
              </div>

              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
              >
                <NumberFormat value={Invoice?.toatalAmount} />
              </div>
            </div>
          ) : null}

          {/*************************************Amount In Words *********************/}
          <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-title w-1/2 sm:w-1/4 text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 whitespace-nowrap capitalize"
            >
              {totalAmountInWords}
            </div>
          </div>

          {/************************************* bank details *********************/}

          {Invoice?.bankNme ? (
            <>
              <div
                className="ml-5 text-#008065"
                style={{
                  color: "#008065",
                  fontFamily: "poppins",
                  fontWeight: 700,
                }}
              >
                BANK DETAILS:
              </div>
              <div className="grid grid-cols-3 ">
                <div className="ml-10">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 700,
                    }}
                  >
                    BankName
                  </div>
                </div>
                <div className="col-span-2 ...">
                  <input
                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                    readOnly
                    value={Invoice?.bankNme}
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 400,
                      width: "210px",
                      verticalAlign: "top",
                    }}
                  />
                </div>
                <div className="ml-10">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 700,
                    }}
                  >
                    Account HolderName:
                  </div>
                </div>
                <div className="col-span-2 ...">
                  <input
                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                    readOnly
                    value={Invoice?.accounHolder}
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 400,
                      width: "210px",
                      verticalAlign: "top",
                    }}
                  />
                </div>
                <div className="ml-10">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 700,
                    }}
                  >
                    Account Number:
                  </div>
                </div>
                <div className="col-span-2 ...">
                  <input
                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                    readOnly
                    value={Invoice?.accounNumber}
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 400,
                      width: "210px",
                      verticalAlign: "top",
                    }}
                  />
                </div>
                <div className="ml-10">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 700,
                    }}
                  >
                    IFSC code:
                  </div>
                </div>
                <div className="col-span-2 ...">
                  <input
                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                    readOnly
                    value={Invoice?.ifscCode}
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 400,
                      width: "210px",
                      verticalAlign: "top",
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}

          {/***********************************UPI PAY*****************************************/}

          {Invoice?.upiType ? (
            <>
              <div
                className="ml-5"
                style={{
                  color: "#008065",
                  fontFamily: "poppins",

                  fontWeight: 700,
                }}
              >
                UPI PAYMENT:
              </div>
              <div className="grid grid-cols-3 ">
                <div className="ml-10">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 700,
                    }}
                  >
                    UPI PAY
                  </div>
                </div>
                <div className="col-span-2 ...">
                  <input
                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                    readOnly
                    value={Invoice?.upiType}
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 400,
                      width: "210px",
                      verticalAlign: "top",
                    }}
                  />
                </div>
                <div className="ml-10">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 700,
                    }}
                  >
                    UPI NUMBER
                  </div>
                </div>
                <div className="col-span-2 ...">
                  <input
                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                    readOnly
                    value={Invoice?.upiNumber}
                    style={{
                      fontFamily: "poppins",

                      fontWeight: 400,
                      width: "210px",
                      verticalAlign: "top",
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}

          {/**************************  term and condition ******************/}
          {Invoice?.termsanddcon ? (
            <>
              <div
                className="flex justify-between  ml-5 mr-5 "
                style={{
                  color: "#008065",
                  fontFamily: "poppins",

                  fontWeight: 700,
                }}
              >
                <label className="  text-base text-#008065 font-semibold">
                  Terms and Conditions:
                </label>
              </div>
              <div className="mx-8 ">
                <p className="sm:text-wrap lg:text-wrap md:text-wrap text-sm break-words break-all mr-4">
                  {Invoice?.termsanddcon}
                </p>
              </div>
            </>
          ) : null}
          {/**********************************Terms*********************************/}
          {Invoice?.notes ? (
            <>
              <div
                className="flex justify-between  ml-5 mr-5 "
                style={{
                  color: "#008065",
                  fontFamily: "poppins",

                  fontWeight: 700,
                }}
              >
                <label className="  text-base text-#008065 font-semibold">
                  Notes:
                </label>
              </div>
              <div className="mx-8 ">
                <p className="sm:text-wrap lg:text-wrap md:text-wrap text-sm break-words break-all mr-4">
                  {Invoice?.notes}
                </p>
              </div>
            </>
          ) : null}
          {/********************************signature*******************************/}
          {Invoice?.signature || Invoice?.seal ? (
            <div className="flex flex-row justify-between">
              <div
                className={
                  Invoice?.signPostion === "R"
                    ? "lg:order-2 xl:order-2 2xl:order-2 md:order-2 flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 w-1/4 2xl:w-1/3 items-left pl-8 justify-center w-full h-40 rounded-lg mt-2  "
                    : "flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-left pl-8 w-full h-32  rounded-lg mt-2"
                }
              >
                {Invoice?.signature && (
                  <p className="font-semibold text-[#008065] pt-2">
                    Signature:
                  </p>
                )}
                <div className="relative">
                  {Invoice?.signature ? (
                    <>
                      <img
                        className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                        alt="sign"
                        src={
                          Invoice?.signature
                            ? Invoice?.signature
                            : Invoice?.signature
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className={
                  Invoice?.sealPostion === "L"
                    ? "lg:order-1 xl:order-1 2xl:order-1 md:order-1 flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/4 2xl:w-1/3 items-center pr-8 justify-center w-full h-40  mt-2"
                    : " flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center pr-8 justify-center w-full h-32 mt-2 "
                }
              >
                {Invoice?.seal && (
                  <p className="font-semibold text-[#008065] pt-2">Seal:</p>
                )}
                <div className="relative">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="object-cover  pb-2 flex flex-col items-center px-4    "
                  >
                    {Invoice?.seal ? (
                      <>
                        <img
                          className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                          alt="sign"
                          src={Invoice?.seal ? Invoice?.seal : Invoice?.seal}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </label>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PrintPurchaseFAProductWise;
