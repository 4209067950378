/* eslint-disable eqeqeq */
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { printPlugin } from '@react-pdf-viewer/print';
import { useCallback, useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "../pages/commen/ConfirmationPopup";
import { currencyGet } from "../redux/actions/currencyAction";
import { expenseDetailAll, expenseDetailDelete } from "../redux/actions/expensePageActions";
// import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { IoCloseSharp } from "react-icons/io5";
import { expenseAll } from "../redux/actions/expenseAction";


const ExpensePageTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [deleteid, setDeleteId] = useState([] as any);
    const [expenseDetails, setExpenseDetails] = useState([] as any);
    const [expense, setExpense] = useState([] as any);
    const [currentValue, setCurrentValue] = useState(null as any)


    const [expenseList, setExpenseList] = useState([] as any)
    const [pdfUrl, setPdfUrl] = useState("");
    const [isPopupOpen, setPopupOpen] = useState(false);
    const usersignin = useSelector((state: any) => state?.userSignin);
    const token = usersignin?.userInfo?.token;

    const fetchData = () => {
        dispatch(expenseDetailAll() as any).then((response: any) => {
            setExpenseDetails(response?.payload);
        });

    }
    const fetchDatas = () => {
        dispatch(expenseAll(token._id) as any).then((res: any) => {
            setExpenseList(res.payload);
        });
    }
    useEffect(() => {
        fetchData();
        fetchDatas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // *******************sort*********************


    const handleCreateInvoice = () => {
        navigate("/app/expensePage");
    };

    const handleChange = useCallback((e: any) => {
        const filteredProducts = expenseDetails.filter(
            (item: any) => item.expenseCategoryId === e
        );
        setExpense(filteredProducts)
        let totalAmount = 0;

        for (const item of filteredProducts) {
            const amount = parseFloat(item.toatalAmount) || 0;
            totalAmount += amount;

        }
        setCurrentValue(totalAmount)
    }, [expenseDetails, expense]);

    const handleConfirm = () => {
        dispatch(expenseDetailDelete(deleteid) as any).then((res: any) => {
            if (res) {
                const updatedExpenseDetails = expenseDetails.filter(
                    (item: any) => item._id !== deleteid
                );
                setExpenseDetails(updatedExpenseDetails);
                if (expense.length > 0) {
                    const filterCategoryId = expense[0].expenseCategoryId;
                    const filteredExpense = updatedExpenseDetails.filter(
                        (expenseItem: any) => expenseItem.expenseCategoryId === filterCategoryId
                    );
                    setExpense(filteredExpense);
                    const totalAmount = filteredExpense.reduce(
                        (total: number, expenseItem: any) => total + (parseFloat(expenseItem.toatalAmount) || 0),
                        0
                    );
                    setCurrentValue(totalAmount);
                } else {
                    setExpense(updatedExpenseDetails);
                    setCurrentValue(0);
                }
                setDeleteId(null);
                setShowConfirmation(false);
            }
        });
    };

    const handleEdit = (params: any) => {
        navigate(`/app/expensePage/${params._id}`);

    };

    const rows = expense?.map((item: any, index: any) => ({
        id: uuidv4(),
        sl_No: index + 1,
        ...item,
    }));

    const handleDelete = (params: any) => {

        setShowConfirmation(true);
        setDeleteId(params?._id);

    };



    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const [currencySymbol, setcurrencySymbol] = useState("₹");

    useEffect(() => {

        dispatch(currencyGet(token?._id) as any).then((res: any) => {
            if (res) {
                let data = res.payload.find((value: any) => value.defaultInfo === true);
                if (data?.currencySymbol) {
                    setcurrencySymbol(data.currencySymbol);
                } else {
                    setcurrencySymbol("₹");
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleView = (data: any) => {
        setPopupOpen(true)
        const queryParams = new URLSearchParams();
        queryParams.append("param1", "#000000");
        queryParams.append("param2", "#000000");
        queryParams.append("param3", "#000000");
        queryParams.append("param4", "#000000");
        queryParams.append("param5", data?.colorTemplateType)
        queryParams.append("param6", data?.selectedFont);
        queryParams.append("param7", data?.currencySymbol ? data?.currencySymbol : currencySymbol);
        queryParams.append("param8", data?.expenseType);
        queryParams.append("param9", data?.totalInWords);
        fetch(`/api/expense-view/${data._id}?${queryParams.toString()}`)
            .then((response) => {


                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                setPdfUrl(url);

            })
            .catch((error) => console.error("Error downloading PDF:", error))

    };
    const columns: GridColDef[] = [
        {
            field: "invoiceNo",
            headerName: "Expense No",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.invoiceNo ? params.row.invoiceNo : "N/A"}</>
            ),
        },
        {
            field: "purchaseReturnDate",
            headerName: "Expense Date",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.expenseDate ? params.row.expenseDate : "N/A"}</>
            ),
        },
        {
            field: "toatalAmount",
            headerName: "Expense Amount",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.toatalAmount ? params.row.toatalAmount : "N/A"}</>
            ),
        },
        {
            field: "paymentType",
            headerName: "Payment Type",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.paymentType ? params.row.paymentType : "N/A"}</>
            ),
        },
        {
            field: "unit_description",
            headerName: "View",
            flex: 1,
            minWidth: 150,
            editable: false,
            renderCell: (params: any) => (
                <>
                    <IconButton
                        onClick={() => handleView(params.row)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#008065"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                        </svg>
                    </IconButton>
                </>
            ),
        },
        {
            field: "action",
            headerName: "Actions",
            editable: false,
            width: 200,
            renderCell: (params: any) => (
                <>
                    <IconButton aria-label="edit"
                        onClick={() => handleEdit(params.row)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5 cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                        </svg>
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(params.row)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5  cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                        </svg>
                    </IconButton>
                </>
            ),
        },
    ];


    const printPluginInstance = printPlugin();
    const { PrintButton } = printPluginInstance;

    const handleClose = () => {
        setPopupOpen(false);
        setPdfUrl("")
    };
    return (
        <div>
            {isPopupOpen && (
                <>
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                        <div className="w-full  max-w-lg bg-transparent p-4 sm:p-6 lg:p-8 transform scale-[1.05] sm:scale-[1.1]">
                            <div
                                className="pdf-viewer-container rounded-lg shadow-lg overflow-hidden"
                                style={{ backgroundColor: 'white', maxWidth: '100%', maxHeight: '90vh' }}
                            >
                                {pdfUrl && (
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                        <div className="flex justify-end items-center p-4 space-x-4">
                                            {/* <zoomPluginInstance.ZoomIn />
                                            <zoomPluginInstance.ZoomOut />
                                            <zoomPluginInstance.Zoom /> */}
                                            <a href={pdfUrl} download className="btn-download">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    height="18px"
                                                    width="18px"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M4.5 12.75v6a2.25 2.25 0 002.25 2.25h10.5A2.25 2.25 0 0019.5 18.75v-6M7.5 9l4.5 4.5m0 0L16.5 9m-4.5 4.5V3"
                                                    />
                                                </svg>
                                            </a>
                                            <button
                                                className="text-blue-500 rounded"
                                                onClick={() => printPluginInstance?.print()}>
                                                <svg
                                                    fill="#008065"
                                                    strokeWidth="2"
                                                    stroke="#008065"
                                                    height="16px"
                                                    width="16px"
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <g id="SVGRepo_iconCarrier">
                                                        <g>
                                                            <g>
                                                                <path d="M488.747,245.202h-62.473V120.094c0.005-0.139,0.021-0.275,0.021-0.414c0-3.087-1.339-5.861-3.466-7.773L336.945,26.05 c-0.33-0.33-0.69-0.63-1.062-0.914c-0.083-0.063-0.164-0.125-0.249-0.186c-0.372-0.266-0.758-0.515-1.164-0.732 c-0.028-0.015-0.057-0.026-0.086-0.041c-0.383-0.201-0.784-0.372-1.193-0.525c-0.083-0.03-0.164-0.062-0.248-0.091 c-0.436-0.149-0.882-0.278-1.342-0.37c-0.018-0.004-0.037-0.005-0.055-0.008c-0.418-0.08-0.845-0.13-1.279-0.159 c-0.122-0.008-0.245-0.015-0.368-0.019c-0.116-0.004-0.229-0.018-0.346-0.018H96.157c-5.771,0-10.449,4.678-10.449,10.449V245.2 h-62.46C10.428,245.202,0,255.631,0,268.45v29.65v133.452c0,31.683,25.784,57.46,57.477,57.46h397.047 c31.693,0,57.477-25.777,57.477-57.46V298.1v-29.65C512,255.631,501.569,245.202,488.747,245.202z M340.005,58.657l50.589,50.573 h-50.589V58.657z M106.606,43.886h212.501v75.794c0,5.771,4.678,10.449,10.449,10.449h75.82v115.073h-298.77V43.886z M491.102,431.553c0,20.16-16.409,36.562-36.579,36.562H57.477c-20.17,0-36.579-16.402-36.579-36.562V308.549h470.204V431.553z M491.103,287.652H20.898V268.45c0-1.297,1.054-2.352,2.35-2.352h72.91h319.668h72.922c1.299,0,2.355,1.055,2.355,2.352V287.652z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </button>
                                            <button
                                                className="text-red-500 rounded"
                                                onClick={() => handleClose()}
                                            >
                                                <IoCloseSharp />

                                            </button>
                                        </div>

                                        <div className="h-full max-h-[75vh] overflow-hidden px-4">
                                            <Viewer fileUrl={pdfUrl}
                                                plugins={[printPluginInstance,]} />
                                        </div>
                                    </Worker>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                <div className="p-4  font-bold text-[#008065]  ml-2">Expenses - Report</div>
                <div className="p-4  font-bold text-[#008065]  ml-2">
                    <button
                        type="button"
                        className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                        style={{ backgroundColor: "#008065" }}
                        onClick={handleCreateInvoice}
                    >
                        <FiPlusCircle className="h-5 w-5" />
                        <span className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`} >Add Expense</span>
                    </button></div>


            </div>
            <div className="flex flex-col lg:flex-row xl:flex-row gap-2 pl-4  sm:px-3  sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-4">
                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                    <label
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#008065",
                        }}
                        className="text-[#008065] "
                    >
                        <span> Expense Category</span>
                    </label>
                    <select
                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "13px",
                            fontWeight: 400,
                            borderColor: "#008065",
                        }}
                        onChange={(e) => handleChange(e.target.value)}
                    >
                        <option value="" disabled selected >
                            Select Category
                        </option>
                        {expenseList?.map((item: any, i: any) => (
                            < option className="w-full" key={i} value={item._id.$oid} >
                                {item.expense_Category}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                    <label
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                        }}
                        className="text-[#008065] "
                    >
                        <span>Current Value</span>
                    </label>
                    <div className="relative">
                        <input
                            type="text"
                            className={
                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                            }
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                            }}
                            placeholder="Current Value..."
                            value={currentValue}
                            readOnly
                        />
                    </div>
                </div>


            </div>
            <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 ml-3 w-[86%] md:w-[93%] lg:w-[97%] xl:w-[96.5%] 2xl:w-[96.6%]">
                {/* <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row">
                    <div className="flex items-center">
                        <div className="relative flex mb-2">
                            <input
                                onChange={onSearch}
                                type="text"
                                id="simple-search"
                                className="w-44 bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                                placeholder="Search"
                                required
                            />
                            <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                                <svg
                                    className="w-4 h-4"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>

                </div> */}
                <Box
                    sx={{
                        height: "84vh",
                        width: "100%",
                        overflowX: "auto",
                        overflowY: "auto",
                    }}
                >
                    <DataGrid
                        // autoHeight
                        sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: 500,
                            ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                            {
                                backgroundColor: "#fff",
                                color: "#008060 !important",
                                fontWeight: 600,
                                fontSize: "14px",
                            },
                            "& .MuiDataGrid-checkboxInput": {
                                color: "#008060 !important",
                            },
                            ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                fontWeight: 600,
                                fontSize: "14px",
                            },
                            ".MuiDataGrid-cell.MuiDataGrid-cell--textRight": {
                                fontWeight: 600,
                                fontSize: "14px",
                            },
                            "@media (min-width: 2555px)": {
                                ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
                                {
                                    minWidth: "265px !important",
                                },
                            },
                        }}
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25, 50]}
                        // checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

export default ExpensePageTable;
