import api from "../axiosConfig";
import { UPIPAYMENT_DELETE_FAIL, UPIPAYMENT_DELETE_REQUEST, UPIPAYMENT_DELETE_SUCCESS, UPIPAYMENT_GET_FAIL, UPIPAYMENT_GET_ONE_FAIL, UPIPAYMENT_GET_ONE_REQUEST, UPIPAYMENT_GET_ONE_SUCCESS, UPIPAYMENT_GET_REQUEST, UPIPAYMENT_GET_SUCCESS, UPIPAYMENT_POST_FAIL, UPIPAYMENT_POST_REQUEST, UPIPAYMENT_POST_SUCCESS, UPIPAYMENT_UPDATE_FAIL, UPIPAYMENT_UPDATE_REQUEST, UPIPAYMENT_UPDATE_SUCCESS } from "../constants/upiPaymentConstants";

export const upiPaymentSave = (company: any) => async (dispatch: any, getState: any) => {
  // company.user_id = environment.user_id;
  dispatch({ type: UPIPAYMENT_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    company.user_id = token._id;
    const { data } = await api.post(
      `/api/upiPayment/save`,
      company,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: UPIPAYMENT_POST_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: UPIPAYMENT_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const upiPaymentAll = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: UPIPAYMENT_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(
      `/api/upiPayment/getall/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: UPIPAYMENT_GET_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: UPIPAYMENT_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const findUpiDetail = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: UPIPAYMENT_GET_ONE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(
      `/api/upiPayment/findOne/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: UPIPAYMENT_GET_ONE_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: UPIPAYMENT_GET_ONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const upiPaymentDelete = (id: string) => async (dispatch: any, getState: any) => {
  dispatch({ type: UPIPAYMENT_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.delete(
      `/api/upiPayment/delete/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );

    return dispatch({ type: UPIPAYMENT_DELETE_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: UPIPAYMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const upiPaymentUpdate = (id: any, updatedet: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: UPIPAYMENT_UPDATE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    updatedet.user_id = token._id;
    const { data } = await api.put(`/api/upiPayment/update/${id}`, updatedet, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: UPIPAYMENT_UPDATE_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: UPIPAYMENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

