import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const TermsTable = ({ termlist, edit, deleteterms }: any) => {
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    terms_Conditions: "",
    user_id: token?._id,
  });

  const [termlists, setTermListValue] = useState([] as any);


  const handleView = (data: any) => {
    setFormData({
      terms_Conditions: data.terms_Conditions,
      user_id: token?._id,
    });
    setShowConfirmation(!showConfirmation);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    setFormData({
      terms_Conditions: "",
      user_id: "",
    });
  };
  useEffect(() => {
    if (termlist) {
      setTermListValue(termlist);
    }
  }, [termlist]);

  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Close the modal if the user clicks outside
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handleCancel();
      }
    };

    // Close modal on ESC or Enterkey press
    const handleEscPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Enter") {
        handleCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscPress);
    };
  }, []);

  const rows = termlist
    .sort((a: any, b: any) => {
      // Ensure the default row comes first
      if (a.defaultTermsInfo && !b.defaultTermsInfo) return -1; // Default row first
      if (!a.defaultTermsInfo && b.defaultTermsInfo) return 1;  // Keep non-default rows below
      return 0;
    })
    .map((item: any, index: any) => ({
      id: uuidv4(),
      sno: index + 1,
      ...item,
    }));

  const getRowClassName = (params: any) => {
    return params.row.defaultTermsInfo ? "green-background" : "";
  };

  const columns: GridColDef[] = [
    { field: "sno", headerName: "S No", width: 150 },
    {
      field: "view",
      headerName: "Terms & Condition",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (
        <>
          <button onClick={() => handleView(params.row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-6 h-6 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
              />
            </svg>
          </button>
        </>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      editable: false,
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (
        <>
          <button onClick={() => edit(params.row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-6 h-6 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>
        </>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      editable: false,
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (

        (
          <>
            <button onClick={() => deleteterms(params.row)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#008065"
                className="w-6 h-6  cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </button>
          </>
        )
      ),
    },
  ];

  return (
    <div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row"></div>

        <div
          className="bg-[#F1F7F6] p-[8px] m-[5px]"
          style={{ borderRadius: "8px" }}
        >
          <Box
            sx={{
              height: "45vh",
              width: "100%",
              overflow: "auto",
            }}
          >
            <DataGrid
              // autoHeight
              sx={{
                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                {
                  color: "rgb(0, 128, 101)",
                  fontWeight: 600,
                  fontSize: "14px",
                },
                ".MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical.css-1b9e9gy":
                  { overflowY: "hidden" },
                ".green-background": {
                  backgroundColor: "rgb(193, 225, 193)",
                },
                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                  fontWeight: 600,
                  fontSize: "14px",
                },
                "& .MuiDataGrid-checkboxInput": {
                  color: "#008060 !important",
                },
              }}
              rows={rows}
              columns={columns}
              checkboxSelection
              getRowClassName={getRowClassName}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
      {showConfirmation && (
        <div
          className="fixed inset-0 z-[5000]"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="absolute inset-0 bg-black bg-opacity-25 backdrop-blur-sm"></div>
          <div className="fixed inset-0 z-[5001] w-screen overflow-y-auto scrollbar-thin">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <div ref={modalRef} className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-[70%] border border-[#008065]">
                <div className='p-2 bg-[#008065]'>
                  <div className='flex p-1 justify-between'>
                    <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Terms & Conditions</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={handleCancel}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                  </div>
                </div>
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <textarea
                        id="message"
                        readOnly
                        className="block p-2.5 h-96 w-full mx-1 font-bold text-sm focus:outline-none rounded"
                        placeholder="Write your thoughts here..."
                        value={formData.terms_Conditions}
                      ></textarea>
                    </p>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={handleCancel}
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsTable;
