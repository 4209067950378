import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UsersGet } from '../../redux/actions/userAction'

const AdminDashboard = () => {
    const dispatch = useDispatch()
    const [internalCount, setInternalCount] = useState()
    const fetchUser = () => {
        dispatch((UsersGet()) as any).then((res: any) => {
            setInternalCount(res?.payload?.length)
        })
    }

    useEffect(() => {
        fetchUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <div>
            <div className="p-4 text-2xl font-bold text-[#045545] mx-2">
                Dashboard
            </div>
            <div className="flex flex-wrap mx-2">
                <div className="w-full mb-3 md:w-1/2 mt-3">
                    <div className="p-4 bg-white rounded-xl md:mr-4 hover:shadow-lg ">
                        <div className='lg:flex md:flex'>
                            <div className="flex justify-between items-center">
                                <div className="h-30">
                                    <svg width="54" height="54" viewBox="0 0 39 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.2">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.242 18.61C9.15696 20.2376 8.57797 22.1499 8.57799 24.106V25.5C8.57799 26.0304 8.36727 26.5391 7.9922 26.9142C7.61713 27.2893 7.10842 27.5 6.57799 27.5C6.04755 27.5 5.53885 27.2893 5.16377 26.9142C4.7887 26.5391 4.57799 26.0304 4.57799 25.5V24.106C4.57799 21.36 5.38999 18.676 6.91399 16.39L10.242 18.61Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.182 16.5C12.598 16.5 11.12 17.292 10.242 18.61L6.91399 16.39C7.71182 15.1936 8.79259 14.2126 10.0604 13.5341C11.3283 12.8555 12.744 12.5003 14.182 12.5H14.578C15.1084 12.5 15.6171 12.7107 15.9922 13.0858C16.3673 13.4609 16.578 13.9696 16.578 14.5C16.578 15.0304 16.3673 15.5391 15.9922 15.9142C15.6171 16.2893 15.1084 16.5 14.578 16.5H14.182ZM18.606 18.61C19.69 20.236 20.27 22.15 20.27 24.106V25.5C20.27 26.0304 20.4807 26.5391 20.8558 26.9142C21.2308 27.2893 21.7396 27.5 22.27 27.5C22.8004 27.5 23.3091 27.2893 23.6842 26.9142C24.0593 26.5391 24.27 26.0304 24.27 25.5V24.106C24.2701 21.3598 23.4573 18.675 21.934 16.39L18.606 18.61Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.664 16.5C16.248 16.5 17.726 17.292 18.604 18.61L21.934 16.39C21.1361 15.1932 20.055 14.2119 18.7868 13.5333C17.5186 12.8547 16.1024 12.4998 14.664 12.5H14.27C13.7396 12.5 13.2308 12.7107 12.8558 13.0858C12.4807 13.4609 12.27 13.9696 12.27 14.5C12.27 15.0304 12.4807 15.5391 12.8558 15.9142C13.2308 16.2893 13.7396 16.5 14.27 16.5H14.664ZM5.76999 26.5C5.76999 26.2348 5.87535 25.9804 6.06288 25.7929C6.25042 25.6054 6.50477 25.5 6.76999 25.5H20.77C21.0352 25.5 21.2896 25.6054 21.4771 25.7929C21.6646 25.9804 21.77 26.2348 21.77 26.5C21.77 26.7652 21.6646 27.0196 21.4771 27.2071C21.2896 27.3946 21.0352 27.5 20.77 27.5H6.76999C6.50477 27.5 6.25042 27.3946 6.06288 27.2071C5.87535 27.0196 5.76999 26.7652 5.76999 26.5Z" fill="#008065" />
                                            <path d="M20.77 8.5C20.77 10.2239 20.0852 11.8772 18.8662 13.0962C17.6472 14.3152 15.9939 15 14.27 15C12.5461 15 10.8928 14.3152 9.67379 13.0962C8.45481 11.8772 7.76999 10.2239 7.76999 8.5C7.76999 6.77609 8.45481 5.12279 9.67379 3.90381C10.8928 2.68482 12.5461 2 14.27 2C15.9939 2 17.6472 2.68482 18.8662 3.90381C20.0852 5.12279 20.77 6.77609 20.77 8.5Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.27 11C14.933 11 15.5689 10.7366 16.0378 10.2678C16.5066 9.79893 16.77 9.16304 16.77 8.5C16.77 7.83696 16.5066 7.20107 16.0378 6.73223C15.5689 6.26339 14.933 6 14.27 6C13.6069 6 12.9711 6.26339 12.5022 6.73223C12.0334 7.20107 11.77 7.83696 11.77 8.5C11.77 9.16304 12.0334 9.79893 12.5022 10.2678C12.9711 10.7366 13.6069 11 14.27 11ZM14.27 15C15.9939 15 17.6472 14.3152 18.8662 13.0962C20.0852 11.8772 20.77 10.2239 20.77 8.5C20.77 6.77609 20.0852 5.12279 18.8662 3.90381C17.6472 2.68482 15.9939 2 14.27 2C12.5461 2 10.8928 2.68482 9.67379 3.90381C8.45481 5.12279 7.76999 6.77609 7.76999 8.5C7.76999 10.2239 8.45481 11.8772 9.67379 13.0962C10.8928 14.3152 12.5461 15 14.27 15Z" fill="#008065" />
                                            <path d="M7.76999 16.5H20.77V26.5H7.76999V16.5Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M24.742 25.11C23.657 26.7376 23.078 28.6499 23.078 30.606V32C23.078 32.5304 22.8673 33.0391 22.4922 33.4142C22.1171 33.7893 21.6084 34 21.078 34C20.5476 34 20.0388 33.7893 19.6638 33.4142C19.2887 33.0391 19.078 32.5304 19.078 32V30.606C19.078 27.86 19.89 25.176 21.414 22.89L24.742 25.11ZM28.682 23C27.1 23 25.62 23.792 24.742 25.11L21.414 22.89C22.2118 21.6936 23.2926 20.7126 24.5604 20.0341C25.8283 19.3555 27.244 19.0003 28.682 19H29.078C29.6084 19 30.1171 19.2107 30.4922 19.5858C30.8673 19.9609 31.078 20.4696 31.078 21C31.078 21.5304 30.8673 22.0391 30.4922 22.4142C30.1171 22.7893 29.6084 23 29.078 23H28.682ZM33.106 25.11C34.19 26.736 34.77 28.65 34.77 30.606V32C34.77 32.5304 34.9807 33.0391 35.3558 33.4142C35.7308 33.7893 36.2396 34 36.77 34C37.3004 34 37.8091 33.7893 38.1842 33.4142C38.5593 33.0391 38.77 32.5304 38.77 32V30.606C38.7701 27.8598 37.9573 25.175 36.434 22.89L33.106 25.11Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M29.164 23C30.748 23 32.228 23.792 33.104 25.11L36.434 22.89C35.636 21.6933 34.5549 20.7122 33.2867 20.0336C32.0185 19.355 30.6023 19 29.164 19H28.77C28.2396 19 27.7308 19.2107 27.3558 19.5858C26.9807 19.9609 26.77 20.4696 26.77 21C26.77 21.5304 26.9807 22.0391 27.3558 22.4142C27.7308 22.7893 28.2396 23 28.77 23H29.164ZM19.77 33C19.77 32.7348 19.8753 32.4804 20.0629 32.2929C20.2504 32.1054 20.5048 32 20.77 32H36.77C37.0352 32 37.2896 32.1054 37.4771 32.2929C37.6646 32.4804 37.77 32.7348 37.77 33C37.77 33.2652 37.6646 33.5196 37.4771 33.7071C37.2896 33.8946 37.0352 34 36.77 34H20.77C20.5048 34 20.2504 33.8946 20.0629 33.7071C19.8753 33.5196 19.77 33.2652 19.77 33Z" fill="#008065" />
                                            <path d="M35.27 15C35.27 16.7239 34.5852 18.3772 33.3662 19.5962C32.1472 20.8152 30.4939 21.5 28.77 21.5C27.0461 21.5 25.3928 20.8152 24.1738 19.5962C22.9548 18.3772 22.27 16.7239 22.27 15C22.27 13.2761 22.9548 11.6228 24.1738 10.4038C25.3928 9.18482 27.0461 8.5 28.77 8.5C30.4939 8.5 32.1472 9.18482 33.3662 10.4038C34.5852 11.6228 35.27 13.2761 35.27 15Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M28.77 17.5C29.433 17.5 30.0689 17.2366 30.5378 16.7678C31.0066 16.2989 31.27 15.663 31.27 15C31.27 14.337 31.0066 13.7011 30.5378 13.2322C30.0689 12.7634 29.433 12.5 28.77 12.5C28.1069 12.5 27.4711 12.7634 27.0022 13.2322C26.5334 13.7011 26.27 14.337 26.27 15C26.27 15.663 26.5334 16.2989 27.0022 16.7678C27.4711 17.2366 28.1069 17.5 28.77 17.5ZM28.77 21.5C30.4939 21.5 32.1472 20.8152 33.3662 19.5962C34.5852 18.3772 35.27 16.7239 35.27 15C35.27 13.2761 34.5852 11.6228 33.3662 10.4038C32.1472 9.18482 30.4939 8.5 28.77 8.5C27.0461 8.5 25.3928 9.18482 24.1738 10.4038C22.9548 11.6228 22.27 13.2761 22.27 15C22.27 16.7239 22.9548 18.3772 24.1738 19.5962C25.3928 20.8152 27.0461 21.5 28.77 21.5Z" fill="#008065" />
                                            <path d="M21.77 22.5H34.77V32.5H21.77V22.5Z" fill="#008065" />
                                        </g>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.71599 16.954C3.5122 18.7606 2.8699 20.8831 2.87 23.054V25C2.87 25.2652 2.76464 25.5196 2.5771 25.7071C2.38957 25.8946 2.13521 26 1.87 26C1.60478 26 1.35042 25.8946 1.16289 25.7071C0.975352 25.5196 0.869995 25.2652 0.869995 25V23.056C0.870604 20.4889 1.63124 17.9794 3.05599 15.844L3.272 15.52C3.34488 15.4107 3.43857 15.3169 3.54772 15.2439C3.65686 15.1708 3.77933 15.12 3.90813 15.0942C4.03692 15.0685 4.16952 15.0684 4.29836 15.0939C4.42719 15.1195 4.54974 15.1701 4.659 15.243C4.76825 15.3159 4.86209 15.4096 4.93514 15.5187C5.00819 15.6279 5.05903 15.7503 5.08475 15.8791C5.11047 16.0079 5.11058 16.1405 5.08505 16.2694C5.05953 16.3982 5.00888 16.5207 4.936 16.63L4.71599 16.954Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.356 13.73C8.17599 13.73 6.14199 14.818 4.93399 16.63L3.26999 15.522C4.04752 14.3558 5.10089 13.3995 6.33665 12.738C7.5724 12.0765 8.95233 11.7303 10.354 11.73H10.794C11.0592 11.73 11.3136 11.8354 11.5011 12.0229C11.6886 12.2104 11.794 12.4648 11.794 12.73C11.794 12.9952 11.6886 13.2496 11.5011 13.4371C11.3136 13.6246 11.0592 13.73 10.794 13.73H10.356ZM16.512 16.93C17.452 18.342 17.954 19.998 17.954 21.694H19.954C19.9544 19.604 19.3357 17.5607 18.176 15.822L17.976 15.522C17.9051 15.4085 17.8122 15.3104 17.7028 15.2335C17.5933 15.1565 17.4695 15.1023 17.3388 15.0741C17.208 15.0459 17.0729 15.0442 16.9414 15.0691C16.81 15.094 16.6849 15.1451 16.5735 15.2192C16.4621 15.2934 16.3668 15.3891 16.2931 15.5008C16.2194 15.6125 16.1689 15.7378 16.1445 15.8693C16.1201 16.0009 16.1224 16.136 16.1511 16.2666C16.1799 16.3973 16.2346 16.5209 16.312 16.63L16.512 16.93Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.892 13.73C13.07 13.73 15.104 14.818 16.312 16.63L17.976 15.522C17.1985 14.3558 16.1451 13.3995 14.9093 12.738C13.6736 12.0765 12.2937 11.7303 10.892 11.73H10.452C10.3207 11.73 10.1906 11.7559 10.0693 11.8061C9.94799 11.8564 9.83775 11.93 9.74489 12.0229C9.65203 12.1157 9.57837 12.226 9.52812 12.3473C9.47786 12.4686 9.452 12.5987 9.452 12.73C9.452 12.8613 9.47786 12.9914 9.52812 13.1127C9.57837 13.234 9.65203 13.3442 9.74489 13.4371C9.83775 13.53 9.94799 13.6036 10.0693 13.6539C10.1906 13.7041 10.3207 13.73 10.452 13.73H10.892Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.496 11.5C11.087 11.5 11.6721 11.3836 12.2181 11.1575C12.764 10.9313 13.2601 10.5998 13.678 10.182C14.0958 9.76412 14.4273 9.26804 14.6535 8.72208C14.8796 8.17611 14.996 7.59095 14.996 7C14.996 6.40905 14.8796 5.82389 14.6535 5.27792C14.4273 4.73196 14.0958 4.23588 13.678 3.81802C13.2601 3.40016 12.764 3.06869 12.2181 2.84254C11.6721 2.6164 11.087 2.5 10.496 2.5C9.30253 2.5 8.15794 2.97411 7.31402 3.81802C6.47011 4.66193 5.996 5.80653 5.996 7C5.996 8.19347 6.47011 9.33807 7.31402 10.182C8.15794 11.0259 9.30253 11.5 10.496 11.5ZM10.496 13.5C12.2199 13.5 13.8732 12.8152 15.0922 11.5962C16.3112 10.3772 16.996 8.72391 16.996 7C16.996 5.27609 16.3112 3.62279 15.0922 2.40381C13.8732 1.18482 12.2199 0.5 10.496 0.5C8.77209 0.5 7.11879 1.18482 5.89981 2.40381C4.68082 3.62279 3.996 5.27609 3.996 7C3.996 8.72391 4.68082 10.3772 5.89981 11.5962C7.11879 12.8152 8.77209 13.5 10.496 13.5ZM19.014 23.372C17.8091 25.179 17.1661 27.3022 17.166 29.474V31.542C17.166 31.8072 17.0606 32.0616 16.8731 32.2491C16.6856 32.4366 16.4312 32.542 16.166 32.542C15.9008 32.542 15.6464 32.4366 15.4589 32.2491C15.2714 32.0616 15.166 31.8072 15.166 31.542V29.474C15.1658 26.908 15.925 24.3993 17.348 22.264L17.614 21.864C17.7615 21.6433 17.9905 21.4903 18.2508 21.4385C18.5111 21.3868 18.7813 21.4405 19.002 21.588C19.2227 21.7355 19.3757 21.9645 19.4275 22.2248C19.4792 22.4851 19.4255 22.7553 19.278 22.976L19.014 23.372Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M24.836 20C23.7358 19.9998 22.6527 20.2714 21.6827 20.7906C20.7127 21.3098 19.886 22.0605 19.276 22.976L17.614 21.866C18.4065 20.6769 19.4804 19.7019 20.7403 19.0275C22.0001 18.353 23.407 18.0001 24.836 18H25.284C25.5492 18 25.8036 18.1054 25.9911 18.2929C26.1786 18.4804 26.284 18.7348 26.284 19C26.284 19.2652 26.1786 19.5196 25.9911 19.7071C25.8036 19.8946 25.5492 20 25.284 20H24.836ZM31.21 23.372C32.4149 25.179 33.0579 27.3022 33.058 29.474V31.542C33.058 31.8072 33.1634 32.0616 33.3509 32.2491C33.5384 32.4366 33.7928 32.542 34.058 32.542C34.3232 32.542 34.5776 32.4366 34.7651 32.2491C34.9526 32.0616 35.058 31.8072 35.058 31.542V29.474C35.0576 26.9078 34.2977 24.3991 32.874 22.264L32.608 21.864C32.4605 21.6433 32.2315 21.4903 31.9712 21.4385C31.7109 21.3868 31.4407 21.4405 31.22 21.588C30.9993 21.7355 30.8463 21.9645 30.7945 22.2248C30.7428 22.4851 30.7965 22.7553 30.944 22.976L31.21 23.372Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M25.386 20C26.4862 19.9998 27.5693 20.2714 28.5393 20.7906C29.5092 21.3098 30.336 22.0605 30.946 22.976L32.61 21.866C31.8173 20.6766 30.7431 19.7014 29.4828 19.027C28.2226 18.3526 26.8153 17.9998 25.386 18H24.936C24.6708 18 24.4164 18.1054 24.2289 18.2929C24.0413 18.4804 23.936 18.7348 23.936 19C23.936 19.2652 24.0413 19.5196 24.2289 19.7071C24.4164 19.8946 24.6708 20 24.936 20H25.386Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M24.996 18C25.587 18 26.1721 17.8836 26.7181 17.6575C27.264 17.4313 27.7601 17.0998 28.178 16.682C28.5958 16.2641 28.9273 15.768 29.1535 15.2221C29.3796 14.6761 29.496 14.0909 29.496 13.5C29.496 12.9091 29.3796 12.3239 29.1535 11.7779C28.9273 11.232 28.5958 10.7359 28.178 10.318C27.7601 9.90016 27.264 9.56869 26.7181 9.34254C26.1721 9.1164 25.587 9 24.996 9C23.8025 9 22.6579 9.47411 21.814 10.318C20.9701 11.1619 20.496 12.3065 20.496 13.5C20.496 14.6935 20.9701 15.8381 21.814 16.682C22.6579 17.5259 23.8025 18 24.996 18ZM24.996 20C26.7199 20 28.3732 19.3152 29.5922 18.0962C30.8112 16.8772 31.496 15.2239 31.496 13.5C31.496 11.7761 30.8112 10.1228 29.5922 8.90381C28.3732 7.68482 26.7199 7 24.996 7C23.2721 7 21.6188 7.68482 20.3998 8.90381C19.1808 10.1228 18.496 11.7761 18.496 13.5C18.496 15.2239 19.1808 16.8772 20.3998 18.0962C21.6188 19.3152 23.2721 20 24.996 20Z" fill="#008065" />
                                    </svg>
                                </div>

                                <div className="text-2xl mr-2">
                                    {/* <NumberFormat
                                            value={productList?.length ? productList?.length : 0}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            renderText={(value:any, props:any) => <span {...props}>{value}</span>}
                                        /> */}
                                </div>
                            </div>

                            <div className='mt-1'>
                                <div className="font-lg text-gray-600 text-sm font-bold ml-3 "> <p>External Users</p>
                                    <p className='text-xl font-bold text-navy-700 dark:text-white'>0</p>                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mb-3 md:w-1/2 mt-3">
                    <div className="p-4 bg-white rounded-xl hover:shadow-lg ">
                        <div className='lg:flex md:flex'>
                            <div className="flex justify-between items-center">
                                <div className="h-30">
                                    <svg width="54" height="54" viewBox="0 0 39 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.2">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.242 18.61C9.15696 20.2376 8.57797 22.1499 8.57799 24.106V25.5C8.57799 26.0304 8.36727 26.5391 7.9922 26.9142C7.61713 27.2893 7.10842 27.5 6.57799 27.5C6.04755 27.5 5.53885 27.2893 5.16377 26.9142C4.7887 26.5391 4.57799 26.0304 4.57799 25.5V24.106C4.57799 21.36 5.38999 18.676 6.91399 16.39L10.242 18.61Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.182 16.5C12.598 16.5 11.12 17.292 10.242 18.61L6.91399 16.39C7.71182 15.1936 8.79259 14.2126 10.0604 13.5341C11.3283 12.8555 12.744 12.5003 14.182 12.5H14.578C15.1084 12.5 15.6171 12.7107 15.9922 13.0858C16.3673 13.4609 16.578 13.9696 16.578 14.5C16.578 15.0304 16.3673 15.5391 15.9922 15.9142C15.6171 16.2893 15.1084 16.5 14.578 16.5H14.182ZM18.606 18.61C19.69 20.236 20.27 22.15 20.27 24.106V25.5C20.27 26.0304 20.4807 26.5391 20.8558 26.9142C21.2308 27.2893 21.7396 27.5 22.27 27.5C22.8004 27.5 23.3091 27.2893 23.6842 26.9142C24.0593 26.5391 24.27 26.0304 24.27 25.5V24.106C24.2701 21.3598 23.4573 18.675 21.934 16.39L18.606 18.61Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.664 16.5C16.248 16.5 17.726 17.292 18.604 18.61L21.934 16.39C21.1361 15.1932 20.055 14.2119 18.7868 13.5333C17.5186 12.8547 16.1024 12.4998 14.664 12.5H14.27C13.7396 12.5 13.2308 12.7107 12.8558 13.0858C12.4807 13.4609 12.27 13.9696 12.27 14.5C12.27 15.0304 12.4807 15.5391 12.8558 15.9142C13.2308 16.2893 13.7396 16.5 14.27 16.5H14.664ZM5.76999 26.5C5.76999 26.2348 5.87535 25.9804 6.06288 25.7929C6.25042 25.6054 6.50477 25.5 6.76999 25.5H20.77C21.0352 25.5 21.2896 25.6054 21.4771 25.7929C21.6646 25.9804 21.77 26.2348 21.77 26.5C21.77 26.7652 21.6646 27.0196 21.4771 27.2071C21.2896 27.3946 21.0352 27.5 20.77 27.5H6.76999C6.50477 27.5 6.25042 27.3946 6.06288 27.2071C5.87535 27.0196 5.76999 26.7652 5.76999 26.5Z" fill="#008065" />
                                            <path d="M20.77 8.5C20.77 10.2239 20.0852 11.8772 18.8662 13.0962C17.6472 14.3152 15.9939 15 14.27 15C12.5461 15 10.8928 14.3152 9.67379 13.0962C8.45481 11.8772 7.76999 10.2239 7.76999 8.5C7.76999 6.77609 8.45481 5.12279 9.67379 3.90381C10.8928 2.68482 12.5461 2 14.27 2C15.9939 2 17.6472 2.68482 18.8662 3.90381C20.0852 5.12279 20.77 6.77609 20.77 8.5Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.27 11C14.933 11 15.5689 10.7366 16.0378 10.2678C16.5066 9.79893 16.77 9.16304 16.77 8.5C16.77 7.83696 16.5066 7.20107 16.0378 6.73223C15.5689 6.26339 14.933 6 14.27 6C13.6069 6 12.9711 6.26339 12.5022 6.73223C12.0334 7.20107 11.77 7.83696 11.77 8.5C11.77 9.16304 12.0334 9.79893 12.5022 10.2678C12.9711 10.7366 13.6069 11 14.27 11ZM14.27 15C15.9939 15 17.6472 14.3152 18.8662 13.0962C20.0852 11.8772 20.77 10.2239 20.77 8.5C20.77 6.77609 20.0852 5.12279 18.8662 3.90381C17.6472 2.68482 15.9939 2 14.27 2C12.5461 2 10.8928 2.68482 9.67379 3.90381C8.45481 5.12279 7.76999 6.77609 7.76999 8.5C7.76999 10.2239 8.45481 11.8772 9.67379 13.0962C10.8928 14.3152 12.5461 15 14.27 15Z" fill="#008065" />
                                            <path d="M7.76999 16.5H20.77V26.5H7.76999V16.5Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M24.742 25.11C23.657 26.7376 23.078 28.6499 23.078 30.606V32C23.078 32.5304 22.8673 33.0391 22.4922 33.4142C22.1171 33.7893 21.6084 34 21.078 34C20.5476 34 20.0388 33.7893 19.6638 33.4142C19.2887 33.0391 19.078 32.5304 19.078 32V30.606C19.078 27.86 19.89 25.176 21.414 22.89L24.742 25.11ZM28.682 23C27.1 23 25.62 23.792 24.742 25.11L21.414 22.89C22.2118 21.6936 23.2926 20.7126 24.5604 20.0341C25.8283 19.3555 27.244 19.0003 28.682 19H29.078C29.6084 19 30.1171 19.2107 30.4922 19.5858C30.8673 19.9609 31.078 20.4696 31.078 21C31.078 21.5304 30.8673 22.0391 30.4922 22.4142C30.1171 22.7893 29.6084 23 29.078 23H28.682ZM33.106 25.11C34.19 26.736 34.77 28.65 34.77 30.606V32C34.77 32.5304 34.9807 33.0391 35.3558 33.4142C35.7308 33.7893 36.2396 34 36.77 34C37.3004 34 37.8091 33.7893 38.1842 33.4142C38.5593 33.0391 38.77 32.5304 38.77 32V30.606C38.7701 27.8598 37.9573 25.175 36.434 22.89L33.106 25.11Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M29.164 23C30.748 23 32.228 23.792 33.104 25.11L36.434 22.89C35.636 21.6933 34.5549 20.7122 33.2867 20.0336C32.0185 19.355 30.6023 19 29.164 19H28.77C28.2396 19 27.7308 19.2107 27.3558 19.5858C26.9807 19.9609 26.77 20.4696 26.77 21C26.77 21.5304 26.9807 22.0391 27.3558 22.4142C27.7308 22.7893 28.2396 23 28.77 23H29.164ZM19.77 33C19.77 32.7348 19.8753 32.4804 20.0629 32.2929C20.2504 32.1054 20.5048 32 20.77 32H36.77C37.0352 32 37.2896 32.1054 37.4771 32.2929C37.6646 32.4804 37.77 32.7348 37.77 33C37.77 33.2652 37.6646 33.5196 37.4771 33.7071C37.2896 33.8946 37.0352 34 36.77 34H20.77C20.5048 34 20.2504 33.8946 20.0629 33.7071C19.8753 33.5196 19.77 33.2652 19.77 33Z" fill="#008065" />
                                            <path d="M35.27 15C35.27 16.7239 34.5852 18.3772 33.3662 19.5962C32.1472 20.8152 30.4939 21.5 28.77 21.5C27.0461 21.5 25.3928 20.8152 24.1738 19.5962C22.9548 18.3772 22.27 16.7239 22.27 15C22.27 13.2761 22.9548 11.6228 24.1738 10.4038C25.3928 9.18482 27.0461 8.5 28.77 8.5C30.4939 8.5 32.1472 9.18482 33.3662 10.4038C34.5852 11.6228 35.27 13.2761 35.27 15Z" fill="#008065" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M28.77 17.5C29.433 17.5 30.0689 17.2366 30.5378 16.7678C31.0066 16.2989 31.27 15.663 31.27 15C31.27 14.337 31.0066 13.7011 30.5378 13.2322C30.0689 12.7634 29.433 12.5 28.77 12.5C28.1069 12.5 27.4711 12.7634 27.0022 13.2322C26.5334 13.7011 26.27 14.337 26.27 15C26.27 15.663 26.5334 16.2989 27.0022 16.7678C27.4711 17.2366 28.1069 17.5 28.77 17.5ZM28.77 21.5C30.4939 21.5 32.1472 20.8152 33.3662 19.5962C34.5852 18.3772 35.27 16.7239 35.27 15C35.27 13.2761 34.5852 11.6228 33.3662 10.4038C32.1472 9.18482 30.4939 8.5 28.77 8.5C27.0461 8.5 25.3928 9.18482 24.1738 10.4038C22.9548 11.6228 22.27 13.2761 22.27 15C22.27 16.7239 22.9548 18.3772 24.1738 19.5962C25.3928 20.8152 27.0461 21.5 28.77 21.5Z" fill="#008065" />
                                            <path d="M21.77 22.5H34.77V32.5H21.77V22.5Z" fill="#008065" />
                                        </g>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.71599 16.954C3.5122 18.7606 2.8699 20.8831 2.87 23.054V25C2.87 25.2652 2.76464 25.5196 2.5771 25.7071C2.38957 25.8946 2.13521 26 1.87 26C1.60478 26 1.35042 25.8946 1.16289 25.7071C0.975352 25.5196 0.869995 25.2652 0.869995 25V23.056C0.870604 20.4889 1.63124 17.9794 3.05599 15.844L3.272 15.52C3.34488 15.4107 3.43857 15.3169 3.54772 15.2439C3.65686 15.1708 3.77933 15.12 3.90813 15.0942C4.03692 15.0685 4.16952 15.0684 4.29836 15.0939C4.42719 15.1195 4.54974 15.1701 4.659 15.243C4.76825 15.3159 4.86209 15.4096 4.93514 15.5187C5.00819 15.6279 5.05903 15.7503 5.08475 15.8791C5.11047 16.0079 5.11058 16.1405 5.08505 16.2694C5.05953 16.3982 5.00888 16.5207 4.936 16.63L4.71599 16.954Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.356 13.73C8.17599 13.73 6.14199 14.818 4.93399 16.63L3.26999 15.522C4.04752 14.3558 5.10089 13.3995 6.33665 12.738C7.5724 12.0765 8.95233 11.7303 10.354 11.73H10.794C11.0592 11.73 11.3136 11.8354 11.5011 12.0229C11.6886 12.2104 11.794 12.4648 11.794 12.73C11.794 12.9952 11.6886 13.2496 11.5011 13.4371C11.3136 13.6246 11.0592 13.73 10.794 13.73H10.356ZM16.512 16.93C17.452 18.342 17.954 19.998 17.954 21.694H19.954C19.9544 19.604 19.3357 17.5607 18.176 15.822L17.976 15.522C17.9051 15.4085 17.8122 15.3104 17.7028 15.2335C17.5933 15.1565 17.4695 15.1023 17.3388 15.0741C17.208 15.0459 17.0729 15.0442 16.9414 15.0691C16.81 15.094 16.6849 15.1451 16.5735 15.2192C16.4621 15.2934 16.3668 15.3891 16.2931 15.5008C16.2194 15.6125 16.1689 15.7378 16.1445 15.8693C16.1201 16.0009 16.1224 16.136 16.1511 16.2666C16.1799 16.3973 16.2346 16.5209 16.312 16.63L16.512 16.93Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.892 13.73C13.07 13.73 15.104 14.818 16.312 16.63L17.976 15.522C17.1985 14.3558 16.1451 13.3995 14.9093 12.738C13.6736 12.0765 12.2937 11.7303 10.892 11.73H10.452C10.3207 11.73 10.1906 11.7559 10.0693 11.8061C9.94799 11.8564 9.83775 11.93 9.74489 12.0229C9.65203 12.1157 9.57837 12.226 9.52812 12.3473C9.47786 12.4686 9.452 12.5987 9.452 12.73C9.452 12.8613 9.47786 12.9914 9.52812 13.1127C9.57837 13.234 9.65203 13.3442 9.74489 13.4371C9.83775 13.53 9.94799 13.6036 10.0693 13.6539C10.1906 13.7041 10.3207 13.73 10.452 13.73H10.892Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.496 11.5C11.087 11.5 11.6721 11.3836 12.2181 11.1575C12.764 10.9313 13.2601 10.5998 13.678 10.182C14.0958 9.76412 14.4273 9.26804 14.6535 8.72208C14.8796 8.17611 14.996 7.59095 14.996 7C14.996 6.40905 14.8796 5.82389 14.6535 5.27792C14.4273 4.73196 14.0958 4.23588 13.678 3.81802C13.2601 3.40016 12.764 3.06869 12.2181 2.84254C11.6721 2.6164 11.087 2.5 10.496 2.5C9.30253 2.5 8.15794 2.97411 7.31402 3.81802C6.47011 4.66193 5.996 5.80653 5.996 7C5.996 8.19347 6.47011 9.33807 7.31402 10.182C8.15794 11.0259 9.30253 11.5 10.496 11.5ZM10.496 13.5C12.2199 13.5 13.8732 12.8152 15.0922 11.5962C16.3112 10.3772 16.996 8.72391 16.996 7C16.996 5.27609 16.3112 3.62279 15.0922 2.40381C13.8732 1.18482 12.2199 0.5 10.496 0.5C8.77209 0.5 7.11879 1.18482 5.89981 2.40381C4.68082 3.62279 3.996 5.27609 3.996 7C3.996 8.72391 4.68082 10.3772 5.89981 11.5962C7.11879 12.8152 8.77209 13.5 10.496 13.5ZM19.014 23.372C17.8091 25.179 17.1661 27.3022 17.166 29.474V31.542C17.166 31.8072 17.0606 32.0616 16.8731 32.2491C16.6856 32.4366 16.4312 32.542 16.166 32.542C15.9008 32.542 15.6464 32.4366 15.4589 32.2491C15.2714 32.0616 15.166 31.8072 15.166 31.542V29.474C15.1658 26.908 15.925 24.3993 17.348 22.264L17.614 21.864C17.7615 21.6433 17.9905 21.4903 18.2508 21.4385C18.5111 21.3868 18.7813 21.4405 19.002 21.588C19.2227 21.7355 19.3757 21.9645 19.4275 22.2248C19.4792 22.4851 19.4255 22.7553 19.278 22.976L19.014 23.372Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M24.836 20C23.7358 19.9998 22.6527 20.2714 21.6827 20.7906C20.7127 21.3098 19.886 22.0605 19.276 22.976L17.614 21.866C18.4065 20.6769 19.4804 19.7019 20.7403 19.0275C22.0001 18.353 23.407 18.0001 24.836 18H25.284C25.5492 18 25.8036 18.1054 25.9911 18.2929C26.1786 18.4804 26.284 18.7348 26.284 19C26.284 19.2652 26.1786 19.5196 25.9911 19.7071C25.8036 19.8946 25.5492 20 25.284 20H24.836ZM31.21 23.372C32.4149 25.179 33.0579 27.3022 33.058 29.474V31.542C33.058 31.8072 33.1634 32.0616 33.3509 32.2491C33.5384 32.4366 33.7928 32.542 34.058 32.542C34.3232 32.542 34.5776 32.4366 34.7651 32.2491C34.9526 32.0616 35.058 31.8072 35.058 31.542V29.474C35.0576 26.9078 34.2977 24.3991 32.874 22.264L32.608 21.864C32.4605 21.6433 32.2315 21.4903 31.9712 21.4385C31.7109 21.3868 31.4407 21.4405 31.22 21.588C30.9993 21.7355 30.8463 21.9645 30.7945 22.2248C30.7428 22.4851 30.7965 22.7553 30.944 22.976L31.21 23.372Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M25.386 20C26.4862 19.9998 27.5693 20.2714 28.5393 20.7906C29.5092 21.3098 30.336 22.0605 30.946 22.976L32.61 21.866C31.8173 20.6766 30.7431 19.7014 29.4828 19.027C28.2226 18.3526 26.8153 17.9998 25.386 18H24.936C24.6708 18 24.4164 18.1054 24.2289 18.2929C24.0413 18.4804 23.936 18.7348 23.936 19C23.936 19.2652 24.0413 19.5196 24.2289 19.7071C24.4164 19.8946 24.6708 20 24.936 20H25.386Z" fill="#008065" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M24.996 18C25.587 18 26.1721 17.8836 26.7181 17.6575C27.264 17.4313 27.7601 17.0998 28.178 16.682C28.5958 16.2641 28.9273 15.768 29.1535 15.2221C29.3796 14.6761 29.496 14.0909 29.496 13.5C29.496 12.9091 29.3796 12.3239 29.1535 11.7779C28.9273 11.232 28.5958 10.7359 28.178 10.318C27.7601 9.90016 27.264 9.56869 26.7181 9.34254C26.1721 9.1164 25.587 9 24.996 9C23.8025 9 22.6579 9.47411 21.814 10.318C20.9701 11.1619 20.496 12.3065 20.496 13.5C20.496 14.6935 20.9701 15.8381 21.814 16.682C22.6579 17.5259 23.8025 18 24.996 18ZM24.996 20C26.7199 20 28.3732 19.3152 29.5922 18.0962C30.8112 16.8772 31.496 15.2239 31.496 13.5C31.496 11.7761 30.8112 10.1228 29.5922 8.90381C28.3732 7.68482 26.7199 7 24.996 7C23.2721 7 21.6188 7.68482 20.3998 8.90381C19.1808 10.1228 18.496 11.7761 18.496 13.5C18.496 15.2239 19.1808 16.8772 20.3998 18.0962C21.6188 19.3152 23.2721 20 24.996 20Z" fill="#008065" />
                                    </svg>

                                </div>
                            </div>
                            <div className='mt-1'>
                                <div className="font-lg text-gray-600 text-sm font-bold ml-3 "> <p>Internal Users</p>
                                    <p className='text-xl font-bold text-navy-700 dark:text-white'>{internalCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard