import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";

// Register Chart.js components
ChartJS.register(
	CategoryScale,
	LinearScale,
	LineElement,
	PointElement,
	BarElement,
	ArcElement,
	Title,
	Tooltip,
	Legend
);

// type SelectedRange = { range: string; startDate: string; endDate: string };

interface DashboardPieDiaProps {
	selectedRange: String;
	oveallSAlesinFixed: Number;
	oveallunpaid: Number;
	pverallCollected: Number;
	currencySymbol: any;
}

const DashboardPieDia: React.FC<DashboardPieDiaProps> = ({
	selectedRange,
	oveallSAlesinFixed,
	oveallunpaid,
	pverallCollected,
	currencySymbol,
}) => {
	const initialPieData = {
		labels: ["No Data  available"],
		datasets: [
			{
				data: [1],
				backgroundColor: ["#d3d3d3"],
				hoverBackgroundColor: ["#d3d3d3"],
			},
		],
	};

	const [pieData, setPieData] = useState(initialPieData);

	useEffect(() => {
		if (oveallSAlesinFixed || pverallCollected || oveallunpaid) {
			setPieData({
				labels: ["Sales", "Collected", "Outstanding"],
				datasets: [
					{
						data: [
							oveallSAlesinFixed as number,
							pverallCollected as number,
							oveallunpaid as number,
						],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
					},
				],
			});
		}
	}, [oveallSAlesinFixed, pverallCollected, oveallunpaid]);
	// useEffect(() => {
	// 	if (
	// 		oveallSAlesinFixed === 0 &&
	// 		oveallunpaid === 0 &&
	// 		pverallCollected === 0
	// 	) {
	// 		setPieData(initialPieData);
	// 	}
	// }, [oveallSAlesinFixed, oveallunpaid, pverallCollected]);

	const pieOptions = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			title: {
				display: true,
				text: `Pie Chart ${selectedRange}`,
			},
			tooltip: {
				callbacks: {
					label: function (context: any) {
						return context.label + currencySymbol + context.raw.toFixed(2);
					},
				},
			},
		},
	};

	return (
		<div className=" w-full h-full">
			<Pie data={pieData} options={pieOptions} />
		</div>
	);
};

export default DashboardPieDia;
