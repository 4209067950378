import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement, // Import BarElement
	Title,
	Tooltip,
} from "chart.js";
import React, { useCallback, useEffect, useState } from "react";

import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { currencyGet } from "../../redux/actions/currencyAction";
import { stockPurchaseGet } from "../../redux/actions/stockAction";
import { registerAll } from "../../redux/actions/userAction";
import DashboardBarDia from "./DashboardBarDia";
import DashboardDonutDia from "./DashboardDonut";
import DashboardLineDia from "./DashboardLineDia";
import DashboardPieDia from "./DashboardPieDia";

ChartJS.register(
	CategoryScale,
	LinearScale,
	LineElement,
	PointElement,
	BarElement, // Register BarElement
	Title,
	Tooltip,
	Legend
);
const StyledDiv = styled("div")({
	overflowY: "auto",
	"&::-webkit-scrollbar": {
		width: "7px",
	},
	"&::-webkit-scrollbar-thumb": {
		backgroundColor: "#d6d3d1",
		borderRadius: "10px",
	},
});

interface DashboardDiagramsProps {
	selectedRange: String;
	lowStock: any[];
	opening_bank_accounts: any[];
	total_opening_amt: Number;
	totalInvoices: Number;
	weekinfloww: Number;
	weekoutfloww: Number;
	totalRevenue: Number;
	directExpenses: Number;
	oveallSAlesinFixed: Number;
	oveallunpaid: Number;
	pverallCollected: Number;
	datasetss: any;
	labels: any[];
	linesets: any;
	lineLables: any[];
}
const DashboardDiagrams: React.FC<DashboardDiagramsProps> = ({
	selectedRange,
	lowStock,
	opening_bank_accounts,
	total_opening_amt,
	totalInvoices,
	weekinfloww,
	weekoutfloww,
	totalRevenue,
	directExpenses,
	oveallSAlesinFixed,
	oveallunpaid,
	pverallCollected,
	datasetss,
	labels,
	linesets,
	lineLables,
}) => {
	const dispatch = useDispatch();
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;

	const [currencySymbol, setcurrencySymbol] = useState("₹");

	const fetchData = useCallback(() => {
		dispatch(registerAll(token?._id) as any).then((res: any) => { });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		dispatch(stockPurchaseGet(token?._id) as any);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="">
			<div className="flex flex-col xl:flex-row gap-4 mt-6 ml-2">
				<div className="flex justify-center p-1 bg-white rounded-lg items-center overflow-hidden w-full h-[300px] 2xl:h-[430px]">
					<div className="w-full h-full">
						<DashboardLineDia
							// selectedRange={selectedRange}
							totalInvoices={totalInvoices}
							linesets={linesets}
							lineLables={lineLables}
						/>
					</div>
				</div>

				<div className="flex justify-center p-1 bg-white rounded-lg items-center overflow-hidden w-full h-[300px] 2xl:h-[430px]">
					<div className="w-full h-full">
						<DashboardBarDia
							// selectedRange={selectedRange}
							weekoutfloww={weekoutfloww}
							weekinfloww={weekinfloww}
							datasetss={datasetss}
							labels={labels}
							currencySymbol={currencySymbol}
						/>
					</div>
				</div>

				<StyledDiv className="relative flex justify-center rounded-lg border border-gray-200 bg-white dark:border-gray-600 dark:bg-navy-800 dark:text-white w-full 2xl:w-[41%] xl:w-[40%] lg:w-full md:w-full  h-[300px] 2xl:h-[430px] overflow-y-auto">
					<div className="flex flex-col w-full space-y-2 p-2">
						<p className="text-md font-semibold text-green-600 text-center">
							Low Stock
						</p>
						<table className="w-full text-sm border-collapse border border-gray-300 dark:border-gray-600 table-fixed">
							<thead className="bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300">
								<tr>{/* <th></th> */}</tr>
							</thead>
							<tbody>
								{lowStock?.map((stock, index) => (
									<tr
										key={index}
										className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
											} dark:bg-gray-800 dark:text-gray-200`}
									>
										<td className="border border-gray-300 dark:border-gray-600 px-4 py-2 text-left break-words">
											{stock.productName}
										</td>
										<td className="border border-gray-300 dark:border-gray-600 px-4 py-2 text-center text-red-600 dark:text-orange-300">
											{stock.quantity}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</StyledDiv>
			</div>

			<div className="flex flex-col xl:flex-row gap-4 mt-6 ml-2">
				<div className="flex justify-center p-1 bg-white rounded-lg items-center overflow-hidden w-full h-[300px] 2xl:h-[430px]">
					<div className="w-full h-full">
						<DashboardPieDia
							selectedRange={selectedRange}
							oveallSAlesinFixed={oveallSAlesinFixed}
							oveallunpaid={oveallunpaid}
							pverallCollected={pverallCollected}
							currencySymbol={currencySymbol}
						/>
					</div>
				</div>
				<div className="flex justify-center p-1 bg-white rounded-lg items-center overflow-hidden w-full h-[300px] 2xl:h-[430px]">
					<div className="w-full h-full">
						<DashboardDonutDia
							// selectedRange={selectedRange}
							totalRevenue={totalRevenue}
							directExpenses={directExpenses}
							currencySymbol={currencySymbol}
						/>
					</div>
				</div>
				<StyledDiv className="relative flex justify-center rounded-lg border border-gray-200 bg-white dark:border-gray-600 dark:bg-navy-800 dark:text-white w-full 2xl:w-[41%] xl:w-[40%] lg:w-full md:w-full h-[300px] 2xl:h-[430px] overflow-y-auto">
					<div className="flex flex-col items-center space-y-2 p-4 min-h-60">
						<p className="text-md font-semibold text-green-600 dark:text-gray-300">
							Cash & Bank
						</p>

						<p className="text-sm font-semibold text-green-600 dark:text-gray-300">
							Opening balance
						</p>

						<h4 className="text-md text-navy-700 dark:text-white">
							{`${currencySymbol}.${total_opening_amt}`}
						</h4>

						{total_opening_amt && (
							<div className="w-full mt-4">
								{opening_bank_accounts.map((bank: any, index) => (
									<div
										key={index}
										className="flex justify-between items-center border-b py-2"
									>
										<div className="flex-1 text-black px-8">
											<ul>
												<li>
													{bank.bankname} - {bank.holder_name}
												</li>
											</ul>
										</div>

										<div className="text-right px-4 text-black">
											{bank.openingamt.toFixed(2)}
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				</StyledDiv>
			</div>
		</div>
	);
};

export default DashboardDiagrams;
