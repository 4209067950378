import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridOverlay } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ClientAll } from "../../redux/actions/clientAction";
import { FiPlusCircle } from "react-icons/fi";
import AddExistClient from "./AddExistClient";

function ClientModel({ closeModal, handleSelect }: any) {
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;
  const clientgetDetails = useSelector((state: any) => state.clientget);
  const { clientgetlist } = clientgetDetails;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [animate, setAnimate] = useState(true);
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([] as any);
  const fetchData = () => {
    dispatch(ClientAll(token?._id) as any)
      .then((res: any) => {
        if (res) {
          setClientList(res.payload);
        }
      })
      .catch((error: any) => {
        console.error("Error fetching clients:", error);
      });
  };
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    const filteredData = clientgetlist?.filter((client: any) => {
      return client?.client_name?.toLowerCase().includes(query);
    });
    setClientList(filteredData);
  };

  const [isSecondPopupOpen, setIsSecondPopupOpen] = useState(false);

  const toggleSecondPopup = () => {
    setIsSecondPopupOpen((prev: any) => !prev);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Disable scroll on body
    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scroll when modal is closed
    };
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const rows = clientList?.map((item: any, index: any) => ({
    id: uuidv4(),
    ...item,
    Sl_No: index + 1,
  }));
  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "Actions",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params) => (
        <>
          <button
            onClick={() => handleSelect(params.row)}
            className=" text-white bg-[#008060] font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
          >
            Select
          </button>
        </>
      ),
    },
    {
      field: "Sl_No",
      headerName: "Sl No",
      minWidth: 120,
      editable: false,
      flex: 2,
    },
    {
      field: "client_name",
      headerName: "Name",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.client_name ? params?.row?.client_name : "N/A"}</>
      ),
    },
    {
      field: "client_address",
      headerName: "Address",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.client_address ? params?.row?.client_address : "N/A"}</>
      ),
    },
    {
      field: "city",
      headerName: "City",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.city ? params?.row?.city : "N/A"}</>
      ),
    },
    {
      field: "state",
      headerName: "State",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.state ? params?.row?.state : "N/A"}</>
      ),
    },
    {
      field: "postal_code",
      headerName: "Postal Code",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.postal_code ? params?.row?.postal_code : "N/A"}</>
      ),
    },
    {
      field: "client_mobile",
      headerName: "Mobile",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.client_mobile ? params?.row?.client_mobile : "N/A"}</>
      ),
    },
    {
      field: "client_email",
      headerName: "Email",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.client_email ? params?.row?.client_email : "N/A"}</>
      ),
    },
    {
      field: "pan_no",
      headerName: "Pan No",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.pan_no ? params?.row?.pan_no : "N/A"}</>
      ),
    },
    {
      field: "gst",
      headerName: "Gst",
      editable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.gst ? params?.row?.gst : "N/A"}</>
      ),
    },
  ];

  const CustomNoRowsOverlay = () => (
    <GridOverlay style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',background:"#F9FAFB",height:"45px" }}>
      <div style={{ position: 'absolute', top: 0, transform: 'translateY(50%)', fontSize: '18px', textAlign: 'center' ,color:"#008065"}}>
        No Data Found
      </div>
    </GridOverlay>
  );

  return (
    <form >
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <motion.div
          className="modal-container"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "spring", damping: 18 }}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 overflow-y-auto">
            <motion.div className="relative z-20 rounded-lg  bg-[white] text-center shadow-xl transform transition-all my-8 w-11/12 mx-auto border border-[#008065] ">
          <div className='items-start justify-between p-2 border-solid border-slate-200 rounded-t bg-[#008065]'>
            <div className='flex p-1 border-solid border-slate-200 rounded-b justify-between  ' >
              <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Existing Clients</h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={closeModal}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
            </div>
          </div>
          <div
            className="bg-[#F1F7F6] p-[8px] m-[5px]"
            style={{ borderRadius: "8px" }}
          >
                <div className="flex flex-wrap justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                  <div className="flex flex-wrap items-center">
                    <div className="relative flex mb-2 mr-2 md:mr-4">
                  <input
                    onChange={(e) => onSearch(e)}
                    type="text"
                    id="simple-search"
                    className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                    placeholder="Search"
                    required
                  />
                  <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </span>
                </div>
		<button
                      type="button"
                      onClick={toggleSecondPopup}
                      className="w-44 flex items-center bg-[#008065] text-white px-4 py-2 rounded-md mb-2"
                    >
                      <FiPlusCircle className="h-5 mr-2" />
                      <span className="ml-2">Add Client</span>
                    </button>
              </div>
            </div>
            <Box
              sx={{
                    height: "60vh",
                width: "100%",
                    // overflowX: "hidden !important",
                    // overflowY: "auto",
              }}
            >
              { clientList ? 
                (
                <DataGrid
                  // autoHeight
                  sx={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 500,
                    ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                    {
                      backgroundColor: "#fff",
                      color: "#008060 !important",
                      fontWeight: 600,
                      fontSize: "14px",
                    },
                    "& .MuiDataGrid-checkboxInput": {
                      color: "#008060 !important",
                    },
                    ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                      fontWeight: 600,
                      fontSize: "14px",
                    },
                    ".css-1rtad1": {
                      display: "block !important",
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  pageSizeOptions={[5, 10, 25, 50]}
                  disableRowSelectionOnClick
                />
              ):(
                <DataGrid
                // autoHeight
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: 500,
                  ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                  {
                    backgroundColor: "#fff",
                    color: "#008060 !important",
                    fontWeight: 600,
                    fontSize: "14px",
                  },
                  "& .MuiDataGrid-checkboxInput": {
                    color: "#008060 !important",
                  },
                  ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                    fontWeight: 600,
                    fontSize: "14px",
                  },
                  ".css-1rtad1": {
                    display: "block !important",
                  },
                }}
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                disableRowSelectionOnClick
              />
              )
              }
              
            </Box>
            <div className="bg-[white] rounded px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
	    {isSecondPopupOpen && (
                  <AddExistClient
                    clientList={clientList}
                    toggleSecondPopup={toggleSecondPopup}
                    setIsSecondPopupOpen={setIsSecondPopupOpen}
                    fetchData={fetchData}
                  />
                )}
          </div>
        </motion.div>
      </div>
    </motion.div>
    </div>
    </form>
  );
}
export default ClientModel;