import api from "../axiosConfig";
import { CASHINHAND_DELETE_FAIL, CASHINHAND_DELETE_REQUEST, CASHINHAND_DELETE_SUCCESS, CASHINHAND_EDIT_FINDONE_FAIL, CASHINHAND_EDIT_FINDONE_REQUEST, CASHINHAND_EDIT_FINDONE_SUCCESS, CASHINHAND_GET_FAIL, CASHINHAND_GET_REQUEST, CASHINHAND_GET_SUCCESS, CASHINHAND_POST_FAIL, CASHINHAND_POST_REQUEST, CASHINHAND_POST_SUCCESS, CASHINHAND_UPDATE_FAIL, CASHINHAND_UPDATE_REQUEST, CASHINHAND_UPDATE_SUCCESS } from "../constants/CashInHandConstants";

export const cashInHandPost = (value: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: CASHINHAND_POST_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();
        const { data } = await api.post(`/api/cashInHand/save`, value, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: CASHINHAND_POST_SUCCESS, payload: data });
    } catch (error: any) {

        return dispatch({
            type: CASHINHAND_POST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


// export const cashInHandGet =
//     () => async (dispatch: any, getState: any) => {
//         dispatch({ type: CASHINHAND_GET_REQUEST });
//         try {
//             const {
//                 userSignin: {
//                     userInfo: { token },
//                 },
//             } = getState();
//             const { data } = await api.get(`/api/cashInHandGet/${token?._id}`);

//             return dispatch({ type: CASHINHAND_GET_SUCCESS, payload: data });
//         } catch (error: any) {
//             dispatch({
//                 type: CASHINHAND_GET_FAIL,
//                 payload:
//                     error.response && error.response.data.message
//                         ? error.response.data.message
//                         : error.message,
//             });
//         }
//     };

export const cashInHandGet = (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: CASHINHAND_GET_REQUEST });
    try {
       const {
          userSignin: {
             userInfo: { token },
          },
       } = getState();
       const { data } = await api.get(`/api/cashInHandGet/${id}`, {
          headers: { Authorization: `Bearer ${token?.tocken}` },
       });
       return dispatch({ type: CASHINHAND_GET_SUCCESS, payload: data });
    } catch (error: any) {
       dispatch({
          type: CASHINHAND_GET_FAIL,
          payload:
             error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
       });
    }
 };
 


export const cashInHandDelete = (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: CASHINHAND_DELETE_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();

        const { data } = await api.delete(`/api/cashInHandDelete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: CASHINHAND_DELETE_SUCCESS, payload: data });

    } catch (error: any) {
        dispatch({
            type:  CASHINHAND_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const cashHandEditFindOne =
    (id: any, payload?: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: CASHINHAND_EDIT_FINDONE_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.get(`/api/cashinHandEditfindone/${id}`, {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            });
            return dispatch({
                type: CASHINHAND_EDIT_FINDONE_SUCCESS,
                payload: data,
            });
        } catch (error: any) {
            dispatch({
                type: CASHINHAND_EDIT_FINDONE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const cashinHandUpdate =
    (id: any, paymentReceiveUpdatedata: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: CASHINHAND_UPDATE_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.put(
                `/api/cashinHandUpdate/put/${id}`,
                paymentReceiveUpdatedata,
                {
                    headers: { Authorization: `Bearer ${token?.tocken}` },
                },
            );
            return dispatch({ type: CASHINHAND_UPDATE_SUCCESS, payload: data });
        } catch (error: any) {
            dispatch({
                type: CASHINHAND_UPDATE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };
