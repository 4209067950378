import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { registerAll, updateUser } from "../../redux/actions/userAction";
import ColorSettingTable from "../../Tables/ColorSettingTable";
import ConfirmationPopup from "../commen/ConfirmationPopup";
import ColorScheme from "./ColorScheme";

const ColourSettingPage = () => {
  const dispatch = useDispatch();
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);
  const [Colorpicker, setColorPicker] = useState([] as any);

  const handleColorChange = (e: any) => {
    // setColorPicker(e.target.value);
  };

  const [clientList, setClientList] = useState("#000000");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = () => {
    dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
      if (res && res.type === "REGISTER_GET_SUCCESS") {
        setClientList(res?.payload?.color ? res?.payload?.color : "#000000");
      }
    });
  };

  useEffect(() => {
    if (userId?.token?._id) {
      fetchData();
    }
  }, [fetchData, userId?.token?._id]);

  const [colorEdit, setColorEdit] = useState(null as any);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let obj = {
      color: Colorpicker[0],
    };

    if (colorEdit) {
      dispatch(updateUser(userId?.token?._id, obj) as any).then((res: any) => {
        if (res) {
          fetchData();
          setColorEdit(null);
          toast.success("Update Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        }
      });
    } else {
      dispatch(updateUser(userId?.token?._id, obj) as any).then((res: any) => {
        if (res) {
          fetchData();
          toast.success("Saved Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        }
      });
    }
  };

  const [ColourEmpty, setColourEmpty] = useState(false);

  const [Colourid, setColourId] = useState();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleConfirm = () => {
    let obj = {
      color: "",
    };
    dispatch(updateUser(userId?.token?._id, obj) as any).then((res: any) => {
      if (res) {
        fetchData();
        // setColourEmpty(!ColourEmpty)
        setShowConfirmation(false);
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const deleteId = (data: any) => {
    setShowConfirmation(true);
    setColourId(data);
  };

  const resetProduct = () => {
    setColourEmpty(!ColourEmpty);
    setColorEdit(null)
  };

  const handleEdit = (value: any) => {
    setColorEdit(value);
  };

  const disabledCancel = clientList?.length > 0 && !colorEdit;
  const disabledSubmit = clientList?.length > 0 && !colorEdit;

  return (
    <>
      <form>
        <div>
          <fieldset className="mx-2 mb-4 rounded-lg p-3">
            <legend className="p-4  font-bold text-[#045545]  text-lg   ml-2">
              Colour Settings
            </legend>
            <div className="text-[#008065] font-semibold mb-4">
              <label>Default Colour</label>
            </div>
            <div className="flex justify-left ml-8 mb-6">
              <ColorScheme
                clientList={clientList}
                handleColorChange={handleColorChange}
                setColorPicker={setColorPicker}
                // ColourEmpty={ColourEmpty}
              />
            </div>
            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                <div className="flex flex-col mb-6 "></div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col  ">
                  <div className="relative"></div>
                </div>
              </div>

              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded">
                  <button
                    type="button"
                    onClick={resetProduct}
                    className={`rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] ${disabledCancel ? `opacity-50  cursor-not-allowed disabled` : ""}`
                    }
                    style={{
                      backgroundColor: "#008065",
                      fontSize: "14px",
                      fontFamily: "poppins",
                    }}
                    disabled={disabledCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 ">
                  <button
                    type="submit"
                    className={`rounded-xl font-bold text-white  text-sm h-8  w-full block  bg-[#008065] ${disabledSubmit ? `opacity-50  cursor-not-allowed disabled` : ""} `}
                    style={{
                      backgroundColor: "#008065",
                      fontSize: "14px",
                      fontFamily: "poppins",
                    }}
                    disabled={disabledSubmit}
                    onClick={(e: any) => handleSubmit(e)}
                  >
                    {colorEdit ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </form>
      <ColorSettingTable
        clientList={clientList}
        deleteId={deleteId}
        edit={handleEdit}
      />
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ColourSettingPage;
