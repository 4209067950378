import api from "../axiosConfig";
import {
	DASHBOARD_GET_FAIL,
	DASHBOARD_GET_REQUEST,
	DASHBOARD_GET_SUCCESS,
	DASHBOARDLINE_GET_FAIL,
	DASHBOARDLINE_GET_REQUEST,
	DASHBOARDLINE_GET_SUCCESS,
} from "../constants/dashBoardConstants";

export const dashBoardGet =
	(selectedPeriod: any, startDate: any, endDate: any) =>
	async (dispatch: any, getState: any) => {
		dispatch({ type: DASHBOARD_GET_REQUEST });
		try {
			const {
				userSignin: {
					userInfo: { token },
				},
			} = getState();

			const { data } = await api.get(`/api/dashBoard/Details/${token?._id}`, {
				headers: { Authorization: `Bearer ${token?.tocken}` },
				params: {
					period: selectedPeriod,
					startDate: startDate,
					endDate: endDate,
				},
			});

			return dispatch({
				type: DASHBOARD_GET_SUCCESS,
				payload: data,
			});
		} catch (error: any) {
			dispatch({
				type: DASHBOARD_GET_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
		}
	};

export const dashBoardGetLine =
	(selectedPeriod: any, startDate: any, endDate: any) =>
	async (dispatch: any, getState: any) => {
		dispatch({ type: DASHBOARDLINE_GET_REQUEST });
		try {
			const {
				userSignin: {
					userInfo: { token },
				},
			} = getState();

			const { data } = await api.get(
				`/api/dashBoardLine/Details/${token?._id}`,
				{
					headers: { Authorization: `Bearer ${token?.tocken}` },
					params: {
						period: selectedPeriod,
						startDate: startDate,
						endDate: endDate,
					},
				}
			);

			return dispatch({
				type: DASHBOARDLINE_GET_SUCCESS,
				payload: data,
			});
		} catch (error: any) {
			dispatch({
				type: DASHBOARDLINE_GET_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
		}
	};
