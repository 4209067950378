import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paymentOutBillList, paymentOutEditFindOne, paymentOutPost, paymentOutUpdate } from '../../redux/actions/paymentOutAction';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from "react-toastify";
import { useData } from '../../layouts/shared/DataProvider';
import { fontFamilyGet } from '../../redux/actions/FontFamilyAction';
import { currencyGet } from '../../redux/actions/currencyAction';
import { InternalGetCount } from '../../redux/actions/InternalInvoiceAction';

const PaymentOutPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const usersignin = useSelector((state: any) => state.userSignin);
  const { userInfo } = usersignin;
  const userid = userInfo.token._id;
  const { selectedFont, setSelectedFont }: any = useData();
  const [paymentOutReceiptNo, setPaymentOutReceiptNo] = useState("");
  const [selectedOption, setselectedOption] = useState("" as any);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      vendorName: "",
      invoiceNO: "",
      balanceAmount: "",
      receivedAmount: "",
      paymentType: "",
      chequeNum: "",
      user_id: userid,
    },
  });

  const params = useParams();
  const { id } = params;


  const [pendingbillOUtBillList, setpendingbillOutBillList] = useState([] as any)
  const [currencySymbol, setcurrencySymbol] = useState("₹");
 


  const fetchData = () => {
    dispatch(paymentOutBillList() as any).then((res: any) => {
      if (res) {
        setpendingbillOutBillList(res.payload)
      }
    });
    dispatch(fontFamilyGet(userid?._id) as any).then((res: any) => {
      if (res?.type === "FONTFAMILY_GET_SUCCESS") {
        let data = res?.payload?.find(
          (value: any) => value?.defaultInfo === true,
        );
        if (data) {
          setSelectedFont(data?.fontfamily);
        } else {
          setSelectedFont("poppins");
        }
      }
    });
    dispatch(currencyGet(userid?._id) as any).then((res: any) => {
      if (res) {
        let data = res.payload.find((value: any) => value.defaultInfo === true);
        if (data?.currencySymbol) {
          setcurrencySymbol(data.currencySymbol);
        } else {
          setcurrencySymbol("₹");
        }
      }
    });
    dispatch(InternalGetCount() as any).then((data: any) => {
      setPaymentOutReceiptNo(data?.payload?.paymentOutReceiptCount)
    });
  };

  const handlePaymentType = (value: any) => {
    setselectedOption(value);
    setValue('chequeNum', "")
  };


  const [purchaseNo, setPurchaseNo] = useState([] as any)
  const [vendorName, setvendorName] = useState(null as any);

  const fetchDatas = () => {
    if (id) {
      dispatch(InternalGetCount() as any).then((data: any) => {
        setPaymentOutReceiptNo(data?.payload?.paymentOutReceiptCount)
      });
      dispatch(paymentOutEditFindOne(id) as any).then((res: any) => {
        if (res?.type === "PAYMENTOUT_EDIT_FINDONE_SUCCESS") {
          setValue('vendorName', res.payload?.vendorName);
          setvendorName(res.payload?.vendorName);
          setValue('invoiceNO', res.payload?.invoiceNo);
          setValue('balanceAmount', res.payload?.previousBalanceAmount);
          setValue('receivedAmount', res.payload?.receivedAmount);
          setValue('paymentType', res.payload?.paymentType);
          setselectedOption(res?.payload?.paymentType);
          setPurchaseNo([{ invoiceNo: res.payload?.invoiceNo }]);
          setValue("chequeNum", res?.payload?.chequeNum);
        }

      })
    }
    else {
      fetchData()
    }

  }

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReceivedAmountChange = (e: any) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");

    const balanceAmount = parseFloat(getValues("balanceAmount"));

    if (value === "") {
      setError("receivedAmount", {
        type: "manual",
        message: "Amount payable is required",
      });
    } else if (parseFloat(value) > balanceAmount) {
      setError("receivedAmount", {
        type: "manual",
        message: "Received amount exceeds balance",
      });
    }
    else {
      clearErrors("receivedAmount");
    }
    e.target.value = value;
  };



  const uniqueVendors = Array?.from(
    new Set(pendingbillOUtBillList?.map((item: any) => item.vendorName))
  ).map((name: any) => {
    return pendingbillOUtBillList?.find((item: any) => item.vendorName === name);
  });

  const vendorNames = (e: any) => {
    clearErrors("vendorName");
    setValue("vendorName", e);
    setvendorName(e)
    const vendorValue = pendingbillOUtBillList.filter((item: any) => item.vendorName === e)
    setPurchaseNo(vendorValue);
  };
  const [previousReceivedAmount, setPreviousReceivedAmount] = useState(Number as any)
  const Purchaseno = (e: any) => {
    const balanceValue = pendingbillOUtBillList.find((item: any) => item.invoiceNo === e)
    setValue("balanceAmount", balanceValue.balanceAmount)
    setPreviousReceivedAmount(balanceValue.receivedAmount)
  }
  const formatDate = moment(new Date()).format("DD/MM/YYYY");

  const onSubmit = (data: any) => {
    // const isReceivedAmountValid = validateReceivedAmount();
    let postdata = {
      paymentOutReceiptNo: paymentOutReceiptNo,
      vendorName: data.vendorName,
      previousReceivedAmount: previousReceivedAmount,
      previousBalanceAmount: data.balanceAmount,
      balanceAmount: data.balanceAmount - parseInt(data.receivedAmount),
      // billAmount: uniqueInvoiceNo?.[0]?.toatalAmount,
      purchaseEntryDate: id ? data.purchaseEntryDate : formatDate,
      chequeNum: data.chequeNum,
      invoiceNO: data.invoiceNO,
      paymentType: data.paymentType,
      receivedAmount: data.receivedAmount,
      user_id: userid,
    };
    if (id) {
      dispatch(paymentOutUpdate(id, postdata) as any).then((res: any) => {

        const queryParams = new URLSearchParams();

        queryParams.append("param6", selectedFont);
        queryParams.append("param7", currencySymbol);

        const url = `/api/paymentout_pdf/${id}/${userid}?${queryParams.toString()}`;

        fetch(url, {
          method: "GET",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link: any = document.createElement("a");
            link.href = url;
            const formattedDate = moment().format("YYYY-MM-DD");
            link.setAttribute(
              "download",
              `paymentreceipt_${formattedDate}.pdf`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

          })
          .catch((error) => console.error("Error downloading PDF:", error));

        if (res) {
          // fetchData();
          toast.success("PaymentOut Update Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
          navigate("/app/paymentsOut");

        }
      });

    }
    else {
      dispatch(paymentOutPost(postdata) as any).then((res: any) => {
        if (res?.payload?.paymentOut_id) {
          const queryParams = new URLSearchParams();
          queryParams.append("param6", selectedFont);
          queryParams.append("param7", currencySymbol);
          const url = `/api/paymentout_pdf/${res?.payload?.paymentOut_id}/${userid}?${queryParams.toString()}`;
          fetch(url, {
            method: "GET",
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob();
            })
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link: any = document.createElement("a");
              link.href = url;
              const formattedDate = moment().format("YYYY-MM-DD");
              link.setAttribute(
                "download",
                `paymentreceipt_${formattedDate}.pdf`,
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            })
            .catch((error) => console.error("Error downloading PDF:", error));
        }
        if (res) {
          // fetchData();
          toast.success("PaymentOut Saved Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
          navigate("/app/paymentsOut");

        }
      });
    }


  }


  const handleCancel = () => {
    reset();
    setValue("vendorName", "");
    clearErrors();
    setselectedOption("");
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-[#E1E8E7] pr-5 md:pr-5  lg:pr-0  xl:pr-0" style={{ fontFamily: "poppins" }}>
            <div className="p-4 text-2xl font-bold text-[#045545]  text-lg   ml-2">
              Payment Out
            </div>
            <fieldset className="mx-3 rounded-lg">
              <legend className="p-4 ml-3 font-bold text-[#008065] ">
                Payment
              </legend>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 xl:pl-4 lg:pl-4 md:pl-4 px-6 md:px-3 mb-4">
                <div className="flex flex-col mb-2 w-full md:w-48 lg:w-full xl:pl-4">

                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span>Vendors Name</span>
                  </label>
                  <select
                    id="vendorName"
                    className="border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: errors.vendorName ? "red" : "#008065",
                    }}
                    value={vendorName ? vendorName : ""}
                    {...register('vendorName'
                      , {
                        onChange: (e) =>
                          vendorNames(e.target.value),
                        required: "Vendor Name is required",
                      }
                    )}
                  >

                    <option value={id ? vendorName : ""} >{id ? vendorName : "Select vendor Name"}</option>
                    {
                      uniqueVendors.map((data: any, index: any) => (
                        <option key={index} value={data.vendorName}>
                          {data.vendorName}
                        </option>
                      ))
                    }
                  </select>
                  {errors.vendorName && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.vendorName.message}</p>}
                </div>
                <div className="flex flex-col mb-2 w-full md:w-48 lg:w-full xl:pl-4">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span>Purchase No</span>
                  </label>
                  <select
                    id="invoiceNO"
                    className="border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: errors.invoiceNO ? "red" : "#008065",
                    }}
                    {...register("invoiceNO", {
                      required: 'Purchase No is required',
                      onChange: (e: any) => Purchaseno(e.target.value),
                    })}
                  >
                    <option value="" disabled hidden>
                      Select Purchase No
                    </option>
                    {
                      purchaseNo.map((data: any, index: any) => (
                        <option key={index} value={data.invoiceNo}>
                          {data.invoiceNo}
                        </option>
                      ))
                    }
                  </select>
                  {errors.invoiceNO && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.invoiceNO.message}</p>}
                </div>
                <div className="flex flex-col mb-2  w-full md:w-48 lg:w-full xl:pl-4">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                      paddingTop: "2px"
                    }}
                  >
                    Balance Amount
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      className={
                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("balanceAmount")}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-2  w-full md:w-48 lg:w-full xl:pl-4">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span> Amount Payable</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: errors.receivedAmount ? "red" : "#008065",
                      }}

                      {...register("receivedAmount", {
                        required: "Amount payable is required", // Required rule
                        validate: {
                          isNotEmpty: (value) => value.trim() !== "" || "Amount payable is required", // Ensure non-empty
                          isLessThanBalance: (value) =>
                            parseFloat(value) <= parseFloat(getValues("balanceAmount") || "0") ||
                            "Received amount exceeds balance", // Compare with balance amount
                        },
                        onChange: handleReceivedAmountChange, // Handle numeric-only input
                      })}
                    />

                    {errors.receivedAmount && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.receivedAmount.message}</p>}
                  </div>
                </div>

                <div className="flex flex-col mb-2  w-full md:w-48 lg:w-full xl:pl-4">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span>Payment Type</span>
                  </label>
                  <select
                    value={selectedOption || ""}
                    id="paymentType"
                    className="border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderColor: errors.paymentType ? "red" : "#008065",
                    }}
                    {...register("paymentType", {
                      onChange: (e) => handlePaymentType(e.target.value),
                      required: "Select The Payment Type",
                    })}
                  >
                    <option value="" disabled>
                      Please Select Type
                    </option>
                    {id ? (
                      <option value={selectedOption}>{selectedOption}</option>
                    ) : (
                      <>
                        <option value="CASH">Cash</option>
                        <option value="CHEQUE">Cheque</option>
                      </>
                    )}
                  </select>
                  {errors.paymentType && (
                    <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>
                      {errors.paymentType.message}
                    </p>
                  )}
                </div>

                {selectedOption === "CHEQUE" && (
                  <div className="flex flex-col mb-2 w-full md:w-48 lg:w-full xl:pl-4">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                        paddingTop: "2px"
                      }}
                    >
                      Cheque No
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={
                          "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: errors.chequeNum
                            ? "#FF0000"
                            : "#008065",
                        }}
                        // {...register("chequeNum", {
                        //     required: "Check Number is Required",
                        // })}
                        {...register("chequeNum", {
                          required: "Cheque No is Required",
                          onChange: (e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            e.target.value = value;

                            // if (value.length === 6) {
                            //   clearErrors("chequeNum");
                            // } else {
                            //   setError("chequeNum", {
                            //     type: "manual",
                            //     message: "Cheque number must be 6 digits",
                            //   });
                            // }
                          },

                        })}
                      />
                      {errors.chequeNum && (
                        <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>
                          {errors.chequeNum.message}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row gap-0 lg:pl-4 xl:pl-4 md:pl-4  sm:px-3  sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-4">

                <div className="flex flex-col mb-2 md:pr-2 w-48 md:w-1/4 pr-0 lg:pr-2  lg:w-1/4 xl:w-1/4 self-center sm:self-auto">

                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span> Payment Type  </span>
                  </label>
                  <select
                    value={selectedOption || ""}
                    id="paymentType"
                    className="border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-11/12 w-full"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderColor: errors.paymentType ? "red" : "#008065",
                    }}
                    {...register("paymentType", {
                      onChange: (e) => handlePaymentType(e.target.value),
                      required: "Select The Payment Type",
                    })}

                  >
                    <option value="" disabled>
                      Please Select Type
                    </option>
                    {id ? (

                      <option value={selectedOption}>{selectedOption}</option>
                    ) : (
                      <>
                        <option value="CASH">Cash</option>
                        <option value="CHEQUE">Cheque</option>
                      </>
                    )}
                  </select>

                  {errors.paymentType && (
                    <p style={{ fontFamily: "poppins", color: "red" }}>
                      {errors.paymentType.message}
                    </p>
                  )}
                </div>
                {selectedOption === "CHEQUE" && (
                  <div className="flex flex-col md:pr-2 mb-2  w-48 md:w-1/4 pr-0 lg:pr-1 pl-0 lg:pl-0  lg:w-1/4 xl:w-1/4 self-center sm:self-auto">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      Cheque No
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={
                          "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full lg:w-11/12"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: errors.chequeNum
                            ? "#FF0000"
                            : "#008065",
                        }}
                        // {...register("chequeNum", {
                        //     required: "Check Number is Required",
                        // })}
                        {...register("chequeNum", {
                          onChange: (e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            e.target.value = value;
                          },
                          required: "Cheque No is Required",
                        })}

                      />
                      {errors.chequeNum && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.chequeNum.message}</p>}
                    </div>
                  </div>
                )}

              </div> */}
              {/* <-Submit cancel-> */}
              <div className="lg:flex md:flex justify-end mb-3">
                <div className="flex justify-end w-full flex-col sm:flex-row pl-4">
                  <div className="pr-4 self-center sm:self-auto">
                    <button
                      type="button"
                      className="rounded-xl mt-3 font-bold text-white  text-sm h-8 lg:w-[10rem] md:w-[10rem] sm:w-[10rem] w-48 bg-[#008065] "
                      onClick={handleCancel}
                      style={{ fontFamily: "poppins", fontSize: "14px" }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="pr-4 self-center sm:self-auto">
                    <button
                      type="submit"
                      className="rounded-xl mt-3 font-bold text-white hover:cursor-pointer text-sm h-8 lg:w-[10rem] md:w-[10rem] sm:w-[10rem] w-48 bg-[#008065] "
                      style={{ fontFamily: "poppins", fontSize: "14px" }}
                    >
                      {"Save"}
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </form >
      </div >
    </div>
  )
}

export default PaymentOutPage
