import React from "react";

function ClientPlusIcon({props}:any) {
  return (
    <svg
     
      xmlns="http://www.w3.org/2000/svg"
      className="mt-1 h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#00A787"
      strokeWidth={2}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
      />
    </svg>
  );
}

export default ClientPlusIcon;
