import { useSelector } from "react-redux";
import NumberFormat from "../commen/NumberFormat";
import { toWords } from "number-to-words";
import CalculateIndianFormattotal from "../commen/CalculateIndianFormattotal";

const PrintSalesFAProductWise = ({
  Invoice,
  componentRef,
  roundOffEnable,
  amountInWords
}: any) => {
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const totalAmount = Invoice.toatalAmount;
  const roundedTotalAmount = Math.round(totalAmount);
  const totalAmountInWords = Number.isFinite(roundedTotalAmount)
    ? amountInWords === "International Format"
      ? toWords(roundedTotalAmount)+" Only"
      : "Rupees " + CalculateIndianFormattotal(roundedTotalAmount) + " Only"
    : "Invalid amount";
  return (
    <>
      <div ref={componentRef}>
        <div style={{ breakBefore: "page" }}></div>
        {/*   image and clien information                */}
        <div className="grid grid-cols-6">
          {Invoice?.imageData ? (
            <div className="col-start-1 col-end-2">
              <div className=" font-title  sm:text-left ">
                <label
                  style={{ fontFamily: "poppins" }}
                  className="   items-start  text-blue      "
                >
                  <input type="file" accept="image/*" className="hidden" />
                  <img
                    className=" pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 "
                    src={Invoice?.imageData}
                    alt="logonew "
                  />
                </label>
              </div>
            </div>
          ) : null}
          <div className="col-start-2 col-span-4 ">
            <div className=" text-center  pt-7 text-4xl ">
              {Invoice?.companyName}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className=" text-center">
            <div className="mt-1">
              <span>
                {Invoice?.companyAddress ? (
                  <span>Address: {Invoice?.companyAddress},</span>
                ) : null}
                {Invoice?.companyCity ? (
                  <span>{Invoice?.companyCity},</span>
                ) : null}

                {Invoice?.companyState ? (
                  <span>{Invoice?.companyState}-</span>
                ) : null}
                {Invoice?.companyPostalCode ? (
                  <span>{Invoice?.companyPostalCode},</span>
                ) : null}
                {Invoice?.companygst ? (
                  <span>
                    {" "}
                    Gstn:
                    {Invoice?.companygst}
                  </span>
                ) : null}
              </span>
            </div>
            <div>
              <span>
                {Invoice?.companyPan ? (
                  <span>PAN: {Invoice?.companyPan},</span>
                ) : null}
                {Invoice?.companyEmail ? (
                  <span>
                    Email:{" "}
                    <span className="lowercase">{Invoice?.companyEmail},</span>
                  </span>
                ) : null}{" "}
                {Invoice?.companyMobile ? (
                  <span>Mobile: {Invoice?.companyMobile},</span>
                ) : null}
              </span>
            </div>
          </div>
        </div>
        <div className="border "></div>
        {/*        *******************   invoice name                */}

        <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
          <div className="justify-center flex flex-row relative ">
            <input
              readOnly
              className="w-11/12 rounded-[7px] text-center xs:w-1/4  focus:outline-none truncate"
              autoComplete="nope"
              style={{
                fontFamily: "poppins",
                fontSize: "34px",
                fontWeight: 700,
              }}
              id="input_focus"
              value={Invoice?.invoicehead}
            />
            {/* </h1> */}
          </div>
          {/*     client details           */}
          <div className=" px-8 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
            <div className="flex-1 md:mx-2">
              <div className="flex flex-col sm:flex-row pt-3  justify-between">
                <div className="">
                  <div className="flex flex-row  items-center mb-2 ">
                    <div
                      className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      style={{
                        color: "#008065",
                        fontWeight: 700,
                      }}
                    >
                      Client Details
                    </div>
                  </div>
                  {Invoice.clientName ? (
                    <>
                      <div className="flex justify-between">
                        <div
                          style={{
                            marginRight: "30px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Name
                        </div>
                        <div className=" ">
                          <input
                            className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                            readOnly
                            value={Invoice.clientName}
                            style={{
                              width: "350px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.clientMobile ? (
                    <>
                      <div className="flex justify-between">
                        <div
                          style={{
                            marginRight: "30px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Mobile
                        </div>
                        <div className="">
                          <input
                            className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                            readOnly
                            value={Invoice.clientMobile}
                            style={{
                              width: "350px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.clientEmail ? (
                    <>
                      <div className="flex justify-between">
                        <div
                          style={{
                            marginRight: "30px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Email
                        </div>
                        <div className="">
                          <input
                            className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                            readOnly
                            value={Invoice.clientEmail}
                            style={{
                              width: "350px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {Invoice.clientgst ? (
                    <>
                      <div className="flex justify-between ">
                        <div
                          style={{
                            marginRight: "45px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          GST
                        </div>
                        <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full">
                          <input
                            className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                            readOnly
                            value={Invoice.clientgst}
                            style={{
                              width: "350px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="">
                  <div className="flex flex-row items-center justify-between mb-2 mr-8">
                    <div
                      style={{
                        color: "#008065",
                        fontWeight: 700,
                      }}
                      className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Sales FA No
                    </div>

                    <div className="font-title flex-1 text-right ">
                      <input
                        readOnly
                        value={Invoice?.invoiceNo}
                        style={{
                          fontFamily: "poppins",
                        }}
                        className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full  block focus:outline-none "
                      />
                    </div>
                    <div></div>
                  </div>

                  {Invoice.salesFADate ? (
                    <>
                      <div className="flex flex-row items-center justify-between mb-2 mr-8">
                        <div
                          style={{
                            color: "#008065",
                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Sales FA Date
                        </div>
                        <div className="font-title flex-1 text-right ">
                          <input
                            readOnly
                            value={Invoice.salesFADate}
                            style={{}}
                            className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-2 sm:ml-auto block focus:outline-none "
                          />
                        </div>
                        <div></div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.PurchaseBillNo ? (
                    <>
                      <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                        <div
                          style={{
                            color: "#008065",
                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                        >
                          Purchase Bill No
                        </div>
                        <div className="font-title flex-1 text-right">
                          <input
                            readOnly
                            value={Invoice.PurchaseBillNo}
                            style={{}}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full ml-2 sm:ml-auto  focus:outline-none "
                          ></input>
                        </div>
                        <div></div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.purchaseBillDate ? (
                    <>
                      <div className="flex flex-row items-center justify-between mb-2 mr-8">
                        <div
                          style={{
                            color: "#008065",
                            fontFamily: "poppins",

                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Purchase Bill Date
                        </div>
                        <div className="font-title flex-1 text-right ">
                          <input
                            readOnly
                            value={Invoice.purchaseBillDate}
                            style={{
                              fontFamily: "poppins",
                            }}
                            className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-2 sm:ml-auto block focus:outline-none "
                          />
                        </div>
                        <div></div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="px-8 mt-3">
            <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white bg-[#008065]">
              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" sm:w-1/4 md:w-1/6 lg:w-2/12 xl:w-2/12 2xl:w-1/12 text-left "
              >
                <span className="inline-block">S No</span>
              </div>
              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left "
              >
                <span className="inline-block">Product</span>
              </div>
              {userdata?.hsnNo ? (
                <div
                  style={{
                    // fontFamily: "",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className="sm:w-1/4 "
                >
                  <span className="">Hsn</span>
                </div>
              ) : (
                <></>
              )}

              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 sm:text-left"
              >
                <span className="">Price</span>
              </div>

              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-left sm:text-center"
              >
                <span>Qty</span>
              </div>
              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-left sm:text-center"
              >
                Disc(%)
              </div>
              {/* {userdata?.gstValue === "IGST" ? (
                                <>
                                    <div
                                        style={{
                                            // fontFamily: "",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                        className="w-full sm:w-1/4 text-center sm:text-center"
                                    >
                                        IGST(%)
                                    </div>
                                </>
                            ) : (<>
                                <div
                                    style={{
                                        // fontFamily: "",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                    }}
                                    className={"w-full sm:w-1/4 text-center sm:text-center"}
                                >
                                    CGST(%)
                                </div>
                                <div
                                    style={{
                                        // fontFamily: "",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                    }}
                                    className={"w-full sm:w-1/4 text-center sm:text-center"}
                                >
                                    <span className="inline-block">SGST(%)</span>
                                </div>
                            </>)} */}
              <div
                style={{
                  // fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-center sm:text-center   "
              >
                Tax
              </div>
              <div
                style={{
                  // fontFamily: "",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-right "
              >
                Total
              </div>
            </div>
          </div>

          {Invoice?.asset?.map((product: any, index: number) => {
            return (
              <div className="sm:px-8">
                <div
                  key={`${index}_${product.id}`}
                  className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row  xl:flex-row flex-col rounded-lg w-full px-4 py-1 items-center relative text-sm"
                >
                  <div
                    style={{
                      fontFamily: "",
                      fontWeight: 700,
                    }}
                    className="w-full sm:w-1/4 md:w-1/6 lg:w-2/12 xl:w-2/12 2xl:w-1/12 text-left flex flex-row sm:block"
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-1/2 flex flex-row text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      S No
                    </span>
                    <span className="inline-block w-full product mb-1 sm:mb-0 ">
                      <input
                        readOnly
                        value={index + 1}
                        style={{
                          fontFamily: "",
                          fontWeight: 400,
                        }}
                        className="font-title  block w-full  sm:text-left text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      />
                    </span>
                  </div>
                  <div
                    style={{
                      fontFamily: "",
                      fontWeight: 700,
                    }}
                    className=" w-full sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left flex flex-row sm:block"
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Product
                    </span>
                    <span className="inline-block w-full  product   mb-1 sm:mb-0">
                      <input
                        readOnly
                        value={product.asset_name}
                        style={{
                          fontFamily: "",
                          fontWeight: 400,
                        }}
                        className="font-title  block w-full sm:text-left  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      />
                    </span>
                  </div>
                  {userdata?.hsnNo ? (
                    <div
                      style={{
                        fontFamily: "",
                        fontWeight: 700,
                      }}
                      className="w-full sm:w-1/4  flex flex-row sm:block"
                    >
                      <span
                        style={{
                          marginBottom: "5px",
                        }}
                        className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Hsn
                      </span>
                      <span className="inline-block w-full  sm:w-full mb-1 sm:mb-0 ">
                        <input
                          readOnly
                          style={{
                            fontFamily: "",
                            fontWeight: 400,
                          }}
                          value={product.hsn ? product.hsn : "N/A"}
                          className="font-title  block w-full sm:text-left text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                        />
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div
                    style={{
                      fontFamily: "",
                      fontWeight: 700,
                    }}
                    className="w-full sm:w-1/4 sm:text-left   flex flex-row sm:block "
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Price
                    </span>
                    <span className="inline-block  w-full  mb-1 sm:mb-0 ">
                      <input
                        style={{
                          fontFamily: "",
                          marginTop: "",
                          fontWeight: 400,
                        }}
                        readOnly
                        value={product?.rate}
                        type={"number"}
                        className="font-title block w-full sm:text-left text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      />
                    </span>
                  </div>

                  <div
                    style={{
                      fontFamily: "",
                      fontWeight: 700,
                    }}
                    className="w-full sm:w-1/4 text-left sm:text-center flex flex-row sm:block   "
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Qty
                    </span>
                    <span className="inline-block w-full mb-1 sm:mb-0">
                      <input
                        readOnly
                        value={product.qty}
                        type={"number"}
                        style={{
                          fontFamily: "",
                          marginTop: "",
                          fontWeight: 400,
                        }}
                        className="font-title text-right block w-full sm:text-center focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      />
                    </span>
                  </div>

                  <div
                    style={{
                      fontFamily: "",
                      fontWeight: 700,
                    }}
                    className=" w-full sm:w-1/4 text-left sm:text-center flex flex-row sm:block   "
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Disc(%)
                    </span>
                    <span className="inline-block  w-full mb-1 sm:mb-0">
                      <input
                        readOnly
                        value={product.discount ? product.discount : "N/A"}
                        type=""
                        style={{
                          fontFamily: "",
                          marginTop: "",
                          fontWeight: 400,
                        }}
                        className="font-title text-right block w-full  sm:text-center focus:outline-none text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md"
                      />
                    </span>
                  </div>

                  {/* {userdata?.gstValue === "IGST" ? (
                                    <>
                                        <div
                                            style={{
                                                fontFamily: "",
                                                fontWeight: 700,
                                            }}
                                            className={"  w-full sm:w-1/4 text-right flex flex-row sm:block"}
                                        >
                                            <span
                                                style={{
                                                    marginBottom: "5px",
                                                }}
                                                className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                IGST(%)
                                            </span>
                                            <span className="inline-block  w-full mb-1 sm:mb-0">
                                                <input
                                                    readOnly
                                                    value={product.prodigst}
                                                    type={"number"}
                                                    style={{
                                                        fontFamily: "",
                                                        marginTop: "",
                                                        fontWeight: 400,
                                                    }}
                                                    className="font-title text-right block w-full 2xl:text-center xl:text-center xl:pl-4 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                                />
                                            </span>
                                        </div>
                                    </>
                                ) : (<>
                                    <div
                                        style={{
                                            fontFamily: "",
                                            fontWeight: 700,
                                        }}
                                        className={"w-full sm:w-1/4 text-right flex flex-row sm:block"}
                                    >
                                        <span
                                            style={{
                                                marginBottom: "5px",
                                            }}
                                            className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                        >
                                            CGST(%)
                                        </span>
                                        <span className="inline-block  w-full mb-1 sm:mb-0">
                                            <input
                                                readOnly
                                                value={product.prodcgst}
                                                type={"number"}
                                                style={{
                                                    fontFamily: "",
                                                    marginTop: "",
                                                    fontWeight: 400,
                                                }}
                                                className={`${"font-title text-right block w-full 2xl:text-center xl:text-center xl:pl-4 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"}`}
                                            />
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "",
                                            fontWeight: 700,
                                        }}
                                        className={"w-full sm:w-1/4 text-right flex flex-row sm:block"}
                                    >
                                        <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md">
                                            SGST(%)
                                        </span>
                                        <span className="inline-block  w-full mb-1 sm:mb-0">
                                            <input
                                                readOnly
                                                value={product.prodsgst}
                                                type={"number"}
                                                style={{
                                                    fontFamily: "",
                                                    marginTop: "",
                                                    fontWeight: 400,
                                                }}
                                                className="font-title text-right block w-full 2xl:text-center xl:text-center xl:pl-4 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            />
                                        </span>
                                    </div>
                                </>)} */}

                  <div
                    style={{
                      fontFamily: "",
                      fontWeight: 700,
                    }}
                    className="w-full sm:w-1/4 text-center sm:text-center flex flex-row sm:block   "
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Tax
                    </span>
                    <span className="inline-block  w-full mb-1 sm:mb-0">
                      <input
                        readOnly
                        value={product?.assetTax}
                        style={{
                          fontFamily: "",
                          marginTop: "",
                          fontWeight: 400,
                        }}
                        className="font-title text-right block w-full sm:text-center focus:outline-none text-xs sm:text-sm xl:text-md  "
                      />
                    </span>
                  </div>
                  <>
                    <div
                      style={{
                        fontFamily: "",
                        fontWeight: 700,
                      }}
                      className="w-full sm:w-1/4 text-right flex flex-row sm:block"
                    >
                      <span
                        style={{
                          marginBottom: "5px",
                        }}
                        className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-md md:text-md xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Total
                      </span>
                      <span className="font-block w-full sm:w-full text-xs sm:text-sm  md:whitespace-nowrap ">
                        <NumberFormat value={product.amount}></NumberFormat>
                      </span>
                    </div>
                  </>
                </div>
              </div>
            );
          })}
          {/********************************** * subtotal *******************************/}
          {Invoice?.subtotal ? (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                subtotal
              </div>

              <div
                className="font-block w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row justify-end sm:block mb-1"
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                <NumberFormat value={Invoice?.subtotal} />
              </div>
            </div>
          ) : null}

          {roundOffEnable == true && (
            <div
              className={
                "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
              }
            >
              <div
                className={
                  "w-full sm:w-1/2 px-4 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
                }
              >
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                  }
                >
                  ROUND OFF
                </div>

                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    "font-title text-lg w-1/2 text-right sm:pr-11 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                  }
                >
                  <NumberFormat value={Invoice.roundOff} />
                </div>
              </div>
            </div>
          )}
          {Invoice?.toatalAmount ? (
            <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                Total
              </div>

              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row justify-end sm:block mb-1"
              >
                <NumberFormat value={Invoice?.toatalAmount} />
              </div>
            </div>
          ) : null}

          {/*************************************Amount In Words *********************/}
          <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-title w-1/2 sm:w-1/4 text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 whitespace-nowrap capitalize"
            >
              {totalAmountInWords} 
            </div>
          </div>

          {/************************************* bank details *********************/}
        </div>
      </div>
    </>
  );
};

export default PrintSalesFAProductWise;
