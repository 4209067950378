export const EXTERNAL_POST_REQUEST = 'EXTERNAL_POST_REQUEST';
export const EXTERNAL_POST_SUCCESS = 'EXTERNAL_POST_SUCCESS';
export const EXTERNAL_POST_FAIL = 'EXTERNAL_POST_FAIL';
export const EXTERNAL_POST_REST = 'EXTERNAL_POST_REST';

export const EXTERNAL_INVOICE_FIND_ALL_REQUEST = 'EXTERNAL_INVOICE_FIND_ALL_REQUEST';
export const EXTERNAL_INVOICE_FIND_ALL_SUCCESS = 'EXTERNAL_INVOICE_FIND_ALL_SUCCESS';
export const EXTERNAL_INVOICE_FIND_ALL_FAIL = 'EXTERNAL_INVOICE_FIND_ALL_FAIL';
export const EXTERNAL_INVOICE_POST_REST = 'EXTERNAL_INVOICE_POST_REST';

export const EXTERNAL_PAGE_POST_REQUEST = 'EXTERNAL_PAGE_POST_REQUEST';
export const EXTERNAL_PAGE_POST_SUCCESS = ' EXTERNAL_PAGE_POST_SUCCESS';
export const EXTERNAL_PAGE_POST_FAIL = 'EXTERNAL_PAGE_POST_FAIL';
export const EXTERNAL_PAGE_POST_RESET = 'EXTERNAL_PAGE_POST_RESET';

export const EXTERNAL_PAGE_DELETE_REQUEST = "CLIENT_DELETE_REQUEST";
export const EXTERNAL_PAGE_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const EXTERNAL_PAGE_DELETE_FAIL = "CLIENT_DELETE_FAIL";
export const EXTERNAL_PAGE_DELETE_REST = "CLIENT_DELETE_REST";