/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Products, ProductsGet } from '../../../redux/actions/productAction';
import { useParams } from 'react-router-dom';

const AddProduct = ({ handleExistingCalulation, prodcutRows, newExistProduct, setNewexistProduct, setClientOpenView, clientviewOpen, setProductRows,
    handleSelect, setSelectedPayment, setchequeAmount, setcreditAmount, setbalanceAmount, setCashAmount, }: any) => {

    const params = useParams();
    const { id } = params;
    const usersignin = useSelector((state: any) => state.userSignin);
    const token = usersignin?.userInfo?.token;
    const [productId, setProducId] = useState("Prod-0001" as any);
    const [formData, setFormData] = useState({
        product_id: productId,
        product_name: "",
        hsn: "",
        price: "",
        units: "",
        qty: "1",
        gst: "",
        subtotal: "",
        GstAmount: "",
        CgstPercentage: "",
        CgstAmount: "",
        SgstPercentage: "",
        SgstAmount: "",
        igstTax: "",
        igstAmount: "",
        sellingPrice: "",
        discount: 0,
        description: "",
        status: true,
        product_flow: "Direct Sale",
        productBar_code:"",
        user_id: token?._id,
    } as any);
    const [errors, setErrors] = useState<Partial<any>>({});
    const [productList, setProductList] = useState([] as any);
    const [productid, setProductid] = useState(null as any);
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        let newvalue = value.trimStart();
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: newvalue,
        }));
        if (name == "price") {
            let rate = Number(value);
            let Quantity = Number(formData.qty);
            let SubTotal = rate * Quantity;
            formData.subtotal = SubTotal;
            if (name == "price" && formData.gst) {
                let rate = Number(value);
                let Quantity = Number(formData.qty);
                let SubTotal = rate * Quantity;
                formData.subtotal = SubTotal;

                let gstamount = Number(formData.gst);
                let GstTotal = (gstamount * formData.subtotal) / 100;
                formData.GstAmount = GstTotal;

                let SgstPercentage = gstamount / 2;
                let CgstPercentage = gstamount / 2;

                let SgstAmount = (SgstPercentage * formData.subtotal) / 100;
                let CgstAmount = (CgstPercentage * formData.subtotal) / 100;

                let igstamount = Number(formData.igstTax);
                let IGstTotal = (igstamount * formData.subtotal) / 100;
                formData.igstAmount = IGstTotal;

                formData.SgstAmount = SgstAmount;
                formData.CgstAmount = CgstAmount;
                formData.SgstPercentage = SgstPercentage;
                formData.CgstPercentage = CgstPercentage;
            }
        }

        if (name == "gst") {
            let gstamount = Number(value);
            let GstTotal = (gstamount * formData.subtotal) / 100;
            formData.GstAmount = GstTotal;

            let SgstPercentage = gstamount / 2;
            let CgstPercentage = gstamount / 2;

            let SgstAmount = (SgstPercentage * formData.subtotal) / 100;
            let CgstAmount = (CgstPercentage * formData.subtotal) / 100;

            formData.SgstAmount = SgstAmount;
            formData.CgstAmount = CgstAmount;
            formData.SgstPercentage = SgstPercentage;
            formData.CgstPercentage = CgstPercentage;
        }
        if (name == "igstTax") {
            let igstamount = Number(value);
            let IGSTTotal = (igstamount * formData.subtotal) / 100;
            formData.igstAmount = IGSTTotal;
        }
    };

    const fetchData = () => {
        dispatch(ProductsGet(token?._id) as any).then((res: any) => {
            if (res) {
                if (res.payload.length > 0) {
                    const [name, num] = res.payload[0].product_id.split("-");
                    let numeric_part = Number(num);
                    let next_numeric_part = numeric_part + 1;
                    const paddedIndex = String(next_numeric_part).padStart(4, "0");
                    setProducId(`Prod-${paddedIndex}`);
                } else {
                    const paddedIndex = String(1).padStart(4, "0");
                    setProducId(`Prod-${paddedIndex}`);
                }
                setProductList(res.payload);
            }
        });
    };

    const resetProduct = () => {
        setFormData({
            product_id: null,
            product_name: "",
            product_flow: "Direct Sale",
            hsn: "",
            price: "",
            units: "",
            qty: "1",
            gst: "",
            subtotal: "",
            GstAmount: "",
            CgstPercentage: "",
            CgstAmount: "",
            SgstPercentage: "",
            SgstAmount: "",
            igstTax: "",
            igstAmount: "",
            sellingPrice: "",
            discount: 0,
            description: "",
            user_id: token?._id,
        } as any);
    };

    const handleCancelClick = () => {
        let obje = {
            product_id: null,
            product_name: null,
            product_flow: null,
            hsn: null,
            price: null,
            units: null,
            qty: null,
            gst: null,
            subtotal: null,
            GstAmount: null,
            CgstPercentage: null,
            CgstAmount: null,
            SgstPercentage: null,
            SgstAmount: null,
            igstTax: null,
            igstAmount: null,
            sellingPrice: null,
            discount: null,
            user_id: null,
            description: null,
        };
        setErrors({ ...obje });
        resetProduct();
        setProductid(null);
        setClientOpenView(false);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validationErrors: Partial<Record<string, string>> = {};
        const sellingPrice = parseFloat(formData.sellingPrice || "0");
        const mrp = parseFloat(formData.price || "0");

        // Validation rules
        if (sellingPrice > mrp) {
            validationErrors.sellingPrice = "Selling price cannot exceed MRP.";
        }

        const isDuplicate = productList.some((product: any) => {
            return (
                product.product_name.trim().toLowerCase() ===
                formData.product_name.trim().toLowerCase() &&
                product._id?.$oid !== id
            );
        });

        if (isDuplicate) {
            validationErrors.product_name = "Product Name already exists.";
        }

        // Required field validations
        ["product_name", "units", "price", "gst", "product_flow", "igstTax", "sellingPrice"].forEach((field) => {
            if (!formData[field]?.trim()) {
                validationErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        setErrors(validationErrors);

        // Proceed only if there are no validation errors
        if (Object.keys(validationErrors).length === 0) {
            dispatch(Products(formData) as any).then((res: any) => {
                if (res) {
                    const newRow = {
                        hsn: formData.hsn,
                        prodcut: formData.product_name,
                        productName: formData.product_name,
                        qty: formData.qty,
                        gst: formData.gst,
                        igst: formData.igstTax,
                        cgst: formData.CgstPercentage,
                        sgst: formData.SgstPercentage,
                        description: formData.description,
                        rate: formData.sellingPrice,
                        discount: formData.discount,
                        salesInvoiceQty: formData.qty,
                        product_flow: formData.product_flow,
                        productUnique_id: res.productUnique_id,
                        productBar_code:"",
                        amount: "",
                        igstAmount: "",
                        cgstAmount: "",
                        sgstAmount: "",
                    };


                    // Handle empty rows in prodcutRows
                    const containsEmptyRows = prodcutRows?.some((row: any) =>
                        Object.values(row).some((value) => !value)
                    );
                    const containsEmptyRowsinExistProduct = newExistProduct?.some((row: any) =>
                        Object.values(row).some((value) => !value)
                    );

                    if (containsEmptyRowsinExistProduct) {
                        const nonEmptyRows = newExistProduct?.filter((row: any) =>
                            Object.values(row).some(Boolean)
                        );
                        setNewexistProduct(nonEmptyRows);
                    }

                    if (containsEmptyRows) {
                        const nonEmptyRows = prodcutRows?.filter((row: any) =>
                            Object.values(row).some(Boolean)
                        );
                        setProductRows(nonEmptyRows);
                    }

                    setProductRows((prevRows: any[]) => {
                        const updatedRows = [...prevRows, newRow];
                        handleExistingCalulation(updatedRows);
                        return updatedRows;
                    });

                    setNewexistProduct((prev: any) => [
                        ...prev,
                        {
                            exist: Number(formData.qty),
                            originalValue: Number(formData.qty),
                        },
                    ]);


                    // Reset form data
                    setFormData({
                        product_id: productId,
                        product_name: "",
                        product_flow: "",
                        hsn: "",
                        price: "",
                        units: "",
                        qty: "1",
                        user_id: token?._id || "",
                        gst: "",
                        subtotal: "",
                        GstAmount: "",
                        CgstPercentage: "",
                        CgstAmount: "",
                        SgstPercentage: "",
                        SgstAmount: "",
                        igstTax: "",
                        igstAmount: "",
                        sellingPrice: "",
                        discount: "",
                        description: "",
                        productBar_code:"",
                        status: true,
                    });

                    fetchData();
                    setProductid(null);
                    setClientOpenView(false);
                    setCashAmount('')
                    setbalanceAmount("")
                    setcreditAmount("")
                    setchequeAmount("")
                    setSelectedPayment('')
                }
            });
        }
    };



    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Transition.Root show={clientviewOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={handleCancelClick}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:w-full md:w-9/12 lg:w-8/12 xl:w-6/12 border border-[#008065]">
                                <div>
                                    <div className='items-start justify-between p-2 border-solid border-slate-200 rounded-t bg-[#008065]'>
                                        <div className='flex p-1 border-solid border-slate-200 rounded-b justify-between  ' >
                                            <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Add New Product</h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={handleCancelClick}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                                        </div>
                                    </div>
                                    {/* <h2
                                        className="text-lg font-semibold mb-4"
                                        style={{ fontFamily: "Poppins", color: "#008065" }}
                                    >
                                        Add New Product
                                    </h2> */}

                                    {/* Form Inputs */}
                                    <form className='p-6'>

                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            {/* Client Name */}
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2 required"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    <span>Product ID</span>
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <input
                                                        readOnly
                                                        value={productId}
                                                        name="product_id"
                                                        onChange={(e) => handleChange(e)}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: "#008065",
                                                        }}
                                                    />
                                                </div>


                                            </div>


                                            {/* Address */}
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2 required"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    <span>Product Name</span>
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <input
                                                        name="product_name"
                                                        value={formData.product_name}
                                                        onChange={(e) => handleChange(e)}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: errors.product_name ? "#ff0000" : "#008065",
                                                        }}
                                                        placeholder="Enter Product"
                                                    />
                                                    {errors.product_name && (
                                                        <span
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontWeight: 700,
                                                                color: "#ff0000",
                                                            }}
                                                            className="text-red-400 text-sm"
                                                        >
                                                            {errors.product_name}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            {/* City */}
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    HSN/SAC
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <input
                                                        type="number"
                                                        value={formData.hsn}
                                                        name="hsn"
                                                        onChange={(e) => handleChange(e)}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: "#008065",
                                                        }}
                                                        placeholder="Enter hsn"
                                                    />
                                                </div>
                                            </div>

                                            {/* State */}
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2 required"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    <span>MRP</span>
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <input
                                                        type="text"
                                                        placeholder="MRP"
                                                        name="price"
                                                        value={formData.price}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            const regex = /^\d*\.?\d*$/;
                                                            if (value === "" || regex.test(value)) {
                                                                handleChange(e);
                                                                const updatedMRP = parseFloat(value || "0");
                                                                const sellingPriceValue = parseFloat(formData.sellingPrice || "0");

                                                                if (sellingPriceValue > updatedMRP) {
                                                                    setErrors((prevErrors) => ({
                                                                        ...prevErrors,
                                                                        sellingPrice: "Selling price cannot exceed MRP",
                                                                    }));
                                                                } else {
                                                                    setErrors((prevErrors) => ({
                                                                        ...prevErrors,
                                                                        sellingPrice: "",
                                                                    }));
                                                                }

                                                                setFormData((prevData: any) => ({
                                                                    ...prevData,
                                                                    price: value,
                                                                }));
                                                            }
                                                        }}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: errors.price ? "#ff0000" : "#008065",
                                                        }}
                                                    />
                                                    {errors.price && (
                                                        <span
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontWeight: 700,
                                                                color: "#ff0000",
                                                            }}
                                                            className="text-red-400 text-sm"
                                                        >
                                                            {errors.price}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            {/* Postal Code */}
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2 required"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    <span> Selling Price</span>
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <input
                                                        type="text"
                                                        placeholder="Selling Price"
                                                        name="sellingPrice"
                                                        value={formData.sellingPrice}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            const regex = /^\d*\.?\d*$/;
                                                            if (value === "" || regex.test(value)) {
                                                                const updatedSellingPrice = parseFloat(value || "0");
                                                                const mrpValue = parseFloat(formData.price || "0");

                                                                if (updatedSellingPrice > mrpValue) {
                                                                    setErrors((prevErrors) => ({
                                                                        ...prevErrors,
                                                                        sellingPrice: "Selling price cannot exceed MRP.",
                                                                    }));
                                                                } else {
                                                                    setErrors((prevErrors) => ({
                                                                        ...prevErrors,
                                                                        sellingPrice: "",
                                                                    }));
                                                                }

                                                                setFormData((prevData: any) => ({
                                                                    ...prevData,
                                                                    sellingPrice: value,
                                                                }));
                                                            }
                                                        }}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: errors.sellingPrice ? "#ff0000" : "#008065",
                                                        }}
                                                    />
                                                    {errors.sellingPrice && (
                                                        <span
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontWeight: 700,
                                                                color: "#ff0000",
                                                            }}
                                                            className="text-red-400 text-sm"
                                                        >
                                                            {errors.sellingPrice}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            {/* Email */}
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2 required"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    <span>Units</span>
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <select
                                                        name="units"
                                                        value={formData.units}
                                                        onChange={(e: any) => handleChange(e)}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: errors.units ? "#ff0000" : "#008065",
                                                        }}
                                                    >
                                                        <option value="" disabled hidden>
                                                            Select Unit
                                                        </option>
                                                        <option value="kg">KG</option>
                                                        <option value="ltr">Ltr</option>
                                                        <option value="qty">Qty</option>
                                                        <option value="others">Others</option>
                                                    </select>
                                                    {errors.units && (
                                                        <span
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontWeight: 700,
                                                                color: "#ff0000",
                                                            }}
                                                            className="text-red-400 text-sm"
                                                        >
                                                            {errors.units}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            {/* Mobile */}
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2 required"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    <span>GST</span>
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <select
                                                        name="gst"
                                                        value={formData.gst}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            handleChange(e);
                                                            setFormData({ ...formData, gst: value });
                                                        }}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: errors.gst ? "#ff0000" : "#008065"
                                                        }}
                                                    >
                                                        <option value="" hidden>
                                                            Select GST
                                                        </option>
                                                        <option value={0}>GST 0%</option>
                                                        <option value={0.25}>GST 0.25%</option>
                                                        <option value={3}>GST 3%</option>
                                                        <option value={5}>GST 5%</option>
                                                        <option value={12}>GST 12%</option>
                                                        <option value={18}>GST 18%</option>
                                                        <option value={28}>GST 28%</option>
                                                        <option value={0}>Exempt</option>
                                                    </select>
                                                    {errors.gst && (
                                                        <span
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontWeight: 700,
                                                                color: "#ff0000",
                                                            }}
                                                            className="text-red-400 text-sm"
                                                        >
                                                            {errors.gst}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>


                                            {/* PAN */}
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2 required"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    <span>IGST</span>
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <select
                                                        name="igstTax"
                                                        value={formData.igstTax}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            handleChange(e);
                                                            setFormData({ ...formData, igstTax: value });
                                                        }}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: !errors.igstTax ? "#008065" : "#ff0000"
                                                        }}
                                                    >
                                                        <option value="" hidden>
                                                            Select IGST
                                                        </option>
                                                        <option value={0}>IGST 0%</option>
                                                        <option value={0.25}>IGST 0.25%</option>
                                                        <option value={3}>IGST 3%</option>
                                                        <option value={5}>IGST 5%</option>
                                                        <option value={12}>IGST 12%</option>
                                                        <option value={18}>IGST 18%</option>
                                                        <option value={28}>IGST 28%</option>
                                                        <option value={0}>Exempt</option>
                                                    </select>
                                                    {errors.igstTax && (
                                                        <span
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontWeight: 700,
                                                                color: "#ff0000",
                                                            }}
                                                            className="text-red-400 text-sm"
                                                        >
                                                            {errors.igstTax}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>


                                            {/* GST */}
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Discount
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <input
                                                        type='text'
                                                        placeholder="Discount (Optional)"
                                                        name="discount"
                                                        value={formData.discount}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            const regex = /^\d*\.?\d*$/;
                                                            if (
                                                                value === "" ||
                                                                (regex.test(value) && parseFloat(value) <= 100)
                                                            ) {
                                                                handleChange(e);
                                                                setFormData({ ...formData, discount: value });
                                                            }
                                                        }}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: "#008065",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <label
                                                    className="flex-1 mb-2 required"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    <span>Product Flow</span>
                                                </label>

                                                <div className="font-title flex-1 text-left">
                                                    <select
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            borderColor: "#008065",
                                                        }}
                                                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12`}

                                                        name="product_flow"
                                                        value={formData.product_flow}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            handleChange(e);
                                                            setFormData((prevState: any) => ({
                                                                ...prevState,
                                                                product_flow: value,
                                                            }));
                                                        }}
                                                        disabled
                                                    >
                                                        <option value={"Direct Sale"}>Direct Sale (No Vendor)</option>
                                                        <option value={"Vendor Required"}>Vendor Order Request</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="flex flex-row justify-between items-center mb-2">
                                                <div
                                                    className="flex-1 mb-2"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Description
                                                </div>
                                                <div className="font-title flex-1 text-left">
                                                    <textarea
                                                        placeholder="Description (Optional)"
                                                        name="description"
                                                        value={formData.description}
                                                        onChange={(e) => handleChange(e)}
                                                        className="border-2 focus:outline-none rounded-[7px] px-2 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            height: "70px",
                                                            resize: "none",
                                                            borderColor: "#008065"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Buttons */}
                                        <div className="mt-6 flex justify-end space-x-4">
                                            <button
                                                type="button"
                                                onClick={handleCancelClick}
                                                className="px-4 py-2 bg-red-500 hover:bg-red-600 rounded text-white"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="px-4 py-2 bg-[#008065] hover:bg-green-800 rounded text-white"
                                                onClick={(e: any) => handleSubmit(e)}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default AddProduct;

