import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { VendorAll, VendorDetails } from "../../redux/actions/vendorAction";

function VendorModel2({ closeModal, handleSelect }: any) {
    const usersignin = useSelector((state: any) => state.userSignin);
    const {
        userInfo: { token },
    } = usersignin;


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [animate, setAnimate] = useState(true);

    const dispatch = useDispatch();

    const [vendorList, setVendorList] = useState([] as any);
    const [vendorValueList, setVendorValueList] = useState([] as any);

    const fetchData = () => {
        dispatch(VendorAll(token?._id) as any)
            .then((res: any) => {
                if (res) {
                    setVendorValueList(res.payload)
                    setVendorList(res.payload)
                }
            })
            .catch((error: any) => {
                console.error("Error fetching clients:", error);
            });
    };


    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        const filteredData = vendorList?.filter((client: any) => {
            return client?.vendor_name?.toLowerCase().includes(query);
        });
        setVendorValueList(filteredData);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rows = vendorValueList?.map((item: any, index: any) => ({
        id: uuidv4(),
        ...item,
        Sl_No: index + 1,
    }));

    const columns: GridColDef[] = [
        {
            field: "action",
            headerName: "Actions",
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (params) => (
                <>
                    <button
                        onClick={() => handleSelect(params.row)}
                        className=" text-white bg-[#008060] font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
                    >
                        Select
                    </button>
                </>
            ),
        },
        {
            field: "Sl_No",
            headerName: "Sl No",
            minWidth: 120,
            editable: false,
            flex: 2,
        },
        {
            field: "vendor_name",
            headerName: "Name",
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (params: any) => (
                <> {params?.row?.vendor_name ? params?.row?.vendor_name : "N/A"}</>
            ),
        },
        {
            field: "vendor_address",
            headerName: "Address",
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.vendor_address ? params?.row?.vendor_address : "N/A"}</>
            ),
        },
        {
            field: "city",
            headerName: "City",
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.city ? params?.row?.city : "N/A"}</>
            ),
        },
        {
            field: "state",
            headerName: "State",
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.state ? params?.row?.state : "N/A"}</>
            ),
        },
        {
            field: "postal_code",
            headerName: "Postal Code",
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.postal_code ? params?.row?.postal_code : "N/A"}</>
            ),
        },
        {
            field: "vendor_mobile",
            headerName: "Mobile",
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.vendor_mobile ? params?.row?.vendor_mobile : "N/A"}</>
            ),
        },
        {
            field: "vendor_email",
            headerName: "Email",
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.vendor_email ? params?.row?.vendor_email : "N/A"}</>
            ),
        },

        {
            field: "gst",
            headerName: "Gst",
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.gst ? params?.row?.gst : "N/A"}</>
            ),
        },
    ];

    return (
        <motion.div
            className="modal-container"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: animate ? 1 : 0,
            }}
            transition={{
                type: "spring",
                damping: 30,
                duration: 0.2,
            }}
        >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75">
                <motion.div className="relative rounded-lg overflow-hidden bg-[white] text-center shadow-xl transform transition-all my-8 w-11/12 mx-auto border border-[#008065]">
                    <div className='p-2 bg-[#008065]'>
                        <div className='flex p-1 justify-between  ' >
                            <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Existing Vendors</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={closeModal}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                        </div>
                    </div>
                    <div
                        className="bg-[#F1F7F6] p-[8px] m-[5px]"
                        style={{ borderRadius: "8px" }}
                    >
                        <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                            <div className="flex items-center">
                                <div className="relative flex mb-2">
                                    <input
                                        onChange={(e) => onSearch(e)}
                                        type="text"
                                        id="simple-search"
                                        className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                                        placeholder="Search Vendor Here"
                                        required
                                    />
                                    <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                                        <svg
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <Box
                            sx={{
                                height: 400,
                                width: "100%",
                                overflowX: "hidden !important",
                                overflowY: "auto",
                            }}
                        >
                            <DataGrid
                                // autoHeight
                                sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                                    {
                                        backgroundColor: "#fff",
                                        color: "#008060 !important",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                    },
                                    "& .MuiDataGrid-checkboxInput": {
                                        color: "#008060 !important",
                                    },
                                    ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                        fontWeight: 600,
                                        fontSize: "14px",
                                    },
                                    ".css-1rtad1": {
                                        display: "block !important",
                                    },
                                }}
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 25, 50]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                        <div className="bg-[white] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
}

export default VendorModel2;
