import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClientSave } from '../../redux/actions/clientAction';

const AddExistClient = ({ toggleSecondPopup, setIsSecondPopupOpen, fetchData, clientList }: any) => {

    const usersignin = useSelector((state: any) => state.userSignin);
    const { userInfo } = usersignin;
    const userid = userInfo.token._id;

    const [formData, setFormData] = useState({
        client_name: "",
        client_email: "",
        state: "",
        city: "",
        postal_code: "",
        client_mobile: "",
        client_address: "",
        pan_no: "",
        gst: "",
        user_id: userid,
    } as any);
    const dispatch = useDispatch()
    const params = useParams();
    const { id } = params;
    const [errors, setErrors] = useState<Partial<any>>({});
    const [fieldErrors, setFieldErrors] = useState<any>({});
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        const fieldName = name as keyof typeof formData;

        if (value.startsWith(" ")) return;

        const updatedValue =
            fieldName === "gst" || fieldName === "pan_no" ? value.toUpperCase() : value;

        setFormData((prevData: any) => ({
            ...prevData,
            [fieldName]: updatedValue,
        }));

        let error = "";

        if (fieldName === "postal_code" && !value.match(/^[0-9]{6}$/) && value) {
            error = "Invalid postal code";
        } else if (
            fieldName === "client_mobile" &&
            !value.match(/^(?:[0-9]{10}|[0-9]{13})$/) &&
            value
        ) {
            error = "Invalid Mobile No";
        } else if (
            fieldName === "client_email" &&
            !value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
            value
        ) {
            error = "Invalid Email";
        } else if (
            fieldName === "pan_no" &&
            updatedValue &&
            !updatedValue.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
        ) {
            error = "Invalid PAN number";
        } else if (
            fieldName === "gst" &&
            !updatedValue.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/) &&
            updatedValue
        ) {
            error = "Invalid GST number";
        }

        if (!error && updatedValue) {
            if (fieldName === "client_email") {
                const duplicate = clientList.some((client: any) => client.client_email === updatedValue);
                if (duplicate) error = "This email is already saved";
            } else if (fieldName === "client_mobile") {
                const duplicate = clientList.some((client: any) => client.client_mobile === updatedValue);
                if (duplicate) error = "This mobile number is already saved";
            } else if (fieldName === "pan_no") {
                const duplicate = clientList.some((client: any) => client.pan_no === updatedValue);
                if (duplicate) error = "This PAN number is already saved";
            } else if (fieldName === "gst") {
                const duplicate = clientList.some((client: any) => client.gst === updatedValue);
                if (duplicate) error = "This GST number is already saved";
            }
        }

        setFieldErrors((prevErrors: any) => ({
            ...prevErrors,
            [fieldName]: error,
        }));

        setErrors((prevErrors: any) => ({
            ...prevErrors,
            [fieldName]: error ? error : undefined,
        }));
    };


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validationErrors: Partial<Record<string, string>> = {};

        const requiredFields = ["client_name"];
        requiredFields.forEach((field) => {
            if (!formData[field]?.trim()) {
                validationErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        if (formData.client_mobile && !formData.client_mobile.match(/^(?:[0-9]{10}|[0-9]{13})$/)) {
            validationErrors.client_mobile = "Invalid Mobile No";
        }
        if (formData.client_email && !formData.client_email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            validationErrors.client_email = "Invalid Email";
        }
        if (formData.postal_code && !formData.postal_code.match(/^[0-9]{6}$/)) {
            validationErrors.postal_code = "Invalid postal code";
        }
        if (formData.pan_no && !formData.pan_no.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)) {
            validationErrors.pan_no = "Invalid PAN number";
        }
        if (formData.gst && !formData.gst.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)) {
            validationErrors.gst = "Invalid GST number";
        }

        if (formData.client_email && clientList.some((client: any) => client.client_email === formData.client_email)) {
            validationErrors.client_email = "This email is already saved";
        }
        if (formData.client_mobile && clientList.some((client: any) => client.client_mobile === formData.client_mobile)) {
            validationErrors.client_mobile = "This mobile number is already saved";
        }
        if (formData.pan_no && clientList.some((client: any) => client.pan_no === formData.pan_no)) {
            validationErrors.pan_no = "This PAN number is already saved";
        }
        if (formData.gst && clientList.some((client: any) => client.gst === formData.gst)) {
            validationErrors.gst = "This GST number is already saved";
        }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        dispatch(ClientSave(formData) as any).then((res: any) => {
            if (res) {
                setFormData({
                    client_name: "",
                    client_email: "",
                    state: "",
                    city: "",
                    postal_code: "",
                    client_mobile: "",
                    client_address: "",
                    pan_no: "",
                    gst: "",
                    user_id: userid,
                });

                fetchData();
                setIsSecondPopupOpen(false);
            }
        });
    };


    return (
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <motion.div
                className="fixed top-0 left-0 w-full min-h-screen md:min-h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
            >
                <div className="relative bg-white rounded-lg sm:w-full md:w-11/12 lg:w-8/12 xl:w-6/12  shadow-lg border border-[#008065]">

                    <div className='items-start justify-between p-2 border-solid border-slate-200 rounded-t bg-[#008065]'>
                        <div className='flex p-1 border-solid border-slate-200 rounded-b justify-between' >
                            <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Add New Client</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={toggleSecondPopup}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                        </div>
                    </div>
                    <div>
                        {/*  <h2
                                className="text-lg font-semibold mb-4"
                                style={{ fontFamily: "Poppins", color: "#008065" }}
                            >
                                Add New Client
                            </h2> */}

                        {/* Form Inputs */}
                        <form className='p-6 '>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-left">
                                {/* Client Name */}
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <div
                                        className="flex-1 mb-2 required"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        <span>Client Name</span>
                                    </div>
                                    <div className="font-title flex-1 text-left">
                                        <input
                                            name="client_name"
                                            value={formData.client_name}
                                            onChange={(e) => handleChange(e)}
                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: errors?.client_name ? "#ff0000" : "#008065",
                                            }}
                                            placeholder="Enter Client Name"
                                        />
                                        {errors?.client_name && (
                                            <p
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontWeight: 700,
                                                    color: "#ff0000",
                                                }}
                                                className="text-red-400 text-sm"
                                            >
                                                {errors?.client_name}
                                            </p>
                                        )}
                                    </div>


                                </div>


                                {/* Address */}
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <div
                                        className="flex-1 mb-2"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        Address
                                    </div>
                                    <div className="font-title flex-1 text-left">
                                        <input
                                            name="client_address"
                                            value={formData.client_address}
                                            onChange={(e) => handleChange(e)}
                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: "#008065",
                                            }}
                                            placeholder="Enter Address"
                                        />
                                    </div>
                                </div>

                                {/* City */}
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <div
                                        className="flex-1 mb-2"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        City
                                    </div>
                                    <div className="font-title flex-1 text-left">
                                        <input
                                            name="city"
                                            value={formData.city}
                                            onChange={(e) => handleChange(e)}
                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: "#008065",
                                            }}
                                            placeholder="Enter City"
                                        />
                                    </div>
                                </div>

                                {/* State */}
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <div
                                        className="flex-1 mb-2"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        State
                                    </div>
                                    <div className="font-title flex-1 text-left">
                                        <input
                                            name="state"
                                            value={formData.state}
                                            onChange={(e) => handleChange(e)}
                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: "#008065",
                                            }}
                                            placeholder="Enter State"
                                        />
                                    </div>
                                </div>

                                {/* Postal Code */}
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <div
                                        className="flex-1 mb-2"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        Postal Code
                                    </div>
                                    <div className="font-title flex-1 text-left">
                                        <input
                                            name="postal_code"
                                            value={formData.postal_code}
                                            onChange={(e) => handleChange(e)}
                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: fieldErrors?.postal_code ? "#ff0000" : "#008065",
                                            }}
                                            placeholder="Enter Postal Code"
                                        />
                                        {fieldErrors?.postal_code && (
                                            <p
                                                style={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                    color: "#ff0000",
                                                }}
                                                className="text-red-400 text-sm"
                                            >
                                                {fieldErrors?.postal_code}
                                            </p>
                                        )}

                                    </div>
                                </div>

                                {/* Email */}
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <div
                                        className="flex-1 mb-2"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        Email
                                    </div>
                                    <div className="font-title flex-1 text-left">
                                        <input
                                            name="client_email"
                                            value={formData.client_email}
                                            onChange={(e) => handleChange(e)}
                                            type="email"
                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: fieldErrors?.client_email ? "#ff0000" : "#008065",
                                            }}
                                            placeholder="Enter Email"
                                        />
                                        {fieldErrors?.client_email && (
                                            <p
                                                style={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                    color: "#ff0000",
                                                }}
                                                className="text-red-400 text-sm"
                                            >
                                                {fieldErrors?.client_email}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                {/* Mobile */}
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <div
                                        className="flex-1 mb-2"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        Mobile
                                    </div>
                                    <div className="font-title flex-1 text-left">
                                        <input
                                            name="client_mobile"
                                            value={formData.client_mobile}
                                            onChange={(e) => handleChange(e)}
                                            type="tel"
                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: fieldErrors?.client_mobile ? "#ff0000" : "#008065",
                                            }}
                                            placeholder="Enter Mobile"
                                        />
                                        {fieldErrors?.client_mobile && (
                                            <p
                                                style={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                    color: "#ff0000",
                                                }}
                                                className="text-red-400 text-sm"
                                            >
                                                {fieldErrors?.client_mobile}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                {/* PAN */}
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <div
                                        className="flex-1 mb-2"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        PAN
                                    </div>
                                    <div className="font-title flex-1 text-left">
                                        <input
                                            name="pan_no"
                                            value={formData.pan_no}
                                            onChange={(e) => handleChange(e)}
                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: fieldErrors?.pan_no ? "#ff0000" : "#008065",
                                            }}
                                            placeholder="Enter PAN"
                                        />
                                        {fieldErrors?.pan_no && (
                                            <p
                                                style={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                    color: "#ff0000",
                                                }}
                                                className="text-red-400 text-sm"
                                            >
                                                {fieldErrors?.pan_no}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                {/* GST */}
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <div
                                        className="flex-1 mb-2"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        GST
                                    </div>
                                    <div className="font-title flex-1 text-left">
                                        <input
                                            name="gst"
                                            value={formData.gst}
                                            onChange={(e) => handleChange(e)}
                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: fieldErrors?.gst ? "#ff0000" : "#008065",
                                            }}
                                            placeholder="Enter GST"
                                        />
                                        {fieldErrors?.gst && (
                                            <p
                                                style={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                    color: "#ff0000",
                                                }}
                                                className="text-red-400 text-sm"
                                            >
                                                {fieldErrors?.gst}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Buttons */}
                            <div className="mt-6 flex justify-end space-x-4">
                                <button
                                    type="button"
                                    onClick={toggleSecondPopup}
                                    className="px-4 py-2 bg-red-500 hover:bg-red-600 rounded text-white"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    onClick={(e: any) => handleSubmit(e)}
                                    className="px-4 py-2 bg-[#008065] hover:bg-green-800 rounded text-white"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}

export default AddExistClient;
