import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { chequeModalSave } from '../../redux/actions/chequeAction';
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useState } from 'react';
import moment from 'moment';
import { registerAll } from '../../redux/actions/userAction';

const ChequeModal = ({ selectedRow, onClose }: any) => {
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);
  const [transferDate, setTransferDate] = useState(new Date() as any);
  const formatDate = moment(new Date()).format("DD/MM/YYYY");
  const [dateFormat, setDateFormat] = useState("DD/MM/YYYY")
  const [formData, setFormData] = useState({
    transferDate: formatDate,
  } as any);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paidTo: selectedRow?.Name || "",
      chequeAmount: selectedRow?.toatalAmount || "",
      withdrawFrom: "CASH",
      chequeNo: selectedRow?.chequeNum || "",
      transferDate: formatDate,
      cashinHand_id: selectedRow?.cashinHand_id || "",
      description: "",
    },
  });
  const dispatch = useDispatch<any>();
  const { transaction_type } = selectedRow;
  const label =
    transaction_type === "PURCHASE" ||
      transaction_type === "PAYMENTOUT" ||
      transaction_type === "PURCHASE(FA)"
      ? "Withdraw From:"
      : "Deposit To:";


  const onSubmit = (data: any) => {
    let chequeModalData = {
      paidTo: data?.paidTo,
      chequeAmount: data.chequeAmount,
      withdrawFrom: data?.withdrawFrom,
      chequeNo: data.chequeNo,
      transferDate: formData.transferDate,
      description: data.description,
      user_id: token?._id,
      cheque_id: selectedRow._id.$oid,
      cashinHand_id: selectedRow?.cashinHand_id
    };
    dispatch(chequeModalSave(chequeModalData) as any).then((res: any) => {
      if (res?.type === "CHEQUE_MODAL_POST_SUCCESS")
        onClose();
    })
    // Add save functionality here
  };

  const CustomInputWithIcon = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className='relative'>
      <input
        type="text"
        value={value}
        readOnly
        {...register('transferDate')}
        className={"w-full p-2 mb-2 border-2 rounded-md border-[#008065] focus:border-[#005a40] focus:outline-none"}
        style={{
          fontFamily: "poppins",
          fontSize: "14px",
          fontWeight: 400,
          borderColor: "#008065",
        }}
        ref={ref}
      />
      <FaCalendarAlt
        className="absolute right-3 top-5 transform -translate-y-1/2 cursor-pointer text-[#008065]"
        onClick={onClick}
      />
    </div>
  ));

  const fetchData = () => {
    dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
      if (res && res.type === "REGISTER_GET_SUCCESS") {

        setDateFormat(res?.payload?.invoiceDateFormat)
        setFormData((prev: any) => ({
          ...prev,
          transferDate: res?.payload?.invoiceDateFormat ? moment(transferDate).format(res?.payload?.invoiceDateFormat ? res?.payload?.invoiceDateFormat : "DD/MM/YYYY") : formatDate,
        }))
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-4 py-0 w-full h-full  mx-auto bg-white rounded-md shadow-lg border-2 border-[#008065]">
      <h1 className="text-2xl font-bold text-[#045545] text-center">Transfer Cheque</h1> {/* Centered Header */}

      <label className="block mb-1 text-md text-[#008065] font-bold">Paid To:</label>
      <input
        type="text"
        className="w-full p-2 mb-2 border-2 rounded-md border-[#008065] focus:border-[#005a40] focus:outline-none"
        {...register('paidTo', { required: true })}
        defaultValue={selectedRow?.Name}
      />
      {errors.paidTo && <p className="text-red-500">This field is required</p>}

      <label className="block mb-1 text-[#008065] font-bold">Cheque Amount:</label>
      <input
        type="number"
        className="w-full p-2 mb-2 border-2 rounded-md border-[#008065] focus:border-[#005a40] focus:outline-none"
        {...register('chequeAmount', { required: true, min: 1 })}
        defaultValue={selectedRow?.toatalAmount}
      />
      {errors.chequeAmount && <p className="text-red-500">This field is required and must be greater than 0</p>}

      <label className="block mb-1 text-[#008065] font-bold">{label}</label>
      <select
        className="w-full p-2 mb-2 border-2 rounded-md border-[#008065] focus:border-[#005a40] focus:outline-none"
        {...register('withdrawFrom', { required: true })}
      >
        <option value="CASH">Cash</option>
        {/* Add more options here as needed */}
      </select>
      {errors.withdrawFrom && <p className="text-red-500">This field is required</p>}

      <label className="block mb-1 text-[#008065] font-bold">Cheque Ref No:</label>
      <input
        type="text"
        className="w-full p-2 mb-2 border-2 rounded-md border-[#008065] focus:border-[#005a40] focus:outline-none"
        {...register('chequeNo', { required: true })}
        defaultValue={selectedRow?.chequeNum}
      />
      {errors.chequeNo && <p className="text-red-500">This field is required</p>}

      <label className="block mb-1 text-[#008065] font-bold">Transfer Date:</label>
      <DatePicker
        selected={transferDate}
        value={formData?.transferDate}
        onChange={(date) => {
          const formattedDate = moment(date).format(dateFormat);
          setTransferDate(formattedDate);
          setFormData({
            ...formData,
            transferDate: formattedDate,
          });
        }}
        dateFormat={dateFormat}
        wrapperClassName="return-datepicker"
        scrollableYearDropdown
        customInput={<CustomInputWithIcon />}
        showPopperArrow={false}
      />
      <label className="block mb-1 text-[#008065] font-bold">Description (Optional):</label>
      <textarea
        className="w-full p-2 mb-2 border-2 rounded-md border-[#008065] focus:border-[#005a40] focus:outline-none"
        {...register('description')}
        placeholder="Enter description"
        style={{ height: 'auto', minHeight: '72px' }} // Ensures the height is flexible and prevents scroll
        rows={3} // This ensures the size is fixed
      />
      <div className="px-4 py-3 sm:px-0 sm:flex justify-center sm:flex-row-reverse">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
        >
          SAVE
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>



  );
};

export default ChequeModal;
