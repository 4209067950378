import { useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ProductTables from "../../../Tables/ProductTables";
import ProductsBulkUpload from "../ProductsBulkUpload";

const ProductTab = () => {
    const [openTab, setOpenTab] = useState(1);
    const navigate = useNavigate();

    const addProduct = () => {
        navigate("/app/products");
    };


    return (
        <div>
            <div className="bg-[#E1E8E7] lg:h-[650px] xl:h-[650px] 2xl:h-[650px] h-screen  ">
                <div className=" ">
                    <ul className="flex flex-wrap text-sm font-medium text-center text-[#008065]  dark:text-gray-400 ">
                        <li className="-mb-px mr-1">
                            <button
                                style={{
                                    borderBottom: openTab === 1 ? "2px solid #008065" : "",
                                }}
                                onClick={() => setOpenTab(1)}
                                className={
                                    "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                                }
                            >
                                Product Details
                            </button>
                        </li>
                        <li className="mr-1">
                            <button
                                onClick={() => setOpenTab(2)}
                                style={{
                                    borderBottom: openTab === 2 ? "2px solid #008065" : "",
                                }}
                                className={
                                    "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                                }
                            >
                                Products Bulk Upload
                            </button>
                        </li>


                    </ul>
                </div>
                <div className=" md:mt-6 ">
                    {/* {openTab === 1 && <ProductPage setProductLists={setProductLists} productLists={productLists} />} */}
                    {openTab === 1 && (
                        <>
                            <div>
                                <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row mr-5 lg:mr-0">
                                    <div className="p-4 font-bold text-[#008065] ml-2">Product Details - Report</div>
                                    <div className="p-4 font-bold text-[#008065] ml-2">
                                        <button
                                            type="button"
                                            className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-xs h-8 px-2 block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                                            style={{ backgroundColor: "#008065" }}
                                            onClick={addProduct}
                                        >
                                            <FiPlusCircle className="h-5 w-5" />
                                            <span className="flex-1 ml-3 text-left whitespace-nowrap text-sm font-bold">
                                                Add Product
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-1">
                                <ProductTables />
                            </div>
                        </>
                    )}
                    {openTab === 2 && <ProductsBulkUpload />}
                </div>
            </div>
        </div>
    );
};

export default ProductTab;
