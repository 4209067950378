import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";

// Register all necessary Chart.js components
ChartJS.register(
	CategoryScale,
	LinearScale,
	LineElement,
	PointElement,
	BarElement,
	ArcElement,
	Title,
	Tooltip,
	Legend
);

// type SelectedRange = { range: string; startDate: string; endDate: string };

interface DashboardDonutDiaProps {
	// selectedRange: SelectedRange;
	totalRevenue: Number;
	directExpenses: Number;
	currencySymbol: any;
}

const DashboardDonutDia: React.FC<DashboardDonutDiaProps> = ({
	// selectedRange,
	totalRevenue,
	directExpenses,
	currencySymbol,
}) => {
	const initialDonutData = {
		labels: ["No Data Available"],
		datasets: [
			{
				data: [1],
				backgroundColor: ["#d3d3d3"],
				hoverBackgroundColor: ["#d3d3d3"],
			},
		],
	};

	const [donutData, setDonutData] = useState(initialDonutData);

	useEffect(() => {
		if (totalRevenue || directExpenses) {
			setDonutData({
				labels: ["Revenue", "Expenses"],
				datasets: [
					{
						data: [totalRevenue as number, directExpenses as number],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
						],
					},
				],
			});
		}
	}, [totalRevenue, directExpenses]);
	// useEffect(() => {
	// 	if (totalRevenue === 0 && directExpenses === 0) {
	// 		setDonutData(initialDonutData);
	// 	}
	// }, [totalRevenue, directExpenses]);

	const donutOptions = {
		responsive: true,
		maintainAspectRatio: false,
		cutout: "50%",
		plugins: {
			title: { display: true, text: "Donut Chart for Revenue and Expenses" },
			tooltip: {
				callbacks: {
					label: (context: any) =>
						`${context.label}: ${currencySymbol} ${context.raw}`,
				},
			},
		},
	};

	return (
		<div className="w-full h-full">
			<Pie data={donutData} options={donutOptions} />
		</div>
	);
};

export default DashboardDonutDia;
