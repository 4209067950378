import Axios from "axios";
import api from "../axiosConfig";
import {
   FEED_BACK_DELETE_FAIL,
   FEED_BACK_DELETE_REQUEST,
   FEED_BACK_DELETE_SUCCESS,
   FEED_BACK_FIND_ALL_FAIL,
   FEED_BACK_FIND_ALL_REQUEST,
   FEED_BACK_FIND_ALL_SUCCESS,
} from "../constants/feedBackConstants";

export const FeedbackPost =
   (feedback: any) => async (dispatch: any, getState: any) => {
      try {
         const config = {
            headers: {
               "Content-Type": "multipart/form-data",
            },
         };
         const { data } = await api.post(
            "/api/feedback/save",
            feedback,
            config
         );
         return data;
      } catch (error: any) {
         return error;
      }
   };

export const feedbackFindAll =
   (limit: any, offset: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: FEED_BACK_FIND_ALL_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await Axios.get(
            `/api/feed-back/find-all?limit=${limit}&offset=${offset}`,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: FEED_BACK_FIND_ALL_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: FEED_BACK_FIND_ALL_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const feedbackDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: FEED_BACK_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await Axios.delete(`/api/feedBack/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: FEED_BACK_DELETE_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: FEED_BACK_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };
