import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useDispatch } from "react-redux";
import { ProductsGet, ProductsUpdate } from "../../redux/actions/productAction";
import Barcode from "react-barcode";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";

const BarCode = () => {
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);
  const [barcodevalues, setBarcodeValues] = useState([] as any);
  const [productListValues, setProductListValues] = useState([] as any);
  const [searchTerm, setSearchTerm] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([] as any);
  const [barcodeNumber, setBarcodeNumber] = useState("");
  const [barcodeRows, setBarcodeRows] = useState<any>([]);
  console.log("barcodeRows------------->", barcodeRows)
  const [barcodeSize, setBarcodeSize] = useState({ width: 1, height: 50 })
  const [productList, setProductList] = useState([] as any);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [existBarcode, setExistBarcode] = useState(false);
  const [labelCount, setLabelCount] = useState<number>(0);  // For label count
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    productName: "",
    barcode_Number: "",
    label_count: "",
    title: "",
    first_line: "",
    second_line: "",
    user_id: userId?.token?._id,
  } as any);

  const [errors, setErrors] = useState<{ productName?: string; barcode_Number?: string; label_count?: string }>({});
  const componentRef = useRef(null);
  const StyledDiv = styled("div")({
    position: "relative",
    marginRight: "0.5rem",
    marginTop: "0.75rem",
    overflowY: "auto",
    maxHeight: "75vh",
    "&::-webkit-scrollbar": {
      width: "7px",
      height: "7px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#FFFFFF",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#d1d5db",
      borderRadius: "4px",
    },
  });
  const dispatch = useDispatch()
  const rows = barcodevalues.map((item: any, index: any) => ({
    id: uuidv4(),
    sl_No: index + 1,
    ...item,
  }));

  const columns: GridColDef[] = [
    { field: "sl_No", headerName: "S No", width: 120 },
    { field: "productName", headerName: "Product Name", width: 190 },
    {
      field: "label_count",
      headerName: "Total Labels",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "title",
      headerName: "Tittle Line",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "first_line",
      headerName: "First Line",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "second_line",
      headerName: "Second Line",
      flex: 2,
      minWidth: 190,
    },
  ];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => {
      const updatedErrors: any = { ...prevErrors };
      if (value.trim()) {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });
    if (name === "productName") {
      setSearchTerm(value);
      if (value) {
        const filtered = productListValues.filter((product: any) =>
          product.product_name.toLowerCase().includes(value.toLowerCase())
        );
        const newValue = filtered.find((product: any) => product.product_name == value);
        setBarcodeNumber(newValue?.productBar_code)
        setFilteredProducts(filtered);
      } else {
        setFilteredProducts([]);
        setBarcodeNumber('')
        setExistBarcode(false)
      }
    }
    if (name === "label_count") {
      setLabelCount(Number(value));
    }
  };

  const handleSelectProduct = (product: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      productName: product.product_name,
      barcode_Number: product.productBar_code
    }))
    setProductList(product)
    setIsProductSelected(true)
    setSearchTerm(product.product_name);
    setSalePrice(product.sellingPrice)
    setCompanyName(product.companyName)
    setBarcodeNumber(product.productBar_code)
    if (product.productBar_code) {
      setExistBarcode(true)
    }
    setFilteredProducts([]);
  };
  const fetchData = () => {
    dispatch(ProductsGet(userId?.token?._id) as any).then((res: any) => {
      if (res) {
        setProductListValues(res.payload);
      }
    });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleGenerateBarcode = () => {
    if (searchTerm) {
      // Generate a unique barcode number (e.g., using timestamp)
      const uniqueBarcode = `BC-${Date.now()}`;
      setBarcodeNumber(uniqueBarcode);
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        barcode_Number: uniqueBarcode,
      }))

    }
  };
  const handlePrint = useReactToPrint({

    content: () => componentRef.current,

    documentTitle: "Invoice Letter",
    onAfterPrint: () => {
      console.log("Print completed!");
    },
  });

  const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value) {
      setBarcodeNumber(value);
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        barcode_Number: value,
      }))
      setErrors((prevErrors) => {
        const updatedErrors: any = { ...prevErrors };
        if (e.target.value.trim()) {
          delete updatedErrors[barcodeNumber];
        }
        return updatedErrors;
      });
      const barcodeExists = productListValues.some((product: any) => product.productBar_code === value);
      if (barcodeExists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          barcode_Number: "Barcode already exists",
        }));
      } else {
        setErrors((prevErrors) => {
          const updatedErrors: any = { ...prevErrors };
          if (value.trim()) {
            delete updatedErrors.barcode_Number;
          }
          return updatedErrors;
        });
      }
    } else {
      setFormData({
        productName: "",
        barcode_Number: "",
        label_count: "",
        title: "",
        first_line: "",
        second_line: "",
        user_id: userId?.token?._id,
      });
      setBarcodeNumber('')
      setSearchTerm('')
      setSalePrice('')
    }

  };
  const validateForm = () => {
    const errors: any = {};
    if (!formData.productName.trim()) {
      errors.productName = "Product name is required.";
    }
    if (!formData.barcode_Number.trim() && barcodeNumber) {
      errors.barcode_Number = "Barcode number is required.";
    }
    if (!formData.label_count.trim() || isNaN(Number(formData.label_count))) {
      errors.label_count = "Label count is required.";
    }
    const barcodeExists = productListValues.some((product: any) => product.productBar_code === barcodeNumber);
    if (barcodeExists && !existBarcode) {
      errors.barcode_Number = "Barcode already exists";
    }
    return errors;
  };
  const handleCreateBarcode = () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    let obj = {
      productBar_code: barcodeNumber,
      product_name: searchTerm,
      price: productList.price,
    }
    dispatch(ProductsUpdate(productList._id.$oid, obj) as any).then((res: any) => {
      if (res) {
        toast.success("Update SuccessFully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
        fetchData();
      }
    });
    setBarcodeRows((prevRows: any) => [
      ...prevRows,
      {
        barcode_Number: barcodeNumber,
        labelCount: formData.label_count,
        companyName: companyName,
        salePrice: salePrice,
        productName: searchTerm,
      }
    ]);

    setBarcodeValues((prevList: any) => [...prevList, formData]);
    setFormData({
      productName: "",
      barcode_Number: "",
      label_count: "",
      title: "",
      first_line: "",
      second_line: "",
      user_id: userId?.token?._id,
    });
    setBarcodeNumber('')
    setSearchTerm('')
    setSalePrice('')
    setCompanyName('')
    setIsProductSelected(false)
    setExistBarcode(false)
  }
  const handlePreview = () => {
    setShowPopup(true);
  };
  const handleSizeChange = (e: any) => {
    const selectedSize = e.target.value;
    switch (selectedSize) {
      case "65":
        setBarcodeSize({ width: 1, height: 50 }); // Adjust as per your requirement
        break;
      case "48":
        setBarcodeSize({ width: 1.5, height: 60 });
        break;
      case "24":
        setBarcodeSize({ width: 2, height: 70 });
        break;
      case "12":
        setBarcodeSize({ width: 2.5, height: 80 });
        break;
      default:
        setBarcodeSize({ width: 1, height: 50 });
    }
  };


  return (
    <>
      <div className="flex items-center justify-center ">
        <div className="p-8 rounded-lg w-full max-w-8xl">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 mb-6">
            <div className="col-span-1 flex justify-center sm:justify-start">
              <h2
                style={{
                  color: "#008065",
                }}
                className="text-lg font-bold text-[#045545] text-center sm:text-left"
              >
                Create Barcode
              </h2>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 sm:justify-end ">
              <div className="col-span-1 justify-left sm:justify-end">
                <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-sm   font-medium text-[#045545]"
                  >
                    Printer:
                  </label>
                  <select className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-auto">
                    <option>Regular Printer</option>
                  </select>
                </div>
              </div>

              <div className="col-span-1 justify-left sm:justify-end">
                <div className="flex flex-col sm:flex-row sm:items-center justify-left gap-2">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-sm font-medium text-[#045545]"
                  >
                    Size:
                  </label>
                  <select className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-auto"
                    onChange={handleSizeChange}>
                    <option value="65">65 Labels(38*21mm)</option>
                    <option value="48">48 Labels(48*24mm)</option>
                    <option value="24">24 Labels(64*34mm)</option>
                    <option value="12">12 Labels(100*44mm)</option>

                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-6 items-start mb-8 w-full">
            <div className="flex flex-col w-full lg:w-3/4 gap-6">
              <div className="flex flex-col sm:flex-row gap-4 w-full">
                <div className="relative w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="itemName"
                  >
                    Product Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={searchTerm}
                    name="productName"
                    autoComplete="off"
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Search product"
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '13px',
                      fontWeight: 400,
                      borderColor: errors?.productName ? "red" : "#00A787",
                    }}
                  />
                  {filteredProducts.length > 0 && (
                    <div
                      className="absolute z-10 bg-white border border-gray-300 rounded shadow-md max-h-48 overflow-y-auto w-full"
                      style={{ top: '100%' }}
                    >
                      {filteredProducts.map((product: any, index: any) => (
                        <div
                          key={index}
                          onClick={() => handleSelectProduct(product)}
                          className="px-2 py-1 hover:bg-gray-200 cursor-pointer text-sm sm:text-base md:text-lg flex justify-between  "
                        >
                          <span className="block truncate text-base">{product.product_name}</span> - <span className="block text-base truncate">{product.productBar_code}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  {errors?.productName && (
                    <p
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {errors?.productName}
                    </p>
                  )}
                </div>
                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="itemCode"
                  >
                    Barcode<span className="text-red-500">*</span>
                  </label>
                  {barcodeNumber ? (<>
                    <input
                      type="text"
                      id="itemCode"
                      value={barcodeNumber}
                      name="barcode_Number"
                      onChange={handleBarcodeChange}
                      disabled={existBarcode ? true : false}
                      placeholder="Assign Code"
                      className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full "
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: 400,
                        borderColor:
                          errors?.barcode_Number
                            ? "red"
                            : "#00A787",
                      }}
                    />
                    {errors?.barcode_Number && (

                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-sm"
                      >
                        {errors?.barcode_Number}
                      </p>
                    )}
                  </>


                  ) : (
                    <button
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                      onClick={handleGenerateBarcode}
                      disabled={!isProductSelected}
                      className={`${isProductSelected
                        ? "rounded-lg font-bold text-white text-sm h-8 w-full block bg-[#008065] flex items-center justify-center gap-2"
                        : "rounded-lg font-bold text-white text-sm h-8 w-full block bg-[#008065] flex items-center justify-center gap-2   opacity-70  cursor-not-allowed disabled"
                        }`}
                    >
                      Assign Barcode
                    </button>
                  )}


                </div>

                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md font-medium mb-2 "
                    htmlFor="noOfLabels"
                  >
                    Total Labels<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="label_count"
                    autoComplete="off"
                    value={formData.label_count}
                    onChange={(e) => handleInputChange(e)}
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '13px',
                      fontWeight: 400,
                      borderColor:
                        errors?.label_count
                          ? "red"
                          : "#00A787",
                    }}
                    id="noOfLabels"
                    placeholder="Total Labels"
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                  />
                  {errors?.label_count && (

                    <p
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {errors?.label_count}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-4 w-full">
                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="line1"
                  >
                    Tittle Line
                  </label>
                  <select
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full cursor-pointer"
                    name="title"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderColor: "#008065",
                    }}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    <option value={companyName}>Company Name</option>
                    <option value={searchTerm}>Product Name</option>
                    <option value={salePrice}>   Sale Price   </option>
                  </select>
                </div>

                {/* First Line */}
                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="firstLine"
                  >
                    First Line
                  </label>
                  <select
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full cursor-pointer"
                    name="first_line"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderColor: "#008065",
                    }}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    <option value={companyName}>Company Name</option>
                    <option value={searchTerm}>Product Name</option>
                    <option value={salePrice}>  Sale Price   </option>
                  </select>
                </div>

                {/* Second Line */}
                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="line2"
                  >
                    Second Line
                  </label>
                  <select
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full cursor-pointer"
                    name="second_line"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderColor: "#008065",
                    }}

                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    <option value={companyName}>{companyName || "Company Name"}</option>
                    <option value={searchTerm}>Product Name</option>
                    <option value={salePrice}> Sale Price  </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/4   flex flex-col items-center gap-4 ">
              <div className="w-full border-2 border-[#008065] rounded-md p-6 text-center lg:mt-7 bg-gray-70  ">
                <p
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#008065",
                  }}
                  className="text-sm font-semibold"
                >
                  {formData.title}
                </p>
                <p
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#008065",
                  }}
                  className="text-sm pb-1"
                >
                  {formData.first_line}
                </p>

                <p
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#008065",
                  }}
                  className="text-sm pt-1"
                >
                  {formData.second_line}
                </p>

                <p className=" mx-auto flex justify-center">
                  {barcodeNumber && (
                    <Barcode
                      value={barcodeNumber}
                      width={1}
                      height={50}
                      fontSize={14}
                      background="#ffffff"
                      lineColor="#000000"
                    />
                  )}</p>


              </div>

              <button
                style={{
                  backgroundColor: "#008065",
                  fontSize: "14px",
                  fontFamily: "Poppins, sans-serif",
                }}
                onClick={handleCreateBarcode}
                className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065] flex items-center justify-center gap-2"
              >
                <IoMdAddCircleOutline className="w-5 h-5" />
                Create Barcode
              </button>
            </div>
          </div>
          <Box
            sx={{
              height: "50vh",
              width: "100%",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <DataGrid
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: 500,
                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                {
                  backgroundColor: "#fff",
                  color: "#008060 !important",
                  fontWeight: 600,
                  fontSize: "14px",
                },
                "& .MuiDataGrid-checkboxInput": {
                  color: "#008060 !important",
                },
                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                  fontWeight: 600,
                  fontSize: "14px",
                },
              }}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>

          <div className="flex justify-end gap-6">
            <button
              style={{
                backgroundColor: "#008065",
                fontSize: "14px",
                fontFamily: "poppins",
              }}
              disabled={barcodevalues.length === 0 ? true : false}
              onClick={handlePreview}
              className={barcodevalues.length === 0 ? "opacity-70 rounded-xl font-bold text-white  text-sm h-8  w-36 block  bg-[#008065]" : " rounded-xl font-bold text-white  text-sm h-8  w-36 block  bg-[#008065] "}
            >
              Preview
            </button>
            <button
              style={{
                backgroundColor: "#008065",
                fontSize: "14px",
                fontFamily: "poppins",
              }}
              className={barcodevalues.length === 0 ? " opacity-70 rounded-xl font-bold text-white  text-sm h-8  w-36 block  bg-[#008065]" : " rounded-xl font-bold text-white  text-sm h-8  w-36 block  bg-[#008065] "}
              disabled={barcodevalues.length === 0 ? true : false}
              onClick={handlePrint}
            >
              Generate
            </button>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 transition-opacity">
          <StyledDiv className="bg-white p-5 rounded-md shadow-lg max-h-[80vh] overflow-y-auto">
            <div className={barcodeSize.width === 2.5 ? "grid grid-cols-2 gap-4" : "grid grid-cols-3 gap-4"}>
              {barcodeRows.map((barcodeRow: any, index: any) => (
                Array.from({ length: parseInt(barcodeRow.labelCount) }, (_, labelIndex) => (
                  <div key={`${index}-${labelIndex}`} className="flex flex-col items-center">
                    <span>{barcodeRow.companyName}</span>
                    <Barcode
                      value={`${barcodeRow.barcode_Number}-${labelIndex + 1}`}
                      width={barcodeSize.width}
                      height={barcodeSize.height}
                      fontSize={14}
                      background="#ffffff"
                      lineColor="#000000"
                    />
                    <span>{barcodeRow.productName}</span>
                    <span>Sale Price - {barcodeRow.salePrice}</span>
                  </div>
                ))
              ))}
            </div>

            <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
            </div>
          </StyledDiv>
        </div>
      )}
      <div style={{ display: "none" }}>
        <div ref={componentRef} >
          <div style={{ breakBefore: "page" }}></div>
          <div
            className={
              barcodeSize.width === 2.5
                ? "grid grid-cols-2 gap-4"
                : "grid grid-cols-3 gap-4"
            }
          >
            {barcodeRows.map((barcodeRow: any, index: any) =>
              Array.from(
                { length: parseInt(barcodeRow.labelCount) },
                (_, labelIndex) => (
                  <div key={`${index}-${labelIndex}`} className="flex flex-col items-center">
                    <span>{barcodeRow.companyName}</span>
                    <Barcode
                      value={`${barcodeRow.barcode_Number}-${labelIndex + 1}`}
                      width={barcodeSize.width}
                      height={barcodeSize.height}
                      fontSize={14}
                      background="#ffffff"
                      lineColor="#000000"
                    />
                    <span>{barcodeRow.productName}</span>
                    <span>Sale Price - {barcodeRow.salePrice}</span>
                  </div>
                )
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BarCode;
