import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { registerAll, updateUser } from '../../redux/actions/userAction';
import { toast } from 'react-toastify';

export const TableSettings = () => {

  const [isChecked, setIsChecked] = useState(false);
  const [isPoNumberChecked, setIsPoNumberChecked] = useState(false);
  const [isPaymentTypeChecked, setIsPaymentTypeChecked] = useState(false);
  const [initialChecked, setInitialChecked] = useState(false);
  const [initialPoNumberChecked, setInitialPoNumberChecked] = useState(false);
  const [initialpaymentTypeChecked, setInitialPaymentTypeChecked] = useState(false);
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);
  const dispatch = useDispatch();

  const handleCheckboxChange = (event: any) => {
    const checked = event.target.checked;
    setIsChecked(checked);
  };

  const handlePoNumberCheckboxChange = (event: any) => {
    const checkedPonumber = event.target.checked;
    setIsPoNumberChecked(checkedPonumber);
  };
 

  const fetchData = () => {
    dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
      if (res && res.type === "REGISTER_GET_SUCCESS") {
        const hsnNo = res?.payload?.hsnNo ? res?.payload?.hsnNo : false;
        const ponumberanddate = res?.payload?.ponumberanddate ? res?.payload?.ponumberanddate : false;
        const paymenttype = res?.payload?.paymenttype ? res?.payload?.paymenttype : false;
        setIsChecked(hsnNo);
        setIsPoNumberChecked(ponumberanddate);
        setIsPaymentTypeChecked(paymenttype);
        setInitialChecked(hsnNo);
        setInitialPoNumberChecked(ponumberanddate);
        setInitialPaymentTypeChecked(paymenttype);
      }
    });
  };

  useEffect(() => {
    if (userId?.token?._id) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let obj = {
      hsnNo: isChecked,
      ponumberanddate: isPoNumberChecked,
      paymenttype: isPaymentTypeChecked
    };

    dispatch(updateUser(userId?.token?._id, obj) as any).then((res: any) => {
      if (res) {
        fetchData();
        toast.success("Saved Successfully!", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
      }
    });
  };

  const handleCancel = () => {
    setIsChecked(initialChecked);
    setIsPoNumberChecked(initialPoNumberChecked);
    setIsPaymentTypeChecked(initialpaymentTypeChecked);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <fieldset className="mx-2 mb-4 rounded-lg p-3">
          <legend className="p-4 font-bold text-[#045545] text-lg ml-2">
            Table Settings
          </legend>
          <div className="flex ml-6 gap-2">
            <div>
              <input
                type="checkbox"
                className="border-2 focus:outline-none mx-2 rounded-[7px] px-2 h-5 w-5 accent-green-700"
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="text-[#008065] font-semibold mb-4">
              <label>Show HSN/SAC Number</label>
            </div>
          </div>

          <div className="flex ml-6 gap-2">
            <div>
              <input
                type="checkbox"
                className="border-2 focus:outline-none mx-2 rounded-[7px] px-2 h-5 w-5 accent-green-700"
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
                checked={isPoNumberChecked}
                onChange={handlePoNumberCheckboxChange}
              />
            </div>
            <div className="text-[#008065] font-semibold mb-4">
              <label>Show PO Number and PO Date </label>
            </div>
          </div>

          {/* <div className="flex ml-6 gap-2">
            <div>
              <input
                type="checkbox"
                className="border-2 focus:outline-none mx-2 rounded-[7px] px-2 h-5 w-5 accent-green-700"
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
                checked={isPaymentTypeChecked}
                onChange={handlePaymentTypeCheckboxChange}
              />
            </div>
            <div className="text-[#008065] font-semibold mb-4">
              <label>Show Payment Type </label>
            </div>
          </div> */}
          <div className="flex w-full flex-col sm:flex-row px-4">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="flex flex-col mb-6"></div>
            </div>
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="flex flex-col">
                <div className="relative"></div>
              </div>
            </div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 mt-5">
              <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 rounded">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2 mt-5">
              <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40">
                <button
                  type="submit"
                  className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
