import React, {
	useCallback,
	useEffect,
	useState,
	useRef,
	forwardRef,
  } from "react";
  import { FormControlLabel, Switch } from "@mui/material";
  import { FaArrowLeft} from "react-icons/fa";
  import { useNavigate } from "react-router-dom";
  import { IoSearch } from "react-icons/io5";
  import { posGet } from "../../redux/actions/posAction";
  import { useDispatch, useSelector } from "react-redux";
  import { currencyGet } from "../../redux/actions/currencyAction";
  import { InternalPost } from "../../redux/actions/InternalInvoiceAction";
  import moment from "moment";
  import { registerAll } from "../../redux/actions/userAction";
  type Product = {
	sellingPrice: number;
	qty: number;
	discount: number;
	gst: number;
	igstTax: number;
	stockQty: number;
	product_flow: string;
  };
  
  const Pos = () => {
	const navigateTo = useNavigate();
	const value = () => {
	  navigateTo("/app/sales");
	};
  
	// const getCurrentDate = () => {
	//   const today = new Date();
	//   const year = today.getFullYear();
	//   const month = String(today.getMonth() + 1).padStart(2, "0");
	//   const day = String(today.getDate()).padStart(2, "0");
	//   return `${day}-${month}-${year}`;
	// };
  
	const [searchTerm, setSearchTerm] = useState("");
	const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  
	const [selectedDropdownRowIndex, setSelectedDropdownRowIndex] =
	  useState<number>(0);
	const [selectedClientRowIndex, setSelectedClientRowIndex] =
	  useState<number>(0);
	// const [selectedProducts, setSelectedProducts] = useState([
	// 	{ unitPrice: 0, quantity: 0, discount: 0 },
	// ]);
	const [totalAmounts, setTotalAmounts] = useState<number[]>([]);
	const [subTotals, setSubTotals] = useState(0);
	const [disAmount, setDisAmount] = useState(0);
	const [nontaxableAmounts, setNontaxableAmounts] = useState<number[]>([]);
  
	const [paymentInReceiptNo, setPaymentInReceiptNo] = useState(null as any);
	const [paymentMethod, setPaymentMethod] = useState("CASH");
	const [creditAmount, setcreditAmount] = useState("" as any);
	const [invoiceBillNo, setinvoiceBillNo] = useState(null as any);
	const [showDropdown, setShowDropdown] = useState(false);
	const [extraFees, setExtraFees] = useState<number | string>("");
	const [balanceChange, setBalanceChange] = useState(0 as any);
	const [invoiceType, setInvoiceType] = useState<string>("Cash Invoice");
  
	const dropdownRef = useRef<HTMLDivElement>(null);
	  const cldropdownRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
  
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(false);
	const [isBillDiscountOpen, setIsBillDiscountOpen] = useState(false);
  
	const [clientSearchTerm, setClientSearchTerm] = useState("");
	const [filteredClients, setFilteredClients] = useState<any[]>([]);
	const [showclDropdown, setClShowDropdown] = useState(false);
	const [selectedClient, setSelectedClient] = useState<any>(null);
	const [isToggledForGST, setIsToggledForGST] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const usersignin = useSelector((state: any) => state.userSignin);
	const User_id = sessionStorage.getItem("userInfo");
	const userId = JSON.parse(User_id as any);
	const [posDate, setposDate] = useState(new Date() as any);
	const formatDate = moment(new Date()).format("DD/MM/YYYY");
	const [dateFormat, setDateFormat] = useState("DD/MM/YYYY");
	const [formData, setFormData] = useState({
	  posDate: formatDate,
	} as any);
  
	const handleGSTToggleChange = (
	  event: React.ChangeEvent<HTMLInputElement>
	) => {
	  setIsToggledForGST(event.target.checked);
	};
	const shortcutbtnRef = useRef<HTMLInputElement>(null);
  
	const quantitybtnRef = React.useRef<HTMLButtonElement>(null);
	const discountbtnRef = React.useRef<HTMLButtonElement>(null);
	const extrafeesbtnRef = React.useRef<HTMLButtonElement>(null);
	const billdiscountbtnRef = React.useRef<HTMLButtonElement>(null);
	const remarksbtnRef = React.useRef<HTMLButtonElement>(null);
	const deletebtnRef = React.useRef<HTMLButtonElement>(null);
	useEffect(() => {
	  setPaymentMethod(invoiceType === "Cash Invoice" ? "CASH" : "CREDIT");
	}, [invoiceType]);
	const dispatch = useDispatch();
	const [posData, setPosData] = useState({
	  saleproducts: [],
	  latest_invoice_no: "",
	  tittleResponse: [] as any[],
	  client_details: [],
	  companyDetails: [],
	  latest_paymentinreceipt_no: 0,
	  settingDetails: [],
	});
	const [tittle, setTittle] = useState({
	  invoiceNumber: "",
	  invoiceYear: "",
	  invoiceDigit: "",
	  invoiceTittle: "",
	});
  
	const fetchData = useCallback(() => {
	  dispatch(posGet() as any).then((response: any) => {
		if (response) {
		  const data = response.payload;
		  const tittleResponse = JSON.parse(data?.tittles || "[]");
		  const tittleList = tittleResponse?.find((x: any) => x);
  
		  setPosData({
			saleproducts: data.productSale || [],
			latest_invoice_no: data.latest_invoice_no || "",
			tittleResponse: tittleResponse || [],
			client_details: data.client_details,
			companyDetails: data.companyDetails,
			latest_paymentinreceipt_no: data.latest_paymentinreceipt_no,
			settingDetails: data.settingDetails,
		  });
  
		  // Set the tittle state with values from tittleList
		  if (tittleList) {
			setTittle({
			  invoiceNumber: tittleList?.invoiceNumber || "",
			  invoiceYear: tittleList?.invoiceYear || "",
			  invoiceDigit: tittleList?.invoiceDigit || "",
			  invoiceTittle: tittleList?.invoiceTittle || "",
			});
		  }
		}
	  });
  
	  dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
		if (res && res.type === "REGISTER_GET_SUCCESS") {
		  setDateFormat(res?.payload?.invoiceDateFormat);
		  setFormData((prev: any) => ({
			...prev,
			posDate: res?.payload?.invoiceDateFormat
			  ? moment(posDate).format(
				  res?.payload?.invoiceDateFormat
					? res?.payload?.invoiceDateFormat
					: "DD/MM/YYYY"
				)
			  : formatDate,
		  }));
		}
	  });
	}, [dispatch]);
  
	useEffect(() => {
	  fetchData();
	}, [fetchData]);
  
	const handleDateChange = (date: any) => {
	  const formattedDate = moment(date).format(dateFormat);
  
	  if (formattedDate !== formData.posDate) {
		setposDate(date);
		setFormData((prevFormData: any) => ({
		  ...prevFormData,
		  posDate: formattedDate,
		}));
	  }
	};
  
	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
	  const value = e.target.value.toLowerCase();
	  setSearchTerm(value);
	  setSelectedDropdownRowIndex(0); // if search reset the index
	  if (value.trim() === "") {
		// Close the dropdown when input is empty
		setFilteredProducts([]);
		setShowDropdown(false);
	  } else {
		// Filter products by name or barcode
		const filtered = posData.saleproducts?.filter(
		  (product: any) =>
			product.productName.toLowerCase().includes(value) ||
			product.productUnique_id.toLowerCase().includes(value) // Barcode filtering
		);
		setFilteredProducts(filtered);
		setShowDropdown(filtered?.length > 0);
	  }
	};
  
	const handleFilterData = (product: any) => {
	  setShowDropdown(false);
	  inputRef.current?.focus();
	  setSearchTerm("");
	  setFilteredProducts([]); //reset filtered product
  
	  const isAlreadySelected = selectedProducts.some(
		(p: any) => p._id === product._id
	  );
  
	  if (!isAlreadySelected) {
		setSelectedProducts([...selectedProducts, product]);
	  }
	};
	
  
	const handleInputChange = (index: number, field: string, value: string) => {
	  if (!isNaN(Number(value)) && Number(value) >= 0) {
		setSelectedProducts((prevProducts) => {
		  let updatedProducts = [...prevProducts];
		  if (updatedProducts?.length === 0) {
			updatedProducts = [
			  {
				sellingPrice: 0,
				qty: 0,
				discount: 0,
				gst: 0,
				igstTax: 0,
				stockQty: 0,
				product_flow: "",
			  },
			];
		  }
  
		  updatedProducts = updatedProducts.map((product, i) =>
			i === index ? { ...product, [field]: Number(value) } : product
		  );
		  if (
			field === "qty" &&
			Number(value) > (updatedProducts[index]?.stockQty || 0) &&
			updatedProducts[index].product_flow === "Vendor Required"
		  ) {
			alert(
			  `Quantity cannot exceed. Available stock (${
				updatedProducts[index]?.stockQty || 0
			  })`
			);
			return prevProducts;
		  }
		  let subTotalTaxDis = 0;
		  let discountedPricee = 0;
		  let nonTaxableAmountss: number[] = [];
		  const newTotals = updatedProducts.map((product) => {
			const unitPrice = product.sellingPrice || 0;
			const quantity = product.qty || 0;
			const discount = product.discount || 0;
			const taxgst = isToggledForGST ? product.igstTax : product.gst || 0;
  
			const discountedPrice = unitPrice * quantity * (1 - discount / 100);
			discountedPricee += discountedPrice;
			nonTaxableAmountss.push(discountedPrice);
			const subTotal = unitPrice * quantity;
			subTotalTaxDis += subTotal;
			return discountedPrice * (1 + taxgst / 100);
		  });
		  setNontaxableAmounts(nonTaxableAmountss);
		  setDisAmount(discountedPricee);
		  setTotalAmounts(newTotals);
		  setSubTotals(subTotalTaxDis);
		  return updatedProducts;
		});
	  }
	};
	useEffect(() => {
	  let subTotalTaxDis = 0;
	  let discountedPricee = 0;
	  let nonTaxableAmountss: number[] = [];
	  // let totalWithGst = 0;
	  const newTotals = selectedProducts.map((item) => {
		const unitPrice = item.sellingPrice || 0;
		const quantity = item.qty || 0;
		const discount = item.discount || 0;
		const gst = isToggledForGST ? item.igstTax : item.gst || 0;
  
		const subTotal = unitPrice * quantity;
  
		const discountedPrice = subTotal * (1 - discount / 100);
		discountedPricee += discountedPrice;
		nonTaxableAmountss.push(discountedPrice);
		const totalWithGst = discountedPrice * (1 + gst / 100);
  
		subTotalTaxDis += subTotal;
  
		return totalWithGst;
	  });
	  setNontaxableAmounts(nonTaxableAmountss);
	  setDisAmount(discountedPricee);
	  setSubTotals(subTotalTaxDis);
	  setTotalAmounts(newTotals);
	}, [isToggledForGST, selectedProducts]);
  
	const token = usersignin?.userInfo?.token;
  
	const [currencySymbol, setcurrencySymbol] = useState("₹");
	useEffect(() => {
	  dispatch(currencyGet(token?._id) as any).then((res: any) => {
		if (res) {
		  let data = res.payload.find((value: any) => value.defaultInfo === true);
		  if (data?.currencySymbol) {
			setcurrencySymbol(data.currencySymbol);
		  } else {
			setcurrencySymbol("₹");
		  }
		}
	  });
	}, []);
  
	useEffect(() => {
	  setFilteredProducts(posData.saleproducts);
	  setShowDropdown(false);
	}, [posData]);
  
	//  table row
	const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
	const qtyRefs = useRef<(HTMLInputElement | null)[]>([]);
	const discountRefs = useRef<(HTMLInputElement | null)[]>([]);
  
	const handleRowClick = (index: number) => {
	  setSelectedRowIndex(index);
	};
	const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
	  if (e.key === "ArrowDown") {
		setSelectedRowIndex((prevIndex) => {
		  const newIndex =
			prevIndex !== null && prevIndex < selectedProducts.length - 1
			  ? prevIndex + 1
			  : prevIndex ?? 0;
		  focusInput(newIndex);
		  return newIndex;
		});
	  } else if (e.key === "ArrowUp") {
		setSelectedRowIndex((prevIndex) => {
		  const newIndex =
			prevIndex !== null && prevIndex > 0 ? prevIndex - 1 : 0;
		  focusInput(newIndex);
		  return newIndex;
		});
	  } else if (e.key.toLowerCase() === "q" && selectedRowIndex !== null) {
		qtyRefs.current[selectedRowIndex]?.focus();
	  } else if (e.key.toLowerCase() === "d" && selectedRowIndex !== null) {
		discountRefs.current[selectedRowIndex]?.focus();
	  }
	};
  
	const focusInput = (index: number) => {
	  discountRefs.current[index]?.focus();
	  qtyRefs.current[index]?.focus();
	};
	const handleQtyDiscountKeyDown = (
	  e: React.KeyboardEvent<HTMLInputElement>,
	  field: "qty" | "discount"
	) => {
	  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
		e.preventDefault();
	  }
	};
	//  Client table row
	const handleClientKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
	  if (selectedClientRowIndex >= 0) {
		if (e.key === "ArrowDown") {
		  e.preventDefault();
		  setSelectedClientRowIndex((prevIndex) =>
			prevIndex < filteredClients.length - 1 ? prevIndex + 1 : prevIndex
		  );
		} else if (e.key === "ArrowUp") {
		  e.preventDefault();
		  setSelectedClientRowIndex((prevIndex) =>
			prevIndex > 0 ? prevIndex - 1 : prevIndex
		  );
		} else if (e.key === "Enter" && selectedClientRowIndex >= 0) {
		  setSelectedClientRowIndex(0);
		  handleClientSelection(filteredClients[selectedClientRowIndex]);
		}
	  }
	};
  
	//search table product by name -----+
	const handleDropdownNavigation = (
	  e: React.KeyboardEvent<HTMLInputElement>
	) => {
	  if (searchTerm !== "") {
		if (e.key === "ArrowDown") {
		  e.preventDefault();
		  setSelectedDropdownRowIndex((prevIndex) => {
			const nextIndex =
			  prevIndex < filteredProducts?.length - 1
				? prevIndex + 1
				: prevIndex;
			scrollToSelectedRow(nextIndex);
			return nextIndex;
		  });
		} else if (e.key === "ArrowUp") {
		  e.preventDefault();
		  setSelectedDropdownRowIndex((prevIndex) => {
			const nextIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
			scrollToSelectedRow(nextIndex);
			return nextIndex;
		  });
		} else if (e.key === "Enter") {
		  setSelectedDropdownRowIndex(0);
		  const selectedProduct = filteredProducts[selectedDropdownRowIndex];
		  handleFilterData(selectedProduct);
		  setSearchTerm("");
		  setFilteredProducts([]);
		}
	  } else {
		setSelectedDropdownRowIndex(0);
	  }
	};
  
	// Function to scroll to the selected row
	const scrollToSelectedRow = (index: number) => {
	  const row = document.getElementById(`dropdown-row-${index}`);
	  if (row) {
		row.scrollIntoView({ behavior: "smooth", block: "center" });
	  }
	};
	//
	useEffect(() => {
	  const handleKeyDown = (event: KeyboardEvent) => {
			  // if (event.key === "Escape" || event.key === "F2") {
				  if ( event.key === "F2") {
				  inputRef.current?.focus();
				  setSearchTerm("");
			  }
				  if (event.key === "Escape"){
					  setShowDropdown(false);
					  setClShowDropdown(false);
					  setIsRemarkModalOpen(false);
					  setIsModalOpen(false);
					  setIsBillDiscountOpen(false);
					  setClShowDropdown(false);
				  }
  
		// if (event.key.toLowerCase() === "q") {
		// 	const target = event.target as HTMLElement;
		// 	const isInputFocused =
		// 		target.tagName === "INPUT" ||
		// 		target.tagName === "TEXTAREA" ||
		// 		target.isContentEditable;
  
		// 	if (isInputFocused) {
		// 		return;
		// 	}
		// 	event.preventDefault();
		// 	quantitybtnRef.current?.click();
		// 	quantitybtnRef.current?.focus();
		// }
		// if (event.key.toLowerCase() === "d") {
		// 	const target = event.target as HTMLElement;
		// 	const isInputFocused =
		// 		target.tagName === "INPUT" ||
		// 		target.tagName === "TEXTAREA" ||
		// 		target.isContentEditable;
  
		// 	if (isInputFocused) {
		// 		return;
		// 	}
  
		// 	event.preventDefault();
		// 	discountbtnRef.current?.click();
		// 	discountbtnRef.current?.focus();
		// }
  
		if (event.key === "F1") {
		  event.preventDefault();
		  shortcutbtnRef.current?.click();
		  shortcutbtnRef.current?.focus();
		}
		if (event.key === "F3") {
		  event.preventDefault();
		  extrafeesbtnRef.current?.click();
		  extrafeesbtnRef.current?.focus();
		  setIsRemarkModalOpen(false);
		  setIsBillDiscountOpen(false);
		}
		if (event.key === "F4") {
		  event.preventDefault();
		  billdiscountbtnRef.current?.click();
		  billdiscountbtnRef.current?.focus();
		  setIsModalOpen(false);
		  setIsRemarkModalOpen(false);
		}
		if (event.key === "F5") {
		  event.preventDefault();
		  remarksbtnRef.current?.click();
		  remarksbtnRef.current?.focus();
		  setIsModalOpen(false);
		  setIsBillDiscountOpen(false);
		}
		if (event.key === "Delete") {
		  event.preventDefault();
		  deletebtnRef.current?.click();
		  deletebtnRef.current?.focus();
		}
	  };
  
	  const handleClickOutside = (event: MouseEvent) => {
		if (
		  dropdownRef.current &&
		  !dropdownRef.current.contains(event.target as Node)
				   ||
				  cldropdownRef.current &&
				  !cldropdownRef.current.contains(event.target as Node)
		) {
		  setShowDropdown(false);
		  inputRef.current?.focus();
		  setSearchTerm("");
				  setClShowDropdown(false);
		}
	  };
  
	  document.addEventListener("mousedown", handleClickOutside);
  
	  window.addEventListener("keydown", handleKeyDown);
  
	  return () => {
		document.removeEventListener("mousedown", handleClickOutside);
		window.removeEventListener("keydown", handleKeyDown);
	  };
	}, []);
  
	const handleSaveExtraFees = () => {
	  const fees = parseFloat(extraFees as string);
	  if (!isNaN(fees)) {
		setExtraFees(fees);
	  } else {
		setExtraFees(0);
	  }
	  setIsModalOpen(false);
	};
	const roundedTotal = totalAmounts.reduce((sum, amount) => sum + amount, 0);
	const subTotal = Math.ceil(roundedTotal * 100) / 100;
  
	const discountAmount = subTotals - disAmount;
	const taxAmountt = subTotal - disAmount;
	const grandTotalValue =
	  subTotal + (typeof extraFees === "number" ? extraFees : Number(extraFees));
	// const grandTotal = Math.round(grandTotalValue);
	// const roundOffAmount = grandTotal - grandTotalValue;
  
	let match = posData.latest_invoice_no?.match(/\d{4}$/);
	let result = "";
	if (match) {
	  let value = match[0];
	  result = (parseInt(value) + 1).toString().padStart(4, "0");
	}
  
	const year = new Date().getFullYear();
  
	let invoice =
	  `${tittle?.invoiceNumber ? tittle?.invoiceNumber : "lala-"}` +
	  // String(`${month}/`) +
	  String(`${tittle?.invoiceYear ? tittle?.invoiceYear : year}/`) +
	  String(
		tittle?.invoiceDigit
		  ? String(Number(tittle?.invoiceDigit) + result)
		  : result
	  );
  
	////////////////////////////////////////////////////companyDetails////////////////////////////////////////
	const companyDetails = posData.companyDetails as Array<{
	  company_name: string;
	  company_address: string;
	  city: string;
	  state: string;
	  postal_code: string;
	  company_mobile: string;
	  company_email: string;
	  pan_no: string;
	  gst: string;
	}>;
	let companyAddress = "";
	let companyCity = "";
	let companyState = "";
	let companyPostalCode = "";
	let companyMobile = "";
	let companyEmail = "";
	let companyPan = "";
	let companyGst = "";
	let companyName = "";
  
	for (let i = 0; i < companyDetails?.length; i++) {
	  companyName = companyDetails[i].company_name;
  
	  companyAddress = companyDetails[i].company_address;
	  companyCity = companyDetails[i].city;
	  companyState = companyDetails[i].state;
	  companyPostalCode = companyDetails[i].postal_code;
	  companyMobile = companyDetails[i].company_mobile;
	  companyEmail = companyDetails[i].company_email;
	  companyPan = companyDetails[i].pan_no;
	  companyGst = companyDetails[i].gst;
	}
  
	////////////////////////////////////////////////////UserData////////////////////////////////////////
  
	const settingDetailss = posData.settingDetails as unknown as {
	  invoiceDateFormat: string;
	  taxType: string;
	  gstValue: string;
	  RoundOffType: string;
	  RoundOffEnabled: boolean;
	  AmountFormat: string;
	  invoiceTemplateType: string;
	  colorTemplateType: string;
	};
  
	let invoiceDateFormat = settingDetailss.invoiceDateFormat || "";
	let taxType = settingDetailss.taxType || "";
	let gstValue = settingDetailss.gstValue || "";
	let RoundOffType = settingDetailss.RoundOffType || "";
	let RoundOffEnabled = settingDetailss.RoundOffEnabled;
	let AmountFormat = settingDetailss.AmountFormat || "";
	let invoiceTemplateType = settingDetailss.invoiceTemplateType || "";
	let colorTemplateType = settingDetailss.colorTemplateType || "";
  
	const [roundOffAmountt, setRoundOffAmount] = useState(0);
	const [grandTotal, setGrandTotal] = useState(grandTotalValue);
	console.log("settingDetailss", settingDetailss);
	useEffect(() => {
	  let calculatedGrandTotal = grandTotalValue;
	  let calculatedRoundOff = 0;
	  if (RoundOffEnabled === true) {
		if (RoundOffType === "Nearest") {
		  calculatedGrandTotal = Math.round(grandTotalValue);
		} else if (RoundOffType === "Up To") {
		  calculatedGrandTotal = Math.ceil(grandTotalValue);
		} else if (RoundOffType === "Down To") {
		  calculatedGrandTotal = Math.floor(grandTotalValue);
		} else {
		  // Default case: no rounding
		  calculatedGrandTotal = grandTotalValue;
		}
	  }
	  calculatedRoundOff = calculatedGrandTotal - grandTotalValue;
  
	  setGrandTotal(calculatedGrandTotal);
	  setRoundOffAmount(calculatedRoundOff);
	}, [RoundOffEnabled, RoundOffType, grandTotalValue]);
  
	const handleSubmit = (e: any) => {
	  const invoiceData = {
		errorMessage: "",
		errors: {},
		paymentInReceiptNo: posData.latest_paymentinreceipt_no,
		invoicehead: "INVOICE",
		invoiceNo: invoice,
		invoicedate: formData.posDate,
		poNumber: "",
		poDate: "",
		duedate: "",
		imageData: "",
		companyName: companyName,
		companyAddress: companyAddress,
		companyCity: companyCity,
		companyState: companyState,
		companyPostalCode: companyPostalCode,
		companyMobile: companyMobile,
		companyEmail: companyEmail,
		companyPan: companyPan,
		companygst: companyGst,
		clientName: selectedClient?.client_name
		  ? selectedClient?.client_name
		  : "CashSale",
		clientAddress: selectedClient?.client_address
		  ? selectedClient?.client_address
		  : "",
		clientCity: selectedClient?.city ? selectedClient?.city : "",
		clientState: selectedClient?.state ? selectedClient?.state : "",
		clientPostalCode: selectedClient?.postal_code
		  ? selectedClient?.postal_code
		  : "",
		clientMobile: selectedClient?.client_mobile
		  ? selectedClient?.client_mobile
		  : "",
		clientEmail: selectedClient?.client_email
		  ? selectedClient?.client_email
		  : "",
		clientPan: selectedClient?.pan_no ? selectedClient?.pan_no : "",
		clientgst: selectedClient?.gst ? selectedClient?.gst : "",
		clientId: selectedClient?._id ? selectedClient?._id : "",
		subtotal: subTotal.toFixed(2),
		toatalAmount: grandTotal.toFixed(2),
		product: selectedProducts.map((data: any, index: number) => ({
		  barcode: data.productUnique_id,
		  prodcut: data.product_name,
		  hsn: data.hsn,
		  rate: data.sellingPrice,
		  qty: data.qty,
		  amount: totalAmounts[index].toFixed(2) || "0.00",
		  productName: data.productName,
		  igst: isToggledForGST ? data.igstTax : "",
		  cgst: isToggledForGST ? "" : data.CgstPercentage,
		  sgst: isToggledForGST ? "" : data.SgstPercentage,
		  gst: isToggledForGST ? "" : data.gst,
		  discount: data.discount,
		  units: data.units,
		  saleType: "POS",
		  igstAmount: isToggledForGST
			? (nontaxableAmounts[index] || 0) * ((data.igstTax || 0) / 100)
			: 0,
  
		  cgstAmount: isToggledForGST
			? 0
			: ((nontaxableAmounts[index] || 0) * ((data.gst || 0) / 100)) / 2,
  
		  sgstAmount: isToggledForGST
			? 0
			: ((nontaxableAmounts[index] || 0) * ((data.gst || 0) / 100)) / 2,
  
		  productUnique_id: data.productUnique_id,
		  product_flow: data.product_flow,
		  nontaxableAmount: nontaxableAmounts[index] || 0,
		})),
		tax: [],
		roundOff: roundOffAmountt,
		RoundOffType: RoundOffType,
		RoundOffEnabled: RoundOffEnabled,
	  //   RoundOffType: "",
	  //   RoundOffEnabled: "",
	  //   AmountFormat: "",
		extrafees: typeof extraFees === "number" ? extraFees.toFixed(2) : "0.00",
		termsanddcon: "",
		bankNme: "",
		accounHolder: "",
		accounNumber: "",
		ifscCode: "",
		upiType: "",
		upiNumber: "",
		notes: "",
		signature: "",
		seal: "",
		otherValue: "",
		dropDown: "",
		user_id: token?._id,
		To: "",
		Subject: "",
		sealPostion: "",
		signPostion: "",
		shipFromName: "",
		shipFromAddress: "",
		shipFromCity: "",
		shipFromState: "",
		shipFromPostalCode: "",
		shipToName: "",
		shipToAddress: "",
		shipToCity: "",
		shipToState: "",
		totalInWords: "",
		shipToPostalCode: "",
		footerNotes: "",
		billTaxType: taxType,
		gstType: gstValue,
		invoiceTemplateType: invoiceTemplateType,
		colorTemplateType: colorTemplateType,
		billModule: "",
		paymentType: paymentMethod,
		cashAmount: "",
		creditAmount: invoiceType === "On Account" ? balanceChangee : 0,
		chequeAmount: "",
		balanceAmount: invoiceType === "On Account" ? balanceChangee : 0,
		chequeNum: "",
		receivedAmount:
		  invoiceType === "On Account" ? creditAmount : grandTotal.toFixed(2),
		// paymentStatus:
		// 	invoiceType === "Cash Invoice"
		// 		? "PAID"
		// 		: creditAmount === 0
		// 		? "UNPAID"
		// 		: "PARTIALLY PAID",
		paymentStatus: invoiceType === "Cash Invoice" ? "PAID" : "UNPAID",
		isClientGst: true,
		isshippingValidPostalCode: true,
		isclientPostalCode: true,
		isClientEmail: true,
		isClientPan: true,
		isclientMobile: true,
		isshippingclientPostalCode: true,
		salesReturnStatus: "",
		serviceDiscount: "",
		serviceTax: "",
		cashinhandId: "",
		gstServiceType: "",
		nontaxableTotal: "",
		saleType: "POS",
		AmountFormat: AmountFormat?AmountFormat:"",
	  };
  
	  dispatch(InternalPost(invoiceData) as any)
		.then((response: any) => {})
		.catch((error: any) => {});
	};
	//  Searh table client name -----+
  
	const handleSearchByClientName = (e: React.ChangeEvent<HTMLInputElement>) => {
	  const value = e.target.value.toLowerCase();
  
	  setClientSearchTerm(value);
	  if (selectedClient) {
		setSelectedClient(null);
	  }
  
	  if (value.trim() === "") {
		// Clear filtered list and hide dropdown if input is empty
		setSelectedClient(null);
		setFilteredClients([]);
		setClShowDropdown(false);
	  } else {
		// Filter client details by name or mobile number
		const filtered = posData.client_details?.filter(
		  (client: any) =>
			client.client_name.toLowerCase().includes(value) ||
			client.client_mobile.toLowerCase().includes(value)
		);
		setFilteredClients(filtered);
		setClShowDropdown(filtered?.length > 0);
	  }
	};
	const handleClientSelection = (client: {
	  _id: string;
	  client_name: string;
	  client_mobile: string;
	}) => {
	  if (!client) return; // Guard clause for invalid client
  
	  // Set the selected client
	  setSelectedClient(client);
  
	  // Reset dropdown and search states
	  setClientSearchTerm("");
	  setClShowDropdown(false);
	  setFilteredClients([]);
	};
  
	const balanceChangee = Math.abs(creditAmount - grandTotal).toFixed(2);
  
	const handleChange = (value: string) => {
	  setcreditAmount(value);
	};
  
	const handleChangeInvoice = (event: React.ChangeEvent<HTMLInputElement>) => {
	  setInvoiceType(event.target.value);
	};
  
	return (
	  <div style={{ fontFamily: "poppins" }}>
		<div className="flex p-2">
		  <div className="sm:w-[15%] xl:w-[10%]">
			<button
			  onClick={value}
			  className=" flex  h-8 px-2 justify-center items-center border-2 [border-color:rgb(0,128,101)] rounded  text-white text-sm bg-[#008065] font-bold focus:ring-2 focus:ring-blue-500 focus:outline-none"
			>
			  <span>
				<FaArrowLeft />
			  </span>
			  <span className="pl-1 sm:pl-2 text-sm">Exit POS</span>
			</button>
		  </div>
		  <div className="w-[70%] sm:w-[80%] text-center text-[#045545] font-bold text-xl">
			POS Billing
		  </div>
		</div>
		<div className="grid grid-cols-1 lg:grid-cols-12 gap-4 p-2 px-4  sm:px-6 ">
		  {/* Column 1 */}
		  <div className="w-full order-1 lg:order-1  lg:col-span-9  ">
			{/* Header Section */}
			<div className="flex flex-col flex-wrap justify-between sm:flex-row border-b pb-2 !text-[#008065] font-bold w-full gap-2">
			  <div className="lg:w-[50%] w-full flex flex-col sm:flex-row justify-between">
				<div className="space-x-2 flex mb-4 sm:mb-2 md:mb-0 lg:mb-0 justify-center items-center">
				  <label>
					<input
					  type="radio"
					  name="invoiceType"
					  value="Cash Invoice"
					  className="mr-1"
					  checked={invoiceType === "Cash Invoice"}
					  onChange={handleChangeInvoice}
					/>{" "}
					Cash Invoice
				  </label>
				  <label>
					<input
					  type="radio"
					  name="invoiceType"
					  value="On Account"
					  className="mr-1"
					  checked={invoiceType === "On Account"}
					  onChange={handleChangeInvoice}
					/>{" "}
					On Account
				  </label>
				</div>
				{/* toggle */}
				<div className="flex justify-center ">
				  {/* <input value="GSt"></input> */}
				  <div className="mr-2">
					<FormControlLabel
					  className="text-md font-bold"
					  label={isToggledForGST ? "IGST" : "GST"}
					  labelPlacement="start"
					  sx={{
						fontFamily: "Poppins",
						fontWeight: 700,
						"& .MuiFormControlLabel-label": {
						  fontSize: "1rem",
						},
					  }}
					  control={
						<Switch
						  checked={isToggledForGST}
						  onChange={handleGSTToggleChange}
						  sx={{
							"& .MuiSwitch-track": {
							  backgroundColor: "#008065",
							},
							"& .MuiSwitch-thumb": {
							  color: "#008065",
							},
						  }}
						/>
					  }
					/>
				  </div>
				</div>
			  </div>
  
			  <div className="flex flex-col sm:flex-row text-center gap-2 ">
				<div className="flex justify-center items-center space-x-2">
				  <span className="w-[30%] sm:w-[20%]">Date:</span>
				  <input
				//    type="date"
					// selected={posDate}
					value={formData?.posDate}
					onChange={handleDateChange}
					// dateFormat={dateFormat}
					// wrapperClassName="return-datepicker"
					// scrollableYearDropdown
					// customInput={<CustomInputWithIcon />}
					// showPopperArrow={false}
				  />
				</div>
				<div className="flex justify-center items-center space-x-2">
				  <span className="w-[30%] sm:w-[40%]">Invoice #:</span>
				  <input
					type="text"
					value={invoice}
					className="w-48 sm:w-60 lg:w-40 h-8 border-2 [border-color:rgb(0,128,101)] px-2  rounded focus:outline-none text-black font-thin focus:ring-2 focus:ring-blue-500 focus:outline-none"
				  />
				</div>
			  </div>
			</div>
			<div className="relative">
			  <div className="w-full sm:w-[70%] lg:w-[55%]  group flex items-center  border-2 [border-color:rgb(0,128,101)] rounded bg-white h-10 focus-within:border-[2.5px] focus-within:outline-none focus-within:border-blue-500">
				<div className="flex-grow">
				  <input
					type="text"
					ref={shortcutbtnRef}
					placeholder="Search Client By Name or Number"
					className="w-full px-2 focus:outline-none"
					onChange={handleSearchByClientName}
					value={
					  selectedClient
						? selectedClient?.client_name
						: clientSearchTerm
					}
					onKeyDown={handleClientKeydown}
				  />
				</div>
				<span className="flex px-4 bg-white flex gap-4 ">
				  <span className="text-gray-400">[F1]</span>
				  <span className="font-bold flex items-center">
					<IoSearch />
				  </span>
				</span>
			  </div>
			  {showclDropdown && (
							  <div
							  ref={cldropdownRef}>
				<table className="absolute z-10 w-full bg-white border border-gray-300 rounded shadow-md max-h-60 overflow-auto">
				  {filteredClients.map((client: any, index: number) => (
					<tr
					  key={client._id}
					  // className={`px-4 py-2 hover:bg-blue-50 cursor-pointer ${selectedClient && selectedClient._id === client._id
					  // 		? "bg-blue-200"
					  // 				: ""
					  // 	}`}
					  className={`hover:bg-blue-50 cursor-pointer transition-all duration-200 ${
						selectedClientRowIndex === index ? "bg-blue-200" : ""
					  }`}
					  onClick={() => handleClientSelection(client)}
					>
					  <td>
						{client.client_name} - {client.client_mobile}{" "}
					  </td>
					</tr>
				  ))}
				</table>
							  </div>
			  )}
			</div>
			{/* Customer Section */}
			<div className="relative">
			  <div className="group flex items-center mt-2 border-2 [border-color:rgb(0,128,101)] rounded bg-white h-10 focus-within:border-[2.5px] focus-within:outline-none focus-within:border-blue-500">
				<div className="flex-grow">
				  <input
					type="text"
					className="w-full px-2 focus:outline-none "
					placeholder="Search By Product Name/Barcode or Scan Barcode "
					autoFocus
					value={searchTerm}
					onChange={handleSearch}
					ref={inputRef}
					onKeyDown={handleDropdownNavigation}
				  />
				</div>
				<span className="flex px-4 bg-white flex gap-4">
				  <span className="text-gray-400">[F2]</span>
				  <span className="font-bold flex items-center">
					<IoSearch />
				  </span>
				</span>
			  </div>
			  {showDropdown && filteredProducts?.length > 0 && (
				<div
				  ref={dropdownRef}
				  className="absolute z-20 w-full border border-gray-300 rounded-lg bg-white shadow-lg max-h-60 overflow-y-auto"
				>
				  <table className="table-auto w-full whitespace-nowrap text-left border-collapse">
					<thead className="bg-gradient-to-r from-blue-100 to-blue-200 text-gray-700">
					  <tr>
						<th className="border-b border-gray-300 px-4 py-2 w-[7%] text-sm font-semibold">
						  #
						</th>
						<th className="border-b border-gray-300 px-4 py-2 w-[15%] text-sm font-semibold">
						  Barcode
						</th>
						<th className="border-b border-gray-300 px-4 py-2 w-[18%] text-sm font-semibold">
						  Product Name
						</th>
						<th className="border-b border-gray-300 px-4 py-2 w-[15%] text-sm font-semibold">
						  Unit Price
						</th>
						<th className="border-b border-gray-300 px-4 py-2 w-[10%] text-sm font-semibold">
						  Stock
						</th>
					  </tr>
					</thead>
					<tbody>
					  {filteredProducts.map((product: any, index: number) => (
						<tr
						  key={product.productId}
						  id={`dropdown-row-${index}`}
						  // className="hover:bg-blue-50 cursor-pointer transition-all duration-200"
						  className={`hover:bg-blue-50 cursor-pointer transition-all duration-200 ${
							selectedDropdownRowIndex === index
							  ? "bg-blue-200"
							  : ""
						  }`}
						  onClick={(e) => handleFilterData(product)}
						>
						  <td className="border-b border-gray-200 px-4 py-2 text-sm text-gray-600">
							{index + 1}
						  </td>
						  <td className="border-b border-gray-200 px-4 py-2 text-sm text-gray-600">
							{product.productUnique_id}
						  </td>
						  <td className="border-b border-gray-200 px-4 py-2 text-sm text-gray-600 truncate">
							{product.productName}
						  </td>
						  <td className="border-b border-gray-200 px-4 py-2 text-sm text-gray-600">
							{product.sellingPrice}
						  </td>
						  <td className="border-b border-gray-200 px-4 py-2 text-sm text-gray-600">
							{product.stockQty}
						  </td>
						</tr>
					  ))}
					</tbody>
				  </table>
				</div>
			  )}
  
			  <div
				className=" z-10 overflow-x-auto h-[48vh] bg-[#F1F7F6]"
				tabIndex={0}
				onKeyDown={handleKeyDown}
			  >
				<table className="table-auto w-full whitespace-nowrap border-2 border-gray-300 bg-[#F1F7F6]  text-left overflow-x-auto overflow-y-auto ">
				  <thead className="!text-[#008065] font-bold h-10 sticky top-0">
					<tr className="border-2 border-gray-300 bg-gray-200">
					  <th className="border-2 border-gray-300 px-2 py-1 w-[7%]">
						S.No
					  </th>
					  <th className="border-2 border-gray-300 px-2 py-1 w-[15%]">
						Barcode
					  </th>
					  <th className="border-2 border-gray-300 px-2 py-1 w-[18%]">
						Product Name
					  </th>
					  <th className="border-2 border-gray-300 px-2 py-1 w-[15%]">
						Unit Price
					  </th>
					  <th className="border-2 border-gray-300 px-2 py-1 w-[10%]">
						Qty
					  </th>
					  <th className="border-2 border-gray-300 px-2 py-1 w-[10%]">
						Discount
					  </th>
					  <th className="border-2 border-gray-300 px-2 py-1 w-[10%]">
						Tax
					  </th>
					  <th className="border-2 border-gray-300 px-2 py-1 w-[15%]">
						Total
					  </th>
					</tr>
				  </thead>
				  <tbody>
					{selectedProducts?.length > 0 ? (
					  selectedProducts.map((product: any, index) => (
						<tr
						  key={index}
						  // className="hover:bg-gray-100"
						  className={`hover:bg-gray-100 ${
							selectedRowIndex === index ? "bg-gray-200" : ""
						  }`}
						  onClick={(e) => handleRowClick(index)}
						>
						  <td className="border-2 border-gray-300 px-2 py-1">
							{index + 1}
						  </td>
						  <td className="border-2 border-gray-300 px-2 py-1">
							{product.productUnique_id}
						  </td>
						  <td className="border-2 border-gray-300 px-2 py-1">
							{product.productName}
						  </td>
						  <td className="border-2 border-gray-300 px-2 py-1">
							{product.sellingPrice}
						  </td>
						  <td className="border-2 border-gray-300 px-2 py-1">
							<input
							  type="number"
							  value={product.qty || ""}
							  ref={(el) => (qtyRefs.current[index] = el)}
							  tabIndex={selectedRowIndex === index ? 0 : -1}
							  onChange={(e) =>
								handleInputChange(index, "qty", e.target.value)
							  }
							  onKeyDown={(e) =>
								handleQtyDiscountKeyDown(e, "qty")
							  }
							  style={{
								backgroundColor:
								  selectedRowIndex === index ? "#e2f7e2" : "",
							  }}
							  className="w-full border border-gray-300 rounded px-1"
							/>
						  </td>
						  <td className="border-2 border-gray-300 px-2 py-1">
							<div className="w-full border border-gray-300 rounded flex px-2 bg-white">
							  <input
								type="number"
								value={product.discount || " "}
								ref={(el) => (discountRefs.current[index] = el)}
								tabIndex={selectedRowIndex === index ? 0 : -1}
								onChange={(e) => {
								  const value = e.target.value.replace(
									/[^0-9.]/g,
									""
								  );
								  if (parseFloat(value) > 100) {
									return 0;
								  }
								  handleInputChange(
									index,
									"discount",
									e.target.value
								  );
								}}
								onKeyDown={(e) =>
								  handleQtyDiscountKeyDown(e, "discount")
								}
								style={{
								  backgroundColor:
									selectedRowIndex === index ? "#e2f7e2" : "",
								}}
								className="w-full outline-none"
							  />
							  <span className="text-gray-600 bg-white">%</span>
							</div>
						  </td>
						  <td className="border-2 border-gray-300 px-2 py-1">
							{isToggledForGST ? product?.igstTax : product?.gst}%
						  </td>
						  <td className="border-2 border-gray-300 px-2 py-1">
							{totalAmounts[index]?.toFixed(2) || "0.00"}
						  </td>
						</tr>
					  ))
					) : (
					  <tr>
						<td
						  colSpan={8}
						  className="border-2 border-gray-300 px-2 py-1 text-center"
						>
						  No products selected.
						</td>
					  </tr>
					)}
				  </tbody>
				</table>
			  </div>
			</div>
		  </div>
  
		  {/* Column 2 */}
  
		  <div className="w-full order-2 lg:order-3 lg:col-span-9 ">
			<div className="grid grid-cols-3 sm:grid-cols-3 flex-wrap gap-4">
			  <button
				className="border-2 [border-color:rgb(0,128,101)] px-4 py-2 bg-gray-200 text-sm hover:bg-[#008065] hover:text-white hover:font-bold focus:bg-[#008065] focus:text-white focus:font-bold focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
				// ref={quantitybtnRef}
				onClick={() => {
				  if (selectedRowIndex !== null && selectedRowIndex >= 0) {
					qtyRefs.current[selectedRowIndex]?.focus();
				  }
				}}
			  >
				<span>Change Qty [Q]</span>
			  </button>
			  <button
				className="border-2 [border-color:rgb(0,128,101)] px-4 py-2 bg-gray-200 text-sm hover:bg-[#008065] hover:text-white hover:font-bold focus:bg-[#008065] focus:text-white focus:font-bold focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
				ref={discountbtnRef}
				onClick={() => {
				  if (selectedRowIndex !== null && selectedRowIndex >= 0) {
					discountRefs.current[selectedRowIndex]?.focus();
				  }
				}}
			  >
				Product Discount [D]
			  </button>
			  <button
				className="border-2 [border-color:rgb(0,128,101)] px-4 py-2 bg-gray-200 text-sm hover:bg-[#008065] hover:text-white hover:font-bold focus:bg-[#008065] focus:text-white focus:font-bold focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
				ref={deletebtnRef}
				onClick={() => alert("Remove product button is clicked")}
			  >
				Remove Product [Del]
			  </button>
			  <button
				className="border-2 [border-color:rgb(0,128,101)] px-4 py-2 bg-gray-200 text-sm hover:bg-[#008065] hover:text-white hover:font-bold focus:bg-[#008065] focus:text-white focus:font-bold focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
				onClick={() => setIsModalOpen(true)}
				// onClick={() => alert("Extrafees button clicked!")}
				ref={extrafeesbtnRef}
			  >
				Extra Fees [F3]
			  </button>
			  {/* Modal */}
			  {isModalOpen && (
				<div
				  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
				  onClick={() => setIsModalOpen(false)}
				>
				  <div
					className="bg-white w-96 rounded-lg shadow-lg border-solid border-2 border-[#008065] 2xl:text-lg"
					onClick={(e) => e.stopPropagation()}
				  >
					{/* Modal Header */}
					<div className="flex justify-between items-center p-4 border-b bg-[#008065]">
					  <h2 className="text-white font-bold text-xl">Extra Fees</h2>
					  <button
						onClick={() => setIsModalOpen(false)}
						className="text-white hover:text-gray-800"
					  >
						✖
					  </button>
					</div>
  
					{/* Modal Content */}
					<div className="p-4 space-y-4">
					  <label
						className="text-lg font-medium text-gray-700"
						htmlFor="extraFees"
					  >
						Enter Extra Fees
					  </label>
					  <input
						id="extraFees"
						type="number"
						value={extraFees}
						onChange={(e) => setExtraFees(Number(e.target.value))}
						autoFocus
						className="border border-gray-400 rounded w-full px-2 py-1 focus:outline-none focus:ring-2 focus:ring-[#008065] focus:border-[#008065]"
					  />
					</div>
  
					{/* Modal Footer */}
					<div className="p-4 border-t flex justify-between">
					  <button
						onClick={() => setIsModalOpen(false)}
						className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
					  >
						Close
					  </button>
					  <button
						onClick={() => handleSaveExtraFees()}
						className="px-4 py-2 bg-[#008065] text-white rounded hover:bg-[#00654c]"
					  >
						Save
					  </button>
					</div>
				  </div>
				</div>
			  )}
  
			  <button
				className="border-2 [border-color:rgb(0,128,101)] px-4 py-2 bg-gray-200 text-sm hover:bg-[#008065] hover:text-white hover:font-bold focus:bg-[#008065] focus:text-white focus:font-bold focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
				ref={billdiscountbtnRef}
				onClick={() => setIsBillDiscountOpen(true)}
			  >
				Bill Discount [F4]
			  </button>
			  {/*Bill Discount Modal */}
			  {isBillDiscountOpen && (
				<div
				  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
				  onClick={() => setIsBillDiscountOpen(false)}
				>
				  <div
					className="bg-white w-96 rounded-lg shadow-lg border-solid border-2 border-[#008065] 2xl:text-lg"
					onClick={(e) => e.stopPropagation()}
				  >
					{/* Modal Header */}
					<div className="flex justify-between items-center p-4 border-b bg-[#008065]">
					  <h2 className="text-white font-bold text-xl">
						Bill Discount
					  </h2>
					  <button
						onClick={() => setIsBillDiscountOpen(false)}
						className="text-white hover:text-gray-800"
					  >
						✖
					  </button>
					</div>
  
					{/* Modal Content */}
					<div className="p-4 space-y-4">
					  <label
						className="text-lg font-medium text-gray-700"
						htmlFor="extraFees"
					  >
						Enter Bill Discount
					  </label>
					  <input
						// id="extraFees"
						type="number"
						// value={extraFees}
						// onChange={(e) => setExtraFees(Number(e.target.value))}
						autoFocus
						className="border border-gray-400 rounded w-full px-2 py-1 focus:outline-none focus:ring-2 focus:ring-[#008065] focus:border-[#008065]"
					  />
					</div>
  
					{/* Modal Footer */}
					<div className="p-4 border-t flex justify-between">
					  <button
						onClick={() => setIsBillDiscountOpen(false)}
						className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
					  >
						Close
					  </button>
					  <button
						// onClick={() => handleSaveExtraFees()}
						className="px-4 py-2 bg-[#008065] text-white rounded hover:bg-[#00654c]"
					  >
						Save
					  </button>
					</div>
				  </div>
				</div>
			  )}
  
			  <button
				className="border-2 [border-color:rgb(0,128,101)] px-4 py-2 bg-gray-200 text-sm hover:bg-[#008065] hover:text-white hover:font-bold focus:bg-[#008065] focus:text-white focus:font-bold focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
				ref={remarksbtnRef}
				// onClick={() => alert("Remarks button is clicked")}
				onClick={() => setIsRemarkModalOpen(true)}
			  >
				Remarks [F5]
			  </button>
			  {/*Remark  Modal */}
			  {isRemarkModalOpen && (
				<div
				  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
				  onClick={() => setIsRemarkModalOpen(false)}
				>
				  <div
					className="bg-white w-96 rounded-lg shadow-lg border-solid border-2 border-[#008065] 2xl:text-lg"
					onClick={(e) => e.stopPropagation()}
				  >
					{/* Modal Header */}
					<div className="flex justify-between items-center p-4 border-b bg-[#008065]">
					  <h2 className="text-white font-bold text-xl">Remarks</h2>
					  <button
						onClick={() => setIsRemarkModalOpen(false)}
						className="text-white hover:text-gray-800"
					  >
						✖
					  </button>
					</div>
  
					{/* Modal Content */}
					<div className="p-4 space-y-4">
					  <label
						className="text-lg font-medium text-gray-700"
						htmlFor="extraFees"
					  >
						Enter Remarks
					  </label>
					  <textarea
						id=""
						autoFocus
						className="border border-gray-400 rounded w-full h-28 px-2 py-1 focus:outline-none focus:ring-2 focus:ring-[#008065] focus:border-[#008065]"
					  />
					</div>
  
					{/* Modal Footer */}
					<div className="p-4 border-t flex justify-between">
					  <button
						onClick={() => setIsRemarkModalOpen(false)}
						className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
					  >
						Close
					  </button>
					  <button
						onClick={() => setIsRemarkModalOpen(false)}
						className="px-4 py-2 bg-[#008065] text-white rounded hover:bg-[#00654c]"
					  >
						Save
					  </button>
					</div>
				  </div>
				</div>
			  )}
			</div>
		  </div>
				  {/* col 3 */} 
				  <div className="w-full order-3 lg:order-2 lg:col-span-3 ">
					  {/* Summary Section */}
					  <div className="flex flex-col sm:flex-row lg:flex-col gap-6">
						  <div className="sm:w-[60%] lg:w-full font-bold border-2 [border-color:rgb(0,128,101)] rounded bg-[#F1F7F6]">
							  <div className="text-center border-b [border-color:rgb(0,128,101)] bg-gray-200 py-2 rounded-t">
								  Bill Details
							  </div>
							  <div className="flex flex-col gap-4 text-[#008065] text-sm 2xl:text-md font-bold px-2 mt-2 mb-2">
								  <div className="flex items-center">
									  <span className="w-[30%] lg:w-[35%]">Subtotal:</span>
									  <span className="w-[70%] lg:w-[65%] text-right text-black font-thin px-2  w-20  rounded">
										  {`${subTotals.toFixed(2)}`}
									  </span>
								  </div>
								  <div className="flex items-center">
									  <span className="w-[30%] lg:w-[35%]">Extra Fees:</span>
									  <span className="w-[70%] lg:w-[65%] text-right text-black font-thin px-2  w-20  rounded">
										  {`${
											  typeof extraFees === "number"
												  ? extraFees.toFixed(2)
												  : "0.00"
										  }`}
									  </span>
								  </div>
  
								  <div className="flex items-center">
									  <span className="w-[30%] lg:w-[35%]">Discount:</span>
									  <span className="w-[70%] lg:w-[65%] text-right text-black font-thin px-2  w-20 rounded">
										  {`-${discountAmount.toFixed(2)}`}
									  </span>
								  </div>
								  {/* <div className="flex items-center">
									  <span className="w-[30%] lg:w-[35%]">Discount:</span>
									  <span className="w-[70%] lg:w-[65%] text-right text-black font-thin px-2  w-20 rounded">
										  {`-${discountAmount.toFixed(2)}`}
									  </span>
								  </div> */}
								  <div className="flex items-center">
									  <span className="w-[30%] lg:w-[35%]">Tax:</span>
									  <span className="w-[70%] lg:w-[65%] text-right text-black font-thin px-2  w-20 rounded">
										  {`${taxAmountt.toFixed(2)}`}
									  </span>
								  </div>
								  <div className="flex items-center">
									  <span className="w-[30%] lg:w-[35%]">Round Off:</span>
									  <span className="w-[70%] lg:w-[65%] text-right text-black font-thin px-2  w-20 rounded">
										  {`${roundOffAmountt.toFixed(2)}`}
									  </span>
								  </div>
							  </div>
							  <div className="flex items-center font-bold  border-t [border-color:rgb(0,128,101)] bg-[#d1e3db] px-2 py-2 rounded-b">
								  <span className="w-[30%] lg:w-[35%]">Total:</span>
								  <span className="w-[70%] lg:w-[65%] text-right  px-2  w-20 rounded">
									  {`${currencySymbol}${grandTotal.toFixed(2)}`}
								  </span>
							  </div>
						  </div>
  
						  <div className="sm:w-[40%] lg:w-full h-28 border-2 [border-color:rgb(0,128,101)] bg-[#F1F7F6]  rounded">
							  <div className="border-b [border-color:rgb(0,128,101)] font-bold bg-gray-200 py-2 pl-2 rounded-t font-bold ">
								  Received Amount{" "}
							  </div>
							  <div className="flex w-full items-center py-4 px-2 gap-2">
								  <span className="w-[60%] sm:w-[60%] lg:w-[65%] font-bold flex items-center space-x-1">
									  <span>{currencySymbol}</span>
									  <input
										  className="w-full py-1 px-1 border-2 [border-color:#008065] focus:outline-none focus:ring-2 rounded focus:ring-blue-500"
										  type="number"
										  value={invoiceType === "Cash Invoice" ? creditAmount : 0}
										  onChange={(e) => handleChange(e.target.value)}
									  />
								  </span>
								  {errorMessage && (
									  <p className="text-red-500 text-xs mt-1">{errorMessage}</p>
								  )}
  
								  <select
									  value={paymentMethod}
									  onChange={(e) => setPaymentMethod(e.target.value)}
									  className="w-[40%] sm:w-[40%] lg:w-[35%] border border-2 [border-color:rgb(0,128,101)] rounded-[7px] focus:outline-none px-2 py-1 text-black font-thin font-bold focus:ring-2 focus:ring-blue-500"
								  >
									  {invoiceType === "Cash Invoice" ? (
										  <option>CASH</option>
									  ) : (
										  <option>CREDIT</option>
									  )}
								  </select>
							  </div>
						  </div>
					  </div>
				  </div>
		  {/* col- 4 */}
		  <div className="w-full order-4 lg:order-4 lg:col-span-3">
			<div className="flex flex-col gap-4">
			  <div className="flex font-bold py-1">
				<span className=" lg:w-[60%]">Change to Return:</span>
				<span className="pl-8 lg:pl-0 lg:w-[40%] text-left lg:text-right">
				  {`${currencySymbol}${balanceChangee}`}
				</span>
			  </div>
			  <div className="py-1">
				<button
				  type="submit"
				  onClick={handleSubmit}
				  className=" border px-4 py-2 bg-[#008065] text-white w-full rounded focus:ring-2 focus:ring-blue-500 focus:outline-none focus:[border-color:rgb(0,128,101)]"
				>
				  Save
				</button>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	);
  };
  
  export default Pos;
  