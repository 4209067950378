
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconStyle, defaultInputSmStyle } from "../commen/defaultStyles";
import NumberFormat from "../commen/NumberFormat";
import PurchasepageVendorUpdateModel from "../commen/PurchasepageVendorUpdateModel";
import StockUpdateModel from "../commen/StockUpdateModel";
import DeleteIcon from "../Icons/DeleteIcon";
import InvoiceIcon from "../Icons/InvoiceIcon";
import SalesFAModel from "./SalesFAModel";
import CalculateRoundOff from "../commen/CalculateRoundOff";
import CalculateRoundTotalAmount from "../commen/CalculateRoundTotalAmount";


function SalesFABillData({
    formData,
    setFormData,
    TaxAllList,
    updateAssetErrors,
    paramsId,
    assetErrors,
    assetRows,
    setAssetRows,
    AssetError,
    setassetErrors,
    editValue,
    setCashAmount,
    setSelectedPayment,
    setbalanceAmount,
    setchequeAmount,
    setcreditAmount,
    creditAmount,
    fieldErrors,
    clientErrors,
    roundOffValue,
    roundOffEnable
}: any) {


    // *******************************Product Sction*********************************

    const template = useSelector((state: any) => state.userColorTemplate);
    const { userdata } = template;
    const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showConfirmationQty, setShowConfirmationQty] = useState<boolean>(false);
    const handleCancel = () => {
        setShowConfirmationQty(false);
        setShowConfirmation(false)
    };

    const handleInputChange = (index: any, fieldName: any, value: any) => {
        // newExistProduct[index]["exist"] = value;
        const newArray: any = [...assetRows];
        if (fieldName === "qty") {
            newExistProduct[index]["exist"] = value;
            if (paramsId) {
                if (newExistProduct[index]["originalValue"] < Number(value)) {
                    setShowConfirmationQty(true)
                    return
                }
            }
            else if (typeof newExistProduct[index]["originalValue"] === 'number' && newExistProduct[index]["originalValue"] < Number(value)) {
                setShowConfirmationQty(true)
                return
            }
        }


        if (fieldName === "rate" || fieldName === "qty") {
            if (!newArray[index].originalRate) {
                newArray[index].originalRate = newArray[index].rate;
            }

            const currentRate = fieldName === "rate" ? Number(value) : Number(newArray[index].rate);
            const currentQty = fieldName === "qty" ? Number(value) : Number(newArray[index].qty);

            if (currentRate > newArray[index].originalRate) {
                const difference = currentRate - newArray[index].originalRate;
                newArray[index]["profit"] = (difference * currentQty).toFixed(2);
                newArray[index]["loss"] = 0;
            } else if (currentRate < newArray[index].originalRate) {
                const difference = newArray[index].originalRate - currentRate;
                newArray[index]["loss"] = (difference * currentQty).toFixed(2);
                newArray[index]["profit"] = 0;
            } else {
                newArray[index]["profit"] = 0;
                newArray[index]["loss"] = 0;
            }

            newArray[index]["amount"] =
                newArray[index].rate && newArray[index].qty
                    ? (currentRate * currentQty).toFixed(2)
                    : "0.00";

            setAssetRows(newArray);
        }



        newArray[index][fieldName] = value;
        updateAssetErrors(index, fieldName, value);

        if (fieldName === "qty" || fieldName === "rate") {
            newArray[index]["amount"] =
                newArray[index].rate && newArray[index].qty
                    ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
                    : !newArray[index].rate
                        ? 0
                        : 0;
            newArray[index]["amount"] = (newArray[index]["amount"]).toFixed(2);
            setAssetRows(newArray);
            let subAmount: any = 0;
            for (let i = 0; i < newArray.length; i++) {
                subAmount += Number(newArray[i].amount);
            }
            subAmount = subAmount.toFixed(2);

            if (paramsId) {

                let discountValue: any = TaxAllList[0]?.discountStatus
                    ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * parseFloat(subAmount) : 0;
                let cgst: any = TaxAllList[0]?.cgstStatus
                    ? (parseFloat(TaxAllList[0]?.cgstPercentage) / 100) * (subAmount - discountValue)
                    : 0;
                let sgst: any = TaxAllList[0]?.sgstStatus
                    ? (parseFloat(TaxAllList[0]?.sgstPercentage) / 100) * (subAmount - discountValue)
                    : 0;
                let igst: any = TaxAllList[0]?.igstStatus
                    ? (parseFloat(TaxAllList[0]?.igstPercentage) / 100) * (subAmount - discountValue)
                    : 0;
                let vat: any = TaxAllList[0]?.vatStatus
                    ? (parseFloat(TaxAllList[0]?.vatPercentage) / 100) * subAmount
                    : 0;
                let others: any = TaxAllList[0]?.othersStatus
                    ? (parseFloat(TaxAllList[0]?.othersPercentage) / 100) * subAmount
                    : 0;
                let extrafees: any = TaxAllList[0]?.extraFeesStatus
                    ? TaxAllList[0]?.extraFees
                    : 0;

                // let Totals: any =
                //     parseFloat(cgst) +
                //     parseFloat(sgst) +
                //     parseFloat(igst) +
                //     parseFloat(vat) +
                //     parseFloat(others) +
                //     parseFloat(extrafees) +
                //     parseFloat(subAmount);

                // let dicount: any = TaxAllList[0]?.discountStatus
                //     ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * Totals
                //     : 0;

                let taxArray: any = [];
                for (let i = 0; i < TaxAllList.length; i++) {
                    if (TaxAllList[0]?.cgstStatus) {
                        let cgstobject = {
                            taxName: "CGST",
                            cgstStatus: TaxAllList[0]?.cgstStatus,
                            taxPersent: TaxAllList[i]?.cgstPercentage,
                            taxAmount: cgst.toFixed(2),
                        };
                        taxArray.push(cgstobject);
                    }
                    if (TaxAllList[0]?.sgstStatus) {
                        let sgstobject = {
                            taxName: "SGST",
                            sgstStatus: TaxAllList[0]?.sgstStatus,
                            taxPersent: TaxAllList[i]?.sgstPercentage,
                            taxAmount: sgst.toFixed(2),
                        };
                        taxArray.push(sgstobject);
                    }
                    if (TaxAllList[0]?.igstStatus) {
                        let igstobject = {
                            taxName: "IGST",
                            igstStatus: TaxAllList[0]?.igstStatus,
                            taxPersent: TaxAllList[i]?.igstPercentage,
                            taxAmount: igst.toFixed(2),
                        };
                        taxArray.push(igstobject);
                    }
                    if (TaxAllList[0]?.vatStatus) {
                        let vatobject = {
                            taxName: "VAT",
                            vatStatus: TaxAllList[0]?.vatStatus,
                            taxPersent: TaxAllList[i]?.vatPercentage,
                            taxAmount: vat.toFixed(2),
                        };
                        taxArray.push(vatobject);
                    }
                    if (TaxAllList[0]?.othersStatus) {
                        let othersobject = {
                            taxName: "OTHERS",
                            othersStatus: TaxAllList[0]?.othersStatus,
                            taxPersent: TaxAllList[i]?.othersPercentage,
                            taxAmount: others.toFixed(2),
                        };
                        taxArray.push(othersobject);
                    }
                    if (TaxAllList[0]?.extraFeesStatus) {
                        let extobject = {
                            taxName: "Extra Fees",
                            extraFeesStatus: TaxAllList[0]?.extraFeesStatus,
                            taxPersent: TaxAllList[i]?.extraFees,
                            taxAmount: Number(extrafees).toFixed(2),
                        };
                        taxArray.push(extobject);
                    }
                    if (TaxAllList[0]?.discountStatus) {
                        let disobject = {
                            taxName: "Discount",
                            discountStatus: TaxAllList[0]?.discountStatus,
                            taxPersent: TaxAllList[i]?.discountPercentage,
                            taxAmount: discountValue.toFixed(2),
                        };
                        taxArray.push(disobject);
                    }
                }

                let Totalamount =
                    parseFloat(cgst) +
                    parseFloat(sgst) +
                    parseFloat(igst) +
                    parseFloat(vat) +
                    parseFloat(others) +
                    parseFloat(extrafees) +
                    parseFloat(subAmount);
                let finalamount = TaxAllList[0]?.discountStatus
                    ? Totalamount - discountValue
                    : Totalamount;

                setFormData({
                    ...formData,
                    product: newArray,
                    subtotal: subAmount,
                    tax: taxArray,
                    roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, finalamount) : 0,
                    toatalAmount: roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount,

                });

                setbalanceAmount(parseFloat(((finalamount) - formData.receivedAmount).toFixed(2)))
                clientErrors["balanceAmount"] = ''
            } else {

                let discountValue: any = TaxAllList[0]?.discountStatus
                    ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * parseFloat(subAmount) : 0;
                let cgst: any = TaxAllList[0]?.cgstStatus
                    ? (parseFloat(TaxAllList[0]?.cgstPercentage) / 100) * (subAmount - discountValue)
                    : 0;
                let sgst: any = TaxAllList[0]?.sgstStatus
                    ? (parseFloat(TaxAllList[0]?.sgstPercentage) / 100) * (subAmount - discountValue)
                    : 0;
                let igst: any = TaxAllList[0]?.igstStatus
                    ? (parseFloat(TaxAllList[0]?.igstPercentage) / 100) * (subAmount - discountValue)
                    : 0;
                let vat: any = TaxAllList[0]?.vatStatus
                    ? (parseFloat(TaxAllList[0]?.vatPercentage) / 100) * subAmount
                    : 0;
                let others: any = TaxAllList[0]?.othersStatus
                    ? (parseFloat(TaxAllList[0]?.othersPercentage) / 100) * subAmount
                    : 0;
                let extrafees: any = TaxAllList[0]?.extraFeesStatus
                    ? TaxAllList[0]?.extraFees
                    : 0;

                // let Totals: any =
                //     parseFloat(cgst) +
                //     parseFloat(sgst) +
                //     parseFloat(igst) +
                //     parseFloat(vat) +
                //     parseFloat(others) +
                //     parseFloat(extrafees) +
                //     parseFloat(subAmount);

                // let dicount: any = TaxAllList[0]?.discountStatus
                //     ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * Totals
                //     : 0;

                let taxArray: any = [];
                for (let i = 0; i < TaxAllList.length; i++) {
                    if (TaxAllList[0]?.cgstStatus) {
                        let cgstobject = {
                            taxName: "CGST",
                            cgstStatus: TaxAllList[0]?.cgstStatus,
                            taxPersent: TaxAllList[i]?.cgstPercentage,
                            taxAmount: cgst.toFixed(2),
                        };
                        taxArray.push(cgstobject);
                    }
                    if (TaxAllList[0]?.sgstStatus) {
                        let sgstobject = {
                            taxName: "SGST",
                            sgstStatus: TaxAllList[0]?.sgstStatus,
                            taxPersent: TaxAllList[i]?.sgstPercentage,
                            taxAmount: sgst.toFixed(2),
                        };
                        taxArray.push(sgstobject);
                    }
                    if (TaxAllList[0]?.igstStatus) {
                        let igstobject = {
                            taxName: "IGST",
                            igstStatus: TaxAllList[0]?.igstStatus,
                            taxPersent: TaxAllList[i]?.igstPercentage,
                            taxAmount: igst.toFixed(2),
                        };
                        taxArray.push(igstobject);
                    }
                    if (TaxAllList[0]?.vatStatus) {
                        let vatobject = {
                            taxName: "VAT",
                            vatStatus: TaxAllList[0]?.vatStatus,
                            taxPersent: TaxAllList[i]?.vatPercentage,
                            taxAmount: vat.toFixed(2),
                        };
                        taxArray.push(vatobject);
                    }
                    if (TaxAllList[0]?.othersStatus) {
                        let othersobject = {
                            taxName: "OTHERS",
                            othersStatus: TaxAllList[0]?.othersStatus,
                            taxPersent: TaxAllList[i]?.othersPercentage,
                            taxAmount: others.toFixed(2),
                        };
                        taxArray.push(othersobject);
                    }
                    if (TaxAllList[0]?.extraFeesStatus) {
                        let extobject = {
                            taxName: "Extra Fees",
                            extraFeesStatus: TaxAllList[0]?.extraFeesStatus,
                            taxPersent: TaxAllList[i]?.extraFees,
                            taxAmount: Number(extrafees).toFixed(2),
                        };
                        taxArray.push(extobject);
                    }
                    if (TaxAllList[0]?.discountStatus) {
                        let disobject = {
                            taxName: "Discount",
                            discountStatus: TaxAllList[0]?.discountStatus,
                            taxPersent: TaxAllList[i]?.discountPercentage,
                            taxAmount: discountValue.toFixed(2),
                        };
                        taxArray.push(disobject);
                    }
                }

                let Totalamount =
                    parseFloat(cgst) +
                    parseFloat(sgst) +
                    parseFloat(igst) +
                    parseFloat(vat) +
                    parseFloat(others) +
                    parseFloat(extrafees) +
                    parseFloat(subAmount);
                let finalamount = TaxAllList[0]?.discountStatus
                    ? Totalamount - discountValue
                    : Totalamount;

                setFormData({
                    ...formData,
                    asset: newArray,
                    subtotal: subAmount,
                    tax: taxArray,
                    roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, finalamount) : 0,
                    toatalAmount: roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount,
                    nontaxableTotal: 0,
                });
                if (creditAmount) {
                    setbalanceAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount)
                    setcreditAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount)

                }
                else {

                    setCashAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount)
                    setchequeAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount)
                    setbalanceAmount(0)
                    fieldErrors.cashAmount = ''
                    fieldErrors.creditAmount = ''
                    fieldErrors.chequeAmount = ''
                    // clientErrors["chequeNum"] = ''
                    formData.chequeNum = ''
                }
            }
        } else {
            setAssetRows(newArray);
        }
    };

    const handleExistingCalulation = (arr: any) => {
        if (arr.length === 0)
            arr.push({ amount: "", hsn: "", asset_name: "", qty: "", rate: "", units: "", profit: "", loss: "" });
        const newArray: any = [...arr];
        for (let i = 0; i < newArray.length; i++) {
            if (newArray[i].qty || newArray[i].rate) {
                newArray[i]["amount"] =
                    newArray[i].rate && newArray[i].qty
                        ? parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)
                        : !newArray[i].rate
                            ? 0
                            : 0;

                setAssetRows(newArray);
                let subAmount: any = 0;
                for (let i = 0; i < newArray.length; i++) {
                    subAmount += newArray[i].amount;
                }
                if (paramsId) {

                    let discountValue: any = TaxAllList[0]?.discountStatus
                        ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * parseFloat(subAmount) : 0;
                    let cgst: any = TaxAllList[0]?.cgstStatus
                        ? (parseFloat(TaxAllList[0]?.cgstPercentage) / 100) * (subAmount - discountValue)
                        : 0;

                    let sgst: any = TaxAllList[0]?.sgstStatus
                        ? (parseFloat(TaxAllList[0]?.sgstPercentage) / 100) * (subAmount - discountValue)
                        : 0;
                    let igst: any = TaxAllList[0]?.igstStatus
                        ? (parseFloat(TaxAllList[0]?.igstPercentage) / 100) * (subAmount - discountValue)
                        : 0;
                    let vat: any = TaxAllList[0]?.vatStatus
                        ? (parseFloat(TaxAllList[0]?.vatPercentage) / 100) * subAmount
                        : 0;
                    let others: any = TaxAllList[0]?.othersStatus
                        ? (parseFloat(TaxAllList[0]?.othersPercentage) / 100) * subAmount
                        : 0;
                    let extrafees: any = TaxAllList[0]?.extraFeesStatus
                        ? TaxAllList[0]?.extraFees
                        : 0;
                    // let Totals: any =
                    //     parseFloat(cgst) +
                    //     parseFloat(sgst) +
                    //     parseFloat(igst) +
                    //     parseFloat(vat) +
                    //     parseFloat(others) +
                    //     parseFloat(extrafees) +
                    //     parseFloat(subAmount);
                    // let dicount: any = TaxAllList[0]?.discountStatus
                    //     ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * Totals
                    //     : 0;

                    let taxArray: any = [];
                    for (let i = 0; i < TaxAllList.length; i++) {
                        if (TaxAllList[0]?.cgstStatus) {
                            let cgstobject = {
                                taxName: "CGST",
                                cgstStatus: TaxAllList[0]?.cgstStatus,
                                taxPersent: TaxAllList[i]?.cgstPercentage,
                                taxAmount: cgst.toFixed(2),
                            };
                            taxArray.push(cgstobject);
                        }
                        if (TaxAllList[0]?.sgstStatus) {
                            let sgstobject = {
                                taxName: "SGST",
                                sgstStatus: TaxAllList[0]?.sgstStatus,
                                taxPersent: TaxAllList[i]?.sgstPercentage,
                                taxAmount: sgst.toFixed(2),
                            };
                            taxArray.push(sgstobject);
                        }

                        if (TaxAllList[0]?.igstStatus) {
                            let igstobject = {
                                taxName: "IGST",
                                igstStatus: TaxAllList[0]?.igstStatus,
                                taxPersent: TaxAllList[i]?.igstPercentage,
                                taxAmount: igst.toFixed(2),
                            };
                            taxArray.push(igstobject);
                        }
                        if (TaxAllList[0]?.vatStatus) {
                            let vatobject = {
                                taxName: "VAT",
                                vatStatus: TaxAllList[0]?.vatStatus,
                                taxPersent: TaxAllList[i]?.vatPercentage,
                                taxAmount: vat.toFixed(2),
                            };
                            taxArray.push(vatobject);
                        }
                        if (TaxAllList[0]?.othersStatus) {
                            let othersobject = {
                                taxName: "OTHERS",
                                othersStatus: TaxAllList[0]?.othersStatus,
                                taxPersent: TaxAllList[i]?.othersPercentage,
                                taxAmount: others.toFixed(2),
                            };
                            taxArray.push(othersobject);
                        }
                        if (TaxAllList[0]?.extraFeesStatus) {
                            let extobject = {
                                taxName: "Extra Fees",
                                extraFeesStatus: TaxAllList[0]?.extraFeesStatus,
                                taxPersent: TaxAllList[i]?.extraFees,
                                taxAmount: Number(extrafees).toFixed(2),
                            };
                            taxArray.push(extobject);
                        }

                        if (TaxAllList[0]?.discountStatus) {
                            let disobject = {
                                taxName: "Discount",
                                discountStatus: TaxAllList[0]?.discountStatus,
                                taxPersent: TaxAllList[i]?.discountPercentage,
                                taxAmount: discountValue.toFixed(2),
                                discountValueNonTax: discountValue,

                            };
                            taxArray.push(disobject);
                        }
                    }
                    let Totalamount =
                        parseFloat(cgst) +
                        parseFloat(sgst) +
                        parseFloat(igst) +
                        parseFloat(vat) +
                        parseFloat(others) +
                        parseFloat(extrafees) +
                        parseFloat(subAmount);
                    let finalamount = TaxAllList[0]?.discountStatus
                        ? Totalamount - discountValue
                        : Totalamount;
                    setFormData({
                        ...formData,
                        asset: newArray,
                        subtotal: subAmount,
                        tax: taxArray,
                        roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, finalamount) : 0,
                        toatalAmount: roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount,
                        nontaxableTotal: 0,
                    });
                    setbalanceAmount(parseFloat(((finalamount) - formData.receivedAmount).toFixed(2)))
                    clientErrors["balanceAmount"] = ''
                } else {

                    let discountValue: any = TaxAllList[0]?.discountStatus
                        ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * parseFloat(subAmount) : 0;
                    let cgst: any = TaxAllList[0]?.cgstStatus
                        ? (parseFloat(TaxAllList[0]?.cgstPercentage) / 100) * (subAmount - discountValue)
                        : 0;

                    let sgst: any = TaxAllList[0]?.sgstStatus
                        ? (parseFloat(TaxAllList[0]?.sgstPercentage) / 100) * (subAmount - discountValue)
                        : 0;
                    let igst: any = TaxAllList[0]?.igstStatus
                        ? (parseFloat(TaxAllList[0]?.igstPercentage) / 100) * (subAmount - discountValue)
                        : 0;
                    let vat: any = TaxAllList[0]?.vatStatus
                        ? (parseFloat(TaxAllList[0]?.vatPercentage) / 100) * subAmount
                        : 0;
                    let others: any = TaxAllList[0]?.othersStatus
                        ? (parseFloat(TaxAllList[0]?.othersPercentage) / 100) * subAmount
                        : 0;
                    let extrafees: any = TaxAllList[0]?.extraFeesStatus
                        ? TaxAllList[0]?.extraFees
                        : 0;
                    // let Totals: any =
                    //     parseFloat(cgst) +
                    //     parseFloat(sgst) +
                    //     parseFloat(igst) +
                    //     parseFloat(vat) +
                    //     parseFloat(others) +
                    //     parseFloat(extrafees) +
                    //     parseFloat(subAmount);
                    // let dicount: any = TaxAllList[0]?.discountStatus
                    //     ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * Totals
                    //     : 0;

                    let taxArray: any = [];
                    for (let i = 0; i < TaxAllList.length; i++) {
                        if (TaxAllList[0]?.cgstStatus) {
                            let cgstobject = {
                                taxName: "CGST",
                                cgstStatus: TaxAllList[0]?.cgstStatus,
                                taxPersent: TaxAllList[i]?.cgstPercentage,
                                taxAmount: cgst.toFixed(2),
                            };
                            taxArray.push(cgstobject);
                        }
                        if (TaxAllList[0]?.sgstStatus) {
                            let sgstobject = {
                                taxName: "SGST",
                                sgstStatus: TaxAllList[0]?.sgstStatus,
                                taxPersent: TaxAllList[i]?.sgstPercentage,
                                taxAmount: sgst.toFixed(2),
                            };
                            taxArray.push(sgstobject);
                        }

                        if (TaxAllList[0]?.igstStatus) {
                            let igstobject = {
                                taxName: "IGST",
                                igstStatus: TaxAllList[0]?.igstStatus,
                                taxPersent: TaxAllList[i]?.igstPercentage,
                                taxAmount: igst.toFixed(2),
                            };
                            taxArray.push(igstobject);
                        }
                        if (TaxAllList[0]?.vatStatus) {
                            let vatobject = {
                                taxName: "VAT",
                                vatStatus: TaxAllList[0]?.vatStatus,
                                taxPersent: TaxAllList[i]?.vatPercentage,
                                taxAmount: vat.toFixed(2),
                            };
                            taxArray.push(vatobject);
                        }
                        if (TaxAllList[0]?.othersStatus) {
                            let othersobject = {
                                taxName: "OTHERS",
                                othersStatus: TaxAllList[0]?.othersStatus,
                                taxPersent: TaxAllList[i]?.othersPercentage,
                                taxAmount: others.toFixed(2),
                            };
                            taxArray.push(othersobject);
                        }
                        if (TaxAllList[0]?.extraFeesStatus) {
                            let extobject = {
                                taxName: "Extra Fees",
                                extraFeesStatus: TaxAllList[0]?.extraFeesStatus,
                                taxPersent: TaxAllList[i]?.extraFees,
                                taxAmount: Number(extrafees).toFixed(2),
                            };
                            taxArray.push(extobject);
                        }

                        if (TaxAllList[0]?.discountStatus) {
                            let disobject = {
                                taxName: "Discount",
                                discountStatus: TaxAllList[0]?.discountStatus,
                                taxPersent: TaxAllList[i]?.discountPercentage,
                                taxAmount: discountValue.toFixed(2),
                                discountValueNonTax: discountValue,

                            };
                            taxArray.push(disobject);
                        }
                    }
                    let Totalamount =
                        parseFloat(cgst) +
                        parseFloat(sgst) +
                        parseFloat(igst) +
                        parseFloat(vat) +
                        parseFloat(others) +
                        parseFloat(extrafees) +
                        parseFloat(subAmount);
                    let finalamount = TaxAllList[0]?.discountStatus
                        ? Totalamount - discountValue
                        : Totalamount;
                    setFormData({
                        ...formData,
                        asset: newArray,
                        subtotal: subAmount,
                        tax: taxArray,
                        roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, finalamount) : 0,
                        toatalAmount: roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount,
                        nontaxableTotal: 0,
                    });

                    setCashAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount)
                    setchequeAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount)
                    setcreditAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount) : finalamount)
                    setbalanceAmount('')
                }
            }
        }
        // else {
        setAssetRows(newArray);
        // }
        let finalErrors: any = [];
        // let object: any = {};
        let values = newArray?.map((item: any, index: any) => {
            let object: any = {};
            object.asset_name = item.asset_name ? true : false;
            object.rate = item.rate ? true : false;
            object.qty = item.qty ? true : false;
            finalErrors.push(object);
            return object

        });

        setassetErrors(values);
    };


    const removeRow = (index: any, row: any) => {
        const newArray = [...assetRows];
        const newErrors = [...assetErrors];
        const existArray = [...newExistProduct];
        existArray.splice(index, 1)
        setNewexistProduct(() => {
            return existArray.length === 0 ? [{ exist: '', originalValue: '' }] : existArray
        });

        newArray.splice(index, 1);
        handleExistingCalulation(newArray);
        setAssetRows(newArray);
        let amountValue = 0
        for (let i = 0; i < newArray.length; i++) {
            amountValue += newArray[i].amount
        }
        if (paramsId) {
            setSelectedPayment(formData.paymentType)
            setbalanceAmount(parseFloat((amountValue - formData.receivedAmount).toFixed(2)))
        }
        else {
            setCashAmount('')
            setbalanceAmount("")
            setcreditAmount("")
            setchequeAmount("")
            setSelectedPayment('')
        }
        if (newErrors.length > 1)
            newErrors.splice(index, 1);
        setassetErrors(newErrors);

        if (existArray.length === 0) {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                ...prevFormData.tax = [],
                subtotal: 0,
                toatalAmount: 0,
            }));
        }
        // setFormData((prevFormData: any) => ({
        //     ...prevFormData,
        //     cashAmount: "",
        // }));
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleConfirm = () => {
        // if (formData.clientName || formData.clientMobile || formData.clientEmail || formData.clientgst) {
        //     setIsModalOpen(true);
        // }
        // else {
        //     // setShowConfirmation(true)
        //     // alert("please choose vendor Details")
        // }
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (editValue) {
            setAssetRows(formData.asset);
            let obj: any = []
            formData.asset.map((item: any) => (
                obj.push({ exist: item.qty, originalValue: item.qty })
            ))
            setNewexistProduct(obj)
        } else {
            setAssetRows([
                { asset_name: "", hsn: "", rate: "", qty: "", amount: 0, },
            ]);
        }
    }, [editValue]);

    const handleSelect = (dataArray: any[]) => {
        setAssetRows(dataArray);
        const containsEmptyRows = assetRows.some((row: any) =>
            Object.values(row).some((value) => !value)
        );
        const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
            Object.values(row).some((value) => !value)
        );

        if (containsEmptyRowsinExistProduct) {
            const nonEmptyRows = newExistProduct.filter((row: any) =>
                Object.values(row).some(Boolean)
            );
            setNewexistProduct(nonEmptyRows);
        }
        if (containsEmptyRows) {
            const nonEmptyRows = assetRows.filter((row: any) =>
                Object.values(row).some(Boolean)
            );
            setAssetRows(nonEmptyRows);
        }
        const newRows = dataArray.map((data) => ({
            asset_name: data.asset_name,
            hsn: data.hsn,
            rate: (parseFloat((data.NonTaxableAmount / data.qty).toFixed(2))),
            qty: 1,
            amount: "",
            // productName: data.prod_Name,
            prodigst: '',
            prodcgst: '',
            prodsgst: '',
            prodvat: "",
            prodothers: "",
            totalgst: "",
            total: "",
            asset_id: data._id.$oid,
            transactionType: "Sales FA",
            fixedRate: (parseFloat((data.NonTaxableAmount / data.qty).toFixed(2))),
            // units: data.units
        }));


        setAssetRows((prevRows: any[]) => {
            const updatedRows = [...prevRows, ...newRows];
            handleExistingCalulation(updatedRows);
            return updatedRows;
        });

        setNewexistProduct((prev: any) => [
            ...prev,
            ...dataArray.map((row: any) => ({
                exist: Number(row.qty),
                originalValue: Number(row.qty)
            }))
        ]);

        setIsModalOpen(false);
    };



    return (
        <div className="py-2 ">
            <div
                className={
                    "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 mb-2 text-white"
                }
                style={{ backgroundColor: "#008065" }}
            >
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full md:w-[17%] lg:w-[17%] xl:w-[10%] 2xl:w-[5%] text-left"}

                >
                    <span className="inline-block">S No</span>
                </div>
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full sm:w-[192%] md:w-[90%] lg:w-[80%] xl:w-[81%] 2xl:w-[81%] text-center"}
                >
                    <span className="inline-block">Assets</span>
                </div>
                {userdata?.hsnNo ? (
                    <>
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className={" w-full md:w-[55%] lg:w-[15%] 2xl:w-[15%] text-left sm:pr-10 text-right"}
                        >
                            <span className="inline-block">HSN/SAC</span>
                        </div>
                    </>
                ) : (<></>)}
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full md:w-[14%] xl:w-[14%] 2xl:w-[14%] text-left sm:pr-5 text-right"}
                >
                    Rate
                </div>
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full md:w-[16%] xl:w-[16%] 2xl:w-[16%] text-left sm:pr-5 text-right"}
                >
                    Qty
                </div>
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={"  w-full md:w-[17%] xl:w-[17%] 2xl:w-[17%] text-right sm:pr-10"}
                >
                    Total
                </div>
            </div>

            {assetRows?.map((productrow: any, index: any) => (
                <div
                    key={index}
                    className={
                        "flex flex-col sm:flex-row rounded-lg  w-full gap-2 md:gap-0  py-1 items-center relative" +
                        (index % 2 !== 0 ? " bg-gray-50 " : "")
                    }
                >
                    <div
                        style={{
                            fontFamily: "poppins",
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: 700,

                        }}
                        className={"w-full md:w-[25%] lg:w-[25%]  xl:w-[25%] 2xl:w-[7%]  text-center  pr-3  flex flex-row sm:block "}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            S No
                        </span>


                        <div className="font-title flex-1 text-right sm:text-center 2xl:text-left 2xl:ml-6 pr-2 sm:pr-0">
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 800,
                                }}

                            >
                                {index + 1}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={" w-full md:w-[135%] xl:w-[141%] 2xl:w-[155%] text-right pr-3 flex flex-row sm:block "}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Assets
                        </span>

                        <div className="font-title flex-1 text-right sm-text-left ">
                            <input
                                autoComplete="nope"
                                value={productrow.asset_name ? productrow.asset_name : ""}
                                placeholder="Asset Name"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor:
                                        !assetErrors[index]?.asset_name && AssetError
                                            ? "red"
                                            : "#00A787",
                                }}
                                className={
                                    defaultInputSmStyle +
                                    " text-right sm:text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                }
                                onChange={(event) => {
                                    const value = event.target.value.trimStart();
                                    handleInputChange(index, "prodcut", value);
                                }}
                                disabled
                            />

                        </div>
                    </div>
                    {userdata?.hsnNo ? (
                        <>
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                                className={" w-full md:w-[50%] xl:w-[33%] 2xl:w-[33%]  text-right  pr-3  flex flex-row sm:block "}
                            >
                                <span className="sm:hidden w-1/2 flex flex-row items-center">
                                    HSN/SAC
                                </span>

                                <div className="font-title flex-1 text-left">
                                    <input
                                        disabled
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            borderColor: "#00A787",
                                        }}
                                        autoComplete="nope"
                                        value={productrow.hsn ? productrow.hsn : ""}
                                        onChange={(event) => {
                                            const value = event.target.value.replace(/[^0-9]/g, '');
                                            handleInputChange(index, "hsn", value)
                                        }}
                                        placeholder="HSN/SAC"
                                        type=""
                                        className={
                                            defaultInputSmStyle +
                                            " text-right  border-2 focus:outline-none rounded-[7px] px-2 h-9  w-full "
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    ) : (<></>)}
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={" w-full md:w-[40%] lg:w-[35%] 2xl:w-[35%]  text-right  pr-3  flex flex-row sm:block"}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Rate
                        </span>

                        <div className="font-title flex-1 text-left">
                            <input
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor:
                                        !assetErrors[index]?.rate && AssetError
                                            ? "red"
                                            : "#00A787",
                                }}
                                autoComplete="nope"
                                value={productrow.rate}
                                onChange={(event) => {
                                    let value = event.target.value.replace(/[^0-9.]/g, '');
                                    if (value.startsWith('0')) {
                                        value = value.substring(1);
                                    }
                                    handleInputChange(index, "rate", value);
                                }}
                                placeholder="Rate"
                                type=""
                                className={
                                    defaultInputSmStyle +
                                    " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                                }


                            />

                        </div>
                    </div>
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={"w-full md:w-[25%] xl:w-[25%] 2xl:w-[25%] text-right  pr-3 lg:pr-0 flex flex-row sm:block "}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Quantity
                        </span>

                        <div className="font-title flex-1 text-left">
                            <input
                                autoComplete="nope"
                                type=""
                                value={productrow.qty}
                                // onChange={(event) => {
                                //     const value = event.target.value.replace(/[^0-9]/g, '');
                                //     handleInputChange(index, "qty", value);
                                // }}
                                onChange={(event) => {
                                    let value = event.target.value.replace(/[^0-9]/g, '');
                                    if (value.startsWith('0')) {
                                        value = value.substring(1);
                                    }
                                    handleInputChange(index, "qty", value);
                                }}
                                placeholder="Qty"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor:
                                        !assetErrors[index]?.qty && AssetError
                                            ? "red"
                                            : "#00A787",
                                }}
                                className={
                                    defaultInputSmStyle +
                                    " text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  "
                                }
                            />

                        </div>
                    </div>
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={" w-full md:w-[36%] lg:w-[36%] xl:w-[36%] 2xl:w-[36%]  text-right pr-2 sm:pr-12 flex flex-row sm:block whitespace-nowrap"}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Total
                        </span>
                        <span className={"inline-block w-1/2 sm:w-full px-1 h-9 overflow-hidden pt-2"}>
                            <NumberFormat value={productrow?.amount} />
                        </span>
                    </div>

                    <div
                        onClick={() => removeRow(index, productrow)}
                        className="w-full sm:w-10 sm:absolute sm:right-0 pl-2 h-9 pt-2 mt-3 sm:mt-[-17px] mb-2 md:mb-0"
                    >
                        <div className="w-full text-[#9C0000] font-semibold font-title sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center p-[5px]">
                            <DeleteIcon className="h-4 w-4" style={IconStyle} />
                            <span className="block sm:hidden">Delete Product</span>
                        </div>
                    </div>
                </div>
            ))}

            {/* Add Product Actions */}

            <div
                style={{ fontFamily: "poppins", fontSize: "14px" }}
                className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-0 md:px-4 pl-2  py-0 md:py-2 items-center sm:justify-end"
            >
                <div
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                    className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
                ></div>

                <div
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                    className="font-title w-full text-right md:pr-8 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
                >
                    {/* {productlist.length ? > 0 ?} */}
                    <button
                        type="button"
                        className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                        style={{ backgroundColor: "#008065" }}
                        onClick={handleConfirm}
                    >
                        <InvoiceIcon style={IconStyle} className="h-5 w-5" />
                        Add Existing Sales FA
                    </button>
                </div>
            </div>
            {isModalOpen && (
                <SalesFAModel
                    closeModal={closeModal}
                    assetRows={assetRows}
                    setAssetRows={setAssetRows}
                    handleSelect={handleSelect}
                    vendorId={formData.vendorID}
                />
            )}
            {showConfirmationQty && (
                <StockUpdateModel
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
            {showConfirmation && (
                <PurchasepageVendorUpdateModel
                    message="Are you sure you want to proceed?"
                    // onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
}

export default SalesFABillData;
