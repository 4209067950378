/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from "@headlessui/react";
import { toWords } from "number-to-words";

import { FormControlLabel, Switch } from "@mui/material";
import moment from "moment";
import React, {
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useData } from "../../layouts/shared/DataProvider";
import { fontFamilyGet } from "../../redux/actions/FontFamilyAction";
import {
	InternalFindOne,
	InternalGetCount,
	InternalInvoiceUpdate,
	InternalPost,
} from "../../redux/actions/InternalInvoiceAction";
import { currencyGet } from "../../redux/actions/currencyAction";
import {
	ServiceFindOne,
	serviceInvoiceUpdate,
	ServicePost,
} from "../../redux/actions/invoiceServiceActions";
import { registerAll, updateUser } from "../../redux/actions/userAction";
import api from "../../redux/axiosConfig";
import PlusCircleIcon from "../Icons/PlusCircleIcon";
import ConfirmationDownload from "../commen/ConfirmationDownload";
import NumberFormat from "../commen/NumberFormat";
import PaymentUpdateModel from "../commen/PaymentUpdateModel";
import { defaultInputSmStyle, IconStyle } from "../commen/defaultStyles";
import SendEmailModel from "../models/SendEmailModel";
import "./../../layouts/App/App.css";
import ColorScheme from "./ColorScheme";
import BankDetails from "./InvoiceDetails/BankDetails";
import ClientDetails from "./InvoiceDetails/ClientDetails";
import GstSection from "./InvoiceDetails/GstSection";
import Notes from "./InvoiceDetails/Notes";
import ProductDetails from "./InvoiceDetails/ProductDetails";
import ProductNewDetails from "./InvoiceDetails/ProductNewDetails";
import ServiceDetails from "./InvoiceDetails/ServiceDetails";
import Signature from "./InvoiceDetails/Signature";
import TermsAndConditions from "./InvoiceDetails/TermsAndConditions";
import UpiPayment from "./InvoiceDetails/UpiPayment";
import Preview from "./Preview";
import PreviewNewProductwise from "./PreviewNewProductwise";
import PreviewService from "./PreviewService";
import Print from "./Print";
import PrintProductwise from "./PrintProductwise";
import PrintService from "./PrintService";
import ShippmentFrom from "./ShippmentFrom";
import ShippmentTo from "./ShippmentTo";
import CalculateIndianFormattotal from "../commen/CalculateIndianFormattotal";
import CalculateRoundTotalAmount from "../commen/CalculateRoundTotalAmount";

let headings = [
	{ id: 1, title: "Terms and Conditions:", status: false },
	{ id: 2, title: "Notes:", status: false },
	{ id: 3, title: "Bank Details:", status: false },
	{ id: 4, title: "UPI Payment:", status: false },
	{ id: 5, title: "Add Signature:", status: false },
];

function InvoicePage() {
	const [isToggled, setIsToggled] = useState(true);
	const [selectedPayment, setSelectedPayment] = useState("");
	const [balanceAmount, setbalanceAmount] = useState(0 as any);
	const [cashAmount, setCashAmount] = useState(null as any);
	const [chequeAmount, setchequeAmount] = useState(0 as any);
	const [creditAmount, setcreditAmount] = useState(0 as any);
	const [receivedAmount, setreceivedAmount] = useState(0 as any);
	const [chequeNum, setchequeNum] = useState<string>("");
	const [serviceBillNo, setserviceBillNo] = useState(null as any);
	const [invoiceBillNo, setinvoiceBillNo] = useState(null as any);
	const [billModules, setbillModule] = useState(null as any);
	const [dateFormat, setDateFormat] = useState("DD/MM/YYYY");
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [stockList, setStockList] = useState([] as any);
	const ref = useRef<HTMLInputElement | null>(null);
	const dispatch = useDispatch<any>();
	const params = useParams();
	const { id } = params;
	const usersignin = useSelector((state: any) => state?.userSignin);
	const token = usersignin?.userInfo?.token;
	const template = useSelector((state: any) => state.userColorTemplate);
	const { userdata } = template;
	const { selectedFont, setSelectedFont }: any = useData();

	const [titls, setTitls] = useState(headings);
	const [termsOpen, setTermsOpen] = useState(false);
	const [banksOpen, setBanksOpen] = useState(false);
	const [notesOpen, setNotesOpen] = useState(false);
	const [upiOpen, setUpiOpen] = useState(false);
	const [signatureOpen, setSignatureOpen] = useState(false);

	const handleHeaders = (item: any, index: any) => {
		let exist = [...headings];
		exist[index].status = !exist[index].status;
		setTitls(exist);
		if (item.id === 1) {
			let bool = item.id === 1 ? true : false;
			setTermsOpen(bool);
		}
		if (item.id === 2) {
			let noteing = item.id === 2 ? true : false;
			setNotesOpen(noteing);
		}

		if (item.id === 3) {
			let banking = item.id === 3 ? true : false;
			setBanksOpen(banking);
		}

		if (item.id === 4) {
			let upipay = item.id === 4 ? true : false;
			setUpiOpen(upipay);
		}
		if (item.id === 5) {
			let signatuared = item.id === 5 ? true : false;
			setSignatureOpen(signatuared);
		}
	};

	// ******************************Invoice Number Autogenarate section*****************************************

	// ****************************Date Picker and Date Section******************************************
	const [addValue, setAddValue] = useState(false);

	const handleAddvalue = () => {
		if (!addValue) {
			setFormData({
				...formData,
				duedate: moment(new Date()).format("DD/MM/YYYY"),
			});
		}
		setAddValue(!addValue);
	};

	const handleRemoveDate = () => {
		setFormData({
			...formData,
			duedate: "", // Set duedate to an empty string
		});
	};

	const [invoiceUpdateId, setInvoiceUpdateId] = useState(null as any);
	const navigate = useNavigate();
	const [invoiceDate, setInvoiceDate] = useState(new Date() as any);
	const [dueDate, setDueDate] = useState(new Date() as any);
	const [poDate, setPoDate] = useState(null as any);
	const formatDate = moment(invoiceDate).format("DD/MM/YYYY");
	const formatDueDate = moment(dueDate).format("DD/MM/YYYY");
	const location = useLocation();
	const invoiceModule = location.state;
	const [conditionError, setConditionError] = useState(false);
	const [qty, setQty] = useState("");

	// ***********************************************************************

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
	const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
	const postalcodeRegex = /^[1-9]\d{5}$/;
	const currentDate = moment();
	const [formData, setFormData] = useState({
		errorMessage: "",
		errors: {},
		paymentInReceiptNo: "",
		invoicehead: "INVOICE",
		invoiceNo: "",
		invoicedate: formatDate,
		poNumber: "",
		poDate: "",
		duedate: formatDueDate,
		imageData: "",
		companyName: "",
		companyAddress: "",
		companyCity: "",
		companyState: "",
		companyPostalCode: "",
		companyMobile: "",
		companyEmail: "",
		companyPan: "",
		companygst: "",
		clientName: "",
		clientAddress: "",
		clientCity: "",
		clientState: "",
		clientPostalCode: "",
		clientMobile: "",
		clientEmail: "",
		clientPan: "",
		clientgst: "",
		subtotal: 0,
		toatalAmount: 0,
		product: [],
		tax: [],
		roundOff: 0,
		extrafees: [],
		termsanddcon: "",
		bankNme: "",
		accounHolder: "",
		accounNumber: "",
		ifscCode: "",
		upiType: "",
		upiNumber: "",
		notes: "",
		signature: "",
		seal: "",
		otherValue: "",
		dropDown: "",
		user_id: token?._id,
		To: "",
		Subject: "",
		sealPostion: "",
		signPostion: "",
		shipFromName: "",
		shipFromAddress: "",
		shipFromCity: "",
		shipFromState: "",
		shipFromPostalCode: "",
		shipToName: "",
		shipToAddress: "",
		shipToCity: "",
		shipToState: "",
		totalInWords: "",
		shipToPostalCode: "",
		footerNotes: "",
		billTaxType: userdata?.taxType,
		gstType: userdata?.gstValue,
		invoiceTemplateType: "",
		colorTemplateType: "",
		billModule: billModules,
		paymentType: selectedPayment,
		cashAmount: cashAmount,
		creditAmount: creditAmount,
		chequeAmount: chequeAmount,
		balanceAmount: balanceAmount,
		chequeNum: chequeNum,
		receivedAmount: receivedAmount,
		paymentStatus: "",
		isClientGst: true,
		isshippingValidPostalCode: true,
		isclientPostalCode: true,
		isClientEmail: true,
		isClientPan: true,
		isclientMobile: true,
		isshippingclientPostalCode: true,
		salesReturnStatus: "",
		serviceDiscount: "",
		serviceTax: "",
		cashinhandId: "",
		gstServiceType: "",
		saleType: "SALES",
		RoundOffType:"",
		RoundOffEnabled:"",
		AmountFormat:"",
	} as any);

	useEffect(() => {
		setFormData((prevFormData: any) => ({
			...prevFormData,
			balanceAmount: balanceAmount ? balanceAmount : "",
			receivedAmount: receivedAmount ? Number(receivedAmount) : 0,
			cashAmount: cashAmount ? cashAmount : 0,
			creditAmount: creditAmount ? creditAmount : 0,
			chequeAmount: chequeAmount ? chequeAmount : 0,
			paymentType: selectedPayment ? selectedPayment : "",
			chequeNum: chequeNum ? chequeNum : "",
		}));
	}, [balanceAmount, receivedAmount, cashAmount, chequeNum]);

	useEffect(() => {
		dispatch(fontFamilyGet(token?._id) as any).then((res: any) => {
			if (res?.type === "FONTFAMILY_GET_SUCCESS") {
				let data = res?.payload?.find(
					(value: any) => value?.defaultInfo === true
				);
				if (data) {
					setSelectedFont(data?.fontfamily);
				} else {
					setSelectedFont("poppins");
				}
			}
		});
	}, []);

	const [roundOffValue, setRoundoffValue] = useState("" as any);

	const [roundOffEnable, setRoundoffEnable] = useState(Boolean);

    const[amountInWords,setAmountInWord]=useState(""as any)
	const [currencySymbol, setcurrencySymbol] = useState("₹");

	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	}, []);

	// ****************************************Image section***************************************************

	const [clientErrors, setClientErrors] = useState<{
		clientName?: string;
		paymentType?: string;
		chequeNum?: string;
		balanceAmount?: string;
	}>({});

	const [sendEmailErrors, setSendEmailErrors] = useState<{ To?: string }>({});
	const validateProdForm = () => {
		if (formData.clientName?.trim() === "") {
			clientErrors["clientName"] = "Client Name is Requried  ";
		}
		if (formData.paymentType?.trim() === "") {
			clientErrors["paymentType"] = "Payment Type is Requried  ";
		}
		if (formData.chequeNum?.trim() === "") {
			clientErrors["chequeNum"] = "Cheque Number is Requried  ";
		}

		if (id) {
			if (parseFloat(formData.toatalAmount) < formData.receivedAmount) {
				clientErrors["balanceAmount"] = "Balance Amount Should not Exceeds";
			}
		}
	};

	const [isEditing, setEditing] = useState(false);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			ref?.current?.blur();
			setEditing(false);
			setFormData({
				...formData,
				invoicehead: tittle ? tittle : "INVOICE",
			});
			const inputElement = document.getElementById("input_focus");
			if (inputElement) {
				inputElement.blur();
			}
		}
	};

	const handleEditClick = () => {
		setEditing(false);
		ref?.current?.focus();
	};

	interface FieldErrors {
		cashAmount?: string;
		creditAmount?: string;
		chequeAmount?: string;
	}

	const [errors, setErrors] = useState({} as any);
	const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		let newValue = value.trimStart();
		setFormData({ ...formData, [name]: value });
		setErrors({
			...errors,
			[name]: "",
		});

		const numericValue = Number(newValue);
		const fieldName = name as keyof FieldErrors;

		let error = "";
		if (
			(fieldName === "cashAmount" ||
				fieldName === "creditAmount" ||
				fieldName === "chequeAmount") &&
			value
		) {
			if (formData?.toatalAmount < numericValue) {
				error = "Invalid Amount";
			} else if (numericValue <= 0) {
				error = " Amount must be Greater than 0";
			}
		}
		if (fieldName === "cashAmount") {
			if (!newValue) {
				error = "Cash amount cannot be empty";
			} else if (numericValue > formData?.toatalAmount) {
				error = "Invalid Amount";
			} else if (numericValue <= 0) {
				error = "Amount must be greater than 0";
			}
		}
		if (fieldName === "chequeAmount") {
			if (!newValue) {
				error = "Cash amount cannot be empty";
			} else if (numericValue > formData?.toatalAmount) {
				error = "Invalid Amount";
			} else if (numericValue <= 0) {
				error = "Amount must be greater than 0";
			}
		}
		setFieldErrors((prevErrors: any) => ({
			...prevErrors,
			[fieldName]: error,
		}));

		if (name == "invoicehead") {
			setTittle(newValue);
		}
		if (value == "CASH") {
			setCashAmount(parseFloat(parseFloat(formData.toatalAmount).toFixed(2)));
			setreceivedAmount(formData.toatalAmount);
			setcreditAmount(0.0);
			setchequeAmount(0.0);
			setbalanceAmount(0.0);
			setchequeNum("");
		}
		if (value == "CREDIT") {
			setcreditAmount(formData.toatalAmount);
			setbalanceAmount(formData.toatalAmount);
			setCashAmount(0.0);
			setchequeAmount(0.0);
			setreceivedAmount(0.0);
			setchequeNum("");
		}
		if (value == "CHEQUE") {
			setchequeAmount(parseFloat(parseFloat(formData.toatalAmount).toFixed(2)));
			setreceivedAmount(formData.toatalAmount);
			setCashAmount(0.0);
			setcreditAmount(0.0);
			setbalanceAmount(0.0);
			setchequeNum("");
		}
		if (name == "paymentType") {
			setSelectedPayment(e.target.value);
			setbalanceAmount(0);
			setchequeNum("");
			fieldErrors.cashAmount = "";
			fieldErrors.creditAmount = "";
			fieldErrors.chequeAmount = "";
			clientErrors["chequeNum"] = "";
			formData.chequeNum = "";
			if (formData.product.length === 0) {
				setShowConfirmation(true);
			}

			if (value === "CREDIT") {
				setbalanceAmount(formData.toatalAmount);
			}
		}
		if (name == "cashAmount") {
			let cashvalue = e.target.value;
			let balance = formData.toatalAmount - Number(cashvalue);
			setbalanceAmount(parseFloat(balance.toFixed(2)));
			setCashAmount(cashvalue);
			setcreditAmount(0.0);
			setchequeAmount(0.0);
			setchequeNum("");
			setreceivedAmount(cashvalue);
		}
		if (name == "creditAmount") {
			let creditvalue = e.target.value;
			let balance = Number(creditvalue);
			setbalanceAmount(balance);
			setcreditAmount(balance);
			setCashAmount(0.0);
			setchequeAmount(0.0);
			setchequeNum("");
		}
		if (name == "chequeAmount") {
			let chequevalue = e.target.value;
			let balance = formData.toatalAmount - Number(chequevalue);
			setbalanceAmount(balance);
			setchequeAmount(Number(chequevalue));
			setreceivedAmount(chequevalue);
			setCashAmount(0.0);
			setcreditAmount(0.0);
		}
		setClientErrors({ ...clientErrors, [e.target.name]: "" });
		if (name === "chequeNum") {
			let numericValue = value.replace(/\D/g, "");
			// if (numericValue.startsWith("0")) {
			//   numericValue = numericValue.slice(1);
			// }
			// e.target.value = numericValue;
			setchequeNum(numericValue);
			if (numericValue === "") {
				setClientErrors((prevErrors) => ({
					...prevErrors,
					chequeNum: "Cheque number cannot be empty",
				}));
			} else {
				setClientErrors((prevErrors) => ({
					...prevErrors,
					chequeNum: "",
				}));
			}
		}
		setErrorEmail(null);
		setSendEmailErrors({ ...sendEmailErrors, [e.target.name]: "" });
		let errorMessage = "";
		let isSendEmail = true;
		let isshippingValidPostalCode = true;
		let isClientGst = true;
		let isclientPostalCode = true;
		let isclientMobile = true;
		let isClientEmail = true;
		let isClientPan = true;
		let isshippingclientPostalCode = true;
		if (e.target.name === "To" && value) {
			isSendEmail = emailRegex.test(value);
			errorMessage = isSendEmail ? "" : "Invalid Email Address";
		}

		if (e.target.name === "shipFromPostalCode" && value) {
			isshippingValidPostalCode = postalcodeRegex.test(value);
			if (!isshippingValidPostalCode) {
				errorMessage = "Invalid Postal Code";
			}
		}

		if (e.target.name === "clientEmail" && value) {
			isClientEmail = emailRegex.test(newValue);
			errorMessage = isClientEmail ? "" : "Invalid Email Address";
		}
		if (e.target.name === "clientMobile" && value) {
			isclientMobile = value.length === 10;
			errorMessage = isclientMobile ? "" : "Invalid  Mobile Number";
		}
		if (e.target.name === "clientPostalCode" && value) {
			isclientPostalCode = postalcodeRegex.test(value);
			errorMessage = isclientPostalCode ? "" : "Invalid Postal Code";
		}
		if (e.target.name === "clientPan" && value) {
			newValue = value.toUpperCase();
			isClientPan = panRegex.test(newValue);
			errorMessage = isClientPan ? "" : "Invalid PAN Number";
		}
		if (e.target.name === "clientgst" && value) {
			newValue = value.toUpperCase();
			isClientGst = gstRegex.test(newValue);
			errorMessage = isClientGst ? "" : "Invalid GST Number";
		}
		if (e.target.name === "shipToPostalCode" && value) {
			isshippingclientPostalCode = postalcodeRegex.test(value);
			errorMessage = isshippingclientPostalCode ? "" : "Invalid Postal Code";
		}
		setFormData({
			...formData,
			[e.target.name]: newValue,
			errors: {
				...formData.errors,
				[`${e.target.name}ErrorMessage`]: errorMessage,
			},
			isshippingValidPostalCode:
				e.target.name === "shipFromPostalCode"
					? isshippingValidPostalCode
					: formData.isshippingValidPostalCode,
			isClientEmail:
				e.target.name === "clientEmail"
					? isClientEmail
					: formData.isClientEmail,
			isclientPostalCode:
				e.target.name === "clientPostalCode"
					? isclientPostalCode
					: formData.isclientPostalCode,
			isClientPan:
				e.target.name === "clientPan" ? isClientPan : formData.isClientPan,
			isClientGst:
				e.target.name === "clientgst" ? isClientGst : formData.isClientGst,
			isclientMobile:
				e.target.name === "clientMobile"
					? isclientMobile
					: formData.isclientMobile,
			isshippingclientPostalCode:
				e.target.name === "shipToPostalCode"
					? isshippingclientPostalCode
					: formData.isshippingclientPostalCode,
			isSendEmail: e.target.name === "To" ? isSendEmail : formData.isSendEmail,
		});
	};
	// ***********************************Start***********************************
	const [tittle, setTittle] = useState(null as any);
	const [companyList, setComapnyList] = useState([] as any);
	const [companyDetails, setcompanyDetails] = useState(false);
	const [TaxAllList, setTaxAllList] = useState([] as any);
	const [ServiceTaxAllList, setServiceTaxAllList] = useState([] as any);
	const [bankList, setBankList] = useState(null as any);
	const [upiList, setUpiList] = useState(null as any);
	const [termsList, setTermsList] = useState(null as any);
	const [notesList, setNotesList] = useState(null as any);
	const [sign, setSign] = useState();
	const fetchData = () => {
		dispatch(InternalGetCount() as any).then((data: any) => {
			if (data) {
				let companyResponse: any = JSON.parse(data?.payload?.company);
				let conpanylist = companyResponse?.find((x: any) => x);
				setComapnyList(conpanylist);
				let tittleResponse: any = JSON.parse(data?.payload?.tittles);
				let tittleList = tittleResponse?.find((x: any) => x);

				let tittleserviceResponse: any = JSON.parse(
					data?.payload?.titleservice
				);
				let tittleserviceList = tittleserviceResponse?.find((x: any) => x);

				setTittle(tittleList?.invoiceTittle);
				setTaxAllList(JSON.parse(data?.payload?.appsettings));
				let bank_detail = JSON.parse(data?.payload?.banked);
				let bankData = bank_detail?.find((x: any) => x);
				setBankList(bankData);
				setBanksOpen(bankData ? true : false);
				titls[2].status = bankData ? true : false;
				let upi_details = JSON.parse(data?.payload?.upi);
				let upiData = upi_details?.find((x: any) => x);
				setUpiList(upiData);
				setUpiOpen(upiData ? true : false);
				titls[3].status = upiData ? true : false;
				let terms_details = JSON.parse(data?.payload?.terms);
				let termsData = terms_details?.find((x: any) => x);
				setTermsList(termsData);
				let footer_Notes = JSON.parse(data?.payload?.footerNotes);
				let footerNotesData = footer_Notes?.find((x: any) => x);
				setTermsOpen(termsData ? true : false);
				titls[0].status = termsData ? true : false;
				let sign_details = JSON.parse(data?.payload?.signatures);
				let signData = sign_details?.find((x: any) => x._id);
				setSign(signData);
				setSignatureOpen(
					signData?.signImage || signData?.sealImage ? true : false
				);
				titls[4].status =
					signData?.signImage || signData?.sealImage ? true : false;
				let notes_details = JSON.parse(data?.payload?.notes);
				let notesData = notes_details?.find((x: any) => x);
				setNotesList(notesData);
				setNotesOpen(notesData ? true : false);
				titls[1].status = notesData ? true : false;
				let newCountValue = data?.payload?.newCount;
				let match = newCountValue?.match(/\d{4}$/);
				let result = "";
				if (match) {
					let value = match[0];
					result = (parseInt(value) + 1).toString().padStart(4, "0");
				}

				let datevalue = moment(new Date()).format("YYYY-MM-DD");
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const [year, month] = datevalue.split("-");
				let invoice =
					`${tittleList?.invoiceNumber ? tittleList?.invoiceNumber : "lala-"}` +
					// String(`${month}/`) +
					String(
						`${tittleList?.invoiceYear ? tittleList?.invoiceYear : year}/`
					) +
					String(
						tittleList?.invoiceDigit
							? String(Number(tittleList?.invoiceDigit) + result)
							: result
					);

				// For ServiceBill No Generation
				let newCountValueservice = data?.payload?.serviceNewCount;
				let matchservice = newCountValueservice?.match(/\d{4}$/);
				let resultservice = "";
				if (matchservice) {
					let value = matchservice[0];
					resultservice = (parseInt(value) + 1).toString().padStart(4, "0");
				}

				let serviceinvoiceNo =
					`${
						tittleserviceList?.serviceNumber
							? tittleserviceList?.serviceNumber
							: "Serv-"
					}` +
					// String(`${monthservice}/`) +
					String(
						`${
							tittleserviceList?.serviceYear
								? tittleserviceList?.serviceYear
								: year
						}/`
					) +
					String(
						tittleList?.serviceDigit
							? String(Number(tittleList?.serviceDigit) + result)
							: resultservice
					);

				let billNo = "";
				if (billModules === "PRODUCT") {
					billNo = invoice;
				} else if (billModules === "SERVICE") {
					let serviceInvoiceNumber = serviceinvoiceNo;
					billNo = serviceInvoiceNumber;
				}
				setserviceBillNo(serviceinvoiceNo);
				setinvoiceBillNo(invoice);
				setFormData((prev: any) => ({
					...prev,
					invoiceNo: billNo,
					bankNme: bankData?.bank_name ? bankData?.bank_name : "",
					accounHolder: bankData?.holder_name ? bankData?.holder_name : "",
					accounNumber: bankData?.account_number
						? bankData?.account_number
						: "",
					ifscCode: bankData?.ifsc_code ? bankData?.ifsc_code : "",
					upiNumber: upiData?.upi_number ? upiData?.upi_number : "",
					upiType: upiData?.upi_pay ? upiData?.upi_pay : "",
					termsanddcon: termsData?.terms_Conditions
						? termsData?.terms_Conditions
						: "",
					signature: signData?.signImage ? signData?._id.$oid : "",
					sealPostion: signData?.sealPostion ? signData?.sealPostion : "",
					signPostion: signData?.signPostion ? signData?.signPostion : "",
					seal: signData?.sealImage ? signData?._id.$oid : "",
					otherValue: signData?.otherValue ? signData?.otherValue : "",
					notes: notesData?.notes ? notesData?.notes : "",
					invoicehead: tittleList?.invoiceTittle
						? tittleList?.invoiceTittle
						: "INVOICE",

					footerNotes: footerNotesData?.footernotes
						? footerNotesData?.footernotes
						: "",
					companyName: conpanylist?.company_name
						? conpanylist?.company_name
						: "",
					companyAddress: conpanylist?.company_address
						? conpanylist?.company_address
						: "",
					companyCity: conpanylist?.city ? conpanylist?.city : "",
					companyState: conpanylist?.state ? conpanylist?.state : "",
					companyPostalCode: conpanylist?.postal_code
						? conpanylist?.postal_code
						: "",
					companyPan: conpanylist?.pan_no ? conpanylist?.pan_no : "",
					companygst: conpanylist?.gst ? conpanylist?.gst : "",
					companyMobile: conpanylist?.company_mobile
						? conpanylist?.company_mobile
						: "",
					companyEmail: conpanylist?.company_email
						? conpanylist?.company_email
						: "",
					paymentInReceiptNo: data?.payload?.paymentInReceiptCount,
				}));
			}
		});
	};
	useEffect(() => {
		if (companyList?.length === 0) {
			setcompanyDetails(true);
		} else {
			setcompanyDetails(false);
		}
		setFormData((prevFormData: any) => ({
			...prevFormData,
			invoicedate: formData?.invoicedate ? formData?.invoicedate : formatDate,
			duedate: formData?.duedate ? formData?.duedate : formatDueDate,
		}));
		// Function to format number in Indian format
	}, [invoiceDate, dueDate, poDate, addValue]);
	const handleBlur = () => {
		setEditing(false);
		setFormData({
			...formData,
			invoicehead: tittle ? tittle : "INVOICE",
		});
	};

	// ***********************************End***********************************

	// ***********************************Submit Section***********************************

	// const handleInputChange = () =>{

	// }
	const [confirmDownload, setConfirmDownload] = useState(false);
	const [productError, setProductError] = useState(false);
	const [serviceError, setServiceError] = useState(false);
	const [prodcutRows, setProductRows] = useState<any>([
		{
			prodcut: "",
			hsn: "",
			rate: "",
			qty: "",
			igst: "",
			cgst: "",
			sgst: "",
			amount: 0,
			igstAmount: "",
			cgstAmount: "",
			sgstAmount: "",
			_id: "",
		},
	] as any);

	const [serviceRows, setServiceRows] = useState<any>([
		{
			service_name: "",
			rate: "",
			qty: "",
			amount: 0,
			serviceUnique_id: "",
		},
	] as any);

	const [prodcutErrors, setProdcutErrors] = useState<any>([
		{ prodcut: false, units: false, rate: false, qty: false },
	] as any);
	const [serviceErrors, setServiceErrors] = useState<any>([
		{ qty: false },
	] as any);

	const updateProductErrors = (index: any, fieldName: any, value: any) => {
		const newErrors = [...prodcutErrors];
		newErrors[index][fieldName] = value ? true : false;
		setProdcutErrors(newErrors);
	};

	const updateServiceErrors = (index: any, fieldName: any, value: any) => {
		const newErrors = [...serviceErrors];
		newErrors[index][fieldName] = value ? true : false;
		setServiceErrors(newErrors);
	};
	const addRow = () => {
		setProductRows([
			...prodcutRows,
			{ prodcut: "", hsn: "", rate: "", qty: "", amount: 0, productName: "" },
		]);

		setServiceRows([
			...serviceRows,
			{ service_name: "", rate: "", qty: "", amount: 0, serviceUnique_id: "" },
		]);

		setProdcutErrors([
			...prodcutErrors,
			{
				prodcut: productError,
				// hsn: productError,
				rate: productError,
				qty: productError,
			},
		]);

		setServiceErrors([
			...serviceErrors,
			{
				qty: serviceError,
			},
		]);
	};

	const getProductCal = (values: any) => {
		let products = [...values.product];
		let finalProduct = products?.map((item: any) => {
			item.prodcgst = TaxAllList[0]?.cgstPercentage || 0;
			item.prodsgst = TaxAllList[0]?.sgstPercentage || 0;
			item.prodigst = TaxAllList[0]?.igstPercentage || 0;
			item.prodvat = TaxAllList[0]?.vatPercentage || 0;
			item.prodothers = TaxAllList[0]?.othersPercentage || 0;
			item.proddiscount = TaxAllList[0]?.discountPercentage || 0;
			item.totalgst =
				parseFloat(TaxAllList[0]?.cgstPercentage || 0) +
				parseFloat(TaxAllList[0]?.sgstPercentage || 0) +
				parseFloat(TaxAllList[0]?.igstPercentage || 0) +
				parseFloat(TaxAllList[0]?.vatPercentage || 0) +
				parseFloat(TaxAllList[0]?.othersPercentage || 0);
			item.grandTotal =
				((parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
					parseFloat(item.rate)) *
				parseFloat(item.qty);
			item.total =
				(parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
				parseFloat(item.rate);
			item.creation_date = new Date();
			return item;
		});
		return finalProduct;
	};

	const fetchDownload = (data: any) => {};

	const downloadInvoice = () => {
		fetchDownload(formData);
	};

	const [invoiceUpdateNewId, setInvoiceUpdateNewId] = useState(null as any);
	const saveAndDownloadInvoice = () => {
		if (amountInWords  === "International Format") {
			const toWords = (num: any) => {
				const numberToWords = require("number-to-words");
				return numberToWords.toWords(num);
			};

			const convertAmount = (amount: any) => {
				const roundedAmount = Math.round(amount);
				const amountInWords = Number.isFinite(roundedAmount)
					? toWords(roundedAmount)
					: "Invalid amount";
				const internationalAmountInWords = `${amountInWords} Only. `;

				return {
					internationalAmountInWords,
				};
			};

			const totalAmount = formData.toatalAmount;
			const result = convertAmount(totalAmount);
			formData.totalInWords = result.internationalAmountInWords;
		} else {
			const totalAmount = formData.toatalAmount;
			const roundedtotalAmount = Math.round(totalAmount);

			const totalAmountInWords = Number.isFinite(roundedtotalAmount)
				? CalculateIndianFormattotal(roundedtotalAmount)
				: "Invalid amount";
			const totalAmountInWordsPrint = "Rupees " + totalAmountInWords + " Only";
			formData.totalInWords = totalAmountInWordsPrint;
		}
		let service = {
			paymentInReceiptNo: formData.paymentInReceiptNo,
			invoicehead: formData.invoicehead,
			invoiceNo: formData.invoiceNo,
			invoicedate: formData.invoicedate,
			poNumber: formData.PoNumber ? formData.PoNumber : "",
			poDate: formData.poDate ? formData.poDate : "",
			duedate: formData.duedate,
			imageData: formData.imageData,
			companyName: formData.companyName,
			companyAddress: formData.companyAddress,
			companyCity: formData.companyCity,
			companyState: formData.companyState,
			companyPostalCode: formData.companyPostalCode,
			companyMobile: formData.companyMobile,
			companyEmail: formData.companyEmail,
			companyPan: formData.companyPan,
			companygst: formData.companygst,
			clientName: formData.clientName,
			clientAddress: formData.clientAddress,
			clientCity: formData.clientCity,
			clientState: formData.clientState,
			clientPostalCode: formData.clientPostalCode,
			clientMobile: formData.clientMobile,
			clientEmail: formData.clientEmail,
			clientPan: formData.clientPan,
			clientgst: formData.clientgst,
			product: formData.product,
			tax: formData.tax,
			subtotal: formData.subtotal,
			paymentType: selectedPayment,
			cashAmount: cashAmount,
			creditAmount: creditAmount,
			chequeAmount: chequeAmount,
			balanceAmount: balanceAmount,
			chequeNum: chequeNum,
			receivedAmount: receivedAmount,
			paymentStatus: "",
			toatalAmount: formData.toatalAmount,
			termsanddcon: formData.termsanddcon,
			bankNme: formData.bankNme,
			accounHolder: formData.accounHolder,
			accounNumber: formData.accounNumber,
			ifscCode: formData.ifscCode,
			upiType: formData.upiType,
			upiNumber: formData.upiNumber,
			signature: formData.signature,
			seal: formData.seal,
			otherValue: formData.otherValue,
			dropDown: formData.dropDown,
			notes: formData.notes,
			user_id: formData.user_id,
			sealPostion: formData.sealPostion,
			signPostion: formData.signPostion,
			totalInWords: formData.totalInWords,
			footerNotes: formData.footerNotes,
			billTaxType: (formData.billTaxType = userdata?.taxType),
			gstServiceType: formData.gstServiceType,
			gstType: userdata?.gstValue,
			roundOff: formData.roundOff,
			RoundOffType:formData.RoundOffType,
			RoundOffEnabled:formData.RoundOffEnabled,
			AmountFormat:formData.AmountFormat,
			invoiceTemplateType: (formData.invoiceTemplateType =
				userdata?.invoiceType),
			colorTemplateType: (formData.colorTemplateType = userdata?.template),
			billModule: (formData.billModule = billModules),
			nontaxableTotal: "",
		};
		if (!id && !invoiceUpdateId) {
			formData.invoicedate = formatDate;
			// formData.product = getProductCal(formData);
			formData.billTaxType = userdata?.taxType;
			formData.gstType = userdata?.gstValue;
			if (billModules != "SERVICE")
				formData.invoiceTemplateType = userdata?.invoiceType;
			formData.colorTemplateType = userdata?.template;

			if (billModules == "SERVICE") {
				dispatch(ServicePost(service) as any).then((response: any) => {
					formData?.product.forEach((item: any) => {
						item.amount = Number(item.amount).toFixed(2);
					});
					if (response?.payload?.invoice_id) {
						const queryParams = new URLSearchParams();
						queryParams.append("param1", Colorpicker[0]);
						queryParams.append("param2", Colorpicker[1]);
						queryParams.append("param3", Colorpicker[2]);
						queryParams.append("param4", Colorpicker[3]);
						if (userdata?.template) {
							queryParams.append("param5", userdata?.template);
						}
						queryParams.append("param6", selectedFont);
						queryParams.append("param7", currencySymbol);
						if (userdata?.invoiceType) {
							queryParams.append("param8", userdata?.invoiceType);
						}
						queryParams.append("param9", formData.totalInWords);

						const url = `/api/service/invoice_pdf/${
							response?.payload?.invoice_id
						}?${queryParams.toString()}`;
						fetch(url, {
							method: "GET",
						})
							.then((response) => {
								if (!response.ok) {
									throw new Error("Network response was not ok");
								}
								return response.blob();
							})
							.then((blob) => {
								const url = window.URL.createObjectURL(new Blob([blob]));
								const link: any = document.createElement("a");
								link.href = url;
								const formattedDate = moment().format("YYYY-MM-DD");
								link.setAttribute(
									"download",
									`invoice_${formData.clientName}_${formattedDate}.pdf`
								);
								document.body.appendChild(link);
								link.click();
								link.parentNode.removeChild(link);
								setProductError(false);
								setServiceError(false);
								setInvoiceUpdateId(response?.payload?.invoice_id);
								setConfirmDownload(false);
							})
							.catch((error) => console.error("Error downloading PDF:", error));
					}
				});
			} else {
				if (invoiceUpdateNewId) {
					if (amountInWords === "International Format") {
						const toWords = (num: any) => {
							const numberToWords = require("number-to-words");
							return numberToWords.toWords(num);
						};

						const convertAmount = (amount: any) => {
							const roundedAmount = Math.round(amount);
							const amountInWords = Number.isFinite(roundedAmount)
								? toWords(roundedAmount)
								: "Invalid amount";
							const internationalAmountInWords = `${amountInWords} Only. `;

							return {
								internationalAmountInWords,
							};
						};

						const totalAmount = formData.toatalAmount;
						const result = convertAmount(totalAmount);
						formData.totalInWords = result.internationalAmountInWords;
					} else {
						const totalAmount = formData.toatalAmount;
						const roundedtotalAmount = Math.round(totalAmount);
						const totalAmountInWords = Number.isFinite(roundedtotalAmount)
							? CalculateIndianFormattotal(roundedtotalAmount)
							: "Invalid amount";
						const totalAmountInWordsPrint =
							"Rupees " + totalAmountInWords + " Only";
						formData.totalInWords = totalAmountInWordsPrint;
					}
					dispatch(
						InternalInvoiceUpdate(invoiceUpdateNewId, formData) as any
					).then((response: any) => {
						const queryParams = new URLSearchParams();
						queryParams.append("param1", Colorpicker[0]);
						queryParams.append("param2", Colorpicker[1]);
						queryParams.append("param3", Colorpicker[2]);
						queryParams.append("param4", Colorpicker[3]);
						if (formData.colorTemplateType) {
							queryParams.append("param5", formData.colorTemplateType);
						}
						queryParams.append("param6", selectedFont);
						queryParams.append("param7", currencySymbol);
						if (formData.invoiceTemplateType) {
							queryParams.append("param8", formData.invoiceTemplateType);
						}

						queryParams.append("param9", formData.totalInWords);

						// Construct the URL with the query parameters

						const url = `/api/invoice_pdf/${invoiceUpdateNewId}?${queryParams.toString()}`;
						fetch(url, {
							method: "GET",
						})
							.then((response) => {
								if (!response.ok) {
									throw new Error("Network response was not ok");
								}
								return response.blob();
							})
							.then((blob) => {
								const url = window.URL.createObjectURL(new Blob([blob]));
								const link: any = document.createElement("a");
								link.href = url;
								const formattedDate = moment().format("YYYY-MM-DD");
								link.setAttribute(
									"download",
									`invoice_${formData.clientName}_${formattedDate}.pdf`
								);
								document.body.appendChild(link);
								link.click();
								link.parentNode.removeChild(link);
								setProductError(false);
								setServiceError(false);
								setConfirmDownload(false);
							})
							.catch((error) => console.error("Error downloading PDF:", error));
					});
				} else {
					if (amountInWords=== "International Format") {
						const toWords = (num: any) => {
							const numberToWords = require("number-to-words");
							return numberToWords.toWords(num);
						};

						const convertAmount = (amount: any) => {
							const roundedAmount = Math.round(amount);
							const amountInWords = Number.isFinite(roundedAmount)
								? toWords(roundedAmount)
								: "Invalid amount";
							const internationalAmountInWords = `${amountInWords} Only. `;

							return {
								internationalAmountInWords,
							};
						};

						const totalAmount = formData.toatalAmount;
						const result = convertAmount(totalAmount);
						formData.totalInWords = result.internationalAmountInWords;
					} else {
						const totalAmount = formData.toatalAmount;
						const roundedtotalAmount = Math.round(totalAmount);
						const totalAmountInWords = Number.isFinite(roundedtotalAmount)
							? CalculateIndianFormattotal(roundedtotalAmount)
							: "Invalid amount";
						const totalAmountInWordsPrint =
							"Rupees " + totalAmountInWords + " Only";
						formData.totalInWords = totalAmountInWordsPrint;
					}

					dispatch(InternalPost(formData) as any).then((response: any) => {
						setInvoiceUpdateNewId(response?.payload?.invoice_id);
						formData?.product.forEach((item: any) => {
							item.amount = Number(item.amount).toFixed(2);
						});

						if (response?.payload?.invoice_id) {
							const queryParams = new URLSearchParams();
							queryParams.append("param1", Colorpicker[0]);
							queryParams.append("param2", Colorpicker[1]);
							queryParams.append("param3", Colorpicker[2]);
							queryParams.append("param4", Colorpicker[3]);
							if (userdata?.template) {
								queryParams.append("param5", userdata?.template);
							}
							queryParams.append("param6", selectedFont);
							queryParams.append("param7", currencySymbol);
							if (userdata?.invoiceType) {
								queryParams.append("param8", userdata?.invoiceType);
							}

							queryParams.append("param9", formData.totalInWords);

							// Construct the URL with the query parameters
							const url = `/api/invoice_pdf/${
								response?.payload?.invoice_id
							}?${queryParams.toString()}`;
							fetch(url, {
								method: "GET",
							})
								.then((response) => {
									if (!response.ok) {
										throw new Error("Network response was not ok");
									}
									return response.blob();
								})
								.then((blob) => {
									const url = window.URL.createObjectURL(new Blob([blob]));
									const link: any = document.createElement("a");
									link.href = url;
									const formattedDate = moment().format("YYYY-MM-DD");
									link.setAttribute(
										"download",
										`invoice_${formData.clientName}_${formattedDate}.pdf`
									);
									document.body.appendChild(link);
									link.click();
									link.parentNode.removeChild(link);
									setProductError(false);
									setServiceError(false);
									setInvoiceUpdateId(response?.payload?.invoice_id);
									setConfirmDownload(false);
								})
								.catch((error) =>
									console.error("Error downloading PDF:", error)
								);
						}
					});
				}
			}
		} else if (id || invoiceUpdateId) {
			let invoiceId = id ? id : invoiceUpdateId;
			formData.invoicedate = formatDate;
			formData.product = getProductCal(formData);
			formData.gstType = userdata?.gstValue;
			formData?.product.forEach((item: any) => {
				item.amount = Number(item.amount).toFixed(2);
			});
			if (billModules == "SERVICE") {
				if (amountInWords === "International Format") {
					const toWords = (num: any) => {
						const numberToWords = require("number-to-words");
						return numberToWords.toWords(num);
					};

					const convertAmount = (amount: any) => {
						const roundedAmount = Math.round(amount);
						const amountInWords = Number.isFinite(roundedAmount)
							? toWords(roundedAmount)
							: "Invalid amount";
						const internationalAmountInWords = `${amountInWords} Only. `;

						return {
							internationalAmountInWords,
						};
					};

					const totalAmount = formData.toatalAmount;
					const result = convertAmount(totalAmount);
					formData.totalInWords = result.internationalAmountInWords;
				} else {
					const totalAmount = formData.toatalAmount;
					const roundedtotalAmount = Math.round(totalAmount);
					const totalAmountInWords = Number.isFinite(roundedtotalAmount)
						? CalculateIndianFormattotal(roundedtotalAmount)
						: "Invalid amount";
					const totalAmountInWordsPrint =
						"Rupees " + totalAmountInWords + " Only";
					formData.totalInWords = totalAmountInWordsPrint;
				}
				dispatch(serviceInvoiceUpdate(invoiceId, service) as any).then(
					(response: any) => {
						const queryParams = new URLSearchParams();
						queryParams.append("param1", Colorpicker[0]);
						queryParams.append("param2", Colorpicker[1]);
						queryParams.append("param3", Colorpicker[2]);
						queryParams.append("param4", Colorpicker[3]);
						if (formData.colorTemplateType) {
							queryParams.append("param5", formData.colorTemplateType);
						}
						queryParams.append("param6", selectedFont);
						queryParams.append("param7", currencySymbol);
						if (formData.invoiceTemplateType) {
							queryParams.append("param8", formData.invoiceTemplateType);
						}
						queryParams.append("param9", formData.totalInWords);

						// Construct the URL with the query parameters

						const url = `/api/service/invoice_pdf/${invoiceId}?${queryParams.toString()}`;
						fetch(url, {
							method: "GET",
						})
							.then((response) => {
								if (!response.ok) {
									throw new Error("Network response was not ok");
								}
								return response.blob();
							})
							.then((blob) => {
								const url = window.URL.createObjectURL(new Blob([blob]));
								const link: any = document.createElement("a");
								link.href = url;
								const formattedDate = moment().format("YYYY-MM-DD");
								link.setAttribute(
									"download",
									`invoice_${formData.clientName}_${formattedDate}.pdf`
								);
								document.body.appendChild(link);
								link.click();
								link.parentNode.removeChild(link);
								setProductError(false);
								setServiceError(false);
								setConfirmDownload(false);
							})
							.catch((error) => console.error("Error downloading PDF:", error));
					}
				);
			} else {
				if (amountInWords === "International Format") {
					const toWords = (num: any) => {
						const numberToWords = require("number-to-words");
						return numberToWords.toWords(num);
					};

					const convertAmount = (amount: any) => {
						const roundedAmount = Math.round(amount);
						const amountInWords = Number.isFinite(roundedAmount)
							? toWords(roundedAmount)
							: "Invalid amount";
						const internationalAmountInWords = `${amountInWords} Only. `;

						return {
							internationalAmountInWords,
						};
					};

					const totalAmount = formData.toatalAmount;
					const result = convertAmount(totalAmount);
					formData.totalInWords = result.internationalAmountInWords;
				} else {
					const totalAmount = formData.toatalAmount;
					const roundedtotalAmount = Math.round(totalAmount);
					const totalAmountInWords = Number.isFinite(roundedtotalAmount)
						? CalculateIndianFormattotal(roundedtotalAmount)
						: "Invalid amount";
					const totalAmountInWordsPrint =
						"Rupees " + totalAmountInWords + " Only";
					formData.totalInWords = totalAmountInWordsPrint;
				}
				dispatch(InternalInvoiceUpdate(invoiceId, formData) as any).then(
					(response: any) => {
						const queryParams = new URLSearchParams();
						queryParams.append("param1", Colorpicker[0]);
						queryParams.append("param2", Colorpicker[1]);
						queryParams.append("param3", Colorpicker[2]);
						queryParams.append("param4", Colorpicker[3]);
						if (formData.colorTemplateType) {
							queryParams.append("param5", formData.colorTemplateType);
						}
						queryParams.append("param6", selectedFont);
						queryParams.append("param7", currencySymbol);
						if (formData.invoiceTemplateType) {
							queryParams.append("param8", formData.invoiceTemplateType);
						}
						queryParams.append("param9", formData.totalInWords);

						// Construct the URL with the query parameters

						const url = `/api/invoice_pdf/${invoiceId}?${queryParams.toString()}`;
						fetch(url, {
							method: "GET",
						})
							.then((response) => {
								if (!response.ok) {
									throw new Error("Network response was not ok");
								}
								return response.blob();
							})
							.then((blob) => {
								const url = window.URL.createObjectURL(new Blob([blob]));
								const link: any = document.createElement("a");
								link.href = url;
								const formattedDate = moment().format("YYYY-MM-DD");
								link.setAttribute(
									"download",
									`invoice_${formData.clientName}_${formattedDate}.pdf`
								);
								document.body.appendChild(link);
								link.click();
								link.parentNode.removeChild(link);
								setProductError(false);
								setServiceError(false);
								setConfirmDownload(false);
							})
							.catch((error) => console.error("Error downloading PDF:", error));
					}
				);
			}
		}
		navigate("/app/sales");
	};

	const [downloadClicked, setDownloadClicked] = useState(false);
	const [downloadUpiClicked, setDownloadUpiClicked] = useState(false);
	const validateBankDetails = useCallback(() => {
		if (
			formData.bankNme &&
			formData.accounNumber &&
			formData.accounHolder &&
			formData.ifscCode
		)
			return true;
		else if (
			formData.bankNme ||
			formData.accounNumber ||
			formData.accounHolder ||
			formData.ifscCode
		)
			return false;
		else return true;
	}, [formData]);

	const validateUpiDetails = useCallback(() => {
		if (formData.upiNumber && formData.upiType) return true;
		else if (formData.upiNumber || formData.upiType) return false;
		else return true;
	}, [formData]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		setDownloadClicked(true);
		setDownloadUpiClicked(true);
		e.preventDefault();
		if (billModules == "SERVICE") {
			let finalErrors = [...serviceErrors];
			var Servicevalidation = serviceRows?.some(
				(item: any) =>
					!item.service_name || !item.rate || !item.qty || item.qty == 0
			);
			serviceRows.forEach((item: any, index: any) => {
				finalErrors[index].service_name = item.service_name ? true : false;
				finalErrors[index].rate = item.rate ? true : false;
				finalErrors[index].qty = item.qty ? true : false;
			});
			setServiceErrors(finalErrors);
		} else if (billModules == "PRODUCT") {
			let finalErrors = [...prodcutErrors];
			var ProductValidation = prodcutRows?.some(
				(item: any) => !item.prodcut || !item.rate || !item.qty || item.qty == 0
			);

			prodcutRows.forEach((item: any, index: any) => {
				finalErrors[index].prodcut = item.prodcut ? true : false;
				finalErrors[index].rate = item.rate ? true : false;
				finalErrors[index].qty = item.qty ? true : false;
			});
			setProdcutErrors(finalErrors);
		}
		const hasError = Object.values(conditionError).some((error) => error);
		if (
			!hasError &&
			!ProductValidation &&
			!Servicevalidation &&
			validateBankDetails() &&
			validateUpiDetails() &&
			formData.clientName &&
			formData.paymentType &&
			(selectedPayment != "CHEQUE" ||
				(selectedPayment === "CHEQUE" &&
					formData.chequeNum &&
					!clientErrors.chequeNum)) &&
			!fieldErrors.cashAmount &&
			!fieldErrors.chequeAmount &&
			!fieldErrors.creditAmount &&
			!formData.errors?.clientMobileErrorMessage &&
			!formData.errors?.clientPostalCodeErrorMessage &&
			!formData.errors?.clientEmailErrorMessage &&
			!formData.errors?.clientPanErrorMessage &&
			!formData.errors?.clientgstErrorMessage &&
			!formData.errors?.shipToPostalCodeErrorMessage &&
			!formData.errors?.shipFromPostalCodeErrorMessage &&
			!formData.errors?.cashAmountErrorMessage &&
			(id
				? parseFloat(formData.toatalAmount) >= formData.receivedAmount
				: formData.subtotal)
		) {
			setConfirmDownload(true);
		} else {
			setProductError(true);
			setServiceError(true);
			validateProdForm();
			setConfirmDownload(false);
		}
	};

	const [clientList, setClientList] = useState(
		userdata?.color ? userdata?.color : "#000000"
	);

	const [Colorpicker, setColorPicker] = useState([] as any);
	const handleColorChange = (e: any) => {
		setColorPicker(e.target.value);
	};

	const handleToggleChange = () => {
		setIsToggled(!isToggled);

		if (billModules == "PRODUCT") {
			setbillModule("SERVICE");
			setFormData({
				...formData,
				tax: [],
				product: [],
				toatalAmount: "",
				subtotal: "",
				invoiceNo: serviceBillNo,
				billModule: "SERVICE",
				clientName: "",
				clientAddress: "",
				clientCity: "",
				clientState: "",
				clientPostalCode: "",
				clientMobile: "",
				clientEmail: "",
				clientPan: "",
				clientgst: "",
			});

			let obj = {
				billModule: "SERVICE",
				tax: [],
				product: [],
				subtotal: "",
				toatalAmount: "",
				template: "",
				taxType: "Bill Wise",
				invoiceType: "invoice-type-service",
			};
			dispatch(updateUser(token?._id, obj) as any).then((res: any) => {});
			setSelectedPayment("");
			setCashAmount("");
			setbalanceAmount("");
			setProductError(false);
			setServiceError(false);
			setClientErrors({
				clientName: undefined,
				paymentType: undefined,
				chequeNum: undefined,
			});
			setProductRows([
				{ prodcut: "", hsn: "", rate: "", qty: "", amount: 0, productName: "" },
			]);
		} else {
			setbillModule("PRODUCT");
			setFormData({
				...formData,
				tax: [],
				product: [],
				toatalAmount: "",
				subtotal: "",
				invoiceNo: invoiceBillNo,
				billModule: "PRODUCT",
				clientName: "",
				clientAddress: "",
				clientCity: "",
				clientState: "",
				clientPostalCode: "",
				clientMobile: "",
				clientEmail: "",
				clientPan: "",
				clientgst: "",
			});
			let obj = {
				billModule: "PRODUCT",
				tax: [],
				product: [],
				toatalAmount: "",
				subtotal: "",
				invoiceType: "invoice-type-02",
				taxType: userdata.taxType,
				template: "",
			};
			dispatch(updateUser(token?._id, obj) as any).then((res: any) => {});
			setSelectedPayment("");
			setCashAmount("");
			setbalanceAmount("");
			setProductError(false);
			setServiceError(false);
			setClientErrors({
				clientName: undefined,
				paymentType: undefined,
				chequeNum: undefined,
			});
			setServiceRows([
				{
					service_name: "",
					rate: "",
					qty: "",
					amount: 0,
					serviceUnique_id: "",
				},
			]);
		}
	};

	// *********************************************EMail Sctions Start**************************************

	const [openmail, setOpenMail] = useState(false as any);
	const handleSectionOpen = (e: any) => {
		if (billModules == "SERVICE") {
			let finalErrors = [...serviceErrors];
			var Servicevalidation = serviceRows?.some(
				(item: any) =>
					!item.service_name || !item.rate || !item.qty || item.qty == 0
			);
			serviceRows.forEach((item: any, index: any) => {
				finalErrors[index].service_name = item.service_name ? true : false;
				finalErrors[index].rate = item.rate ? true : false;
				finalErrors[index].qty = item.qty ? true : false;
			});
			setServiceErrors(finalErrors);
		} else {
			let finalErrors = [...prodcutErrors];
			var ProductValidation = prodcutRows?.some(
				(item: any) => !item.prodcut || !item.rate || !item.qty || item.qty == 0
			);

			prodcutRows.forEach((item: any, index: any) => {
				finalErrors[index].prodcut = item.prodcut ? true : false;
				finalErrors[index].rate = item.rate ? true : false;
				finalErrors[index].qty = item.qty ? true : false;
			});
			setProdcutErrors(finalErrors);
		}
		const hasError = Object.values(conditionError).some((error) => error);
		if (
			!hasError &&
			!ProductValidation &&
			!Servicevalidation &&
			validateBankDetails() &&
			formData.clientName &&
			formData.paymentType &&
			(selectedPayment != "CHEQUE" ||
				(selectedPayment === "CHEQUE" &&
					formData.chequeNum &&
					!clientErrors.chequeNum)) &&
			!formData.errors?.clientMobileErrorMessage &&
			!fieldErrors.cashAmount &&
			!fieldErrors.chequeAmount &&
			!fieldErrors.creditAmount &&
			!formData.errors?.clientPostalCodeErrorMessage &&
			!formData.errors?.clientEmailErrorMessage &&
			!formData.errors?.clientPanErrorMessage &&
			!formData.errors?.clientgstErrorMessage &&
			(id
				? parseFloat(formData.toatalAmount) >= formData.receivedAmount
				: formData.subtotal)
		) {
			setOpenMail(true);
		} else {
			setProductError(true);
			setServiceError(true);
			validateProdForm();
		}
	};

	const [errorEmail, setErrorEmail] = useState(null as any);
	const handleSendEmail = () => {
		if (formData.To?.trim() !== "" && emailRegex.test(formData.To)) {
			formData.invoicedate = formatDate;
			const emailViewPdf = {
				fromEmail: formData.From,
				toEmail: formData.To,
				subject: formData.Subject,
				message: formData.Message,
				clientName: formData.clientName,
			};

			let service = {
				invoicehead: formData.invoicehead,
				invoiceNo: formData.invoiceNo,
				invoicedate: formData.invoicedate,
				poNumber: formData.PoNumber ? formData.PoNumber : "",
				poDate: formData.poDate ? formData.poDate : "",
				duedate: formData.duedate,
				imageData: formData.imageData,
				companyName: formData.companyName,
				companyAddress: formData.companyAddress,
				companyCity: formData.companyCity,
				companyState: formData.companyState,
				companyPostalCode: formData.companyPostalCode,
				companyMobile: formData.companyMobile,
				companyEmail: formData.companyEmail,
				companyPan: formData.companyPan,
				companygst: formData.companygst,
				clientName: formData.clientName,
				clientAddress: formData.clientAddress,
				clientCity: formData.clientCity,
				clientState: formData.clientState,
				clientPostalCode: formData.clientPostalCode,
				clientMobile: formData.clientMobile,
				clientEmail: formData.clientEmail,
				clientPan: formData.clientPan,
				clientgst: formData.clientgst,
				product: formData.product,
				tax: formData.tax,
				subtotal: formData.subtotal,
				paymentType: selectedPayment,
				cashAmount: cashAmount,
				creditAmount: creditAmount,
				chequeAmount: chequeAmount,
				balanceAmount: balanceAmount,
				chequeNum: chequeNum,
				receivedAmount: receivedAmount,
				paymentStatus: "",
				toatalAmount: formData.toatalAmount,
				termsanddcon: formData.termsanddcon,
				bankNme: formData.bankNme,
				accounHolder: formData.accounHolder,
				accounNumber: formData.accounNumber,
				ifscCode: formData.ifscCode,
				upiType: formData.upiType,
				upiNumber: formData.upiNumber,
				signature: formData.signature,
				seal: formData.seal,
				otherValue: formData.otherValue,
				dropDown: formData.dropDown,
				notes: formData.notes,
				user_id: formData.user_id,
				sealPostion: formData.sealPostion,
				signPostion: formData.signPostion,
				totalInWords: formData.totalInWords,
				footerNotes: formData.footerNotes,
				billTaxType: (formData.billTaxType = userdata?.taxType),
				gstServiceType: formData.gstServiceType,
				roundOff: formData.roundOff,
				RoundOffType:formData.RoundOffType,
			    RoundOffEnabled:formData.RoundOffEnabled,
				AmountFormat:formData.AmountFormat,
				gstType: userdata?.gstValue,
				invoiceTemplateType: formData.invoiceTemplateType
					? formData.invoiceTemplateType
					: userdata?.invoiceType,
				colorTemplateType: (formData.colorTemplateType = userdata?.template),
				billModule: (formData.billModule = billModules),
				nontaxableTotal: "",
			};
			if (!id) {
				if (billModules == "SERVICE") {
					if (amountInWords === "International Format") {
						const toWords = (num: any) => {
							const numberToWords = require("number-to-words");
							return numberToWords.toWords(num);
						};

						const convertAmount = (amount: any) => {
							const roundedAmount = Math.round(amount);
							const amountInWords = Number.isFinite(roundedAmount)
								? toWords(roundedAmount)
								: "Invalid amount";
							const internationalAmountInWords = `${amountInWords} Only. `;

							return {
								internationalAmountInWords,
							};
						};

						const totalAmount = formData.toatalAmount;
						const result = convertAmount(totalAmount);
						formData.totalInWords = result.internationalAmountInWords;
					} else {
						const totalAmount = formData.toatalAmount;
						const roundedtotalAmount = Math.round(totalAmount);
						const totalAmountInWords = Number.isFinite(roundedtotalAmount)
							? CalculateIndianFormattotal(roundedtotalAmount)
							: "Invalid amount";
						const totalAmountInWordsPrint =
							"Rupees " + totalAmountInWords + " Only";
						formData.totalInWords = totalAmountInWordsPrint;
					}
					dispatch(ServicePost(service) as any).then((response: any) => {
						formData?.product.forEach((item: any) => {
							item.amount = Number(item.amount).toFixed(2);
						});
						const queryParams = new URLSearchParams();
						queryParams.append("param1", Colorpicker[0]);
						queryParams.append("param2", Colorpicker[1]);
						queryParams.append("param3", Colorpicker[2]);
						queryParams.append("param4", Colorpicker[3]);
						if (userdata?.template) {
							queryParams.append("param5", userdata?.template);
						}
						queryParams.append("param6", selectedFont);
						queryParams.append("param7", currencySymbol);
						if (userdata?.invoiceType) {
							queryParams.append("param8", userdata?.invoiceType);
						}
						queryParams.append("param9", formData.totalInWords);
						if (response?.payload?.invoice_id) {
							fetch(
								`/api/service/send_email_pdf/${
									response?.payload?.invoice_id
								}?${queryParams.toString()}`
							)
								.then((response) => {
									if (!response.ok) {
										throw new Error("Network response was not ok");
									}
									return response.blob();
								})
								.then((blob) => {
									api
										.post(`/api/service/upload`, emailViewPdf)
										.then((pdfResp: any) => {
											console.log("Blob uploaded successfully:", pdfResp);
											if (pdfResp.data.message) {
												toast.success("Email Send Successfully", {
													position: toast.POSITION.BOTTOM_CENTER,
													autoClose: 800,
												});
											}
										})

										.catch((error: any) => {
											console.error("Error uploading blob:", error);
										});
								})
								.catch((error) =>
									console.error("Error downloading PDF:", error)
								);
						}
					});
				} else {
					if (invoiceUpdateNewId) {
						if (amountInWords === "International Format") {
							const toWords = (num: any) => {
								const numberToWords = require("number-to-words");
								return numberToWords.toWords(num);
							};

							const convertAmount = (amount: any) => {
								const roundedAmount = Math.round(amount);
								const amountInWords = Number.isFinite(roundedAmount)
									? toWords(roundedAmount)
									: "Invalid amount";
								const internationalAmountInWords = `${amountInWords} Only. `;

								return {
									internationalAmountInWords,
								};
							};

							const totalAmount = formData.toatalAmount;
							const result = convertAmount(totalAmount);
							formData.totalInWords = result.internationalAmountInWords;
						} else {
							const totalAmount = formData.toatalAmount;
							const roundedtotalAmount = Math.round(totalAmount);
							const totalAmountInWords = Number.isFinite(roundedtotalAmount)
								? CalculateIndianFormattotal(roundedtotalAmount)
								: "Invalid amount";
							const totalAmountInWordsPrint =
								"Rupees " + totalAmountInWords + " Only";
							formData.totalInWords = totalAmountInWordsPrint;
						}
						dispatch(
							InternalInvoiceUpdate(invoiceUpdateNewId, formData) as any
						).then((response: any) => {
							const queryParams = new URLSearchParams();
							queryParams.append("param1", Colorpicker[0]);
							queryParams.append("param2", Colorpicker[1]);
							queryParams.append("param3", Colorpicker[2]);
							queryParams.append("param4", Colorpicker[3]);
							if (formData.colorTemplateType) {
								queryParams.append("param5", formData.colorTemplateType);
							}
							queryParams.append("param6", currencySymbol);
							queryParams.append("param7", selectedFont);
							if (formData.invoiceTemplateType) {
								queryParams.append("param8", formData?.invoiceTemplateType);
							}
							queryParams.append("param9", formData.totalInWords);
							fetch(
								`/api/send_email_pdf/${invoiceUpdateNewId}?${queryParams.toString()}`
							)
								.then((response) => {
									if (!response.ok) {
										throw new Error("Network response was not ok");
									}
									return response.blob();
								})
								.then((blob) => {
									api
										.post(`/api/upload`, emailViewPdf)
										.then((pdfResp: any) => {
											console.log("Blob uploaded successfully:", pdfResp);
											if (pdfResp.data.message) {
												toast.success("Email Send Successfully", {
													position: toast.POSITION.BOTTOM_CENTER,
													autoClose: 800,
												});
											}
										})
										.catch((error: any) => {
											console.error("Error uploading blob:", error);
										});
								})
								.catch((error) =>
									console.error("Error downloading PDF:", error)
								);
						});
					} else {
						if (amountInWords === "International Format") {
							const toWords = (num: any) => {
								const numberToWords = require("number-to-words");
								return numberToWords.toWords(num);
							};

							const convertAmount = (amount: any) => {
								const roundedAmount = Math.round(amount);
								const amountInWords = Number.isFinite(roundedAmount)
									? toWords(roundedAmount)
									: "Invalid amount";
								const internationalAmountInWords = `${amountInWords} Only. `;

								return {
									internationalAmountInWords,
								};
							};

							const totalAmount = formData.toatalAmount;
							const result = convertAmount(totalAmount);
							formData.totalInWords = result.internationalAmountInWords;
						} else {
							const totalAmount = formData.toatalAmount;
							const roundedtotalAmount = Math.round(totalAmount);
							const totalAmountInWords = Number.isFinite(roundedtotalAmount)
								? CalculateIndianFormattotal(roundedtotalAmount)
								: "Invalid amount";
							const totalAmountInWordsPrint =
								"Rupees " + totalAmountInWords + " Only";
							formData.totalInWords = totalAmountInWordsPrint;
						}
						dispatch(InternalPost(formData) as any).then((response: any) => {
							setInvoiceUpdateNewId(response?.payload?.invoice_id);
							formData?.product.forEach((item: any) => {
								item.amount = Number(item.amount).toFixed(2);
							});
							const queryParams = new URLSearchParams();
							queryParams.append("param1", Colorpicker[0]);
							queryParams.append("param2", Colorpicker[1]);
							queryParams.append("param3", Colorpicker[2]);
							queryParams.append("param4", Colorpicker[3]);
							if (userdata?.template) {
								queryParams.append("param5", userdata?.template);
							}
							queryParams.append("param6", currencySymbol);
							queryParams.append("param7", selectedFont);
							if (userdata?.invoiceType) {
								queryParams.append("param8", userdata?.invoiceType);
							}
							queryParams.append("param9", formData.totalInWords);
							if (response?.payload?.invoice_id) {
								fetch(
									`/api/send_email_pdf/${
										response?.payload?.invoice_id
									}?${queryParams.toString()}`
								)
									.then((response) => {
										if (!response.ok) {
											throw new Error("Network response was not ok");
										}
										return response.blob();
									})
									.then((blob) => {
										api
											.post(`/api/upload`, emailViewPdf)
											.then((pdfResp: any) => {
												console.log("Blob uploaded successfully:", pdfResp);
												if (pdfResp.data.message) {
													toast.success("Email Send Successfully", {
														position: toast.POSITION.BOTTOM_CENTER,
														autoClose: 800,
													});
												}
											})
											.catch((error: any) => {
												console.error("Error uploading blob:", error);
											});
									})
									.catch((error) =>
										console.error("Error downloading PDF:", error)
									);
							}
						});
					}
				}
			} else if (id || invoiceUpdateId) {
				let invoiceId = id ? id : invoiceUpdateId;
				formData.invoicedate = formatDate;
				formData.product = getProductCal(formData);
				formData?.product.forEach((item: any) => {
					item.amount = Number(item.amount).toFixed(2);
				});
				if (billModules == "SERVICE") {
					if (amountInWords === "International Format") {
						const toWords = (num: any) => {
							const numberToWords = require("number-to-words");
							return numberToWords.toWords(num);
						};

						const convertAmount = (amount: any) => {
							const roundedAmount = Math.round(amount);
							const amountInWords = Number.isFinite(roundedAmount)
								? toWords(roundedAmount)
								: "Invalid amount";
							const internationalAmountInWords = `${amountInWords} Only. `;

							return {
								internationalAmountInWords,
							};
						};

						const totalAmount = formData.toatalAmount;
						const result = convertAmount(totalAmount);
						formData.totalInWords = result.internationalAmountInWords;
					} else {
						const totalAmount = formData.toatalAmount;
						const roundedtotalAmount = Math.round(totalAmount);
						const totalAmountInWords = Number.isFinite(roundedtotalAmount)
							? CalculateIndianFormattotal(roundedtotalAmount)
							: "Invalid amount";
						const totalAmountInWordsPrint =
							"Rupees " + totalAmountInWords + " Only";
						formData.totalInWords = totalAmountInWordsPrint;
					}
					dispatch(serviceInvoiceUpdate(invoiceId, service) as any).then(
						(response: any) => {
							const queryParams = new URLSearchParams();
							queryParams.append("param1", Colorpicker[0]);
							queryParams.append("param2", Colorpicker[1]);
							queryParams.append("param3", Colorpicker[2]);
							queryParams.append("param4", Colorpicker[3]);
							if (formData.colorTemplateType) {
								queryParams.append("param5", formData.colorTemplateType);
							}
							queryParams.append("param6", selectedFont);
							queryParams.append("param7", currencySymbol);
							if (formData.invoiceTemplateType) {
								queryParams.append("param8", formData.invoiceTemplateType);
							}
							queryParams.append("param9", formData.totalInWords);
							fetch(
								`/api/service/send_email_pdf/${invoiceId}?${queryParams.toString()}`
							)
								.then((response) => {
									if (!response.ok) {
										throw new Error("Network response was not ok");
									}
									return response.blob();
								})
								.then((blob) => {
									api
										.post(`/api/service/upload`, emailViewPdf)
										.then((pdfResp: any) => {
											console.log("Blob uploaded successfully:", pdfResp);
											if (pdfResp.data.message) {
												toast.success("Email Send Successfully", {
													position: toast.POSITION.BOTTOM_CENTER,
													autoClose: 800,
												});
											}
										})

										.catch((error: any) => {
											console.error("Error uploading blob:", error);
										});
								})
								.catch((error) =>
									console.error("Error downloading PDF:", error)
								);
						}
					);
				} else {
					if (amountInWords === "International Format") {
						const toWords = (num: any) => {
							const numberToWords = require("number-to-words");
							return numberToWords.toWords(num);
						};

						const convertAmount = (amount: any) => {
							const roundedAmount = Math.round(amount);
							const amountInWords = Number.isFinite(roundedAmount)
								? toWords(roundedAmount)
								: "Invalid amount";
							const internationalAmountInWords = `${amountInWords} Only. `;

							return {
								internationalAmountInWords,
							};
						};

						const totalAmount = formData.toatalAmount;
						const result = convertAmount(totalAmount);
						formData.totalInWords = result.internationalAmountInWords;
					} else {
						const totalAmount = formData.toatalAmount;
						const roundedtotalAmount = Math.round(totalAmount);
						const totalAmountInWords = Number.isFinite(roundedtotalAmount)
							? CalculateIndianFormattotal(roundedtotalAmount)
							: "Invalid amount";
						const totalAmountInWordsPrint =
							"Rupees " + totalAmountInWords + " Only";
						formData.totalInWords = totalAmountInWordsPrint;
					}
					dispatch(InternalInvoiceUpdate(invoiceId, formData) as any).then(
						(response: any) => {
							const queryParams = new URLSearchParams();
							queryParams.append("param1", Colorpicker[0]);
							queryParams.append("param2", Colorpicker[1]);
							queryParams.append("param3", Colorpicker[2]);
							queryParams.append("param4", Colorpicker[3]);
							if (formData.colorTemplateType) {
								queryParams.append("param5", formData.colorTemplateType);
							}
							queryParams.append("param6", currencySymbol);
							queryParams.append("param7", selectedFont);
							if (formData.invoiceTemplateType) {
								queryParams.append("param8", formData.invoiceTemplateType);
							}
							queryParams.append("param9", formData.totalInWords);

							fetch(
								`/api/send_email_pdf/${invoiceId}?${queryParams.toString()}`
							)
								.then((response) => {
									if (!response.ok) {
										throw new Error("Network response was not ok");
									}
									return response.blob();
								})
								.then((blob) => {
									api
										.post(`/api/upload`, emailViewPdf)
										.then((pdfResp: any) => {
											console.log("Blob uploaded successfully:", pdfResp);
											if (pdfResp.data.message) {
												toast.success("Email Send Successfully", {
													position: toast.POSITION.BOTTOM_CENTER,
													autoClose: 800,
												});
											}
										})
										.catch((error: any) => {
											console.error("Error uploading blob:", error);
										});
								})
								.catch((error) =>
									console.error("Error downloading PDF:", error)
								);
						}
					);
				}
			}

			setOpenMail(false);
			setErrorEmail(null);
			setOpenView(false);
			setFormData({
				...formData,
				To: "",
				Subject: "",
				Message: "",
				errors: { ...formData.errors, ToErrorMessage: "" },
			});
		} else {
			if (formData.To?.trim() === "") {
				sendEmailErrors["To"] = "Email is requried  ";
				setErrorEmail(sendEmailErrors);
			}
		}
	};

	// *********************************************EMail Sctions End**************************************
	const [editValue, setEditValue] = useState(false as any);

	useEffect(() => {
		if (!id) {
			fetchData();
			formData.billTaxType = userdata?.taxType;
			formData.gstType = userdata?.gstValue;
			formData.invoiceTemplateType = userdata?.invoiceType;
			formData.colorTemplateType = userdata?.template;
		} else {
			if (invoiceModule == "PRODUCT") {
				dispatch(InternalFindOne(id) as any).then((response: any) => {
					let invoiceDetails = JSON.parse(response?.payload.products);

					const combinedTaxRow = invoiceDetails.tax.reduce(
						(acc: any, taxRow: any) => {
							return {
								cgstStatus: taxRow.cgstStatus ?? acc.cgstStatus,
								cgstPercentage:
									taxRow.cgstStatus === true
										? Number(taxRow?.taxPersent)
										: acc.cgstPercentage || 0,

								sgstStatus: taxRow.sgstStatus ?? acc.sgstStatus,
								sgstPercentage:
									taxRow.sgstStatus === true
										? Number(taxRow?.taxPersent)
										: acc.sgstPercentage || 0,

								igstStatus: taxRow.igstStatus ?? acc.igstStatus,
								igstPercentage:
									taxRow.igstStatus === true
										? Number(taxRow?.taxPersent)
										: acc.igstPercentage || 0,

								othersStatus: taxRow.othersStatus ?? acc.othersStatus,
								othersPercentage:
									taxRow.othersStatus === true
										? Number(taxRow?.taxPersent)
										: acc.othersPercentage || 0,

								discountStatus: taxRow.discountStatus ?? acc.discountStatus,
								discountPercentage:
									taxRow.discountStatus === true
										? Number(taxRow?.taxPersent)
										: acc.discountPercentage || 0,

								extraFeesStatus: taxRow.extraFeesStatus ?? acc.extraFeesStatus,
								extraFees:
									taxRow.extraFeesStatus === true
										? Number(taxRow?.taxPersent)
										: acc.extraFees || 0,
							};
						},
						{}
					);

					let accountSettings = JSON.parse(response?.payload.app_settings);
					setTaxAllList([combinedTaxRow]);
					let products = invoiceDetails.product;
					if (id) {
						setEditValue(true);
						let newTaxStatus: any = [];
						let newTaxArr: any = [];
						for (let o in accountSettings[0]) {
							if (accountSettings[0][o] === true) {
								newTaxStatus.push(o);
							}
						}

						let finalTotal = invoiceDetails.subtotal;
						newTaxStatus.forEach((element: string) => {
							if (element === "cgstStatus") {
								let taxAmt =
									(invoiceDetails.subtotal -
										(Number(accountSettings[0].discountPercentage) / 100) *
											invoiceDetails.subtotal) *
									(Number(accountSettings[0].cgstPercentage) / 100);

								newTaxArr.push({
									taxName: "CGST",
									cgstStatus: accountSettings[0].cgstStatus,
									taxAmount: taxAmt.toFixed(2).toString(),
									taxPersent: accountSettings[0].cgstPercentage,
								});
								finalTotal = Number(finalTotal) + Number(taxAmt);
							} else if (element === "sgstStatus") {
								let taxAmt =
									(invoiceDetails.subtotal -
										(Number(accountSettings[0].discountPercentage) / 100) *
											invoiceDetails.subtotal) *
									(Number(accountSettings[0].sgstPercentage) / 100);
								newTaxArr.push({
									taxName: "SGST",
									cgstStatus: accountSettings[0].sgstStatus,
									taxAmount: taxAmt.toFixed(2).toString(),
									taxPersent: accountSettings[0].sgstPercentage,
								});
								finalTotal = Number(finalTotal) + Number(taxAmt);
							} else if (element === "extraFeesStatus") {
								newTaxArr.push({
									taxName: "Extra Fees",
									cgstStatus: accountSettings[0].extraFeesStatus,
									taxAmount: accountSettings[0].extraFees,
									taxPersent: accountSettings[0].extraFees,
								});
								finalTotal =
									Number(finalTotal) + Number(accountSettings[0].extraFees);
							} else if (element === "igstStatus") {
								let taxAmt =
									(invoiceDetails.subtotal -
										(Number(accountSettings[0].discountPercentage) / 100) *
											invoiceDetails.subtotal) *
									(Number(accountSettings[0].igstPercentage) / 100);
								newTaxArr.push({
									taxName: "IGST",
									cgstStatus: accountSettings[0].igstStatus,
									taxAmount: taxAmt.toFixed(2).toString(),
									taxPersent: accountSettings[0].igstPercentage,
								});
								finalTotal = Number(finalTotal) + Number(taxAmt);
							} else if (element === "vatStatus") {
								let taxAmt =
									(invoiceDetails.subtotal *
										Number(accountSettings[0].vatPercentage)) /
									100;
								newTaxArr.push({
									taxName: "VAT",
									cgstStatus: accountSettings[0].vatStatus,
									taxAmount: taxAmt.toFixed(2).toString(),
									taxPersent: accountSettings[0].vatPercentage,
								});
								finalTotal = Number(finalTotal) + Number(taxAmt);
							} else if (element === "othersStatus") {
								let taxAmt =
									(invoiceDetails.subtotal *
										Number(accountSettings[0].othersPercentage)) /
									100;
								newTaxArr.push({
									taxName: "OTHERS",
									cgstStatus: accountSettings[0].othersStatus,
									taxAmount: taxAmt.toFixed(2).toString(),
									taxPersent: accountSettings[0].othersPercentage,
								});
								finalTotal = Number(finalTotal) + Number(taxAmt);
							}
						});
						if (newTaxStatus.includes("discountStatus")) {
							let taxAmt =
								(Number(accountSettings[0].discountPercentage) / 100) *
								invoiceDetails.subtotal;
							newTaxArr.push({
								taxName: "Discount",
								cgstStatus: accountSettings[0].discountStatus,
								taxAmount: taxAmt.toFixed(2).toString(),
								taxPersent: accountSettings[0].discountPercentage,
							});
							finalTotal -= taxAmt;
						}
						let isSendEmail = invoiceDetails.isSendEmail ? true : true;
						let clientgst = invoiceDetails.clientgst ? true : true;
						let clientEmail = invoiceDetails.companyEmail ? true : true;
						let clientpostal = invoiceDetails.companyEmail ? true : true;
						let clientpan = invoiceDetails.clientPan ? true : true;
						let clientmobile = invoiceDetails.clientMobile ? true : true;
						//let cashAmountt = invoiceDetails.cashAmount ? true : false;
						let shipmentToPostalCode = invoiceDetails.shipToPostalCode
							? true
							: true;
						let shipmentFromPostalCode = invoiceDetails.shipFromPostalCode
							? true
							: true;
						let billTaxType = invoiceDetails.billTaxType;
						let gstType = invoiceDetails.gstType;
						let invoiceTemplateType = invoiceDetails.invoiceTemplateType;
						let colorTemplateType = invoiceDetails.colorTemplateType;
						setTermsOpen(invoiceDetails.termsanddcon ? true : false);
						titls[0].status = invoiceDetails.termsanddcon ? true : false;
						setNotesOpen(invoiceDetails.notes ? true : false);
						titls[1].status = invoiceDetails.notes ? true : false;
						setBanksOpen(invoiceDetails.bankNme ? true : false);
						titls[2].status = invoiceDetails.bankNme ? true : false;
						setUpiOpen(invoiceDetails.upiType ? true : false);
						titls[3].status = invoiceDetails.upiType ? true : false;
						setSignatureOpen(
							invoiceDetails.signature || invoiceDetails.seal ? true : false
						);
						titls[4].status =
							invoiceDetails.signature || invoiceDetails.seal ? true : false;
						// let termsanddconvalue = invoiceDetails.termsanddcon
						setFormData({
							...invoiceDetails,
							tax:
								invoiceDetails.billTaxType == "Bill Wise" ? [...newTaxArr] : [],
							toatalAmount:
								invoiceDetails.billTaxType == "Bill Wise"
									? roundOffEnable == true
										? CalculateRoundTotalAmount(roundOffValue, finalTotal)
										: finalTotal
									: roundOffEnable == true
									? CalculateRoundTotalAmount(
											roundOffValue,
											invoiceDetails.subtotal
									  )
									: invoiceDetails.subtotal,
							paymentType: invoiceDetails.paymentType,
							receivedAmount: invoiceDetails.receivedAmount,
							isClientGst: clientgst,
							isclientPostalCode: clientpostal,
							isClientEmail: clientEmail,
							isSendEmail: isSendEmail,
							isClientPan: clientpan,
							isclientMobile: clientmobile,
							isshippingValidPostalCode: shipmentToPostalCode,
							isshippingclientPostalCode: shipmentFromPostalCode,
							billTaxType: billTaxType,
							gstType: gstType,
							invoiceTemplateType: invoiceTemplateType,
							colorTemplateType: colorTemplateType,
							// termsanddcon: termsanddconvalue
						});
						setchequeNum(invoiceDetails.chequeNum);
						setRoundoffValue(invoiceDetails.RoundOffType)
						setRoundoffEnable(invoiceDetails.RoundOffEnabled)
						setAmountInWord(invoiceDetails.AmountFormat)
						setreceivedAmount(invoiceDetails.receivedAmount);
						let balance = invoiceDetails.toatalAmount
							? invoiceDetails.toatalAmount - invoiceDetails.receivedAmount
							: 0;
						setbalanceAmount(balance);
						setSelectedPayment(invoiceDetails.paymentType);
						let finalErrors: any = [];

						let value = products?.map((item: any) => {
							let object: any = {};
							object.prodcut = item.prodcut ? true : false;
							object.rate = item.rate ? true : false;
							object.qty = item.qty ? true : false;

							finalErrors.push(object);

							return object; // Return the constructed object
						});

						setProdcutErrors(value);
						setIsToggled(!isToggled);
						setbillModule("PRODUCT");
					}
				});
			} else {
				dispatch(ServiceFindOne(id) as any).then((response: any) => {
					let tax = JSON.parse(response?.payload.app_settings);
					setServiceTaxAllList(tax);
					let serviceDetails = JSON.parse(response?.payload.products);
					let services = serviceDetails.product;
					if (id) {
						setEditValue(true);
						let newTaxStatus: any = [];
						let newTaxArr: any = [];
						for (let o in tax[0]) {
							if (tax[0][o] === true) {
								newTaxStatus.push(o);
							}
						}
						let finalTotal = serviceDetails.subtotal;
						newTaxStatus.forEach((element: string) => {
							if (element === "servicetaxStatus") {
								let taxAmt =
									(serviceDetails.subtotal *
										Number(tax[0].servicetaxPercentage)) /
									100;
								newTaxArr.push({
									taxName: "Service Tax",
									servicetaxStatus: tax[0].servicetaxStatus,
									taxAmount: taxAmt.toFixed(2).toString(),
									taxPersent: tax[0].servicetaxPercentage,
								});
								finalTotal = Number(finalTotal) + Number(taxAmt);
							}
						});
						if (newTaxStatus.includes("servicediscountStatus")) {
							let taxAmt =
								(finalTotal * Number(tax[0].servicediscountPercentage)) / 100;
							newTaxArr.push({
								taxName: "Discount",
								cgstStatus: tax[0].servicediscountStatus,
								taxAmount: taxAmt.toFixed(2).toString(),
								taxPersent: tax[0].servicediscountPercentage,
							});
							finalTotal -= taxAmt;
						}

						let isSendEmail = serviceDetails.isSendEmail ? true : true;
						let clientgst = serviceDetails.clientgst ? true : true;
						let clientEmail = serviceDetails.companyEmail ? true : true;
						let clientpostal = serviceDetails.companyEmail ? true : true;
						let clientpan = serviceDetails.clientPan ? true : true;
						let clientmobile = serviceDetails.clientMobile ? true : true;
						let shipmentToPostalCode = serviceDetails.shipToPostalCode
							? true
							: true;
						let billTaxType = serviceDetails.billTaxType;
						let invoiceTemplateType = serviceDetails.invoiceTemplateType;
						let colorTemplateType = serviceDetails.colorTemplateType;
						setTermsOpen(serviceDetails.termsanddcon ? true : false);
						titls[0].status = serviceDetails.termsanddcon ? true : false;
						setNotesOpen(serviceDetails.notes ? true : false);
						titls[1].status = serviceDetails.notes ? true : false;
						setBanksOpen(serviceDetails.bankNme ? true : false);
						titls[2].status = serviceDetails.bankNme ? true : false;
						setUpiOpen(serviceDetails.upiType ? true : false);
						titls[3].status = serviceDetails.upiType ? true : false;
						setSignatureOpen(
							serviceDetails.signature || serviceDetails.seal ? true : false
						);
						titls[4].status =
							serviceDetails.signature || serviceDetails.seal ? true : false;
						// let termsanddconvalue = invoiceDetails.termsanddcon
						setFormData({
							...serviceDetails,
							tax: [...serviceDetails.tax],
							serviceDiscount:
								newTaxArr[0]?.servicetaxStatus === true
									? newTaxArr[0].taxPersent
									: "",
							serviceTax:
								newTaxArr[0]?.servicetaxStatus === true
									? newTaxArr[0].taxPersent
									: "",
							toatalAmount: serviceDetails.toatalAmount,
							isClientGst: clientgst,
							isclientPostalCode: clientpostal,
							isClientEmail: clientEmail,
							isSendEmail: isSendEmail,
							isClientPan: clientpan,
							isclientMobile: clientmobile,
							isshippingValidPostalCode: shipmentToPostalCode,
							billTaxType: billTaxType,
							paymentType: serviceDetails.paymentType,
							invoiceTemplateType: invoiceTemplateType,
							colorTemplateType: colorTemplateType,
							// termsanddcon: termsanddconvalue
						});
						setRoundoffValue(serviceDetails.RoundOffType)
						setRoundoffEnable(serviceDetails.RoundOffEnabled)
						setAmountInWord(serviceDetails.AmountFormat)
						setreceivedAmount(serviceDetails.receivedAmount);
						let balance = serviceDetails.toatalAmount
							? serviceDetails.toatalAmount - serviceDetails.receivedAmount
							: 0;
						setbalanceAmount(balance);
						setSelectedPayment(serviceDetails.paymentType);
						let finalErrors: any = [];

						let value = services?.map((item: any, index: any) => {
							let object: any = {};
							object.service_name = item.service_name ? true : false;
							object.rate = item.rate ? true : false;
							object.qty = item.qty ? true : false;
							finalErrors.push(object);
							return object;
						});
						setServiceErrors(value);
					}
				});
				setIsToggled(!isToggled);
				setbillModule("SERVICE");
			}
		}
	}, [id, billModules]);

	const closeModal = () => {
		setErrorEmail(null);
		setOpenView(false);
		setOpenMail(false);
		setFormData({
			...formData,
			To: "",
			Subject: "",
			Message: "",
			errors: { ...formData.errors, ToErrorMessage: "" },
		});
	};

	const [viewOpen, setOpenView] = useState(false);
	const cancelButtonRef = useRef(null);
	const componentRef = useRef(null);

	// const [preViewdDis, setPreViewDis] = useState(true)

	const openChoosePreview = () => {
		if (billModules == "SERVICE") {
			let finalErrors = [...serviceErrors];
			var Servicevalidation = serviceRows?.some(
				(item: any) =>
					!item.service_name || !item.rate || !item.qty || item.qty == 0
			);
			serviceRows.forEach((item: any, index: any) => {
				finalErrors[index].service_name = item.service_name ? true : false;
				finalErrors[index].rate = item.rate ? true : false;
				finalErrors[index].qty = item.qty ? true : false;
			});
			setServiceErrors(finalErrors);
		} else {
			let finalErrors = [...prodcutErrors];
			var ProductValidation = prodcutRows?.some(
				(item: any) => !item.prodcut || !item.rate || !item.qty || item.qty == 0
			);

			prodcutRows.forEach((item: any, index: any) => {
				finalErrors[index].prodcut = item.prodcut ? true : false;
				finalErrors[index].rate = item.rate ? true : false;
				finalErrors[index].qty = item.qty ? true : false;
			});
			setProdcutErrors(finalErrors);
		}
		const hasError = Object.values(conditionError).some((error) => error);
		if (
			!hasError &&
			!ProductValidation &&
			!Servicevalidation &&
			formData.clientName &&
			formData.paymentType &&
			(selectedPayment != "CHEQUE" ||
				(selectedPayment === "CHEQUE" &&
					formData.chequeNum &&
					!clientErrors.chequeNum)) &&
			!formData.errors?.clientMobileErrorMessage &&
			!fieldErrors.cashAmount &&
			!fieldErrors.chequeAmount &&
			!fieldErrors.creditAmount &&
			!formData.errors?.clientPostalCodeErrorMessage &&
			!formData.errors?.clientEmailErrorMessage &&
			!formData.errors?.clientPanErrorMessage &&
			!formData.errors?.clientgstErrorMessage &&
			(id
				? parseFloat(formData.toatalAmount) >= formData.receivedAmount
				: formData.subtotal)
		) {
			setOpenView(true);
		} else {
			setProductError(true);
			setServiceError(true);
			validateProdForm();
		}
	};

	const handlePrint = useReactToPrint({
		content: () => getPrintContent(),
		documentTitle: "Invoice Letter",
		onAfterPrint: () => {
			console.log("Print completed!");
		},
	});

	const getPrintContent = (): any => {
		if (billModules == "SERVICE") {
			let finalErrors = [...serviceErrors];
			var Servicevalidation = serviceRows?.some(
				(item: any) =>
					!item.service_name || !item.rate || !item.qty || item.qty == 0
			);
			serviceRows.forEach((item: any, index: any) => {
				finalErrors[index].service_name = item.service_name ? true : false;
				finalErrors[index].rate = item.rate ? true : false;
				finalErrors[index].qty = item.qty ? true : false;
			});
			setServiceErrors(finalErrors);
		} else {
			let finalErrors = [...prodcutErrors];
			var ProductValidation = prodcutRows?.some(
				(item: any) => !item.prodcut || !item.rate || !item.qty || item.qty == 0
			);

			prodcutRows.forEach((item: any, index: any) => {
				finalErrors[index].prodcut = item.prodcut ? true : false;
				finalErrors[index].rate = item.rate ? true : false;
				finalErrors[index].qty = item.qty ? true : false;
			});
			setProdcutErrors(finalErrors);
		}
		const hasError = Object.values(conditionError).some((error) => error);
		if (
			!hasError &&
			!ProductValidation &&
			!Servicevalidation &&
			formData.clientName &&
			formData.paymentType &&
			(selectedPayment != "CHEQUE" ||
				(selectedPayment === "CHEQUE" &&
					formData.chequeNum &&
					!clientErrors.chequeNum)) &&
			!formData.errors?.clientMobileErrorMessage &&
			!fieldErrors.cashAmount &&
			!fieldErrors.chequeAmount &&
			!fieldErrors.creditAmount &&
			!formData.errors?.clientPostalCodeErrorMessage &&
			!formData.errors?.clientEmailErrorMessage &&
			!formData.errors?.clientPanErrorMessage &&
			!formData.errors?.clientgstErrorMessage &&
			(id
				? parseFloat(formData.toatalAmount) >= formData.receivedAmount
				: formData.subtotal)
		) {
			return componentRef.current;
		} else {
			setProductError(true);
			setServiceError(true);
			validateProdForm();
		}
	};
	const handleCancel = () => {
		setConfirmDownload(false);
	};
	const handleCancelForPaymentType = () => {
		setShowConfirmation(false);
		setSelectedPayment("");
	};
	const User_id = sessionStorage.getItem("userInfo");
	const userId = JSON.parse(User_id as any);

	const fetchDatas = () => {
		dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
			if (res && res.type === "REGISTER_GET_SUCCESS") {
				setClientList(res?.payload?.color ? res?.payload?.color : "#000000");
				setbillModule(
					res?.payload?.billModule ? res?.payload?.billModule : "PRODUCT"
				);
				setRoundoffValue(res?.payload?.RoundOffType);
				setRoundoffEnable(res?.payload?.RoundOffEnabled);
				setAmountInWord(res?.payload?.AmountFormat)
				setDateFormat(res?.payload?.invoiceDateFormat);
				setFormData((prev: any) => ({
					...prev,
					invoicedate: res?.payload?.invoiceDateFormat
						? moment(invoiceDate).format(
								res?.payload?.invoiceDateFormat
									? res?.payload?.invoiceDateFormat
									: "DD/MM/YYYY"
						  )
						: formatDate,
					duedate: res?.payload?.invoiceDateFormat
						? moment(dueDate).format(
								res?.payload?.invoiceDateFormat
									? res?.payload?.invoiceDateFormat
									: "DD/MM/YYYY"
						  )
						: formatDueDate,
					billTaxType: res?.payload?.taxType,
					billModule: res?.payload?.billModule
						? res?.payload?.billModule
						: "PRODUCT",
						RoundOffType:res?.payload?.RoundOffType,
						RoundOffEnabled:res?.payload?.RoundOffEnabled,
						AmountFormat:res?.payload?.AmountFormat


				}));
			}
		});
	};

	useEffect(() => {
		if (userId?.token?._id) {
			fetchDatas();
		}
	}, []);

	const handleOpen = (value: any) => {
		if (value == "addTrueterms") {
			setFormData({ ...formData, termsanddcon: "" });
			setTermsOpen(false);
			titls[0].status = false;
		}
		if (value == "addTruenotes") {
			setFormData({ ...formData, notes: "" });
			setNotesOpen(false);
			titls[1].status = false;
		}
		if (value == "addBank") {
			setFormData({
				...formData,
				bankNme: "",
				accounHolder: "",
				accounNumber: "",
				ifscCode: "",
			});
			setBanksOpen(false);
			titls[2].status = false;
		}
		if (value == "addTrueUpi") {
			setFormData({ ...formData, upiNumber: "", upiType: "" });
			setUpiOpen(false);
			titls[3].status = false;
		}
		if (value == "addSigntuare") {
			setFormData({ ...formData, signature: "", seal: "", otherValue: "" });
			setSignatureOpen(false);
			titls[4].status = false;
		}
	};
	const handleCancelpop = () => {
		setcompanyDetails(false);
		navigate("/app/company");
	};
	return (
		<>
			<div>
				{userdata?.taxType == "Bill Wise" && billModules == "PRODUCT" ? (
					<>
						<div style={{ display: "none" }}>
							<Print
								Invoice={formData}
								componentRef={componentRef}
								roundOffEnable={roundOffEnable}
								amountInWords={amountInWords}
							/>
						</div>
					</>
				) : (
					<>
						{billModules == "PRODUCT" && (
							<div style={{ display: "none" }}>
								<PrintProductwise
									Invoice={formData}
									componentRef={componentRef}
									roundOffEnable={roundOffEnable}
									amountInWords={amountInWords}
								/>
							</div>
						)}
					</>
				)}
				{billModules !== "PRODUCT" ? (
					<>
						<div style={{ display: "none" }}>
							<PrintService
								ServiceInvoice={formData}
								componentRef={componentRef}
								roundOffEnable={roundOffEnable}
								amountInWords={amountInWords}
							/>
						</div>
					</>
				) : (
					""
				)}
			</div>
			<div className="flex flex-wrap">
				<div className="w-full lg:w-10/12 pl-4 pr-4 sm:pl-4 sm:pr-0 mb-4 sm:mb-1 margin  items-start bg-[white] min-h-screen">
					<form>
						<div className="bg-[white] sticky z-10 top-0 left-0 right-0 pb-2 ">
							<div className="justify-center flex flex-row relative">
								<input
									className={`${
										isEditing ? " w-3/6" : "w-3/6 "
									} rounded-[7px] text-center xs:w-1/4 mt-2 focus:outline-emerald-500 lg:text-3xl xl:text-3xl 2xl:text-3xl md:text-3xl text-base`}
									autoComplete="nope"
									style={{
										fontFamily: "poppins",
										fontWeight: 700,
									}}
									onBlur={handleBlur}
									value={formData.invoicehead}
									type="text"
									ref={ref}
									id="input_focus"
									aria-label="Search"
									name="invoicehead"
									onChange={(e) => handleChange(e)}
									onFocus={(e) => {
										setEditing(true);
									}}
									onKeyDown={handleKeyDown}
								/>
								{/* </h1> */}
								<div
									className={`${
										isEditing ? "ml-2 " : "ml-2"
									} mt-3 lg:mt-4 2xl:mt-4 xl:mt-4 md:mt-4`}
								>
									{isEditing ? (
										<></>
									) : (
										<button
											type="button"
											onClick={handleEditClick}
											id="combo_1"
											aria-label="Aria Name"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth="1.5"
												stroke="#008065"
												className="w-6 h-6 cursor-pointer"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
												/>
											</svg>
										</button>
									)}
								</div>
							</div>
						</div>
						<hr className="mx-8" style={{ borderColor: "#008065" }} />
						<div className="grid grid-cols-6">
							<div className="col-start-1 col-end-2"></div>

							<div className="col-start-2 col-span-4 ">
								{formData.companyName ? (
									<div
										className=" text-center  pt-2 text-sm font-[1500] sm:font-normal sm:text-lg "
										style={{
											fontWeight: 900,
											fontFamily: "poppins",
										}}
									>
										{formData.companyName}
									</div>
								) : null}
							</div>
						</div>

						<div className="flex flex-col justify-center items-center pb-2">
							<div
								className="capitalize text-center"
								style={{ fontFamily: "poppins" }}
							>
								<div className="mt-1">
									<span className="p-1">
										{formData.companyAddress ? (
											<span>Address: {formData.companyAddress},</span>
										) : null}
										{formData.companyCity ? (
											<span>{formData.companyCity},</span>
										) : null}
										{formData.companyState ? (
											<span>{formData.companyState}-</span>
										) : null}
										{formData.companyPostalCode ? (
											<span>{formData.companyPostalCode},</span>
										) : null}
									</span>
								</div>
								<div>
									<span className="flex flex-col sm:flex-row justify-center">
										{formData.companygst ? (
											<span>Gstn: {formData.companygst},</span>
										) : null}
										{formData.companyPan ? (
											<span>PAN: {formData.companyPan},</span>
										) : null}
									</span>
									<span className="flex flex-col sm:flex-row justify-center items-center">
										{formData.companyEmail ? (
											<span className="flex items-center mr-1 pl-0">
												<span className="whitespace-nowrap">Email:</span>
												<span className="lowercase  sm:w-full w-[190px] overflow-x-auto sm:overflow-visible whitespace-nowrap hide-scrollbar pl-1">
													{formData.companyEmail},
												</span>
											</span>
										) : null}

										{formData.companyMobile ? (
											<span className="flex items-center">
												<span className="whitespace-nowrap">Mobile:</span>
												<span className="ml-1">{formData.companyMobile}</span>
											</span>
										) : null}
									</span>
								</div>
							</div>
						</div>

						<hr className="mx-8" style={{ borderColor: "#008065" }} />

						<div>
							<ClientDetails
								currentDate={currentDate}
								dateFormat={dateFormat}
								handleChange={handleChange}
								formData={formData}
								clientErrors={clientErrors}
								setClientErrors={setClientErrors}
								setFormData={setFormData}
								paramsId={id}
								invoiceDate={invoiceDate}
								setInvoiceDate={setInvoiceDate}
								billModules={billModules}
								addValue={addValue}
								dueDate={dueDate}
								poDate={poDate}
								serviceBillNo={serviceBillNo}
								setDueDate={setDueDate}
								setPoDate={setPoDate}
								handleAddvalue={handleAddvalue}
								handleRemoveDate={handleRemoveDate}
							/>
						</div>
						{billModules == "PRODUCT" && (
							<div className={"flex flex-col sm:flex-row pt-3 pr-8"}>
								<div className="  flex-1 md:mx-2">
									<ShippmentFrom
										handleChange={handleChange}
										formData={formData}
										setFormData={setFormData}
									/>
								</div>
								<div className="  flex-1 md:mx-2">
									<ShippmentTo
										handleChange={handleChange}
										formData={formData}
										setFormData={setFormData}
									/>
								</div>
							</div>
						)}

						<hr className="mr-8 ml-4 mt-2" style={{ borderColor: "#008065" }} />
						<div className="py-2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4">
							{formData.billTaxType == "Product Wise" &&
							billModules == "PRODUCT" ? (
								<>
									<ProductNewDetails
										setSelectedPayment={setSelectedPayment}
										setcreditAmount={setcreditAmount}
										creditAmount={creditAmount}
										fieldErrors={fieldErrors}
										clientErrors={clientErrors}
										setchequeAmount={setchequeAmount}
										setCashAmount={setCashAmount}
										setbalanceAmount={setbalanceAmount}
										setreceivedAmount={setreceivedAmount}
										stockList={stockList}
										setStockList={setStockList}
										formData={formData}
										setFormData={setFormData}
										TaxAllList={TaxAllList}
										paramsId={id}
										updateProductErrors={updateProductErrors}
										prodcutErrors={prodcutErrors}
										prodcutRows={prodcutRows}
										setProductRows={setProductRows}
										productError={productError}
										addRow={addRow}
										setProductError={setProductError}
										setProdcutErrors={setProdcutErrors}
										editValue={editValue}
										setConditionError={setConditionError}
										conditionError={conditionError}
										qty={qty}
										setQty={setQty}
										roundOffValue={roundOffValue}
										roundOffEnable={roundOffEnable}
									/>
								</>
							) : (
								<>
									{billModules == "PRODUCT" && (
										<ProductDetails
											setSelectedPayment={setSelectedPayment}
											setcreditAmount={setcreditAmount}
											creditAmount={creditAmount}
											fieldErrors={fieldErrors}
											clientErrors={clientErrors}
											setchequeAmount={setchequeAmount}
											setCashAmount={setCashAmount}
											setbalanceAmount={setbalanceAmount}
											setreceivedAmount={setreceivedAmount}
											stockList={stockList}
											setStockList={setStockList}
											formData={formData}
											setFormData={setFormData}
											TaxAllList={TaxAllList}
											paramsId={id}
											updateProductErrors={updateProductErrors}
											prodcutErrors={prodcutErrors}
											prodcutRows={prodcutRows}
											setProductRows={setProductRows}
											productError={productError}
											addRow={addRow}
											setProductError={setProductError}
											setProdcutErrors={setProdcutErrors}
											editValue={editValue}
											roundOffValue={roundOffValue}
											roundOffEnable={roundOffEnable}
										/>
									)}
								</>
							)}

							{billModules !== "PRODUCT" ? (
								<>
									<ServiceDetails
										formData={formData}
										setbalanceAmount={setbalanceAmount}
										setFormData={setFormData}
										setCashAmount={setCashAmount}
										setSelectedPayment={setSelectedPayment}
										ServiceTaxAllList={ServiceTaxAllList}
										clientErrors={clientErrors}
										paramsId={id}
										updateServiceErrors={updateServiceErrors}
										serviceErrors={serviceErrors}
										serviceRows={serviceRows}
										setServiceRows={setServiceRows}
										serviceError={serviceError}
										addRow={addRow}
										setServiceError={setServiceError}
										setServiceErrors={setServiceErrors}
										editValue={editValue}
										setreceivedAmount={setreceivedAmount}
										roundOffValue={roundOffValue}
										roundOffEnable={roundOffEnable}
									/>
								</>
							) : (
								""
							)}
							{billModules == "PRODUCT" && (
								<div
									className={
										"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 items-center"
									}
								>
									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
										}
									>
										SUBTOTAL
									</div>
									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"
										}
									>
										<NumberFormat
											value={formData.subtotal ? formData.subtotal : 0}
										/>
									</div>
								</div>
							)}

							{formData.billTaxType == "Product Wise" ? (
								<></>
							) : (
								<>
									{billModules == "PRODUCT" && (
										<GstSection TaxAllList={TaxAllList} formData={formData} />
									)}
								</>
							)}
							{roundOffEnable == true && billModules == "PRODUCT" ? (
								<>
									<div
										className={
											"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
										}
									>
										<div
											className={
												"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
											}
										>
											<div
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
												className={
													"w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
												}
											>
												ROUND OFF
											</div>
											<div
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
												className={
													"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
												}
											>
												<NumberFormat value={formData.roundOff} />
											</div>
										</div>
									</div>
								</>
							) : (
								<></>
							)}

							{/* {billModules !== 'PRODUCT' ? (
                <>
                  <ServiceTaxSection ServiceTaxAllList={ServiceTaxAllList} formData={formData} />
                </>
              ) : ""} */}
							{billModules == "PRODUCT" ? (
								<>
									<div
										className={
											"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
										}
									>
										<div
											className={
												"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
											}
										>
											<div
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
												className={
													"w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
												}
											>
												TOTAL
											</div>
											<div
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
												className={
													"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
												}
											>
												<NumberFormat value={formData.toatalAmount} />
											</div>
										</div>
									</div>
								</>
							) : (
								<></>
							)}

							<div
								className={
									"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
								}
							>
								<div
									className={
										"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
									}
								>
									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
										}
									>
										PAYMENT TYPE
									</div>
									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"font-title text-lg w-1/2  text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
										}
									>
										<select
											name="paymentType"
											id="paymentType"
											className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
											style={{
												fontFamily: "poppins",
												fontSize: "13px",
												fontWeight: 400,
												borderColor: clientErrors.paymentType
													? "red"
													: "#008065",
											}}
											value={id ? formData.paymentType : selectedPayment}
											onChange={(e: any) => handleChange(e)}
										>
											{id ? (
												<>
													<option value={formData.paymentType}>
														{id ? formData.paymentType : selectedPayment}
													</option>
												</>
											) : (
												<>
													<option value="" disabled>
														PAYMENT TYPE
													</option>
													<option value="CASH">CASH</option>
													{billModules === "PRODUCT" && (
														<>
															<option value="CREDIT">CREDIT</option>
															<option value="CHEQUE">CHEQUE</option>
															{/* <option value="CARD">CARD</option>
                      <option value="UPI">UPI</option>
                      <option value="EMI">EMI</option> */}
														</>
													)}
												</>
											)}
										</select>
										{/* {clientErrors.paymentType && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {clientErrors.paymentType}
                      </p>
                    )} */}
									</div>
								</div>
							</div>
							{id && (
								<div className="flex flex-row sm:justify-end w-full items-center text-black">
									<div className="w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center">
										<div
											style={{
												fontFamily: "Poppins, sans-serif",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className="w-1/2 text-right sm:pr-9 flex sm:block items-center"
										>
											RECEIVED AMOUNT
										</div>
										<div
											style={{
												fontFamily: "Poppins, sans-serif",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className="font-title text-lg w-1/2 text-right sm:pr-9 flex justify-end sm:block items-center whitespace-nowrap"
										>
											<input
												style={{
													fontFamily: "Poppins, sans-serif",
													fontSize: "14px",
													fontWeight: 400,
													borderColor: "#00A787",
												}}
												readOnly
												autoComplete="off"
												name="receivedAmount"
												value={id ? formData.receivedAmount : 0.0}
												onChange={handleChange}
												placeholder="0.00"
												type="number"
												className={
													defaultInputSmStyle +
													" text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
												}
											/>
										</div>
									</div>
								</div>
							)}

							{!id && selectedPayment === "CASH" && (
								<div
									className={
										"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
									}
								>
									<div
										className={
											"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
										}
									>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
											}
										>
											CASH AMOUNT
										</div>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
											}
										>
											<input
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 400,
													borderColor: fieldErrors.cashAmount
														? "red"
														: "#00A787",
												}}
												readOnly={billModules === "SERVICE"}
												name="cashAmount"
												value={cashAmount}
												min="1"
												autoComplete="nope"
												onChange={(e) => {
													handleChange(e);
												}}
												placeholder="0.00"
												type="Number"
												className={
													defaultInputSmStyle +
													" text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
												}
											/>
											{fieldErrors.cashAmount ? (
												<span
													style={{
														fontFamily: "poppins",
														fontWeight: 700,
														color: "#ff0000",
													}}
													className="text-red-400 text-xs"
												>
													{fieldErrors.cashAmount}
												</span>
											) : errors.cashAmount ? (
												<span
													style={{
														fontFamily: "poppins",
														fontWeight: 700,
														color: "#ff0000",
													}}
													className="text-red-400 text-xs"
												>
													{errors.cashAmount}
												</span>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
							)}
							{!id && selectedPayment === "CREDIT" && (
								<div
									className={
										"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
									}
								>
									<div
										className={
											"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
										}
									>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
											}
										>
											CREDIT AMOUNT
										</div>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
											}
										>
											<input
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 400,
													borderColor: "#00A787",
												}}
												readOnly
												autoComplete="nope"
												name="creditAmount"
												value={creditAmount}
												onChange={(e: any) => handleChange(e)}
												placeholder="0.00"
												type="Number"
												className={
													defaultInputSmStyle +
													" text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
												}
											/>
										</div>
									</div>
								</div>
							)}
							{!id && selectedPayment === "CHEQUE" && (
								<div
									className={
										"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
									}
								>
									<div
										className={
											"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
										}
									>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
											}
										>
											CHEQUE AMOUNT
										</div>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
											}
										>
											<input
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 400,
													borderColor: fieldErrors.chequeAmount
														? "red"
														: "#00A787",
												}}
												autoComplete="nope"
												name="chequeAmount"
												value={chequeAmount}
												placeholder="0.00"
												onChange={(e: any) => handleChange(e)}
												type="Number"
												className={
													defaultInputSmStyle +
													" text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
												}
											/>
											{fieldErrors.chequeAmount ? (
												<span
													style={{
														fontFamily: "poppins",
														fontWeight: 700,
														color: "#ff0000",
													}}
													className="text-red-400 text-xs"
												>
													{fieldErrors.chequeAmount}
												</span>
											) : errors.chequeAmount ? (
												<span
													style={{
														fontFamily: "poppins",
														fontWeight: 700,
														color: "#ff0000",
													}}
													className="text-red-400 text-xs"
												>
													{errors.chequeAmount}
												</span>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
							)}
							{selectedPayment === "UPI" && (
								<div
									className={
										"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
									}
								>
									<div
										className={
											"w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
										}
									>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
											}
										>
											UPI AMOUNT
										</div>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
											}
										>
											<input
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 400,
													borderColor: "#00A787",
												}}
												autoComplete="nope"
												onChange={(e: any) => handleChange(e)}
												readOnly
												value={formData?.toatalAmount}
												placeholder="0.00"
												type="Number"
												className={
													defaultInputSmStyle +
													" text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
												}
											/>
										</div>
									</div>
								</div>
							)}
							{selectedPayment === "NETBANKING" && (
								<div
									className={
										"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
									}
								>
									<div
										className={
											"w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
										}
									>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
											}
										>
											NET BANKING AMOUNT
										</div>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
											}
										>
											<input
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 400,
													borderColor: "#00A787",
												}}
												autoComplete="nope"
												onChange={(e: any) => handleChange(e)}
												readOnly
												value={formData?.toatalAmount}
												placeholder="0.00"
												type="Number"
												className={
													defaultInputSmStyle +
													" text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
												}
											/>
										</div>
									</div>
								</div>
							)}
							{selectedPayment === "EMI" && (
								<div
									className={
										"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
									}
								>
									<div
										className={
											"w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
										}
									>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
											}
										>
											EMI AMOUNT
										</div>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
											}
										>
											<input
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 400,
													borderColor: "#00A787",
												}}
												autoComplete="nope"
												onChange={(e: any) => handleChange(e)}
												readOnly
												value={formData?.toatalAmount}
												placeholder="0.00"
												type="Number"
												className={
													defaultInputSmStyle +
													" text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
												}
											/>
										</div>
									</div>
								</div>
							)}

							<div
								className={
									"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
								}
							>
								<div
									className={
										"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
									}
								>
									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
										}
									>
										BALANCE AMOUNT
									</div>

									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"font-title text-lg w-1/2   text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
										}
									>
										<input
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 400,
												borderColor: clientErrors.balanceAmount
													? "red"
													: "#00A787",
											}}
											value={balanceAmount}
											onChange={(e: any) => handleChange(e)}
											readOnly
											// name="balanceAmt"
											autoComplete="nope"
											placeholder="0.00"
											type=""
											className={
												defaultInputSmStyle +
												" text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
											}
										/>
										{clientErrors.balanceAmount && (
											<p
												style={{
													fontFamily: "poppins",
													fontWeight: 700,
													color: "#ff0000",
												}}
												className="text-red-400 text-xs"
											>
												{clientErrors.balanceAmount}
											</p>
										)}
									</div>
								</div>
							</div>
							<div
								className={
									"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
								}
							>
								<div
									className={
										"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
									}
								>
									{selectedPayment == "CHEQUE" && (
										<>
											<div
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
												className={
													"w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
												}
											>
												CHEQUE NUMBER
											</div>
											<div
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
												className={
													"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
												}
											>
												<input
													style={{
														fontFamily: "poppins",
														fontSize: "14px",
														fontWeight: 400,
														borderColor: clientErrors.chequeNum
															? "red"
															: "#00A787",
													}}
													value={chequeNum}
													onChange={(e: any) => handleChange(e)}
													name="chequeNum"
													autoComplete="nope"
													// placeholder="0.00"
													type=""
													className={
														defaultInputSmStyle +
														" text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
													}
												/>
												{/* {clientErrors.chequeNum && (
                          <p
                            style={{
                              fontFamily: "poppins",
                              fontWeight: 700,
                              color: "#ff0000",
                            }}
                            className="text-red-400 text-xs"
                          >
                            {clientErrors.chequeNum}
                          </p>
                        )} */}
											</div>
										</>
									)}
								</div>
							</div>
						</div>

						<div
							className={
								"grid xl:grid-cols-5 lg:grid-cols-5 sm:grid-cols-5 md:grid-cols-5 xs:grid-cols-1 mb-6"
							}
						>
							{titls.map((item, index) =>
								!item.status ? (
									<div
										key={index}
										onClick={() => handleHeaders(item, index)}
										className="rounded-xl font-bold text-[#008065] flex text-sm ml-4 mt-2 cursor-pointer"
									>
										<PlusCircleIcon style={IconStyle} className="h-5 w-5" />
										{item.title}
									</div>
								) : null
							)}

							{termsOpen && (
								<div
									key="terms"
									className={
										"xl:col-span-5 lg:col-span-5 sm:col-span-5 md:col-span-5 xs:col-span-1"
									}
								>
									<TermsAndConditions
										termsList={termsList}
										formData={formData}
										handleChange={handleChange}
										setFormData={setFormData}
										editValue={editValue}
										handleOpen={handleOpen}
									/>
								</div>
							)}

							{notesOpen && (
								<div
									key="notes"
									className={
										"xl:col-span-5 lg:col-span-5 sm:col-span-5 md:col-span-5 xs:col-span-1"
									}
								>
									<Notes
										notesList={notesList}
										formData={formData}
										handleChange={handleChange}
										setFormData={setFormData}
										editValue={editValue}
										handleOpen={handleOpen}
									/>
								</div>
							)}

							{banksOpen && (
								<div
									key="banks"
									className={
										"xl:col-span-5 lg:col-span-5 sm:col-span-5 md:col-span-5 xs:col-span-1"
									}
								>
									<BankDetails
										bankList={bankList}
										formData={formData}
										handleChange={handleChange}
										setFormData={setFormData}
										editValue={editValue}
										downloadClicked={downloadClicked}
										setDownloadClicked={setDownloadClicked}
										handleOpen={handleOpen}
									/>
								</div>
							)}

							{upiOpen && (
								<div
									key="upi"
									className={
										"xl:col-span-5 lg:col-span-5 sm:col-span-5 md:col-span-5 xs:col-span-1"
									}
								>
									<UpiPayment
										upiList={upiList}
										formData={formData}
										handleChange={handleChange}
										setFormData={setFormData}
										editValue={editValue}
										downloadUpiClicked={downloadUpiClicked}
										setDownloadUpiClicked={setDownloadUpiClicked}
										handleOpen={handleOpen}
									/>
								</div>
							)}

							{signatureOpen && (
								<div
									key="signature"
									className={
										"xl:col-span-5 lg:col-span-5 sm:col-span-5 md:col-span-5 xs:col-span-1"
									}
								>
									<Signature
										sign={sign}
										formData={formData}
										setFormData={setFormData}
										editValue={editValue}
										handleOpen={handleOpen}
									/>
								</div>
							)}
						</div>
					</form>
				</div>
				<div className="sticky top-[12px] right-0 w-full lg:w-2/12 pl-2 pr-4 sm:pr-2 mt-3 mx-4 md:mx-0 self-start xl:pb-20 sm:pb-40 xs:pb-40">
					<div style={{ fontFamily: "poppins", fontSize: "14px" }}>
						<button
							type="button"
							className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
							style={{ backgroundColor: "#008065" }}
							onClick={openChoosePreview}
						>
							Pre View
						</button>
					</div>
					<div
						style={{ fontFamily: "poppins", fontSize: "14px" }}
						className="mt-2"
					>
						<button
							className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
							style={{ backgroundColor: "#008065" }}
							onClick={handlePrint}
						>
							Print
						</button>
					</div>
					<div
						style={{ fontFamily: "poppins", fontSize: "14px" }}
						className=" w-full text-right mt-2 "
					>
						<button
							// size="sm"
							type="submit"
							className="rounded-xl font-bold text-white  flex flex-row-reverse flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
							style={{ backgroundColor: "#008065" }}
							onClick={(e: any) => handleSubmit(e)}
						>
							<svg
								className="pr-2 text-white"
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								viewBox="0 0 20 20"
								fill="white"
							>
								<mask
									id="mask0_278_27"
									style={{ maskType: "alpha" }}
									maskUnits="userSpaceOnUse"
									x="0"
									y="0"
									width="20"
									height="20"
								>
									<rect width="20" height="20" fill="white" />
								</mask>
								<g mask="url(#mask0_278_27)">
									<path
										d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
										fill="white"
									/>
								</g>
							</svg>
							{/* {params.id !== "new" ? "Update" : "Download"} */}
							Download
						</button>
					</div>
					<div
						style={{ fontFamily: "poppins", fontSize: "14px" }}
						className=" w-full text-right mt-2 "
					>
						<button
							type="button"
							className="rounded-xl font-bold text-white  flex flex-row-reverse  items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full whitespace-nowrap"
							style={{ backgroundColor: "#008065" }}
							onClick={(e: any) => handleSectionOpen(e)}
						>
							<svg
								className="pl-2"
								width="30"
								height="30"
								viewBox="0 0 20 20"
								fill="white"
								xmlns="http://www.w3.org/2000/svg"
							>
								<mask
									id="mask0_278_36"
									style={{ maskType: "alpha" }}
									maskUnits="userSpaceOnUse"
									x="0"
									y="0"
									width="23"
									height="23"
								>
									<rect
										y="12.0752"
										width="16"
										height="16"
										transform="rotate(-49 0 12.0752)"
										fill="white"
									/>
								</mask>
								<g mask="url(#mask0_278_36)">
									<path
										d="M11.3749 19.3134L3.32462 12.3154L15.6598 6.25476L11.3749 19.3134ZM10.7402 16.995L13.4073 8.84593L5.70878 12.6213L7.46977 14.1521L10.8487 11.7893L8.97919 15.4642L10.7402 16.995Z"
										fill="white"
									/>
								</g>
							</svg>
							Send Email
						</button>
					</div>
					<div className="flex flex-row items-center justify-center w-full mx-2">
						<span
							style={{
								fontFamily: "poppins",
								fontWeight: 700,
								color: "#008065",
							}}
							className=" mb-2 sm:mb-0 -mr-6"
						>
							{billModules === "PRODUCT" ? "PRODUCT" : "SERVICE"}
						</span>
						<div className="mr-6">
							<FormControlLabel
								className="text-md font-bold "
								label=""
								labelPlacement="start"
								sx={{ fontFamily: "poppins", fontWeight: 700 }}
								control={
									<Switch
										disabled={invoiceModule ? true : false}
										checked={isToggled}
										onChange={handleToggleChange}
										sx={{
											"& .MuiSwitch-track": {
												backgroundColor: "#008065",
											},
											"& .MuiSwitch-thumb": {
												color: "#008065",
											},
										}}
									/>
								}
							/>
						</div>
					</div>

					<div
						style={{ fontFamily: "poppins", fontSize: "14px" }}
						className=" w-full  mt-2 sm:mb-auto mb-16"
					>
						<ColorScheme
							clientList={clientList}
							handleColorChange={handleColorChange}
							setColorPicker={setColorPicker}
							// ColourEmpty={ColourEmpty}
						/>
					</div>
				</div>
			</div>

			{openmail && (
				<Transition.Root show={openmail} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						initialFocus={cancelButtonRef}
						onClose={setOpenMail}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
						<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
										<SendEmailModel
											closeModal={closeModal}
											handleChange={handleChange}
											formData={formData}
											setFormData={setFormData}
											handleSendEmail={handleSendEmail}
											sendEmailErrors={sendEmailErrors}
											errorEmail={errorEmail}
											setErrorEmail={setErrorEmail}
										/>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			)}
			{confirmDownload && (
				<ConfirmationDownload
					onDownload={downloadInvoice}
					onSaveDownload={saveAndDownloadInvoice}
					onCancel={handleCancel}
				/>
			)}
			<Transition.Root show={viewOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={cancelButtonRef}
					onClose={setOpenView}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:mx-8 lg:w-10/12 md:w-11/12 ">
									{userdata?.taxType == "Bill Wise" &&
									billModules == "PRODUCT" ? (
										<Preview
											Invoice={formData}
											componentRef={componentRef}
											closeModal={closeModal}
											selectedFont={selectedFont}
											roundOffEnable={roundOffEnable}
											amountInWords={amountInWords}
										/>
									) : (
										<>
											{billModules == "PRODUCT" && (
												<PreviewNewProductwise
													Invoice={formData}
													componentRef={componentRef}
													closeModal={closeModal}
													selectedFont={selectedFont}
													roundOffEnable={roundOffEnable}
													amountInWords={amountInWords}
												/>
											)}
										</>
									)}
									{billModules !== "PRODUCT" ? (
										<>
											<PreviewService
												ServiceInvoice={formData}
												componentRef={componentRef}
												closeModal={closeModal}
												selectedFont={selectedFont}
												roundOffEnable={roundOffEnable}
												amountInWords={amountInWords}
											/>
										</>
									) : (
										""
									)}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			{showConfirmation && (
				<PaymentUpdateModel
					message="Are you sure you want to proceed?"
					onCancel={handleCancelForPaymentType}
				/>
			)}
			{!companyList && companyDetails && (
				<>
					<div
						className="relative z-10"
						aria-labelledby="modal-title"
						role="dialog"
						aria-modal="true"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
						<div className="fixed inset-0 z-10 w-screen overflow-y-auto scrollbar-thin">
							<div className="flex min-h-full items-end  justify-center p-4 text-center items-center sm:p-0">
								<div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-[40%]">
									<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
										<div className="mt-2">
											<p
												className="text-xl text-black"
												style={{ fontFamily: "Poppins" }}
											>
												Please Register Your Company Details
											</p>
										</div>
									</div>
									<div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
										<button
											onClick={handleCancelpop}
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-[#008065] px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto"
											style={{ fontFamily: "Poppins" }}
										>
											Click Here to Register
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default InvoicePage;
