import { toWords } from 'number-to-words';
import { useSelector } from "react-redux";
import NumberFormat from "../commen/NumberFormat";
import CalculateIndianFormattotal from '../commen/CalculateIndianFormattotal';


const PreviewPurchaseProductwise = ({ Invoice, componentRef, closeModal, selectedFont,roundOffEnable,amountInWords }: any) => {
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const totalAmount = Invoice.toatalAmount;
    const roundedTotalAmount = Math.round(totalAmount);
    const totalAmountInWords = Number.isFinite(roundedTotalAmount)
      ? amountInWords === "International Format"
        ? toWords(roundedTotalAmount) + " Only"
        : "Rupees " + CalculateIndianFormattotal(roundedTotalAmount) + " Only"
      : "Invalid amount";
  const handleCancelClick = () => {
    closeModal();
  };
  return (
    <div content={componentRef} style={{ fontFamily: selectedFont }}>
      <div style={{ breakBefore: "page" }}></div>
      {/*   image and client information   */}

      <div className="grid grid-cols-6 ">
        <div className="col-start-1 col-end-2">
          {Invoice.imageData ? (
            <div className=" font-title  sm:text-left ">
              <label
                style={{ fontFamily: selectedFont }}
                className="   items-start  text-blue      "
              >
                <img
                  className="pr-0 sm:pr-8 md:pr-8 pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 "
                  src={Invoice.imageData}
                  alt="logonew "
                />
              </label>
            </div>
          ) : null}
        </div>

        <div className="col-start-2 col-span-4 ">
          {Invoice.companyName ? (
            <div className=" text-center  pt-7 text-lg font-semibold  sm:font-normal sm:text-4xl ">
              {Invoice.companyName}
            </div>
          ) : null}
        </div>
      </div>

      <div className="border "></div>
      {/*        *******************   invoice name                */}

      <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
        <div className="justify-center flex flex-row relative ">
          <span
            className="w-64 md:w-11/12 lg:w-11/12 2xl:w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none"
            style={{
              fontFamily: selectedFont,
              fontWeight: 700,
            }}
          >
            {Invoice.invoicehead}
          </span>
          {/* <span
                  readOnly
                  className="w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none "
                  autoComplete="nope"
                  style={{
                    fontFamily:selectedFont,
                    fontWeight: 700,
                  }}
                  id="input_focus"
                  value={Invoice.invoicehead}
                /> */}
          {/* </h1> */}
        </div>
        {/*     client details           */}
        <div className=" px-4 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
          <div className="flex-1 md:mx-2 column-reverse">
            <div className="flex flex-col sm:flex-row pt-3  justify-between pre-reverse">
              <div className="lg:w-[90%] xl:w-[50%]  xl:w-[50%] 2xl:w-[50%] md:w-[70%]  mr-2 ">
                <div className="flex flex-row  items-center mb-2 ">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      color: "#008065",
                      fontFamily: selectedFont,

                      fontWeight: 700,
                    }}
                  >
                    Vendor Details
                  </div>
                </div>
                {Invoice.vendorName ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Name
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-[90%] w-1/2">
                        <input
                          className="focus:outline-none  text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.vendorName}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.vendorMobile ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4 "
                      >
                        Mobile
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full w-1/2 ">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.vendorMobile}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.vendorEmail ? (
                  <>
                    <div className="flex justify-between  ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Email
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full w-1/2 ">
                        <input
                          className="focus:outline-none  text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.vendorEmail}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.vendorgst ? (
                  <>
                    <div className="flex justify-between  ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        GST
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.vendorgst}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="">
                <div className="flex flex-row items-center justify-between mb-2 mr-8">
                  <div
                    style={{
                      color: "#008065",
                      fontFamily: selectedFont,

                      fontWeight: 700,
                    }}
                    className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Purchase Entry No
                  </div>
                  <div className="font-title flex-1 text-right ">
                    <input
                      readOnly
                      value={Invoice.invoiceNo}
                      style={{
                        fontFamily: selectedFont,
                      }}
                      className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-3 sm:ml-auto block focus:outline-none "
                    />
                  </div>
                  <div></div>
                </div>

                {Invoice.purchaseEntryDate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2 mr-8">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        purchase Entry Date
                      </div>
                      <div className="font-title flex-1 text-right ">
                        <div>
                          <input
                            readOnly
                            value={Invoice.purchaseEntryDate}
                            style={{
                              fontFamily: selectedFont,
                            }}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md ml-3 sm:ml-auto block w-full  focus:outline-none  "
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.PurchaseBillNo ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2 mr-8">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Purchase Bill No
                      </div>
                      <div className="font-title flex-1 text-right ">
                        <div>
                          <input
                            readOnly
                            value={Invoice.PurchaseBillNo}
                            style={{
                              fontFamily: selectedFont,
                            }}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md ml-3 sm:ml-auto block w-full  focus:outline-none  "
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.purchaseBillDate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        purchase Bill Date
                      </div>
                      <div className="font-title flex-1 text-right">
                        <input
                          readOnly
                          value={Invoice.purchaseBillDate}
                          style={{
                            fontFamily: selectedFont,
                          }}
                          className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full ml-3 sm:ml-auto  focus:outline-none "
                        ></input>
                      </div>
                      <div></div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* product details */}
        <div className="px-6 mt-3">
          {/* Header Row */}
          <div className="hidden sm:flex rounded-md w-full flex-row px-2 py-2 text-white bg-[#008065]">
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className="2xl:w-[8%] xl:w-[7%] lg:w-[6.5%] md:w-[6%] sm:w-[6%] text-left font-bold text-sm overflow-hidden">
              S No
            </div>
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className="2xl:w-[21%] xl:w-[20%] lg:w-[18%] md:w-[17%] sm:w-[17%] text-left font-bold text-sm overflow-hidden">
              Product
            </div>
            {userdata?.hsnNo ? (
              <div style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
                className="2xl:w-[9%] xl:w-[8.5%] lg:w-[9%] md:w-[9%] sm:w-[9%] text-center font-bold text-sm overflow-hidden">
                HSN
              </div>
            ) : (<></>)}
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className="2xl:w-[13%] xl:w-[15%] lg:w-[14%] md:w-[14%] sm:w-[14%] text-right font-bold text-sm overflow-hidden">
              Price
            </div>
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className="2xl:w-[16%] xl:w-[14%] lg:w-[9%] md:w-[8%] sm:w-[8%] text-center font-bold text-sm overflow-hidden">
              Qty
            </div>
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className="2xl:w-[9%] xl:w-[8.5%] lg:w-[9%] md:w-[9%] sm:w-[9%] text-center font-bold text-sm overflow-hidden">
              Disc(%)
            </div>
            {userdata?.gstValue === "IGST" ? (
              <div style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }} className="2xl:w-[19%] xl:w-[16%] lg:w-[26%] md:w-[31%] sm:w-[46%] text-center font-bold text-sm overflow-hidden">
                IGST(%)
              </div>
            ) : (
              <>
                <div style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }} className="2xl:w-[9%] xl:w-[8.5%] lg:w-[9%] md:w-[12%] sm:w-[12%] text-center font-bold text-sm overflow-hidden">
                  CGST(%)
                </div>
                <div style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }} className="2xl:w-[9%] xl:w-[8.5%] lg:w-[9%] md:w-[12%] sm:w-[12%] text-center font-bold text-sm overflow-hidden">
                  SGST(%)
                  {/* SGST(%) */}
                </div>
              </>
            )}
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }} className="2xl:w-[18%] xl:w-[20%] lg:w-[17%] md:w-[15%] sm:w-[15%] text-right font-bold text-sm overflow-hidden">
              Total
            </div>
          </div>
        </div>
        {Invoice?.product?.map((product: any, index: number) => {
          return (
            <div className="px-4 py-1 sm:px-8">
              <div
                key={`${index}_${product.id}`}
                className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row xl:flex-row flex-col rounded-lg w-full items-center relative text-sm"
              >
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className="w-full 2xl:w-[8%] xl:w-[7%] lg:w-[6.5%] md:w-[6%] sm:w-[6%] text-left font-bold text-sm overflow-hidden flex flex-row sm:block"
                >
                  <span className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                    S No
                  </span>
                  <span className="inline-block w-full product">
                    <input
                      readOnly
                      value={index + 1}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      className="font-title block w-full text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-right sm:text-left pl-0 sm:pl-2 focus:outline-none"
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className="w-full 2xl:w-[21%] xl:w-[20%] lg:w-[18%] md:w-[17%] sm:w-[17%] text-right md:text-left xl:text-left 2xl:text-left flex flex-row sm:block"
                >
                  <span className="sm:hidden w-1/2 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                    Product
                  </span>
                  <span className="inline-block w-full product">
                    <input
                      readOnly
                      value={product.prodcut}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      className="font-title block w-full text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-right sm:text-left focus:outline-none"
                    />
                  </span>
                </div>

                {userdata?.hsnNo ? (
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                    className="w-full 2xl:w-[9%] xl:w-[8.5%] lg:w-[9%] md:w-[9%] sm:w-[9%] text-right md:text-left xl:text-left 2xl:text-left flex flex-row sm:block"
                  >
                    <span className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                      Hsn
                    </span>
                    <span className="inline-block w-full sm:w-full mb-1 sm:mb-0">
                      <input
                        readOnly
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 400,
                        }}
                        value={product.hsn ? product.hsn : "N/A"}
                        className="font-title block w-full text-xs sm:text-sm lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-right sm:text-center focus:outline-none"
                      />
                    </span>
                  </div>
                ) : (<></>)}

                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className="w-full 2xl:w-[13%] xl:w-[15%] lg:w-[14%] md:w-[14%] sm:w-[13%] text-right flex flex-row sm:block"
                >
                  <span className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                    Price
                  </span>
                  <span className="inline-block w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product?.rate}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      className="font-title block w-full text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-right sm:text-right focus:outline-none"
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className="w-full 2xl:w-[16%] xl:w-[14%] lg:w-[9%] md:w-[8%] sm:w-[8%] text-right flex flex-row sm:block"
                >
                  <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                    Qty
                  </span>
                  <span className="inline-block w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product.qty}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      className="font-title block w-full text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-right sm:text-center focus:outline-none"
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className="w-full 2xl:w-[9%] xl:w-[8.5%] lg:w-[9%] md:w-[9%] sm:w-[9%] text-right flex flex-row sm:block"
                >
                  <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                    Disc(%)
                  </span>
                  <span className="inline-block w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product.discount}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      className="font-title block w-full text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-right sm:text-center focus:outline-none"
                    />
                  </span>
                </div>
                {userdata?.gstValue === "IGST" ? (
                  <>
                    <div
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 700,
                      }}
                      className="w-full 2xl:w-[19%] xl:w-[16%] lg:w-[26%] md:w-[31%] sm:w-[46%] text-right flex flex-row sm:block"
                    >
                      <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                        IGST(%)
                      </span>
                      <span className="inline-block w-full mb-1 sm:mb-0">
                        <input
                          readOnly
                          value={product.prodigst}
                          type={"number"}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                          }}
                          className="font-title block w-full text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-right sm:text-center focus:outline-none"
                        />
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 700,
                      }}
                      className="w-full 2xl:w-[9%] xl:w-[8.5%] lg:w-[9%] md:w-[12%] sm:w-[12%] text-right flex flex-row sm:block"
                    >
                      <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                        CGST(%)
                      </span>
                      <span className="inline-block w-full mb-1 sm:mb-0">
                        <input
                          readOnly
                          value={product.cgst || product.prodcgst}
                          type={"number"}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                          }}
                          className="font-title block w-full text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-right sm:text-center focus:outline-none"
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 700,
                      }}
                      className="w-full 2xl:w-[9%] xl:w-[8.5%] lg:w-[9%] md:w-[12%] sm:w-[12%] text-right flex flex-row sm:block"
                    >
                      <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                        SGST(%)
                      </span>
                      <span className="inline-block w-full mb-1 sm:mb-0">
                        <input
                          readOnly
                          value={product.sgst || product.prodsgst}
                          type={"number"}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                          }}
                          className="font-title block w-full text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-right sm:text-center focus:outline-none"
                        />
                      </span>
                    </div>
                  </>)}
                <>
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                    className="w-full 2xl:w-[18%] xl:w-[20%] lg:w-[17%] md:w-[15%] sm:w-[15%] text-right flex flex-row sm:block"
                  >
                    <span className="sm:hidden flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                      Total
                    </span>
                    <span className="font-block w-full text-xs sm:text-sm">
                      <NumberFormat value={product.amount}></NumberFormat>
                    </span>
                  </div>
                </>
              </div>
            </div>
          );
        })}

        {/********************************** * subtotal *******************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Subtotal
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-block w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={Invoice.subtotal}

            //   displayType={"text"}
            //   thousandSeparator={true}
            />
          </div>
        </div>
        {/* ******************************cgstPercentage**************/}

        {roundOffEnable == true && (
          <div
            className={
              "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
            }
          >
            <div
              className={
                "w-full sm:w-1/2 px-4 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                }
              >
                Round Off
              </div>
              
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "font-title text-lg w-1/2 text-right sm:pr-4 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                }
              >
                <NumberFormat value={Invoice.roundOff} />
              </div>
            </div>
          </div>
        )}
        {/*************************total****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Total
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={Invoice.toatalAmount}
            />
          </div>
        </div>
        {/*************************AMOUNT IN WORDS****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 whitespace-nowrap capitalize"
          >
            {totalAmountInWords}
          </div>
        </div>


        {/********************************Cancel*********************************/}
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            // onClick={() => setOpenView(!viewOpen)}
            // ref={cancelButtonRef}
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default PreviewPurchaseProductwise
