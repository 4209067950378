import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "../commen/ConfirmationPopup";
import { contactDelete, contactFindAll } from "../../redux/actions/contactAction";
import { clientgetReducer } from "../../redux/reducers/clientReducers";

function Contact() {
    const dispatch = useDispatch();
    const [sortOrder, setSortOrder] = useState("default");
    const [contactSearch, setContactSearch] = useState([] as any);
    const [contactData, setContactData] = useState([] as any);
    const [contactId, setContactId] = useState(null as any);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [totalRows, setTotalRows] = useState(0);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });

    const fetchData = (page: number, pageSize: number) => {
        const offset = page * pageSize;
        dispatch(contactFindAll(pageSize, offset) as any).then((res: any) => {
            if (res && res.type === "CONTACT_FIND_ALL_SUCCESS") {
                const contacts = Array.isArray(res.payload?.contactus) ? res.payload.contactus : [];
                setContactData(contacts);
                setContactSearch(contacts)
                setTotalRows(res.payload?.total_count || 0);

            }
        });
    };


    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        let data = [...contactSearch];
        data = data?.filter((value: any) =>
            value?.companyName?.toLowerCase().includes(query?.toLowerCase())
        );
        setContactData(data);
    };
    
    useEffect(() => {
        fetchData(paginationModel.page, paginationModel.pageSize);
    }, [paginationModel]);

    const handleConfirm = () => {
        dispatch(contactDelete(contactId) as any).then((res: any) => {
            if (res) {
                fetchData(paginationModel.page, paginationModel.pageSize);
                setContactId(null);
                setShowConfirmation(false);
            }
        });
    };
    const handleCancel = () => {
        setShowConfirmation(false);
    };
    const handleSortChange = (newSortOrder: any) => {
        const sortedData = [...contactSearch].sort((a, b) => {
            if (newSortOrder === "asc") {
                return a.firstName.localeCompare(b.firstName);
            } else {
                return b.firstName.localeCompare(a.firstName);
            }
        });
        setContactData(sortedData);
        setSortOrder(newSortOrder);
    };
    const deleteId = (data: any) => {
        setShowConfirmation(true);
        setContactId(data._id.$oid);
    };
    const rows = contactData?.map((item: any, index: any) => ({
        id: uuidv4(),
        ...item,
        Sl_No: index + 1,
    }));

    const columns: GridColDef[] = [
        {
            field: "Sl_No",
            headerName: "S No",
            minWidth: 110,
            flex: 1,
            editable: false,
        },
        {
            field: "firstName",
            headerName: "First Name",
            editable: false,
            minWidth: 200,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.firstName ? params?.row?.firstName : "N/A"}</>
            ),
        },
        {
            field: "lastName",
            headerName: "Last Name",
            editable: false,
            minWidth: 200,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.lastName ? params?.row?.lastName : "N/A"}</>
            ),
        },
        {
            field: "email",
            headerName: "Email",
            editable: false,
            minWidth: 300,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.email ? params?.row?.email : "N/A"}</>
            ),
        },

        {
            field: "query",
            headerName: "Feedback",
            editable: false,
            minWidth: 300,
            flex: 2,
            renderCell: (params: any) => (
                <>{params?.row?.query ? params?.row?.query : "N/A"}</>
            ),
        },
        {
            field: "action",
            headerName: "Actions",
            editable: false,
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton
                        aria-label="delete"
                        onClick={() => {
                            deleteId(params.row);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5  cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                        </svg>
                    </IconButton>
                </>
            ),
        },

    ];

    return (
        <div className="">
            <div className="p-4 text-2xl font-bold text-[#045545] mx-2">
                Contact
            </div>
            <div className="bg-[#F1F7F6]  rounded-xl px-3 py-3 mx-2">
                <div className="flex justify-between flex-col lg:flex-row md:flex-row xl:flex-row 2xl:flex-row ">
                    <div className="flex items-center">
                        <div className="relative flex mb-2">
                            <input
                                onChange={(e) => onSearch(e)}
                                type="text"
                                id="simple-search"
                                className="w-48 bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                                placeholder="Search"
                                required
                            />
                            <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                                <svg
                                    className="w-4 h-4"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="block mb-2">
                        <div className="relative flex items-center">
                            <select
                                defaultValue={""}
                                id="sortDropdown"
                                onChange={(e) => handleSortChange(e.target.value)}
                                className="block  h-[2.6rem] w-48 p-2 text-sm  border border-[#00A787] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
                            >
                                <option value="" disabled hidden>
                                    Sort By
                                </option>
                                <option value="asc">First Name A to Z</option>
                                <option value="desc">First Name Z to A</option>
                            </select>
                        </div>
                    </div>
                </div>

                <Box
                    sx={{
                        height: "80vh",
                        width: "100%",
                        overflowX: "auto",
                        overflowY: "auto",
                    }}
                >
                    <DataGrid
                        // autoHeight
                        rows={rows}
                        columns={columns}
                        sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: 500,
                            // Styling goes here
                        }}
                        checkboxSelection
                        disableRowSelectionOnClick
                        paginationMode="server"
                        rowCount={totalRows}
                        paginationModel={paginationModel}
                        onPaginationModelChange={(newModel) => {
                            setPaginationModel(newModel);
                            // Optionally clear rows if fetching a completely new page
                            if (newModel.page !== paginationModel.page) {
                                setContactData([]);
                            }
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                    />
                </Box>
            </div>
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
}

export default Contact;
