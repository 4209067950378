import { toWords } from 'number-to-words';
import NumberFormat from "../commen/NumberFormat";
import { useSelector } from 'react-redux';
import CalculateIndianFormattotal from '../commen/CalculateIndianFormattotal';

const PreviewPurchaseBillwise = ({ Invoice, componentRef, closeModal, selectedFont,roundOffEnable,amountInWords }: any) => {
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const totalAmount = Invoice.toatalAmount;
    const roundedTotalAmount = Math.round(totalAmount);
    const totalAmountInWords = Number.isFinite(roundedTotalAmount)
      ? amountInWords === "International Format"
        ? toWords(roundedTotalAmount) + " Only"
        : "Rupees " + CalculateIndianFormattotal(roundedTotalAmount) + " Only"
      : "Invalid amount";

  const handleCancelClick = () => {
    closeModal();
  };

  return (
    <div content={componentRef} style={{ fontFamily: selectedFont }}>
      <div style={{ breakBefore: "page" }}></div>
      {/*   image and client information                */}

      <div className="grid grid-cols-6">
        <div className="col-start-1 col-end-2">
          {Invoice.imageData ? (
            <div className=" font-title  sm:text-left ">
              <label
                style={{ fontFamily: selectedFont }}
                className="   items-start  text-blue      "
              >
                <img
                  className="pr-0 sm:pr-8 md:pr-8 pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 "
                  src={Invoice.imageData}
                  alt="logonew "
                />
              </label>
            </div>
          ) : null}
        </div>

        <div className="col-start-2 col-span-4 ">
          {Invoice.companyName ? (
            <div className=" text-center  pt-7 text-lg font-semibold  sm:font-normal sm:text-4xl ">
              {Invoice.companyName}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="capitalize text-center">
          <div className="mt-1">
            <span>
              {Invoice.companyAddress ? (
                <span>Address: {Invoice.companyAddress},</span>
              ) : null}
              {Invoice.companyCity ? <span>{Invoice.companyCity},</span> : null}
              {Invoice.companyState ? (
                <span>{Invoice.companyState}-</span>
              ) : null}
              {Invoice.companyPostalCode ? (
                <span>{Invoice.companyPostalCode},</span>
              ) : null}
              {Invoice.companygst ? (
                <span>

                  Gstn:
                  {Invoice.companygst}
                </span>
              ) : null}
            </span>
          </div>
          <div>
            <span>
              {Invoice.companyPan ? (
                <span>PAN: {Invoice.companyPan},</span>
              ) : null}
              {Invoice.companyEmail ? (
                <span>
                  Email:
                  <span className="lowercase">{Invoice.companyEmail},</span>
                </span>
              ) : null}
              {Invoice.companyMobile ? (
                <span>Mobile: {Invoice.companyMobile},</span>
              ) : null}
            </span>
          </div>
        </div>
      </div>
      <div className="border "></div>
      {/*        *******************   invoice name                */}

      <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
        <div className="justify-center flex flex-row relative ">
          <span
            className="w-64 md:w-11/12 lg:w-11/12 2xl:w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none"
            style={{
              fontFamily: selectedFont,
              fontWeight: 700,
            }}
          >
            {Invoice.invoicehead}
          </span>
          {/* <span
                readOnly
                className="w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none "
                autoComplete="nope"
                style={{
                  fontFamily:selectedFont,
                  fontWeight: 700,
                }}
                id="input_focus"
                value={Invoice.invoicehead}
              /> */}
          {/* </h1> */}
        </div>
        {/*     client details           */}
        <div className=" px-4 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
          <div className="flex-1 md:mx-2 column-reverse">
            <div className="flex flex-col sm:flex-row pt-3  justify-between pre-reverse">
              <div className="lg:w-[90%] xl:w-[50%]  xl:w-[50%] 2xl:w-[50%] md:w-[70%]  mr-2 ">
                <div className="flex flex-row  items-center mb-2 ">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      color: "#008065",
                      fontFamily: selectedFont,

                      fontWeight: 700,
                    }}
                  >
                    Vendor Details
                  </div>
                </div>
                {Invoice.vendorName ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Name
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-[90%] w-1/2 ">
                        <input
                          className="focus:outline-none  text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.vendorName}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.vendorMobile ? (
                  <>
                    <div className="flex justify-between  ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Mobile
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full w-1/2 ">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full "
                          readOnly
                          value={Invoice.vendorMobile}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.vendorEmail ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Email
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full w-1/2 ">
                        <input
                          className="focus:outline-none  text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.vendorEmail}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.vendorgst ? (
                  <>
                    <div className="flex justify-between  ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        GST
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full w-1/2 ">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.vendorgst}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="">
                <div className="flex flex-row items-center justify-between mb-2 mr-8">
                  <div
                    style={{
                      color: "#008065",
                      fontFamily: selectedFont,

                      fontWeight: 700,
                    }}
                    className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    INVOICE #
                  </div>
                  <div className="font-title flex-1 text-right ">
                    <input
                      readOnly
                      value={Invoice.invoiceNo}
                      style={{
                        fontFamily: selectedFont,
                      }}
                      className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-3 sm:ml-auto block focus:outline-none "
                    />
                  </div>
                  <div></div>
                </div>
                {Invoice.purchaseEntryDate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2 mr-8">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        purchase Entry Date
                      </div>
                      <div className="font-title flex-1 text-right ">
                        <div>
                          <input
                            readOnly
                            value={Invoice.purchaseEntryDate}
                            style={{
                              fontFamily: selectedFont,
                            }}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md ml-3 sm:ml-auto block w-full  focus:outline-none  "
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.PurchaseBillNo ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2 mr-8">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Purchase Bill No
                      </div>
                      <div className="font-title flex-1 text-right ">
                        <div>
                          <input
                            readOnly
                            value={Invoice.PurchaseBillNo}
                            style={{
                              fontFamily: selectedFont,
                            }}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md ml-3 sm:ml-auto block w-full  focus:outline-none  "
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.purchaseBillDate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        purchase Bill Date
                      </div>
                      <div className="font-title flex-1 text-right">
                        <input
                          readOnly
                          value={Invoice.purchaseBillDate}
                          style={{
                            fontFamily: selectedFont,
                          }}
                          className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full ml-3 sm:ml-auto  focus:outline-none "
                        ></input>
                      </div>
                      <div></div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* product details */}
        <div className="px-8 mt-3">
          <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white bg-[#008065]">
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" sm:w-1/4 md:w-1/6 lg:w-2/12 xl:w-1/12 2xl:w-1/12 text-left "
            >
              <span className="inline-block">S No</span>
            </div>
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left "
            >
              <span className="inline-block">Product</span>
            </div>
            {userdata?.hsnNo ? (
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-left sm:text-left"
              >
                <span className="inline-block ">Hsn</span>
              </div>
            ) : (<></>)}
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4  sm:text-left"
            >
              Price 
            </div>

            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="w-full sm:w-1/4 text-center sm:text-center   "
            >
              Quantity
            </div>
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4 text-right 2xl:text-right   "
            >
              Total
            </div>
          </div>
        </div>
        {Invoice?.product?.map((product: any, index: number) => {
          return (
            <div className='sm:px-8'>
            <div
            key={`${index}_${product.id}`}
            className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row  xl:flex-row flex-col rounded-lg w-full   px-4 sm:px-3  py-1 items-center relative text-sm"
          >
            <div
              style={{
                fontFamily: selectedFont,
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4 md:w-1/6 lg:w-2/12 xl:w-1/12 2xl:w-1/12 text-left flex flex-row sm:block"
            >
              <span
                style={{
                  marginBottom: "5px",
                }}
                className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
              >
                S No
              </span>
              <span className="inline-block w-full  product mb-1 sm:mb-0 sm:pl-4 ">
                <input
                  readOnly
                  value={index + 1}
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 400,
                  }}
                  className="font-title block w-full sm:text-left text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                />
              </span>

            </div>
            <div
              style={{
                fontFamily: selectedFont,
                fontWeight: 700,
              }}
              className=" w-full  sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left  flex flex-row  "
            >
              <span
                style={{
                  marginBottom: "5px",
                }}
                className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
              >
                Product
              </span>
              <span className="inline-block w-full product  mb-1 sm:mb-0">
                <input
                  readOnly
                  value={product.prodcut}
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 400,
                  }}
                  className="font-title  block w-full sm:text-left  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                />
              </span>
            </div>

            {userdata?.hsnNo ? (
               <div
               style={{
                 fontFamily: selectedFont,
                 fontWeight: 700,
               }}
               className=" w-full sm:w-1/4 text-left lg:pr-4 flex flex-row   "
             >
               <span
                 style={{
                   marginBottom: "5px",
                 }}
                 className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
               >
                 Hsn
               </span>
               <span className="inline-block w-full  sm:w-full mb-1 sm:mb-0 ">
                 <input
                   readOnly
                   style={{
                     fontFamily: selectedFont,
                     fontWeight: 400,
                   }}
                   value={product.hsn ? product.hsn : "N/A"}
                   className="font-title block w-full sm:text-left text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                 />
               </span>
             </div>
              ) : (<></>)}

            <div
              style={{
                fontFamily: selectedFont,
                fontWeight: 700,
              }}
              className="w-full sm:w-1/4  sm:text-left flex flex-row  "
            >
              <span
                style={{
                  marginBottom: "5px",
                }}
                className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
              >
                Price
              </span>
              <span className="inline-block  w-full   mb-1 sm:mb-0 ">
                <input
                  style={{
                    fontFamily: selectedFont,
                    marginTop: "",
                    fontWeight: 400,
                  }}
                  readOnly
                  value={product?.rate}
                  type={"number"}
                  className="font-title  block w-full  sm:text-left text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                />
              </span>
            </div>

            <>
              <div
                style={{
                  fontFamily: selectedFont,
                  fontWeight: 700,
                }}  
                className=" w-full sm:w-1/4 text-center sm:text-center  flex flex-row   "
              >
                <span
                  style={{
                    marginBottom: "5px",
                  }}
                  className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  Quantity
                </span>
                <span className="inline-block  w-full mb-1 sm:mb-0">
                  <input
                    readOnly
                    value={product.qty}
                    type={"number"}
                    style={{
                      fontFamily: selectedFont,
                      marginTop: "",
                      fontWeight: 400,
                    }}
                    className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
              </div>
            </>

            <>
              <div
                style={{
                  fontFamily: selectedFont,
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-right flex flex-row "
              >
                <span
                  style={{
                    marginBottom: "5px",
                  }}
                  className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  Total
                </span>
                <span className="font-block w-full sm:w-full  md:w-full text-xs  sm:text-sm lg:w-full ">
                  <NumberFormat value={product.amount}></NumberFormat>
                </span>
              </div>
            </>
          </div>
          </div>
          );
        })}

        {/********************************** * subtotal *******************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Subtotal
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-block w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={Invoice.subtotal}

            //   displayType={"text"}
            //   thousandSeparator={true}
            />
          </div>
        </div>
        {/* ******************************cgstPercentage**************/}

        {Invoice?.tax
          ?.sort((a: any, b: any) => {
            const order = ['Discount', 'CGST', 'SGST', 'IGST', 'OTHERS'];
            return order.indexOf(a.taxName) - order.indexOf(b.taxName);
          })
          .map((tax: any, index: number) => {
            return (
              <div key={index}>
                {/* Render each tax item with specific details */}
                <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="font-title w-[60%] sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                  >
                    {tax.taxName}
                    {tax.taxName !== "Extra Fees" ? (
                      <span className="ml-2">@</span>
                    ) : (
                      ""
                    )}
                    {tax.taxName !== "Extra Fees" ? tax.taxPersent : ""}
                    {tax.taxName !== "Extra Fees" ? <span>%</span> : ""}
                  </div>

                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                  >
                    <NumberFormat value={tax.taxAmount} />
                  </div>
                </div>

                {/* Similarly render other tax details */}
                {/* Example: SCGST, IGST, vat, OTHERS, extrafees, DISCOUNT */}
              </div>
            );
          })}
                 {roundOffEnable == true && (
          <div
            className={
              "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
            }
          >
            <div
              className={
                "w-full sm:w-1/2 px-4 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                }
              >
                Round Off
              </div>
              
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "font-title text-lg w-1/2 text-right sm:pr-7 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                }
              >
                <NumberFormat value={Invoice.roundOff} />
              </div>
            </div>
          </div>
        )}
        {/*************************total****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Total
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={Invoice.toatalAmount}
            />
          </div>
        </div>

        {/*************************AMOUNT IN WORDS****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-full text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 capitalize"
          >
            {totalAmountInWords} 

          </div>


        </div>

        {/************************************* bank details *********************/}
        {Invoice?.bankNme ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5"
              style={{
                color: "#008065",
                fontFamily: selectedFont,

                fontWeight: 700,
              }}
            >
              <label className="block my-2 2xl:ml-3 xl:ml-3 md:ml-5  text-sm font-semibold text-(#008065)">
                BANK DETAILS:
              </label>
            </div>
            <div className=" 2xl:ml-5   2xl:mr-5  xl:ml-5   xl:mr-5  lg:ml-5   lg:mr-5 md:ml-5   md:mr-5">
              <div className="text md:flex  md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    BankName:
                  </label>
                </div>

                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-base focus:outline-none "
                    type="text"
                    value={Invoice?.bankNme}
                  />
                </div>
              </div>

              <div className="text md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Account HolderName:
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-base focus:outline-none "
                    type="text"
                    value={Invoice?.accounHolder}
                  />
                </div>
              </div>

              <div className="text md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Account Number:
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow ">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-base     focus:outline-none"
                    type="text"
                    value={Invoice?.accounNumber}
                  />
                </div>
              </div>
              <div className="text md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    IFSC code:
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-base      focus:outline-none "
                    type="text"
                    value={Invoice.ifscCode}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {/***********************************UPI PAY*****************************************/}
        {Invoice.upiType ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5"
              style={{
                color: "#008065",
                fontFamily: selectedFont,

                fontWeight: 700,
              }}
            >
              <label className="block my-2 2xl:ml-3 xl:ml-3 md:ml-5  text-sm font-semibold text-(#008065)">
                UPI PAYMENT:
              </label>
            </div>
            <div className="2xl:ml-5   2xl:mr-5  xl:ml-5   xl:mr-5  lg:ml-5   lg:mr-5 md:ml-5   md:mr-5 ">
              <div className="text md:flex md:items-center mx-6  ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    UPI PAY
                  </label>
                </div>

                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 focus:outline-none "
                    value={Invoice.upiType}
                  />
                </div>
              </div>

              <div className=" md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    UPI NUMBER
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 focus:outline-none "
                    value={Invoice.upiNumber}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {/**************************  term and condition ******************/}
        {Invoice.termsanddcon ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5 "
              style={{
                color: "#008065",
                fontFamily: selectedFont,

                fontWeight: 700,
              }}
            >
              <label className=" 2xl:ml-2 xl:ml-3 lg:ml-3 md:ml-3 text-base text-#008065 font-semibold">
                Terms and Conditions:
              </label>
            </div>
            <div className="2xl:ml-8 xl:ml-8 lg:ml-8 md:ml-8 ml-8 ">
              <p className="sm:text-wrap lg:text-wrap md:text-wrap text-sm break-words break-all mr-4">

                {Invoice.termsanddcon}
              </p>
            </div>
          </>
        ) : null}

        {/********************* notes  ***************************** */}

        {Invoice.notes ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5 "
              style={{
                color: "#008065",
                fontFamily: selectedFont,

                fontWeight: 700,
              }}
            >
              <label className=" 2xl:ml-2 xl:ml-3 lg:ml-3 md:ml-3 text-base text-#008065 font-semibold">
                Notes:
              </label>
            </div>
            <div className="2xl:ml-8 xl:ml-8 lg:ml-8 md:ml-8 ml-8 ">
              <p className="sm:text-wrap lg:text-wrap md:text-wrap text-sm break-words break-all mr-4">

                {Invoice.notes}
              </p>
            </div>
          </>
        ) : null}

        {/********************************signature*******************************/}
        <div className="flex  sm:mx-4">
          <div className="p-2.5  w-full  text-sm focus:outline-none mt-2 ">
            <div className=" 2xl:flex xl:flex lg:flex md:flex sm:block  justify-between  ">
              <div
                className={
                  Invoice?.signPostion === "R"
                    ? "lg:order-2 xl:order-2 2xl:order-2 md:order-2 flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 w-1/4 2xl:w-1/3 items-center justify-center w-full h-40 rounded-lg mt-2  "
                    : "flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center justify-center w-full h-32  rounded-lg mt-2"
                }
              >
                {Invoice?.signature && (
                  <p className="font-semibold text-[#008065] pt-2">
                    Signature:
                  </p>
                )}
                <div className="relative">
                  {Invoice?.signature ? (
                    <>
                      <img
                        className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                        alt="sign"
                        src={
                          Invoice?.signature
                            ? Invoice?.signature
                            : Invoice?.signature
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className={
                  Invoice?.sealPostion === "L"
                    ? "lg:order-1 xl:order-1 2xl:order-1 md:order-1 flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/4 2xl:w-1/3 items-center justify-center w-full h-40  mt-2"
                    : " flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center justify-center w-full h-32 mt-2 "
                }
              >
                {Invoice?.seal && (
                  <p className="font-semibold text-[#008065] pt-2">Seal:</p>
                )}
                <div className="relative">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="object-cover  pb-2 flex flex-col items-center px-4    "
                  >
                    {Invoice?.seal ? (
                      <>
                        <img
                          className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                          alt="sign"
                          src={Invoice?.seal ? Invoice?.seal : Invoice?.seal}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/********************************Cancel*********************************/}
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            // onClick={() => setOpenView(!viewOpen)}
            // ref={cancelButtonRef}
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default PreviewPurchaseBillwise
