export const EXPENSE_ITEM_POST_REQUEST = 'EXPENSE_ITEM_POST_REQUEST';
export const EXPENSE_ITEM_POST_SUCCESS = 'EXPENSE_ITEM_POST_SUCCESS';
export const EXPENSE_ITEM_POST_FAIL = 'EXPENSE_ITEM_POST_FAIL';
export const EXPENSE_ITEM_POST_RESET = 'EXPENSE_ITEM_POST_RESET';

export const EXPENSE_ITEM_GET_REQUEST = 'EXPENSE_ITEM_GET_REQUEST';
export const EXPENSE_ITEM_GET_SUCCESS = 'EXPENSE_ITEM_GET_SUCCESS';
export const EXPENSE_ITEM_GET_FAIL = 'EXPENSE_ITEM_GET_FAIL';
export const EXPENSE_ITEM_GET_RESET = 'EXPENSE_ITEM_GET_RESET';

export const EXPENSE_ITEM_GET_ONE_REQUEST = 'EXPENSE_ITEM_GET_ONE_REQUEST';
export const EXPENSE_ITEM_GET_ONE_SUCCESS = 'EXPENSE_ITEM_GET_ONE_SUCCESS';
export const EXPENSE_ITEM_GET_ONE_FAIL = 'EXPENSE_ITEM_GET_ONE_FAIL';
export const EXPENSE_ITEM_GET_ONE_RESET = 'EXPENSE_ITEM_GET_ONE_RESET';

export const EXPENSE_ITEM_UPDATE_REQUEST = 'EXPENSE_ITEM_UPDATE_REQUEST';
export const EXPENSE_ITEM_UPDATE_SUCCESS = 'EXPENSE_ITEM_UPDATE_SUCCESS';
export const EXPENSE_ITEM_UPDATE_FAIL = 'EXPENSE_ITEM_UPDATE_FAIL';
export const EXPENSE_ITEM_UPDATE_RESET = 'EXPENSE_ITEM_UPDATE_RESET';

export const EXPENSE_ITEM_DELETE_REQUEST = 'EXPENSE_ITEM_DELETE_REQUEST';
export const EXPENSE_ITEM_DELETE_SUCCESS = 'EXPENSE_ITEM_DELETE_SUCCESS';
export const EXPENSE_ITEM_DELETE_FAIL = 'EXPENSE_ITEM_DELETE_FAIL';
export const EXPENSE_ITEM_DELETE_RESET = 'EXPENSE_ITEM_DELETE_RESET';