import { useEffect, useState } from 'react'
import Navbar from '../Header/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import Header from '../../pages/components/Header';
import { useLocation } from 'react-router-dom';
import SideBarHeader from '../../pages/components/SideBarHeader';

export default function Dashboard(props: any) {
    const { children } = props
    const location = useLocation();
    const pathname = location.pathname;
    const [isSidebarOpen, setSidebarOpen] = useState(false)
    const [subMenuList, setsubMenuList] = useState(false)
    const userId = sessionStorage.getItem("userInfo");
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen)
    }
    const closeSideBar = () => {
        setSidebarOpen(true);
    }
    const openSideBar = () => {
        setSidebarOpen(false);
    }
    const toggleDropdown = () => {
        setsubMenuList(!subMenuList)
    }
    const closeMenu = () => {
        if (subMenuList) {
            setsubMenuList(!subMenuList)
        }
    }
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 768px)").matches
    );

    useEffect(() => {
        window
            .matchMedia("(max-width: 768px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, [matches]);

    return (
        <>
            <div>{(userId && location.pathname != '/app/pos') ?
                (<> <Sidebar
                    isOpen={isSidebarOpen}
                    setsubMenuList={setsubMenuList}
                    subMenuList={subMenuList}
                    toggleDropdown={toggleDropdown}
                    toggleSidebar={toggleSidebar}
                    closeSideBar={closeSideBar}
                    openSideBar={openSideBar}
                />
                    <div className={`flex-1  ${isSidebarOpen && !pathname.includes('invoice') ? 'ml-6 lg:ml-12' :
                        isSidebarOpen && pathname.includes('invoice') ? 'ml-2 lg:ml-12' : 'md:ml-12 lg:ml-64 test'
                        }`}>
                        <span onClick={closeMenu}>
                            <SideBarHeader toggleSidebar={toggleSidebar} isOpen={isSidebarOpen} />
                        </span>
                        <span onClick={closeMenu}>{children}</span>
                    </div>

                </>) :
                (<>
                    {location.pathname === '/'
                        || location.pathname === '/free-freelancer-invoice.html'
                        || location.pathname === '/free-hourly-rate-invoice.html'
                        || location.pathname === '/free-photography-invoice.html'
                        || location.pathname === '/free-consulting-invoice.html'
                        || location.pathname === '/free-graphic-design-invoice.html'
                        || location.pathname === '/free-gst-invoice.html'
                        || location.pathname === '/free-proforma-invoice.html'
                        || location.pathname === '/free-quotation-invoice.html'
                        || location.pathname === '/free-medical-invoice.html'
                        || location.pathname === '/free-delivery-note-invoice.html'
                        || location.pathname === '/free-blank-invoice.html'
                        || location.pathname === '/free-auto-service-invoice.html'
                        || location.pathname === '/free-bill-free-invoice.html'
                        || location.pathname === '/free-commercial-invoice.html'
                        || location.pathname === '/free-contractor-invoice.html'
                        || location.pathname === '/free-credit-notes-invoice.html'
                        || location.pathname === '/free-debit-notes-invoice.html'
                        || location.pathname === '/free-delivery-challan-invoice.html'
                        || location.pathname === '/free-e-invoice.html'
                        || location.pathname === '/free-invoice-generator.html'
                        || location.pathname === '/free-quotation-estimate-invoice.html'
                        || location.pathname === '/free-payment-voucher-invoice.html'
                        || location.pathname === '/free-receipt-voucher-invoice.html'
                        || location.pathname === '/free-refund-voucher-invoice.html'
                        || location.pathname === '/free-service-invoice.html'
                        || location.pathname === '/free-sales-invoice.html'
                        || location.pathname === '/free-tax-invoice.html'
                        || location.pathname === '/free-teaching-invoice.html'
                        || location.pathname === '/free-textiles-invoice.html'
                        || location.pathname === '/free-timesheet-invoice.html'
                        || location.pathname === '/free-welding-invoice.html'
                        || location.pathname === '/free-electronics-shops-invoice.html'
                        || location.pathname === '/free-retail-stores-invoice.html'
                        || location.pathname === '/free-electrical-shops-invoice.html'
                        || location.pathname === '/free-electrician-invoice.html'
                        || location.pathname === '/free-architectural-invoice.html'
                        || location.pathname === '/free-farm-invoice.html'
                        || location.pathname === '/free-architect-invoice.html'
                        || location.pathname === '/free-repair-invoice.html'
                        || location.pathname === '/free-transportation-invoice.html' 
                        || location.pathname === '/free-retailstores-invoice.html'
                        || location.pathname === '/free-recruitment-invoice.html'
                        || location.pathname === '/free-tuition-invoice.html'
                        || location.pathname === '/free-donation-receipt-invoice.html'
                        || location.pathname === '/free-maintenance-invoice.html'
                        || location.pathname === '/free-cargo-invoice.html'
                        || location.pathname === '/free-finance-invoice.html'
                        || location.pathname === '/free-stylish-invoice.html'
                        || location.pathname === '/free-repair-estimate-invoice.html'
                        || location.pathname === '/free-veterinary-invoice.html'
                        || location.pathname === '/free-broker-invoice.html'
                        || location.pathname === '/free-musician-invoice.html'
                        || location.pathname === '/free-marketing-invoice.html'
                        || location.pathname === '/free-maid-service-invoice.html'
                        || location.pathname === '/free-jewellery-invoice.html'
                        || location.pathname === '/free-mobile-shops-invoice.html'
                        || location.pathname === '/free-auto-repair-shops-invoice.html'
                        || location.pathname === '/free-saloon-invoice.html'
                        || location.pathname === '/free-bakery-invoice.html'
                        || location.pathname === '/free-plumbing-invoice.html'
                        || location.pathname === '/free-catering-invoice.html'
                        || location.pathname === '/free-studio-invoice.html'
                        || location.pathname === '/free-videography-invoice.html'
                        || location.pathname === '/free-psycologist-invoice.html'
                        || location.pathname === '/free-accommadation-invoice.html' 
                        || location.pathname === '/free-taxi-invoice.html'
                        || location.pathname === '/free-supplier-invoice.html'
                        || location.pathname === '/free-cleaning-invoice.html'
                        || location.pathname === '/free-dental-invoice.html'
                        || location.pathname === '/free-wood-working-invoice.html'
                        || location.pathname === '/free-painting-invoice.html'
                        || location.pathname === '/free-home-appliances-invoice.html'
                        || location.pathname === '/free-ware-houses-invoice.html'
                        || location.pathname === '/free-household-goods-invoice.html'
                        || location.pathname === '/free-oil-mill-invoice.html'
                        || location.pathname === '/free-clubs-canteen-service-invoice.html'
                        || location.pathname === '/free-courier-service-invoice.html'
                        || location.pathname === '/free-petroleum-products-invoice.html'
                        || location.pathname === '/free-embroidery-work-invoice.html'
                        || location.pathname === '/free-flour-mills-invoice.html'
                        || location.pathname === '/free-footwear-invoice.html'
                        || location.pathname === '/free-stationary-invoice.html'
                        || location.pathname === '/free-garment-manufacture-invoice.html'
                        || location.pathname === '/free-handloom-weaving-invoice.html'
                        || location.pathname === '/free-honey-gathering-invoice.html'
                        || location.pathname === '/free-packing-packaging-invoice.html'
                        || location.pathname === '/free-pickle-making-invoice.html'
                        || location.pathname === '/free-sand-mining-invoice.html'
                        || location.pathname === '/free-laundry-work-invoice.html'
                        || location.pathname === '/free-bill-generator.html'
                        || location.pathname === '/free-e-way-bill-invoice.html'
                        || location.pathname === '/free-Sole-trader-invoice.html'
                        || location.pathname === '/free-travel-tourism-invoice.html'
                        || location.pathname === '/free-saw-mill-work-invoice.html'
                        || location.pathname === '/free-book-shop-invoice.html'
                        || location.pathname === '/free-printing-press-work-invoice.html'
                        || location.pathname === '/free-rice-mill-invoice.html'
                        || location.pathname === '/free-gym-fitness-invoice.html'
                        || location.pathname === '/free-standard-invoice.html'
                        || location.pathname === '/free-super-market-invoice.html'
                        || location.pathname === '/free-opticals-shops-invoice.html'
                        || location.pathname === '/free-simple-invoice.html'
                        || location.pathname === '/free-construction-invoice.html'
                                              


                        

                        ? <Header /> : <Navbar />}
                    <span onClick={closeMenu}>{children}</span>
                </>
                )
            }
            </div>
        </>
    )
}
