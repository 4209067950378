/* eslint-disable react-hooks/rules-of-hooks */
import { createBrowserRouter } from "react-router-dom";
import AuthenticatedRoutes from "./Auth/AuthenticatedRoutes";
import PaymentOutTab from "./Tables/PaymentOutTab";
import PaymentReceiveTab from "./Tables/PaymentReceiveTab";
import PurchaseTableNew from "./Tables/PurchaseTableNew";
import SalesReturnTab from "./Tables/SalesReturnTab";
import SalesTabe from "./Tables/SalesTabe";
import Stock from "./Tables/Stock";
import Container from "./container/Container";
import ChangePassword from "./pages/Authentication/ChangePassword";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import LoginPage from "./pages/Authentication/LoginPage";
import MailVerification from "./pages/Authentication/MailVerification";
import OtpScreenPage from "./pages/Authentication/OtpScreenPage";
import RegisterPage from "./pages/Authentication/RegisterPage";
import VerifyCodePage from "./pages/Authentication/VerifyCodePage";
import ProformaInvoice from "./pages/Invoices/ProformaInvoice";
import ServiceTab from "./pages/Service/ServiceTab";
import AdminDashboard from "./pages/SuperAdmin/AdminDashboard";
import AdminPayments from "./pages/SuperAdmin/AdminPayments";
import ExternalInvoice from "./pages/SuperAdmin/ExternalInvoice";
import FeedbackSA from "./pages/SuperAdmin/FeedbackSA";
import Unit from "./pages/SuperAdmin/Unit";
import User from "./pages/SuperAdmin/User";
import Unauthorized from "./pages/commen/Unauthorized";
import AppSettingPage from "./pages/components/AppSettingPage";
import ClientPage from "./pages/components/ClientPage";
import CompanyPage from "./pages/components/CompanyPage";
import DashboardHome from "./pages/components/DashboardHome";
import DeliveryChallanPage from "./pages/components/DeliveryChallanPage";
import FeedbackPage from "./pages/components/FeedbackPage";
import HomePage from "./pages/components/HomePage";
import ProductTab from "./pages/components/InvoiceDetails/ProductTab";
import VendorTab from "./pages/components/InvoiceDetails/VendorTab";
import InvoicePage from "./pages/components/InvoicePage";
import NotificationPage from "./pages/components/NotificationPage";
import PaymentOutPage from "./pages/components/PaymentOutPage";
import PaymentReceivePage from "./pages/components/PaymentReceivePage";
import ProtectedRoute from "./pages/components/ProtectedRoute";
import PurchaseReturnTab from "./pages/components/PurchaseDetails/PurchaseReturnTab";
import PurchasePage from "./pages/components/PurchasePage";
import PurchaseReturn from "./pages/components/PurchaseReturn";
import Quotation from "./pages/components/Quotation";
import SalesReturn from "./pages/components/SalesReturn";
import ServiceSettingsPage from "./pages/components/ServiceSettingsPage";
import SignaturePage from "./pages/components/SignaturePage";
import UserDetails from "./pages/components/UserDetails";
import UserSettingsTab from "./pages/components/UserSettingsTab";
import QuotationTableReport from "./Tables/QuotationTableReport";
import DeliveryChallanTable from "./Tables/DeliveryChallanTable";
import ExpenseCategory from "./pages/components/ExpenseCategory";
import ExpensesPage from "./pages/components/ExpensesPage";
import ExpensePageTable from "./Tables/ExpensePageTable";
import Blankinvoice from "./pages/Invoices/Blankinvoice";
import Consultinginvoice from "./pages/Invoices/Consultinginvoice";
import Autoserviceinvoice from "./pages/Invoices/Autoserviceinvoice";
import Billfreeinvoice from "./pages/Invoices/Billfreeinvoice";
import Commericalinvoice from "./pages/Invoices/Commercialinvoice";
import Medicalinvoice from "./pages/Invoices/Medicalinvoice";
import Photographyinvoice from "./pages/Invoices/Photographyinvoice";
import Graphicdesigninvoice from "./pages/Invoices/Graphicdesigninvoice";
import Hourlyrateinvoice from "./pages/Invoices/Hourlyrateinvoice";
import Gstinvoice from "./pages/Invoices/Gstinvoice";
import Billgenerator from "./pages/Invoices/Billgenerator";
import Contractorinvoice from "./pages/Invoices/Contractorinvoice";
import Creditnotesinvoice from "./pages/Invoices/Creditnotesinvoice";
import Deliverynoteinvoice from "./pages/Invoices/Deliverynoteinvoice";
import Einvoice from "./pages/Invoices/Einvoice";
import Electricalshopinvoice from "./pages/Invoices/Electricalshopsinvoice";
import Gymfitnessinvoice from "./pages/Invoices/Gymfitnessinvoice";
import Invoicegenerator from "./pages/Invoices/Invoicegenerator";
import Paymentvoucherinvoice from "./pages/Invoices/Paymentvoucherinvoice";
import Quotationestimateinvoice from "./pages/Invoices/Quotationestimateinvoice";
import Receiptvoucherinvoice from "./pages/Invoices/Receiptvoucherinvoice";
import Refundvoucherinvoice from "./pages/Invoices/Refundvoucherinvoice";
import Salesinvoice from "./pages/Invoices/Salesinvoice";
import Servicesinvoice from "./pages/Invoices/Serviceinvoice";
import Soletraderinvoice from "./pages/Invoices/Soletraderinvoice";
import Taxinvoice from "./pages/Invoices/Taxinvoice";
import Teachinginvoice from "./pages/Invoices/Teachinginvoice";
import Textilesinvoice from "./pages/Invoices/Textilesinvoice";
import Timesheetinvoice from "./pages/Invoices/Timesheetinvoice";
import Weldinginvoice from "./pages/Invoices/Weldinginvoice";
import Freelancerinvoice from "./pages/Invoices/Freelancerinvoice";
import Debitnotesinvoice from "./pages/Invoices/Debitnotesinvoice";
import Electronicsshopsinvoice from "./pages/Invoices/Electronicsshopsinvoice";
import Retailstoreinvoice from "./pages/Invoices/Retailstoreinvoice";
import Deliverychallaninvoice from "./pages/Invoices/Deliverychallaninvoice";
import Electricalinvoice from "./pages/Invoices/Electricianinvoice";
import Aruchitecturalinvoice from "./pages/Invoices/Aruchitecturalinvoice";
import Farminvoice from "./pages/Invoices/Farminvoice";
import Aruchitectinvoice from "./pages/Invoices/Aruchitectinvoice";
import Repairinvoice from "./pages/Invoices/Repairinvoice";
import Transporatationinvoice from "./pages/Invoices/Transporatationinvoice";
import Recuritmentinvoice from "./pages/Invoices/Recuritmentinvoice";
import Tuitioninvoice from "./pages/Invoices/Tuitioninvoice";
import Donationreceiptinvoice from "./pages/Invoices/Donationreceiptinvoice";
import Maintenanceinvoice from "./pages/Invoices/Maintenanceinvoice";
import Cargoinvoice from "./pages/Invoices/Cargoinvoice";
import Financeinvoice from "./pages/Invoices/Financeinvoice";
import Stylshinvoice from "./pages/Invoices/Stylishinvoice";
import Repairestimateinvoice from "./pages/Invoices/Repairestimateinvoice";
import Verterinaryinvoice from "./pages/Invoices/Verterinaryinvoice";
import Brokerinvoice from "./pages/Invoices/Brokerinvoice";
import Musicianinvoice from "./pages/Invoices/Musicianinvoice";
import Marketinginvoice from "./pages/Invoices/Marketinginvoice";
import Maidservicesinvoice from "./pages/Invoices/Maidservicesinvoice";
import Jewelleryinvoice from "./pages/Invoices/Jewelleryinvoice";
import Opticalshopinvoice from "./pages/Invoices/Opticalshopinvoice";
import Mobileshopinvoice from "./pages/Invoices/Mobileshopinvoice";
import Autorepairsmechanic from "./pages/Invoices/Autorepairsmechanic";
import Salooninvoice from "./pages/Invoices/Salooninvoice";
import Bakeryinvoice from "./pages/Invoices/Bakeryinvoice";
import Plumbinginvoice from "./pages/Invoices/Plumbinginvoice";
import Cateringinvoice from "./pages/Invoices/Cateringinvoice";
import Studioinvoice from "./pages/Invoices/Studioinvoice";
import Videographyinvoice from "./pages/Invoices/Videographyinvoice";
import Psycologistinvoice from "./pages/Invoices/Psycologistinvoice";
import Accommadationinvoice from "./pages/Invoices/Accommadationinvoice";
import Supplierinvoice from "./pages/Invoices/SupplierInvoice";
import Cleaninginvoice from "./pages/Invoices/Cleaninginvoice";
import Dentalinvoice from "./pages/Invoices/Dentalinvoice";
import Woodworkinginvoice from "./pages/Invoices/Woodworkinginvoice";
import Paintinginvoice from "./pages/Invoices/Paintinginvoice";
import HomeAppliancesinvoice from "./pages/Invoices/HomeAppliancesinvoice";
import WareHousesinvoice from "./pages/Invoices/WareHousesinvoice";
import OilMillInvoice from "./pages/Invoices/OilMillInvoice";
import SawMillworkinvoice from "./pages/Invoices/SawMillworkinvoice";
import ClubsCanteenservicesinvoice from "./pages/Invoices/ClubsCanteenservicesInvoice";
import Couriesservicesinvoice from "./pages/Invoices/CouriesserviceInvoice";
import PetroleumProductinvoice from "./pages/Invoices/PetroleumProductsInvoice";
import EmbroiderWorkinvoice from "./pages/Invoices/EmbroideryWorkinvoice";
import FlourMillsinvoice from "./pages/Invoices/FlourMillsInvoice";
import Footwearinvoice from "./pages/Invoices/Footwearinvoice";
import Stationaryinvoice from "./pages/Invoices/Stationaryinvoice";
import GarmentManfactureInvoice from "./pages/Invoices/GarmentManufactureInvoice";
import HandloomWeavinginvoice from "./pages/Invoices/HandloomWeavinginvoice";
import HoneyGatheringInvoice from "./pages/Invoices/HoneyGatheringInvoice";
import PickleMakingInvoice from "./pages/Invoices/PickleMakingInvoice";
import PackingPackagingInvoice from "./pages/Invoices/PackingPackagingInvoice";
import PrintingPressWorkInvoice from "./pages/Invoices/PrintingPressWorkInvoice";
import SandMiningInvoice from "./pages/Invoices/SandMiningInvoice";
import LaundryWorkInvoice from "./pages/Invoices/LaundryWorkInvoice ";
import Ewaybillinvoice from "./pages/Invoices/Ewaybillinvoice";
import Traveltourisminvoice from "./pages/Invoices/Traveltourisminvoice";
import BookShopinvoice from "./pages/Invoices/BookShopinvoice";
import RiceMilliInvoice from "./pages/Invoices/RiceMillInvoice";
import Starndardinvoice from "./pages/Invoices/Standardinvoice";
import Supremarketinvoice from "./pages/Invoices/Supermarketinvoice";
import HouseholdGoodsinvoice from "./pages/Invoices/HouseholdGoodsinvoice";
import ConstructionInvoice from "./pages/Invoices/ConstructionInvoice";
import SimpleInvoice from "./pages/Invoices/SimpleInvoice";
import Taxiinvoice from "./pages/Invoices/Taxiinvoice";
import CashInHand from "./pages/components/CashInHand";
import Cheques from "./pages/components/Cheques";
import FixedAssets from "./pages/components/FixedAssets";
import CashInHandTab from "./Tables/CashInHandTab";
import FixedAssetsReport from "./Tables/FixedAssetsReport";
import PurchaseFixedAssetReport from "./pages/components/PurchaseFixedAssetReport";
import PurchaseFixedAssetPage from "./pages/components/PurchaseFixedAssetPage";
import SalesFixedAssetTab from "./Tables/SalesFixedAssetTab";
import SalesFixedAssetPage from "./pages/components/SalesFixedAssetPage";
import UPIPaymentPage from "./pages/components/UPIPaymentPage";
import Reports from "./pages/components/InvoiceDetails/Reports";
import ClientsTab from "./Tables/ClientsTab";
import ServiceSettings from "./pages/Service/ServiceSettings";
import ProductPage from "./pages/components/ProductPage";
// import ProductPage from "./pages/components/ProductPage";
import ExpenseCategoryTab from "./Tables/ExpenseCategoryTab";
import BankTab from "./Tables/BankTab";
import BankPage from "./pages/components/BankPage";
import UPIPaymentTab from "./Tables/UPIPaymentTab";
import ExpenseItem from "./pages/components/ExpenseItem";
import ExpenseItemReport from "./Tables/ExpenseItemReport";
import Pos from "./pages/components/Pos";
import BarCode from "./pages/components/BarCode";
import Contact from "./pages/SuperAdmin/Contact";



const routes = createBrowserRouter([
  {
    path: "/",
    element: <Container />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/:id",
        element: <HomePage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/register",
        element: <RegisterPage />,
      },
      {
        path: "/forgetPassword",
        element: <ForgetPassword />,
      },
      {
        path: "/changePassword",
        element: <ChangePassword />,
      },
      {
        path: "/OtpScreenPage",
        element: <OtpScreenPage />,
      },
      {
        path: "/registration/:id",
        element: <MailVerification />,
      },
      {
        path: "/verify",
        element: <VerifyCodePage />,
      },
      {
        path: "/free-proforma-invoice.html",
        element: <ProformaInvoice />,
      },
      {
        path: "/free-freelancer-invoice.html",
        element: <Freelancerinvoice />,
      },
      {
        path: "/free-blank-invoice.html",
        element: <Blankinvoice />,
      },
      {
        path: "/free-consulting-invoice.html",
        element: <Consultinginvoice />,
      },
      {
        path: "/free-auto-service-invoice.html",
        element: <Autoserviceinvoice />,
      },
      {
        path: "/free-book-shop-invoice.html",
        element: <BookShopinvoice />,
      },
      {
        path: "/free-bill-free-invoice.html",
        element: <Billfreeinvoice />,
      },
      {
        path: "/free-commercial-invoice.html",
        element: <Commericalinvoice />,
      },
      {
        path: "/free-medical-invoice.html",
        element: <Medicalinvoice />,
      },
      {
        path: "/free-photography-invoice.html",
        element: <Photographyinvoice />,
      },
      {
        path: "/free-graphic-design-invoice.html",
        element: <Graphicdesigninvoice />,
      },
      {
        path: "free-hourly-rate-invoice.html",
        element: <Hourlyrateinvoice />,
      },
      {
        path: "/free-gst-invoice.html",
        element: <Gstinvoice />,
      },
      {
        path: "/free-bill-generator.html",
        element: <Billgenerator />,
      },
      {
        path: "/free-contractor-invoice.html",
        element: <Contractorinvoice />,
      },
      {
        path: "/free-travel-tourism-invoice.html",
        element: <Traveltourisminvoice />,
      },
      {
        path: "/free-super-market-invoice.html",
        element: <Supremarketinvoice />,
      },
      {
        path: "free-credit-notes-invoice.html",
        element: <Creditnotesinvoice />,
      },
      {
        path: "free-debit-notes-invoice.html",
        element: <Debitnotesinvoice />,
      },
      {
        path: "/free-delivery-note-invoice.html",
        element: <Deliverynoteinvoice />,
      },
      {
        path: "/free-delivery-challan-invoice.html",
        element: <Deliverychallaninvoice />,
      },

      {
        path: "/free-e-invoice.html",

        element: <Einvoice />,
      },
      {
        path: "/free-gst-invoice.html",
        element: <Electricalshopinvoice />,
      },
      {
        path: "/free-e-way-bill-invoice.html",
        element: <Ewaybillinvoice />,
      },
      {
        path: "/free-gym-fitness-invoice.html",
        element: <Gymfitnessinvoice />,
      },
      {
        path: "/free-household-goods-invoice.html",
        element: < HouseholdGoodsinvoice />,
      },
      {
        path: "/free-hourly-rate-invoice.html",
        element: <Hourlyrateinvoice />,
      },
      {
        path: "/free-invoice-generator.html",
        element: <Invoicegenerator />,
      },
      {
        path: "/free-payment-voucher-invoice.html",
        element: <Paymentvoucherinvoice />,
      },
      {
        path: "/free-quotation-estimate-invoice.html",
        element: <Quotationestimateinvoice />,
      },
      {
        path: "/free-receipt-voucher-invoice.html",
        element: <Receiptvoucherinvoice />,
      },
      {
        path: "/free-rice-mill-invoice.html",
        element: <RiceMilliInvoice />,
      },
      {
        path: "/free-refund-voucher-invoice.html",
        element: <Refundvoucherinvoice />,
      },
      {
        path: "/free-sales-invoice.html",
        element: <Salesinvoice />,
      },
      {
        path: "/free-service-invoice.html",
        element: <Servicesinvoice />,
      },
      {
        path: "/free-sole-trader-invoice.html",
        element: <Soletraderinvoice />,
      },
      {
        path: "/free-tax-invoice.html",
        element: <Taxinvoice />,
      },
      {
        path: "/free-teaching-invoice.html",
        element: <Teachinginvoice />,
      },
      {
        path: "/free-textiles-invoice.html",
        element: <Textilesinvoice />,
      },
      {
        path: "/free-timesheet-invoice.html",
        element: <Timesheetinvoice />,
      },
      {
        path: "/free-welding-invoice.html",
        element: <Weldinginvoice />,
      },
      {
        path: "/free-electronics-shops-invoice.html",
        element: <Electronicsshopsinvoice />,
      },
      {
        path: "/free-retail-stores-invoice.html",
        element: <Retailstoreinvoice />,
      },
      {
        path: "/free-electrical-shops-invoice.html",
        element: <Electricalshopinvoice />,
      },
      {
        path: "/free-electrician-invoice.html",
        element: <Electricalinvoice />,
      },
      {
        path: "/free-architectural-invoice.html",
        element: <Aruchitecturalinvoice />,
      },
      {
        path: "/free-farm-invoice.html",
        element: <Farminvoice />,
      },
      {
        path: "/free-construction-invoice.html",
        element: <ConstructionInvoice />,
      },
      {
        path: "/free-footwear-invoice.html",
        element: <Footwearinvoice />,
      },
      {
        path: "/free-architect-invoice.html",
        element: <Aruchitectinvoice />,
      },
      {
        path: "/free-repair-invoice.html",
        element: <Repairinvoice />,
      },

      {
        path: "/free-transportation-invoice.html",
        element: <Transporatationinvoice />,
      },
      {
        path: "/free-recruitment-invoice.html",
        element: <Recuritmentinvoice />,
      },
      {
        path: "/free-tuition-invoice.html",
        element: <Tuitioninvoice />,
      },
      {
        path: "/free-donation-receipt-invoice.html",
        element: <Donationreceiptinvoice />,
      },
      {
        path: "/free-maintenance-invoice.html",
        element: <Maintenanceinvoice />,
      },
      {
        path: "/free-cargo-invoice.html",
        element: <Cargoinvoice />,
      },
      {
        path: "/free-finance-invoice.html",
        element: <Financeinvoice />,
      },
      {
        path: "/free-stylish-invoice.html",
        element: <Stylshinvoice />,
      },
      {
        path: "/free-simple-invoice.html",
        element: <SimpleInvoice />,
      },
      {
        path: "/free-repair-estimate-invoice.html",
        element: <Repairestimateinvoice />,
      },
      {
        path: "/free-veterinary-invoice.html",
        element: <Verterinaryinvoice />,
      },
      {
        path: "/free-broker-invoice.html",
        element: <Brokerinvoice />,
      },
      {
        path: "/free-musician-invoice.html",
        element: <Musicianinvoice />,
      },
      {
        path: "/free-marketing-invoice.html",
        element: <Marketinginvoice />,
      },
      {
        path: "/free-maid-service-invoice.html",
        element: <Maidservicesinvoice />,
      },
      {
        path: "/free-jewellery-invoice.html",
        element: <Jewelleryinvoice />,
      },
      {
        path: "/free-mobile-shops-invoice.html",
        element: <Mobileshopinvoice />,
      },
      {
        path: "/free-opticals-shops-invoice.html",
        element: <Opticalshopinvoice />,
      },
      {
        path: "/free-auto-repair-shops-invoice.html",
        element: <Autorepairsmechanic />,
      },
      {
        path: "/free-saloon-invoice.html",
        element: <Salooninvoice />,
      },
      {
        path: "/free-bakery-invoice.html",
        element: <Bakeryinvoice />,
      },
      {
        path: "/free-plumbing-invoice.html",
        element: <Plumbinginvoice />,
      },
      {
        path: "/free-catering-invoice.html",
        element: <Cateringinvoice />,
      },
      {
        path: "/free-studio-invoice.html",
        element: <Studioinvoice />,
      },
      {
        path: "/free-videography-invoice.html",
        element: <Videographyinvoice />,
      },
      {
        path: "/free-psycologist-invoice.html",
        element: <Psycologistinvoice />,
      },
      {
        path: "/free-accommadation-invoice.html",
        element: <Accommadationinvoice />,
      },
      {
        path: "/free-taxi-invoice.html",
        element: <Taxiinvoice />,
      },
      {
        path: "/free-supplier-invoice.html",
        element: <Supplierinvoice />,
      },
      {
        path: "/free-cleaning-invoice.html",
        element: <Cleaninginvoice />,
      },
      {
        path: "/free-dental-invoice.html",
        element: <Dentalinvoice />,
      },
      {
        path: "/free-wood-working-invoice.html",
        element: <Woodworkinginvoice />,
      },
      {
        path: "/free-painting-invoice.html",
        element: <Paintinginvoice />,
      },
      {
        path: "/free-home-appliances-invoice.html",
        element: <HomeAppliancesinvoice />,
      },
      {
        path: "/free-ware-houses-invoice.html",
        element: <WareHousesinvoice />,
      },
      {
        path: "/free-oil-mill-invoice.html",
        element: <OilMillInvoice />,
      },
      {
        path: "/free-saw-mill-work-invoice.html",
        element: <SawMillworkinvoice />,
      },
      {
        path: "/free-clubs-canteen-service-invoice.html",
        element: <ClubsCanteenservicesinvoice />,
      },
      {
        path: "/free-courier-service-invoice.html",
        element: <Couriesservicesinvoice />,
      },
      {
        path: "/free-petroleum-products-invoice.html",
        element: <PetroleumProductinvoice />,
      },
      {
        path: "/free-embroidery-work-invoice.html",
        element: <EmbroiderWorkinvoice />,
      },
      {
        path: "/free-flour-mills-invoice.html",
        element: <FlourMillsinvoice />,
      },
      {
        path: "/free-stationary-invoice.html",
        element: <Stationaryinvoice />,
      },
      {
        path: "/free-standard-invoice.html",
        element: <Starndardinvoice />,
      },
      {
        path: "/free-garment-manufacture-invoice.html",
        element: <GarmentManfactureInvoice />,
      },
      {
        path: "/free-handloom-weaving-invoice.html",
        element: <HandloomWeavinginvoice />,
      },
      {
        path: "/free-honey-gathering-invoice.html",
        element: <HoneyGatheringInvoice />,
      },
      {
        path: "/free-pickle-making-invoice.html",
        element: <PickleMakingInvoice />,
      },
      {
        path: "/free-packing-packaging-invoice.html",
        element: <PackingPackagingInvoice />,
      },
      {
        path: "/free-printing-press-work-invoice.html",
        element: <PrintingPressWorkInvoice />,
      },
      {
        path: "/free-sand-mining-invoice.html",
        element: <SandMiningInvoice />,
      },
      {
        path: "/free-laundry-work-invoice.html",
        element: <LaundryWorkInvoice />,
      },




    ],
  },
  {
    path: "/app",
    element: <AuthenticatedRoutes />,
    children: [
      {
        path: "home",
        element: <DashboardHome />,
      },
      {
        path: "clients",
        element: <ClientsTab />,
      },
      {
        path: "client",
        element: <ClientPage />,
      },
      {
        path: "client/:id",
        element: <ClientPage />,
      },
      {
        path: "vendors",
        element: <VendorTab />,
      },
      {
        path: "invoice",
        element: <InvoicePage />,
      },
      {
        path: "invoice/:id",
        element: <InvoicePage />,
      },
      {
        path: "product",
        element: <ProductTab />,
      },
      {
        path: "products",
        element: <ProductPage />,
      },
      {
        path: "products/:id",
        element: <ProductPage />,
      },
      {
        path: "company",
        element: <CompanyPage />,
      },
      {
        path: "purchase",
        element: <PurchaseTableNew />,
      },
      {
        path: "purchaseReturn",
        element: <PurchaseReturnTab />,
      },
      {
        path: "purchaseReturnInvoice",
        element: <PurchaseReturn />,
      },
      {
        path: "purchaseReturnInvoice/:id",
        element: <PurchaseReturn />,
      },
      {
        path: "purchasePage",
        element: <PurchasePage />,
      },
      {
        path: "purchasePage/:id",
        element: <PurchasePage />,
      },
      {
        path: "sales",
        element: <SalesTabe />,
      },
      {
        path: "salesReturnTab",
        element: <SalesReturnTab />,
      },
      {
        path: "salesreturn",
        element: <SalesReturn />,
      },
      {
        path: "salesreturn/:id",
        element: <SalesReturn />,
      },
      {
        path: "quotationPage",
        element: <Quotation />,
      },
      {
        path: "quotationPage/:id",
        element: <Quotation />,
      },
      {
        path: "deliveryChallan",
        element: <DeliveryChallanPage />,
      },
      {
        path: "deliveryChallan/:id",
        element: <DeliveryChallanPage />,
      },
      {
        path: "deliveryChallanTab",
        element: <DeliveryChallanTable />,
      },
      {
        path: "stock",
        element: <Stock />,
      },
      {
        path: "bankReports",
        element: <BankTab/>,
      },
      {
        path: "bankpage",
        element: <BankPage/>,
      },
      {
        path: "bankpage/:id",
        element: <BankPage/>,
      },
      {
        path: "upiPaymentReport",
        element: <UPIPaymentTab />,
      },
      {
        path: "upiPayment",
        element: <UPIPaymentPage />,
      },
      {
        path: "upiPayment/:id",
        element: <UPIPaymentPage />,
      },
      
      {
        path: "cashInHand",
        element: <CashInHandTab />,
      },
      {
        path: "cheques",
        element: <Cheques />,
      },
      {
        path: "fixedAssets",
        element: <FixedAssets />,
      },
      {
        path: "cashInHandPage",
        element: <CashInHand />,
      },
      {
        path: "cashInHandPage/:id",
        element: <CashInHand />,
      },
      {
        path: "payments",
        element: <PaymentReceiveTab />,
      },
      {
        path: "paymentsReceive",
        element: <PaymentReceivePage />,
      },
      {
        path: "paymentsReceive/:id",
        element: <PaymentReceivePage />,
      },
      {
        path: "service",
        element: <ServiceTab />,
      },
      {
        path: "services",
        element: <ServiceSettings />,
      },
      {
        path: "services/:id",
        element: <ServiceSettings />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "expenseCategoryReport",
        element: <ExpenseCategoryTab />,
      },
      {
        path: "expenseCategory",
        element: <ExpenseCategory />,
      },
      {
        path: "expenseCategory/:id",
        element: <ExpenseCategory />,
      },
      {
        path: "expensePage",
        element: <ExpensesPage />,
      },
      {
        path: "expenseItem",
        element: <ExpenseItem />,
      },
	  
	  {
        path: "expenseItem/:id",
        element: <ExpenseItem />,
      },
      {
        path: "expenseItemReport",
        element: <ExpenseItemReport/>,
      },
      {
        path: "fixedassetsReport",
        element: <FixedAssetsReport />,
      },
      {
        path: "fixedassets",
        element: <FixedAssets />,
      },
      {
        path: "fixedassets/:id",
        element: <FixedAssets />,
      },
      {
        path: "purchaseFixedAsset",
        element: <PurchaseFixedAssetReport />,
      },
      {
        path: "purchaseFixedAssetentry",
        element: <PurchaseFixedAssetPage />,
      },
      {
        path: "purchaseFixedAssetentry/:id",
        element: <PurchaseFixedAssetPage />,
      },
      {
        path: "expensePage/:id",
        element: <ExpensesPage />,
      },
      {
        path: "expensePageTable",
        element: <ExpensePageTable />,
      },
      {
        path: "serviceSettings",
        element: <ServiceSettingsPage />,
      },
      {
        path: "signature",
        element: <SignaturePage />,
      },
      {
        path: "appSetting",
        element: <AppSettingPage />,
      },
      {
        path: "paymentsOut",
        element: <PaymentOutTab />,
      },
      {
        path: "paymentOutPage",
        element: <PaymentOutPage />,
      },
      {
        path: "paymentOutPage/:id",
        element: <PaymentOutPage />,
      },
      {
        path: "salesFixedAssetTab",
        element: <SalesFixedAssetTab />,
      },
      {
        path: "salesFixedAssetPage",
        element: <SalesFixedAssetPage />,
      },
      {
        path: "SalesFixedAssetPage/:id",
        element: <SalesFixedAssetPage />,
      },
      {
        path: "pos",
        element: <Pos/>,
      },
      {
        path: "pos/:id",
        element: <Pos/>,
      },
      {
        path: "barcode",
        element: <BarCode/>,
      },
      {
        path: "barcode/:id",
        element: <BarCode/>,
      },
      
      
      
      {
        path: "feedback",
        // element: <FeedbackPage />,
        element: (
          <ProtectedRoute
            path="feedback"
            element={<FeedbackPage />}
            requiredRole="Admin"
          />
        ),
      },
      {
        path: "quotation",
        element: <QuotationTableReport />
      },
      {
        path: "notification",
        element: <NotificationPage />,
      },
      {
        path: "Users",
        element: <User />,
      },
      {
        path: "AdminPayment",
        element: <AdminPayments />,
      },
      {
        path: "ExternalInvoice",
        element: <ExternalInvoice />,
      },
      {
        path: "Contact",
        element: <Contact />,
      },
      {
        path: "FeedbackSA",
        element: <FeedbackSA/>
      },

      {
        path: "AdminDashboard",
        element: <AdminDashboard />,
      },
      {
        path: "Units",
        element: <Unit />,
      },
      {
        path: "adduser",
        element: <UserDetails />,
      },
      {
        path: "unauthorized",
        element: <Unauthorized />,
      },
      {
        path: "usersetting",
        element: <UserSettingsTab />,
      },
      
    ],
  },
]);
export default routes;