import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NoteGet } from '../../redux/actions/notesAction';

const NoteModel = ({ closeModal, handleSelect }: any) => {
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [animate, setAnimate] = useState(true);
  const dispatch = useDispatch();

  const [noteList, setNoteList] = useState(null as any);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F1F7F6",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F1F1F1",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const fetchData = () => {
    dispatch(NoteGet(token?._id) as any)
      .then((res: any) => {
        if (res) {
          setNoteList(res.payload);
        }
      })
      .catch((error: any) => {
        console.error("Error fetching terms:", error);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: animate ? 1 : 0,
      }}
      transition={{
        type: "spring",
        damping: 30,
        duration: 0.2,
      }}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75">
        <motion.div className="relative rounded-lg bg-[white] overflow-hidden text-center shadow-xl transform transition-all my-8 w-11/12 mx-auto border border-[#008065]">
          <div className='items-start justify-between p-2 border-solid border-slate-200 rounded-t bg-[#008065]'>
            <div className='flex p-1 border-solid border-slate-200 rounded-b justify-between'>
              <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Existing Notes</h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={closeModal}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
            </div>
          </div>
          <TableContainer
            sx={{ height: "70vh", overflowX: "auto", scrollbarWidth: "thin" }}
          >
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" , whiteSpace: "nowrap"}}
                  >
                    S No
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" , whiteSpace: "nowrap"}}
                  >
                    Notes
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065", whiteSpace: "nowrap" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {noteList?.map((item: any, rowIndex: any) => (
                  <StyledTableRow key={rowIndex}>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "14px", fontWeight: 600 }}
                    >
                      {rowIndex + 1}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        maxWidth: 400,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {item.notes}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "14px", fontWeight: 600 }}
                    >
                      <button
                        onClick={() => handleSelect(item)}
                        className="text-white  bg-[#00A787]  px-2 py-1 rounded-md"
                      >
                        Select
                      </button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="bg-[white] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default NoteModel

