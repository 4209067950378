import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useData } from "../../layouts/shared/DataProvider";
import { fontFamilyGet } from "../../redux/actions/FontFamilyAction";
import { InternalGetCount, paymentPendingBillList } from "../../redux/actions/InternalInvoiceAction";
import { currencyGet } from "../../redux/actions/currencyAction";
import {
    paymentReceiveEditFindOne,
    paymentReceivePost,
    paymentReceiveUpdate
} from "../../redux/actions/paymentReceiveAction";

const PaymentReceivePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [invoiceDate, setInvoiceDate] = useState(new Date() as any);
    const formatDate = moment(invoiceDate).format("DD/MM/YYYY");




    const [pendingInvoiceList, setPendingInvoiceList] = useState([] as any);
    const [clientName, setclientName] = useState(null as any);

    const [invoiceNo, setinvoiceNo] = useState(null as any);
    const [pendinginvoiceNo, setPendingInvoiceNo] = useState(null as any);
    const [selectedOption, setselectedOption] = useState("");
    const [cashAmount, setcashAmount] = useState(0);
    const [chequeAmount, setchequeAmount] = useState(0);
    const { selectedFont, setSelectedFont }: any = useData();
    const [paymentInReceiptNo, setPaymentInReceiptNo] = useState("");
    const usersignin = useSelector((state: any) => state?.userSignin);
    const token = usersignin?.userInfo?.token;


    const { userInfo } = usersignin;
    const userid = userInfo.token._id;
    const params = useParams();
    const { id } = params;

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        getValues,
        setError,
        formState: { errors },
        clearErrors,
    }
        = useForm({
            defaultValues: {
                billModule: "PRODUCT",
                clientName: "",
                invoiceNO: "",
                balanceAmount: "",
                paymentType: "",
                chequeNum: "",
                receivedAmount: "",
                user_id: userid,

            },
        });


    useEffect(() => {
        dispatch(fontFamilyGet(token?._id) as any).then((res: any) => {
            if (res?.type === "FONTFAMILY_GET_SUCCESS") {
                let data = res?.payload?.find(
                    (value: any) => value?.defaultInfo === true,
                );
                if (data) {
                    setSelectedFont(data?.fontfamily);
                } else {
                    setSelectedFont("poppins");
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // const validateReceivedAmount = () => {
    //     const balanceAmount = parseFloat(getValues("balanceAmount") || "0");
    //     const receivedAmount = parseFloat(getValues("receivedAmount") || "0");

    //     if (receivedAmount > balanceAmount) {
    //         setCustomErrors((prev) => ({
    //             ...prev,
    //             receivedAmount: "Received amount exceeds balance",
    //         }));
    //         return false;
    //     } else {
    //         setCustomErrors((prev) => ({ ...prev, receivedAmount: "" })); // Clear the error
    //         return true;
    //     }
    // };
    const handleReceivedAmountChange = (e: any) => {
        const value = e.target.value.replace(/[^0-9.]/g, "");
        const balanceAmount = parseFloat(getValues("balanceAmount"));

        if (value === "") {
            setError("receivedAmount", {
                type: "manual",
                message: "Amount payable is required",
            });
        } else if (parseFloat(value) > balanceAmount) {
            setError("receivedAmount", {
                type: "manual",
                message: "Received amount exceeds balance",
            });
        }
        else {
            clearErrors("receivedAmount");
        }
        e.target.value = value;
    };
    const [currencySymbol, setcurrencySymbol] = useState("₹");
    useEffect(() => {

        dispatch(currencyGet(token?._id) as any).then((res: any) => {
            if (res) {
                let data = res.payload.find((value: any) => value.defaultInfo === true);
                if (data?.currencySymbol) {
                    setcurrencySymbol(data.currencySymbol);
                } else {
                    setcurrencySymbol("₹");
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const uniqueClients = Array?.from(
        new Set(pendingInvoiceList?.map((item: any) => item.clientName))
    ).map((name: any) => {
        return pendingInvoiceList?.find((item: any) => item.clientName === name);
    });
    const uniqueInvoiceNo = pendingInvoiceList?.filter(
        (value: any) => value?.invoiceNo === invoiceNo

    );




    const fetchData = () => {
        dispatch(paymentPendingBillList() as any).then((res: any) => {
            if (res) {
                setPendingInvoiceList(res.payload);
            }
        });
        dispatch(InternalGetCount() as any).then((data: any) => {
            setPaymentInReceiptNo(data?.payload?.paymentInReceiptCount)
        });
    };

    useEffect(() => {
        if (id) {
            const fetchDataAsync = async () => {
                try {
                    if (id) {
                        const response = await dispatch(paymentReceiveEditFindOne(id) as any);
                        const clientName = response.payload.client_name;
                        setclientName(clientName);
                        setValue('clientName', response.payload.client_name);
                        setValue('invoiceNO', response.payload.invoiceNo);
                        setPendingInvoiceNo([{ invoiceNo: response.payload?.invoiceNo }])
                        setValue('balanceAmount', response.payload.balanceAmount);
                        setValue('receivedAmount', response.payload.receivedAmount);
                        setValue('paymentType', response.payload.paymentType);
                        setselectedOption(response.payload.paymentType)
                        setValue("chequeNum", response?.payload?.chequeNum);


                        // const uniqueInvoiceNo = [
                        //     { balanceAmount: response.payload.previousBalanceAmount }
                        // ];
                        // // let balance = uniqueInvoiceNo?.[0]?.balanceAmount;
                        // // // setBalanceAmount(balance)
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle errors as needed
                }
            };
            fetchDataAsync();
        } else {
            fetchData();
        }


        // Call the async function
    }, [id, dispatch, setValue]);

    const handleCancelClick = () => {
        let defaultValue = {
            clientName: "",
            invoiceNO: "",
            balanceAmount: "",
            receivedAmount: "",
            paymentType: "",
        }
        reset({ ...defaultValue });
    }

    const handleClientDropdownChange = (value: any) => {
        setclientName(value);
        setinvoiceNo("");
        const clientValue = pendingInvoiceList.filter((item: any) => item.clientName === value)
        setPendingInvoiceNo(clientValue);
        setValue('balanceAmount', "")
        setValue('invoiceNO', "")

    };
    const handleInivoiceNo = (e: any) => {
        const balanceValue = pendingInvoiceList.find((item: any) => item.invoiceNo === e)
        setinvoiceNo(e);
        setValue('balanceAmount', balanceValue.balanceAmount)
        // let balance = uniqueInvoiceNo?.[0]?.balanceAmount
        // // setBalanceAmount(balance)

    };
    const handlePaymentType = (value: any) => {
        setselectedOption(value);
        setValue('chequeNum', "")
    };


    const onSubmit = (data: any) => {
        // const isReceivedAmountValid = validateReceivedAmount();
        const paymentType = data.paymentType;
        if (paymentType === 'CASH') {
            setcashAmount(data.receivedAmount)
            setchequeAmount(0)
        }
        else {
            setchequeAmount(data.receivedAmount)
            setcashAmount(0)
        }
        if (id) {

            let postupdatedata = {
                //balanceAmount: previousBalanceAmount - data.receivedAmount,
                chequeNum: data.chequeNum,
                paymentType: data.paymentType,
                receivedAmount: data.receivedAmount,
                invoicedate: data.invoicedate,
                cashAmount: cashAmount,
                chequeAmount: chequeAmount,
                creditAmount: 0,
                invoiceNO: data.invoiceNO,
                user_id: userid,
            };
            dispatch(paymentReceiveUpdate(id, postupdatedata) as any).then((res: any) => {
                if (id) {
                    const queryParams = new URLSearchParams();

                    queryParams.append("param6", selectedFont);
                    queryParams.append("param7", currencySymbol);

                    const url = `/api/paymentreceipt_pdf/${id}/${userid}?${queryParams.toString()}`;

                    fetch(url, {
                        method: "GET",
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            return response.blob();
                        })
                        .then((blob) => {
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link: any = document.createElement("a");
                            link.href = url;
                            const formattedDate = moment().format("YYYY-MM-DD");
                            link.setAttribute(
                                "download",
                                `paymentreceipt_${formattedDate}.pdf`,
                            );
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                            //setProductError(false);
                            //setInvoiceUpdateId(response?.payload?.invoice_id);
                        })
                        .catch((error) => console.error("Error downloading PDF:", error));
                }
                if (res) {
                    fetchData();
                    toast.success("Payment Update Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    navigate("/app/payments");

                }
            });
        }
        else {

            let postdata = {
                billModule: data.billModule,
                paymentInReceiptNo: paymentInReceiptNo,
                clientName: data.clientName,
                invoicedate: formatDate,
                previousReceivedAmount: uniqueInvoiceNo?.[0]?.receivedAmount,
                previousBalanceAmount: data.balanceAmount,
                balanceAmount: data.balanceAmount - data.receivedAmount,
                // billAmount: uniqueInvoiceNo?.[0]?.toatalAmount,
                chequeNum: data.chequeNum,
                invoiceNO: data.invoiceNO,
                cashAmount: cashAmount,
                chequeAmount: chequeAmount,
                creditAmount: 0,
                paymentType: data.paymentType,
                receivedAmount: data.receivedAmount,
                user_id: userid,
            };
            dispatch(paymentReceivePost(postdata) as any).then((res: any) => {
                if (res?.payload?.paymentreceive_id) {
                    const queryParams = new URLSearchParams();

                    queryParams.append("param6", selectedFont);
                    queryParams.append("param7", currencySymbol);

                    const url = `/api/paymentreceipt_pdf/${res?.payload?.paymentreceive_id}/${userid}?${queryParams.toString()}`;

                    fetch(url, {
                        method: "GET",
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            return response.blob();
                        })
                        .then((blob) => {
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link: any = document.createElement("a");
                            link.href = url;
                            const formattedDate = moment().format("YYYY-MM-DD");
                            link.setAttribute(
                                "download",
                                `paymentreceipt_${formattedDate}.pdf`,
                            );
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                            //setProductError(false);
                            //setInvoiceUpdateId(response?.payload?.invoice_id);
                        })
                        .catch((error) => console.error("Error downloading PDF:", error));
                }
                if (res) {
                    fetchData();
                    toast.success("Payment Saved Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    navigate("/app/payments");
                }
            });
        }
    };

    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-[#E1E8E7] pr-5 md:pr-5  lg:pr-0  xl:pr-0" style={{ fontFamily: "poppins" }}>
                        <div className="p-4 text-2xl font-bold text-[#045545]  text-lg   ml-2">
                            Payment In
                        </div>
                        <fieldset className="mx-3 rounded-lg">
                            <legend className="p-4  ml-4 font-bold text-[#008065] ">
                                Payment
                            </legend>
                            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:pl-4 xl:pl-4 px-8 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-4">
                                <div className="flex flex-col mb-2 w-full xl:pl-4">

                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span>Bill Module</span>
                                    </label>
                                    <select
                                        id="billmodule"
                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            borderColor: errors.billModule
                                                ? "#FF0000"
                                                : "#008065",
                                        }}
                                        {...register("billModule", {
                                            required: "Bill Module is required",
                                        })}
                                    >
                                        <option value="PRODUCT">PRODUCT</option>
                                    </select>
                                    {errors.billModule && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.billModule.message}</p>}
                                </div>
                                <div className="flex flex-col mb-2 w-full xl:pl-4">

                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span>Clients Name</span>
                                    </label>
                                    <select
                                        id="clientsName"
                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            borderColor: errors.clientName
                                                ? "#FF0000"
                                                : "#008065",
                                        }}


                                        value={clientName ? clientName : ""}
                                        {...register("clientName", {
                                            onChange: (e) =>
                                                handleClientDropdownChange(e.target.value),
                                            required: "Client Name is required",
                                        })}
                                    >
                                        <option value={id ? clientName : ""} >{id ? clientName : "Select Client Name"}</option>

                                        {uniqueClients?.map((item, i) => (
                                            < option
                                                className="w-full"
                                                key={i}
                                                value={item?.clientName}
                                            >
                                                {item?.clientName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.clientName && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.clientName.message}</p>}
                                </div>
                                <div className="flex flex-col mb-2 w-full xl:pl-4">
                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span>Invoice No</span>
                                    </label>
                                    <select
                                        id="invoiceNO"
                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            borderColor: errors.invoiceNO
                                                ? "#FF0000"
                                                : "#008065",
                                        }}
                                        {...register("invoiceNO", {
                                            onChange: (e) =>
                                                handleInivoiceNo(e.target.value),
                                            required: "Invoice No is required",
                                        })}
                                    >
                                        <option disabled hidden value="">Select Invoice No</option>
                                        {pendinginvoiceNo?.map((item: any, i: any) => (
                                            < option className="w-full" key={i} value={item.invoiceNo} >
                                                {item.invoiceNo}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.invoiceNO && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.invoiceNO.message}</p>}
                                </div>
                                <div className="flex flex-col mb-2 w-full xl:pl-4">
                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                            paddingTop: "2px"
                                        }}
                                    >
                                        Balance Amount
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="number"
                                            className={
                                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                            }
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,

                                            }}
                                            // value={id ? uniqueInvoiceNo?.[0]?.balanceAmount : uniqueInvoiceNo?.[0]?.balanceAmount}
                                            {...register("balanceAmount")}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col mb-2 w-full xl:pl-4">
                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span> Amount Received</span>
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className={
                                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                            }
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: errors.receivedAmount
                                                    ? "#FF0000"
                                                    : "#008065",
                                            }}
                                            {...register("receivedAmount", {
                                                required: "Amount payable is required", // Required rule
                                                validate: {
                                                    isNotEmpty: (value) => value.trim() !== "" || "Amount payable is required", // Ensure non-empty
                                                    isLessThanBalance: (value) =>
                                                        parseFloat(value) <= parseFloat(getValues("balanceAmount") || "0") ||
                                                        "Received amount exceeds balance", // Compare with balance amount
                                                },
                                                onChange: handleReceivedAmountChange, // Handle numeric-only input
                                            })}
                                        />
                                        {errors.receivedAmount && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.receivedAmount.message}</p>}
                                    </div>
                                </div>

                                <div className="flex flex-col mb-2 w-full xl:pl-4">
                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span> Payment Type  </span>
                                    </label>
                                    <select
                                        id="paymentType"
                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "13px",
                                            fontWeight: 400,
                                            borderColor: errors.paymentType
                                                ? "#FF0000"
                                                : "#008065",
                                        }}
                                        {...register("paymentType", {
                                            onChange: (e) =>
                                                handlePaymentType(e.target.value),
                                            required: "Select The Payment Type",
                                        })}

                                    >
                                        <option disabled hidden value="">Select Payment Type</option>
                                        <option value="CASH">Cash</option>
                                        <option value="CHEQUE">Cheque</option>
                                    </select>
                                    {errors.paymentType && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.paymentType.message}</p>}
                                </div>
                                {selectedOption === "CHEQUE" && (
                                    <div className="flex flex-col mb-2 w-full xl:pl-4">
                                        <label
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                                color: "#008065",
                                                paddingTop: "2px"
                                            }}
                                        >
                                            Cheque No
                                        </label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                className={
                                                    "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                                }
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    borderColor: errors.chequeNum
                                                        ? "#FF0000"
                                                        : "#008065",
                                                }}
                                                // {...register("chequeNum", {
                                                //     required: "Check Number is Required",
                                                // })}
                                                {...register("chequeNum", {
                                                    onChange: (e) => {
                                                        const value = e.target.value.replace(/[^0-9]/g, "");
                                                        e.target.value = value;

                                                        // if (value.length === 6) {
                                                        //     clearErrors("chequeNum");
                                                        // } else {
                                                        //     setError("chequeNum", {
                                                        //         type: "manual",
                                                        //         message: "Cheque number must be 6 digits",
                                                        //     });
                                                        // }
                                                    },
                                                    required: "Cheque number is required",
                                                })}

                                            />
                                            {errors.chequeNum && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.chequeNum.message}</p>}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row gap-0 md:lg:pl-4 lg:pl-4 xl:pl-4 sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-4">
                                <div className="flex flex-col mb-2 w-48 md:w-1/4 md:pr-2 lg:pr-2  lg:w-1/4 xl:w-1/4 self-center sm:self-auto">
                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span> Amount Received</span>
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className={
                                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full lg:w-11/12"
                                            }
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: errors.receivedAmount
                                                    ? "#FF0000"
                                                    : "#008065",
                                            }}
                                            {...register("receivedAmount", {
                                                required: "Amount payable is required", // Required rule
                                                validate: {
                                                    isNotEmpty: (value) => value.trim() !== "" || "Amount payable is required", // Ensure non-empty
                                                    isLessThanBalance: (value) =>
                                                        parseFloat(value) <= parseFloat(getValues("balanceAmount") || "0") ||
                                                        "Received amount exceeds balance", // Compare with balance amount
                                                },
                                                onChange: handleReceivedAmountChange, // Handle numeric-only input
                                            })}
                                        />
                                        {errors.receivedAmount && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.receivedAmount.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-col mb-2 w-48 md:w-1/4 md:pr-2 lg:pr-0 xl:pr-0 lg:pl-2 xl:pl-2 lg:w-1/4 xl:w-1/4 self-center sm:self-auto">

                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span> Payment Type  </span>
                                    </label>
                                    <select
                                        id="paymentType"
                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full lg:w-11/12"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "13px",
                                            fontWeight: 400,
                                            borderColor: errors.paymentType
                                                ? "#FF0000"
                                                : "#008065",
                                        }}
                                        {...register("paymentType", {
                                            onChange: (e) =>
                                                handlePaymentType(e.target.value),
                                            required: "Select The Payment Type",
                                        })}

                                    >
                                        <option disabled hidden value="">Select Payment Type</option>
                                        <option value="CASH">Cash</option>
                                        <option value="CHEQUE">Cheque</option>
                                    </select>
                                    {errors.paymentType && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.paymentType.message}</p>}
                                </div>
                                {selectedOption === "CHEQUE" && (
                                    <div className="flex flex-col md:pr-2 lg:pr-0 xl:pr-0 mb-2 w-48 md:w-1/4 pl-0 lg:pl-2 lg:w-1/4 xl:w-1/4 self-center sm:self-auto">
                                        <label
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                                color: "#008065",
                                            }}
                                        >
                                            Cheque No
                                        </label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                className={
                                                    "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full lg:w-11/12"
                                                }
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    borderColor: errors.chequeNum
                                                        ? "#FF0000"
                                                        : "#008065",
                                                }}
                                                // {...register("chequeNum", {
                                                //     required: "Check Number is Required",
                                                // })}
                                                {...register("chequeNum", {
                                                    onChange: (e) => {
                                                        const value = e.target.value.replace(/[^0-9]/g, "");
                                                        e.target.value = value;
                                                    },
                                                    required: "Check Number is Required",
                                                })}

                                            />
                                            {errors.chequeNum && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.chequeNum.message}</p>}
                                        </div>
                                    </div>
                                )}
                            </div> */}
                            {/* <-Submit cancel-> */}

                            <div className="lg:flex md:flex justify-end mb-3">
                                <div className="flex justify-end w-full flex-col sm:flex-row pl-4">
                                    <div className="pr-4 self-center sm:self-auto">
                                        <button
                                            type="button"
                                            className="rounded-xl mt-3 font-bold text-white  text-sm h-8 lg:w-[10rem] md:w-[10rem] sm:w-[10rem] w-48 bg-[#008065] "
                                            onClick={() => handleCancelClick()}
                                            style={{ fontFamily: "poppins", fontSize: "14px" }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="pr-4 self-center sm:self-auto">
                                        <button
                                            type="submit"
                                            className="rounded-xl mt-3 font-bold text-white hover:cursor-pointer text-sm h-8 lg:w-[10rem] md:w-[10rem] sm:w-[10rem] w-48 bg-[#008065] "
                                            style={{ fontFamily: "poppins", fontSize: "14px" }}
                                        >
                                            {"Save"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                </form >
            </div >

        </>
    );
};

export default PaymentReceivePage;
