import { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import AppTab from "./AppTab";
import ColourSettingPage from "./ColourSettingPage";
import CountryPage from "./CountryPage";
import FontDropdown from "./fontFamilylist";
import Template from "./InvoiceDetails/Template";
import SignaturePage from "./SignaturePage";
import { TableSettings } from "./TableSettings";

import InvoiceSettings from "./InvoiceDetails/Invoice_setting";
import InvoiceTypes from "./InvoiceTypes";


const AppSettingPage = () => {
  const [openTab, setOpenTab] = useState(1);


//scroll click
   const [isOverflowing, setIsOverflowing] = useState(false);
   const carouselRef = useRef<HTMLUListElement>(null);

   const checkOverflow = () => {
    if (carouselRef.current) {
      const { scrollWidth, clientWidth } = carouselRef.current;
      setIsOverflowing(scrollWidth > clientWidth);
    }
    };

    useEffect(() => {
    // Check overflow on mount and resize
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
    }, []);
  
  const scrollLeft = () => {
  if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };


  return (
    <div>
      <div className="bg-[#E1E8E7] h-full  ">
        <div className="relative w-full">

          {isOverflowing && (
                <button
                  onClick={scrollLeft}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-300 text-[#008065] p-2 rounded-full z-10"
                >
                  <FaChevronLeft />
                </button>
             )}

          <ul ref={carouselRef} className="flex overflow-x-auto px-6 whitespace-nowrap text-sm font-medium text-center text-[#008065]  dark:text-gray-400 ">
           
          <style>{`
    
                ul::-webkit-scrollbar {
                height: 4px;
                width: 6px; 
	              display: none;
	              transition: visibility 0.3s ease;
                }

                ul::-webkit-scrollbar-thumb {
	              background-color: rgba(24, 120, 99, 0.5);
              border-radius: 6px; 
                }
                ul::-webkit-scrollbar-track {
                background: #F5F5F5; 
                   }

              `}</style>
           
           
           
            <li className="mr-1">
              <button
                style={{
                  borderBottom: openTab === 1 ? "2px solid #008065" : "",
                }}
                onClick={() => setOpenTab(1)}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Tax Settings
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(2)}
                style={{
                  borderBottom: openTab === 2 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Currency
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(3)}
                style={{
                  borderBottom: openTab === 3 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Signature
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(4)}
                style={{
                  borderBottom: openTab === 4 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Table Settings
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(5)}
                style={{
                  borderBottom: openTab === 5 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Invoice Settings
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(6)}
                style={{
                  borderBottom: openTab === 6 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Colour Settings
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(7)}
                style={{
                  borderBottom: openTab === 7 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Font Family
              </button>
            </li>
           
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(9)}
                style={{
                  borderBottom: openTab === 9 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Invoice Types

              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(10)}
                style={{
                  borderBottom: openTab === 10 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Invoice Templates
              </button>
            </li>
          </ul>

              {isOverflowing && (
                  <button
                    onClick={scrollRight}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-300 text-[#008065] p-2 rounded-full z-10"
                  >
                    <FaChevronRight />
                  </button>
              )}

        </div>
        <div className="mx-0 md:mx-2 my-3 ">
          {openTab === 1 && <AppTab />}
          {openTab === 2 && <CountryPage />}
          {openTab === 3 && <SignaturePage />}
          {openTab === 4 && <TableSettings />}
          {openTab === 5 && <InvoiceSettings />}
          {openTab === 6 && <ColourSettingPage />}
          {openTab === 7 && <FontDropdown />}
          {/* {openTab === 8 && <TaxSettings />} */}
          {openTab === 9 && <InvoiceTypes />}
          {openTab === 10 && <Template />}
        </div>
      </div>
    </div>
  );
};

export default AppSettingPage;
