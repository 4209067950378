import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { toast } from "react-toastify";
import ConfirmationPopup from "../commen/ConfirmationPopup";
import { FooterNoteGet, FooterNoteSave, FooternoteDelete, FooternotesUpdate } from '../../redux/actions/footernotesAction';
import FooterNotesTable from '../../Tables/FooterNotesTable';

const FooterNotesPage = () => {
    const dispatch = useDispatch();
    const usersignin = useSelector((state: any) => state.userSignin);
    const {
        userInfo: { token },
    } = usersignin;
    const [formData, setFormData] = useState({
        footernotes: "",
        user_id: token?._id,
        defaultFooterNotesInfo: false
    });
    const [errors, setErrors] = useState({} as any);
    const [footernotesList, setFooterNotesList] = useState([] as any);
    const [footernoteid, setFooterNoteid] = useState(null as any);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, defaultFooterNotesInfo: e.target.checked });
    };

    const [footernotesPageErrors, setFooterNotesPageErrors] = useState<{
        footernotes?: string;
    }>({});

    const validateProdForm = () => {
        if (formData.footernotes.trim() === "") {
            setFooterNotesPageErrors({
                ...footernotesPageErrors,
                footernotes: "FooterNotes is  Required.",
            });
        }
    };


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFooterNotesPageErrors({
            ...footernotesPageErrors,
            [e.target.name]: "",
        });
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({
            ...errors,
            [e.target.name]: "",
        });
    };

    const handleCancelClick = () => {
        setFooterNotesPageErrors({
            ...footernotesPageErrors,
            footernotes: "",
        });
        resetProduct();
        setFooterNoteid(null);
    };

    const fetchData = () => {
        dispatch(FooterNoteGet(token?._id) as any).then((res: any) => {
            if (res) {
                setFooterNotesList(res.payload);
            }
        });
    };

    const resetProduct = () => {
        setFormData({
            footernotes: "",
            user_id: token?._id,
            defaultFooterNotesInfo: false
        });
    };



    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        const trimmedFormData = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value,]));

        if (trimmedFormData.footernotes) {
            if (footernoteid) {
                dispatch(FooternotesUpdate(footernoteid, trimmedFormData) as any).then((res: any) => {
                    if (res) {
                        resetProduct();
                        fetchData();
                        setFooterNoteid(null);
                    }
                    toast.success("Update SuccessFully !", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                });
            }
            else {
                dispatch(FooterNoteSave(trimmedFormData) as any).then((res: any) => {
                    if (res) {
                        resetProduct();
                        fetchData();
                    }
                    toast.success("Saved SuccessFully !", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                });
            }
        }
        else {
            validateProdForm();
        }
    };

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEdit = (data: any) => {
        setFooterNotesPageErrors({
            ...footernotesPageErrors,
            footernotes: "",
        });
        setFormData({
            footernotes: data.footernotes,
            user_id: token?._id,
            defaultFooterNotesInfo: data.defaultFooterNotesInfo

        });
        setFooterNoteid(data._id?.$oid);
    };


    const onDelete = (data: any) => {
        setShowConfirmation(true);
        setFooterNoteid(data._id?.$oid);
    };



    const handleConfirm = () => {
        dispatch(FooternoteDelete(footernoteid) as any).then((res: any) => {
            if (res) {
                fetchData();
                setFooterNoteid(null);
                setShowConfirmation(false);
                resetProduct();
            }
        });
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };
    return (
        <div>
            <fieldset className="mx-3 mb-4 rounded-lg">
                <legend className="p-4  font-bold text-[#045545]  text-lg      ml-2">
                    Footer Notes
                </legend>
                <div className="rounded-xl px-2 ">
                    <div className="flex ml-2 gap-2">
                        <div>
                            <input
                                type="checkbox"
                                name="defaultFooterNotesInfo"
                                placeholder="defaultFooterNotesInfo"
                                checked={formData.defaultFooterNotesInfo}
                                onChange={handleCheckboxChange}
                                className={
                                    "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                                }
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                }}
                            />
                        </div>
                        <div className="text-[#008065] font-semibold">
                            <label>Default Footer Notes Info</label>
                        </div>
                    </div>
                </div>
                <div className=" rounded-xl px-3  mx-2">
                    <div>
                        <label className="block mb-2 text-sm font-semibold text-[#008065] mt-2">
                            Footer Notes:
                        </label>
                        <textarea
                            name="footernotes"
                            id="message"
                            rows={4}
                            className={`block p-2.5 w-full mx-1 text-sm border-2 focus:outline-none rounded border-[#008065] border-solid${footernotesPageErrors.footernotes
                                ? " border-2  border-[red] w-full"
                                : ""
                                }`}
                            placeholder="Ex : This Is Computer Generated Invoice."
                            onChange={(e: any) => handleChange(e)}
                            value={formData.footernotes ? formData.footernotes : ""}
                        >
                            {/* {text.length > 0 && text.split("\n").map((l, i) => <p key={i}>{l}</p>)} */}
                        </textarea>
                        {footernotesPageErrors.footernotes && (
                            <span
                                style={{
                                    fontFamily: "poppins",
                                    fontWeight: 700,
                                    color: "#ff0000",
                                }}
                                className="text-red-400 text-xs"
                            >
                                {footernotesPageErrors.footernotes}
                            </span>
                        )}
                    </div>
                    <div className="flex w-full flex-col py-1 sm:flex-row">
                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>

                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                            <div className="flex flex-col  ">
                                <div className="relative"></div>
                            </div>
                        </div>

                        <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                            <div className="flex flex-col w-40  rounded">
                                <button
                                    type="button"
                                    className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-sm h-8   block    bg-[#008065] "
                                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                                    onClick={handleCancelClick}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                            <div className="flex flex-col w-40 ">
                                <button
                                    onClick={(e: any) => handleSubmit(e)}
                                    className="rounded-xl font-bold text-white flex flex-row items-center bg-[#008065] justify-center  text-sm h-8 block  "
                                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                                >
                                    {footernoteid ? "Update" : "Save"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <FooterNotesTable
                footernotesList={footernotesList}
                edit={onEdit}
                deletefooternotes={onDelete}
            />
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    )
}

export default FooterNotesPage
